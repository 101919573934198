import React from "react";
import O2ActiveState from "./o2ActiveState";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardO2ActiveState = ({states}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator view={states && states.length}
                                    title={t('lsi.o2TreatmentActivates')}>
            <O2ActiveState states={states}/>
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardO2ActiveState);
