import React from 'react';
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";
import Heading from "../../../shared/custom/headings/heading/Heading";
import TitleH2 from "../../../shared/custom/headings/title/TitleH2";
import DiariesL from "./DiariesL";
import BtnDefault from "../../../shared/bootstrap/button/default/BtnDefault";

const DiaryTemplateL = ({
                            newNote,
                            setNewNote,
                            addNote,
                            diaries,
                            editNoteAction,
                            deleteFile,
                            uploadFileHandler,
                            subjectTypes,
                            downloadAction,
                            readonly,
                            diaryTemplate,
                            titleLabel = 'note.title',
                            actionLabel = 'note.action.new'
                        }: any) => {
    const {t} = useLanguageForShared()

    return (
        <>
            <Heading
                left={<TitleH2 title={t(titleLabel)}/>}
                rightTop={<>{!readonly && <BtnDefault className={'mb-2 text-capitalize'}
                                                      icon={'plus'}
                                                      onClick={() => setNewNote(true)}
                                                      title={t(actionLabel)}
                >
                    {t(actionLabel)}
                </BtnDefault>}</>}
            />
            <div className="row mt-3">
                <>
                    <DiariesL
                        downloadAction={downloadAction}
                        deleteFile={deleteFile}
                        readonly={readonly}
                        addNote={addNote}
                        diaries={diaries}
                        newNote={newNote}
                        setNewNote={setNewNote}
                        editNoteAction={editNoteAction}
                        uploadFileHandler={uploadFileHandler}
                        diaryTemplate={diaryTemplate}
                    />
                </>
            </div>
        </>
    );
};

export default DiaryTemplateL;
