export class MedicinalModel {
    constructor() {
        this.PatientId = "";
        this.Brand = "";
        this.Name = "";
        this.Description = "";
        this.Serial = "";
        this.LotNumber = "";
        this.DeliveryDate = "";
        this.PickUpDate = "";
    }
}