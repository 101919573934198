import axios from "axios";
import { path } from "../../utils/constant";

export function findCurrentServiceIndicators(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{currentServiceIndicators' + queryParams + '{districtname patientsActivating patientsActive patientsDisactivating provisionsNegative provisionsPositive  provisionsSuspended}}'
        });
}

export function findServiceIndicators(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{serviceIndicatorsHistoryReview(' + queryParams + '){pageInfo{endCursor hasNextPage}nodes{districtname year monthName monthNumber patientsActivating patientsActive patientsDisactivating provisionsNegative provisionsPositive provisionsSuspended}}}'
        });
}

export function findServiceIndicatorsChart(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{serviceIndicatorsHistoryGraph(' + queryParams + '){monthName monthNumber year patientsActive districtname patientsActivated patientsDisactivated}}'
        });
}

export function findServiceIndicatorsCostForTreatment(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{invoiceServiceIndicators(' + queryParams + '){year month cost description}}'
        });
}
export function findServiceIndicatorsStatus(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{currentServiceIndicators(' + queryParams + '){patientsActive patientsWithDeliveries patientsInAssistance}}'
        });
}
export function findServiceIndicatorsGraph(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{serviceIndicatorsGraph(' + queryParams + '){year month ordinary extraordinary}}'
        });
}
export function findServiceIndicatorsStatusService(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{statusServiceIndicators(' + queryParams + '){year month active activating inactive treatmentFamily}}'
        });
}
export function findServiceIndicatorsLox(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{loxServiceIndicators(' + queryParams + '){year month delivered prescribed}}'
        });
}

export function findServiceIndicatorsFilters(queryParams) {
    return axios.post(
        path,
        {
            query: 'query {serviceIndicatorsHistoryGraphFilters(' + queryParams + '){districts}}'
        });
}
