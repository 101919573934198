import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import BtnPrimary from '../../../../shared/bootstrap/button/primary/BtnPrimary'
import BtnSecondary from '../../../../shared/bootstrap/button/secondary/BtnSecondary'
import ModalCustom from '../../../../shared/bootstrap/modal/ModalCustom'
import {validationNewNoteV} from '../notes/formDataV'
import EventStepData from './steps/EventStepData'
import EventStepInstructions from './steps/EventStepInstructions'
import EventStepSummary from './steps/EventStepSummary'
import {FileDataModel} from '../../models/FileDataModel'
import {useForm} from "../../../../shared/utils/forms/useForm";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {subjectType} from "../../models/ClinicalDiaryItemModel";

function EventStoreV({
                        closeHandler,
                        addNote,
                        showNotifyComponentHandler,
                         subjectTypes
                    }: {
    closeHandler: () => void
    subjectTypes: any[]
    addNote: (data: any, callback: (data: any) => void) => void
    showNotifyComponentHandler: (data: any) => void
}) {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch()
    const [step, setStep] = useState<number>(0)

    const [files, setFiles] = useState<FileDataModel[] | undefined>(undefined)
    const [isAddFile, setIsAddFile] = useState(false)
    const validations = [{}, validationNewNoteV(t)]
    const [isSubmitStep, setIsSubmitStep] = useState(false)
    const [successStep, setSuccessStep] = useState<any | undefined>(undefined)

    const {
        dataForm,
        dataFormatted,
        errors,
        changeValue,
        isValid,
        setData,
    } = useForm(() => {
    }, validations[step], {
        Date: new Date().toISOString(),
    })
    const addFileHandler = (file: FileDataModel) => {
        setFiles([...(files || []), file])
    }
    const stepList: { component: React.ReactNode }[] = [
        {
            component: <EventStepInstructions/>,
        },
        {
            component: (
                <EventStepData
                    subjectTypes={subjectTypes}
                    dataFormatted={dataFormatted}
                    dataForm={dataForm}
                    changeValue={changeValue}
                    isSubmit={isSubmitStep}
                    errors={errors}
                />
            ),
        },
        {
            component: (
                <EventStepSummary
                    subjectTypes={subjectTypes}
                    selectEvent={dataFormatted}
                    addFileInListHandler={addFileHandler}
                    removeFileInListHandler={(file) =>
                        setFiles([
                            ...(files || []).filter(
                                (ele) => ele.file.path !== file.file.path
                            ),
                        ])
                    }
                    files={files}
                    setIsAddFile={setIsAddFile}
                    isAddFile={isAddFile}
                />
            ),
        },
    ]
    const prevStep = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }
    const nextStep = () => {
        if (isValid()) {
            setIsSubmitStep(false)
            setStep(step + 1)
        } else {
            setIsSubmitStep(true)
        }
    }
    const confirm = () => {
        addNote({
            ...dataFormatted,
            Attachments:
                files?.map((file) => ({
                    FileBase64: file.base64,
                    FileName: file.name || file.file.name,
                    FileType: file.FileType,
                })) || undefined,
        }, (data: any) => {
            setSuccessStep(data)
        })
    }

    const openNotify = () => {
        closeHandler()
        showNotifyComponentHandler(successStep)
    }

    const footerComponent = (
        <div className={'btn-group-distance'}>
            {!successStep ?
                (<div className={'btn-group-distance'}>
                    {step > 0 && (
                        <BtnSecondary
                            onClick={prevStep}
                            disabled={isAddFile}
                            title={t('action.back')}
                        >
                            {t('action.back')}
                        </BtnSecondary>
                    )}
                    {stepList.length - 1 === step ? (
                        <BtnPrimary
                            onClick={confirm}
                            disabled={isAddFile}
                            title={t('action.confirm')}
                        >
                            {t('action.confirm')}
                        </BtnPrimary>
                    ) : (
                        <BtnPrimary
                            onClick={nextStep}
                            title={t('action.continue')}
                        >
                            {t('action.continue')}
                        </BtnPrimary>
                    )}
                    <BtnSecondary
                        onClick={closeHandler}
                        title={t('action.cancel')}
                    >
                        {t('action.cancel')}
                    </BtnSecondary>
                </div>) :
                (<div className={"btn-group-distance bnt-group-modal-footer-center"}>
                    <BtnPrimary
                        onClick={openNotify}
                        title={t('action.yesContinue')}
                    >
                        {t('action.yesContinue')}
                    </BtnPrimary>
                    <BtnSecondary
                        onClick={closeHandler}
                        title={t('action.noClose')}
                    >
                        {t('action.noClose')}
                    </BtnSecondary>
                </div>)
            }
        </div>
    )

    return (
        <ModalCustom
            title={successStep ? '' : t('note.newNote.title')}
            closeModal={closeHandler}
            footerTemplate={footerComponent}
        >
            {successStep ? <div>
                    <div className="success-content">
                        <h1 className="title-success">{t('notify.noteSuccess' )}</h1>
                        <img src={`${process.env.REACT_APP_LOAD_CONFIG}/assets/images/success-ok.png`}
                             className="img-success"/>
                        <div className="text-success">{t('notify.sendNotify_note')}</div>
                    </div>
                </div>
                : stepList[step].component}
        </ModalCustom>
    )
}

export default EventStoreV
