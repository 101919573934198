import {formatDateMonthYear, formatDateMonthYearYY} from "../../../../utils/dateUtils";
import useGraphServiceIndicator from "../useGraphServiceIndicator";

export default function (states = [], t){
    const dataTable =[[
        t('lsi.chart.monthYear'),
        t('lsi.chart.delivered'),
        t('lsi.chart.prescribed'),
    ], ...states.map(ele => [formatDateMonthYearYY(ele.month, ele.year), ele.delivered, ele.prescribed ])]
    const {dateTicks} = useGraphServiceIndicator(dataTable)
    return {
        dataTable,
        dateTicks
    }
}
