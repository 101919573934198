import React from 'react'
import { Attachment } from '../../../models/ClinicalDiaryItemModel'
import FileItemManageV from './FileItemManageV'

export default function ListFilesV({
  attachments,
  deleteItemHandler,
  isEditMode,
    downloadAction
}: {
  downloadAction: (data: any) => void
  attachments: Attachment[]
  deleteItemHandler?: (id: string) => void
  isEditMode: boolean
}) {
  return (
    <div className={`list-files mt-2 ${isEditMode ? 'column' : ''}`}>
      {attachments?.map((file) => (
        <FileItemManageV
            downloadAction={downloadAction}
          attachment={file}
          key={file.Id}
          deleteHandler={() => deleteItemHandler && deleteItemHandler(file.Id)}
          isEditMode={isEditMode}
        />
      ))}
    </div>
  )
}
