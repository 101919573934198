import React from "react"
import { tableSortByDate } from "../../../component/table/tableSort";
import {

} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultProvisionAccessoriesSorted } from "./ProvisionInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ AccessoriesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionAccessoriesTable({ provisionAccessories, provisionType }) {
    const {t} = useLanguageForShared()

    const columns = [
/*        {
            isDummyField: true,
            dataField: "Foto",
            text: "",


            //formatter: FotoColumnFormatter,
            formatter: AccessoriesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "AccessoryType",
            text: t('lsi.table.tipology'),
            sort: true,



        },
        {
            dataField: "ItemNumber",
            text: t('lsi.table.itemNumber'),
            sort: true,



        },
        {
            dataField: "Description",
            text: t('lsi.table.description'),
            sort: false,


        },
        {
            dataField: "Serial",
            text: t('lsi.table.serial'),
            sort: false,


        },
        {
            dataField: "LotNumber",
            text: t('lsi.table.lotNumber'),
            sort: false,


        }
    ];
    //controllo se è di tipo assistenza
    if (provisionType >= 3 && provisionType <= 6) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: t('lsi.table.date'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            {
                isDummyField: true,
                dataField: "",
                text: t('lsi.table.documentation'),


                formatter: AssetsColumnFormatter,
            }
        );
    } else if (provisionType === 11) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: t('lsi.table.deliveryDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            {
                isDummyField: true,
                dataField: "",
                text: t('lsi.table.documentation'),


                formatter: AssetsColumnFormatter,
            }
        );
    } else if (provisionType === 13) {
        columns.push(
            {
                dataField: "PickUpDate",
                text: t('lsi.table.pickUpDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            {
                isDummyField: true,
                dataField: "",
                text: t('lsi.table.documentation'),


                formatter: AssetsColumnFormatter,
            }
        );
    }
    else {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: t('lsi.table.deliveryDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            {
                dataField: "PickUpDate",
                text: t('lsi.table.pickUpDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            {
                isDummyField: true,
                dataField: "",
                text: t('lsi.table.documentation'),
                formatter: AssetsColumnFormatter,
            }
        );
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={provisionAccessories}
            columns={columns}
            defaultSorted={defaultProvisionAccessoriesSorted}
            noDataIndication={t('lsi.noDataAvailable')}
        >
        </BootstrapTable>
    );
}
