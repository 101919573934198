import React from "react";
import {
    
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultProvisionConsumablesSorted } from "./ProvisionInfoUIHelpers";
import { tableSortByDate } from "../../../component/table/tableSort";
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ ConsumablesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionConsumablesTable({ provisionConsumables }) {
    const {t} = useLanguageForShared()

    const columns = [
/*        {
            isDummyField: true,
            dataField: "Foto",
            text: "",


            //formatter: FotoColumnFormatter,
            formatter: ConsumablesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "ConsumableType",
            text: t('lsi.table.tipology'),
            sort: true,


            
        },
        {
            dataField: "Description",
            text: t('lsi.table.description'),
            sort: false,


        },
        {
            dataField: "Serial",
            text: t('lsi.table.itemNumber'),
            sort: false,


        },
        {
            dataField: "LotNumber",
            text: t('lsi.table.lotNumber'),
            sort: false,


        },
        {
            dataField: "Quantity",
            text: t('lsi.table.quantity'),
            sort: true,


            
        },
        {
            dataField: "DeliveryDate",
            text: t('lsi.table.deliveryDate'),
            sort: true,


            sortFunc: tableSortByDate,
            
        },
        {
            isDummyField: true,
            dataField: "",
            text: t('lsi.table.documentation'),


            formatter: AssetsColumnFormatter,
        },
    ];
    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={provisionConsumables}
            columns={columns}
            defaultSorted={defaultProvisionConsumablesSorted}
            noDataIndication={t('lsi.noDataAvailable')}
        >
        </BootstrapTable>
    );
}
