import { createSlice } from "@reduxjs/toolkit";
import { CurrentServiceIndicatorModel } from "../../models/currentServiceIndicatorModel";
import { ServiceIndicatorModel } from "../../models/serviceIndicatorModel";
import { PaginationModel } from "../../models/paginationModel";

const initialServiceIndicatorsState = {
    currentServiceIndicators: undefined,
    pagination: new PaginationModel(),
    serviceIndicatorsList: [],
    serviceIndicatorsListForFile: [],
    filterDistrictName: [],
    costForTreatments: [],
    extraordinaryPerformance: [],
    o2States: [],
    o2ActiveStates: [],
    vtdActiveStates: [],
    percentageTreatments: undefined,
    serviceIndicatorsChartData: []
};

export const serviceIndicatorsSlice = createSlice({
    name: "serviceIndicators",
    initialState: initialServiceIndicatorsState,
    reducers: {
        setCostForTreatments: (state, action) => {
            state.costForTreatments = action.payload;
        },
        setPercentageTreatments: (state, action) => {
            state.percentageTreatments = action.payload;
        },
        setExtraordinaryPerformance: (state, action) => {
            state.extraordinaryPerformance = action.payload;
        },
        setO2States: (state, action) => {
            state.o2States = action.payload;
        },
        setO2ActiveStates: (state, action) => {
            state.o2ActiveStates = action.payload;
        },
        setVtdActiveStates: (state, action) => {
            state.vtdActiveStates = action.payload;
        },
        currentServiceIndicatorsFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.currentServiceIndicators) {
                    let model = new CurrentServiceIndicatorModel();
                    entities.data.currentServiceIndicators.forEach((el) => {
                        model.PatientsActivating += el.patientsActivating;
                        model.PatientsActive += el.patientsActive;
                        model.PatientsDisactivating += el.patientsDisactivating;
                        model.ProvisionsNegative += el.provisionsNegative;
                        model.ProvisionsPositive += el.provisionsPositive;
                        //model.ProvisionsScheduled += el.provisionsScheduled;
                        model.ProvisionsSuspended += el.provisionsSuspended;
                    });
                    state.currentServiceIndicators = model;
                } else {
                    state.currentServiceIndicators = undefined;
                }
            } catch (error) {
                console.error(error);
            }
        },
        serviceIndicatorsFetched: (state, action) => {
            try {
                const { entities, holdPagination, isChangedSort } = action.payload;
                if (entities.data.serviceIndicatorsHistoryReview) {
                    let newServiceIndicatorsList = [];
                    entities.data.serviceIndicatorsHistoryReview.nodes.forEach((el, index) => {
                        let model = new ServiceIndicatorModel();
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + el.monthNumber;
                        } else {
                            monthNumber = el.monthNumber;
                        }
                        model.Id = el.year + "" + monthNumber + "" + el.districtname;
                        model.MonthNumber = el.year + "" + monthNumber;
                        model.DistrictName = el.districtname ? el.districtname : "-";
                        model.PatientsActive = el.patientsActive;
                        model.PatientsActivating = el.patientsActivating;
                        model.PatientsDisactivating = el.patientsDisactivating;
                        model.ProvisionsPositive = el.provisionsPositive;
                        model.ProvisionsSuspended = el.provisionsSuspended;
                        model.ProvisionsNegative = el.provisionsNegative;
                        //model.ProvisionsScheduled = el.provisionsScheduled;

                        newServiceIndicatorsList.push(model);
                    });

                    let pageInfo = entities.data.serviceIndicatorsHistoryReview.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.serviceIndicatorsList = newServiceIndicatorsList;
                    } else {
                        state.serviceIndicatorsList = state.serviceIndicatorsList.length > 0 && holdPagination ? state.serviceIndicatorsList.concat(newServiceIndicatorsList) : newServiceIndicatorsList;
                    }
                    state.pagination = newPagination;
                } else {
                    state.serviceIndicatorsList = [];
                    state.pagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        serviceIndicatorsForFileFetched: (state, action) => {
            try {
                const { entities, callback } = action.payload;
                let newServiceIndicatorsList = [];
                if (entities.data.serviceIndicatorsHistoryReview) {
                    entities.data.serviceIndicatorsHistoryReview.nodes.forEach((el, index) => {
                        let model = new ServiceIndicatorModel();
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + el.monthNumber;
                        } else {
                            monthNumber = el.monthNumber;
                        }
                        model.Id = el.year + "" + monthNumber + "" + el.districtname;
                        model.MonthNumber = el.year + "" + monthNumber;
                        model.DistrictName = el.districtname ? el.districtname : "-";
                        model.PatientsActive = el.patientsActive;
                        model.PatientsActivating = el.patientsActivating;
                        model.PatientsDisactivating = el.patientsDisactivating;
                        model.ProvisionsPositive = el.provisionsPositive;
                        model.ProvisionsSuspended = el.provisionsSuspended;
                        model.ProvisionsNegative = el.provisionsNegative;
                        //model.ProvisionsScheduled = el.provisionsScheduled;

                        newServiceIndicatorsList.push(model);
                    });
                }
                callback(newServiceIndicatorsList);
            } catch (error) {
                console.error(error);
            }
        },
        serviceIndicatorsChartFetched: (state, action) => {
            try {
                let serviceIndicatorsChart = action.payload.entities.data.serviceIndicatorsHistoryGraph;
                state.serviceIndicatorsChartData = serviceIndicatorsChart;
            } catch (error) {
                console.error(error);
            }
        },
        serviceIndicatorsFitlersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.serviceIndicatorsHistoryGraphFilters) {
                    let districts = entities.data.serviceIndicatorsHistoryGraphFilters.districts;

                    let districtsArray = [];
                    districts.forEach((e) => {
                        if (e) {
                            districtsArray.push(e);
                        } else {
                            districtsArray.push("-");
                        }
                    });

                    state.filterDistrictName = districtsArray.sort();
                } else {
                    state.filterDistrictName = [];
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
});

export const selectCostForTreatments = (state) => state.serviceIndicators.costForTreatments;
export const selectPercentageTreatments = (state) => state.serviceIndicators.percentageTreatments;
export const selectExtraordinaryPerformance = (state) => state.serviceIndicators.extraordinaryPerformance;
export const selectO2States = (state) => state.serviceIndicators.o2States;
export const selectO2ActiveStates = (state) => state.serviceIndicators.o2ActiveStates;
export const selectVtdActiveStates = (state) => state.serviceIndicators.vtdActiveStates;
