import React, { useState } from "react";
import {
    headerSortingClasses,
    sortCaret

} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientDevicesSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ DevicesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";
import "../../../assets/css/table.css";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientDevicesTable({ devices, history }) {
    const {t} = useLanguageForShared()

    const columns = [
      /*  {
            isDummyField: true,
            dataField: "Photo",
            text: "",

            //formatter: FotoColumnFormatter,
            formatter: DevicesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,
            attrs:{
                'data-test': 'patient-id'
            }
        },
        {
            dataField: "DeviceType",
            text: t('lsi.table.tipologyDevice'),
            sort: true,
            attrs:{
                'data-test': 'device-type'
            }
        },
        {
            dataField: "BrandAndModel",
            text: t('lsi.table.description'),
            sort: true,
            attrs:{
                'data-test': 'brand-and-model'
            }
        },
        {
            dataField: "Serial",
            text: t('lsi.table.serial'),
            sort: true,
            attrs:{
                'data-test': 'serial'
            }
        },
        {
            dataField: "ItemNumber",
            text: t('lsi.table.itemNumber'),
            sort: true,
            attrs:{
                'data-test': 'item-number'
            }
        },
        {
            dataField: "DeliveryDate",
            text: t('lsi.table.deliveryDate'),
            sort: true,
            attrs:{
                'data-test': 'delivery-date'
            },
            sortFunc: tableSortByDate,

        },
        {
            dataField: "PickUpDate",
            text: t('lsi.table.pickUpDate'),
            sort: true,
            attrs:{
                'data-test': 'pick-up-date'
            },
            sortFunc: tableSortByDate,

        },
        /*{
            isDummyField: true,
            dataField: "Assets",
            text: "Documentazione",


            formatter: AssetsColumnFormatter
        },*/
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (e.target.localName === "td") {
                history.push(`/dispositivi/${row.Id}/dettaglio`)
            }
        }
    }

    const [count, setCount] = useState(20);

    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table-head-custom table-hover table-vertical-center"
                bootstrap4
                keyField="Id"
                data={(devices || []).slice(0, count)}
                columns={columns}
                defaultSorted={defaultPatientDevicesSorted}
                noDataIndication={t('lsi.noDataAvailable')}
                selectRow={selectRow}
            >
            </BootstrapTable>
            {
                devices.length > 20 && count <= 20 &&
                <div data-test="patient-devices-more-other" style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setCount(60)}>{t('lsi.more')}</div>            }
        </>
    );
}
