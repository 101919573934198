import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialStaffFilter } from "./StaffsUIHelpers";

const StaffsUIContext = createContext();

export function useStaffsUIContext() {
    return useContext(StaffsUIContext);
}

export function StaffsUIProvider({ staffsUIEvents, children }) {

    let staffsSelectedFilter = JSON.parse(localStorage.getItem('staffsSelectedFilters-...'));

    if (!staffsSelectedFilter) {
        staffsSelectedFilter = {
            filter: {
                SearchText: "",
                BirthDate: "",
                Titles: [],
                Districts: []
            },
            // sortOrder: "desc", // asc||desc
            // sortField: "InchargeDate",
        };
    }

    const [queryParams, setQueryParamsBase] = useState(staffsSelectedFilter);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams
    };

    return <StaffsUIContext.Provider value={value}>{children}</StaffsUIContext.Provider>;
}