import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {DateColumnFormatter} from "../../../component/table/column-formatters/TypeColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function Prescriptions({prescriptions}) {
    const {t} = useLanguageForShared()

    function physicianFormatter(cellContent, row) {
        return (
            <span>
              {cellContent?.lastName?.toUpperCase() || '-'} {cellContent?.firstName}
            </span>
        );
    }

    function posologyFormatter(cellContent, row) {
        return (
            <div className={"d-flex justify-content-center"}>
                <div className={'pr-5 text-left'} style={{minWidth: '130px'}} data-test="generic">
                    <div className={'pr-2 text-dark font-size-sm'}>{t('lsi.prescription.generic')}</div>
                    <span><span data-test="generic-flow" className={''}>{row?.genericFlow}</span> <small>{t('lsi.prescription.flow')}</small></span>
                    <span className={'px-2'}>|</span>
                    <span> <span data-test="generic-hours" className={''}>{row?.genericHours}</span> <small>{t('lsi.prescription.hours')}</small></span>
                </div>
                <div className={'pr-5 text-left'} style={{minWidth: '130px'}} data-test="nightly">
                    <div className={'pr-2 text-dark font-size-sm'}>{t('lsi.prescription.nightlyFlow')}</div>
                    <span><span data-test="nightly-flow">{row?.nightlyFlow}</span> <small>{t('lsi.prescription.flow')}</small></span>
                    <span className={'px-2'}>|</span>
                    <span><span data-test="nightly-hours">{row?.nightlyHours}</span> <small>{t('lsi.prescription.hours')}</small></span>
                </div>
                <div className={'text-left'} style={{minWidth: '130px'}} data-test="daily">
                    <div className={'pr-2 text-dark font-size-sm'}>{t('lsi.prescription.dailyFlow')}</div>
                    <span><span data-test="daily-flow">{row?.dailyFlow}</span> <small>{t('lsi.prescription.flow')}</small></span>
                    <span className={'px-2'}>|</span>
                    <span><span data-test="daily-hours">{row?.dailyHours}</span> <small>{t('lsi.prescription.hours')}</small></span>
                </div>
            </div>
        );
    }

    const columns = [
        {
            dataField: "prescriptionDate",
            text: t('lsi.table.date'),
            sort: false,
            attrs:{
                'data-test': 'col-prescription-date'
            },
            formatter: DateColumnFormatter,
        },
        {
            dataField: "patientNumber",
            text: t('lsi.table.patientId'),
            sort: true,
            attrs:{
                'data-test': 'col-patient-number'
            },
        },
        {
            dataField: "physician",
            text: t('lsi.table.physician'),
            sort: true,
            attrs:{
                'data-test': 'col-physician'
            },
            formatter: physicianFormatter,
        },
        {
            dataField: "posology",
            text: t('lsi.table.posology'),
            sort: true,
            attrs:{
                'data-test': 'col-posology'
            },
            formatter: posologyFormatter,
        },
    ];
    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={prescriptions}
            columns={columns}
            noDataIndication={t('lsi.noDataAvailable')}
        >
        </BootstrapTable>


    );
}
