import ModalCustomVH from "../../../shared/bootstrap/modal/ModalCustomVH";
import {FormElements} from "../../../shared/bootstrap/form/FormElements";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";
import {useForm} from "../../../shared/utils/forms/useForm";
import BtnPrimary from "../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useFormStructureData} from "./useFormStructureData";
import SuccessMessage from "./StepModalMessage";
import {useEffect, useState} from "react";
import http from "../../../shared/utils/http";
import httpData from "../../../shared/utils/httpData";

export default function ModalNotify({
                                        template,
                                        closeHandler,
                                        emails
                                    }: { template: any, closeHandler: (data?: any) => void, emails: string[] }) {
    const {t, lang} = useLanguageForShared()
    const [isSuccess, setIsSuccess] = useState(false);

    const submitHandler = () => {
        console.log(dataForm)
        http.post('eventnotify/SendNotifyMail', {
            ...dataForm,
            language: lang,
            url: template?.baseLink || ''
        }).then(res => {
            setIsSuccess(true)
        })

    }

    const {
        formStructureType, formStructure, validationStructure
    } = useFormStructureData({t, emails})

    const {
        dataForm,
        changeValue,
        errors,
        setData,
        isValid,
        submitAction,
        isSubmit,
    } = useForm(submitHandler, validationStructure(), {
        emailBody: template?.defaultMessage(),
        eventType: template?.eventType
    })

    useEffect(() => {
        setData({
            emailBody: template?.defaultMessage(),
            eventType: template?.eventType
        })
    }, [template])


    const footerLayout = <div className={'btn-group-distance footer-notify'}>
        {isSuccess ? <BtnPrimary onClick={closeHandler}>
                {t('action.close')}
            </BtnPrimary>
            : <>
                <BtnPrimary onClick={submitAction}>
                    {t('action.send')}
                </BtnPrimary>
                <BtnSecondary onClick={closeHandler}>
                    {t('action.close')}
                </BtnSecondary>
            </>}
    </div>

    const title = () =>
        <>
            {isSuccess ?
                <div className={'title-heading success'}>
                    {t('notify.success')}
                </div>
                :
                <div className={'title-heading'}>
                    {`${t('notify.sentNotify')} "${template?.label()}"`}
                </div>}
        </>

    return (
        <>
            <ModalCustomVH
                size={'lg'}
                className={`modal-notify ${isSuccess ? 'step-success' : ''}`}
                titleLayout={<div
                    className={'col-12'}>{title()}</div>}
                closeModal={closeHandler}
                isFullScreen={false}
                footerTemplate={footerLayout}>
                {!isSuccess && <div className={'content-modal'}>
                    <div className={'col-12 mb-4'}>
                        {template.titleComponent()}
                    </div>
                    <div className={'col-12'}>
                        {false && <FormElements
                            structure={formStructureType}
                            dataForm={dataForm}
                            errors={errors}
                            changeValue={changeValue}
                        />}
                      <FormElements
                            structure={formStructure}
                            dataForm={dataForm}
                            errors={errors}
                            changeValue={changeValue}
                            isSubmit={isSubmit}
                        />
                    </div>
                </div>}
                {isSuccess && <SuccessMessage/>}
            </ModalCustomVH>

        </>
    );
}
