import * as requestFromServer from "./staffsCrud";
import { staffsSlice } from "./staffsSlice";
import { spinnerCreate } from "../spinner/spinnerActions";
import moment from "moment";

const { actions } = staffsSlice;

function getCustomerId() {
    return localStorage.getItem("customerId");
}

export const fetchStaffsFilters = birthDate => async dispatch => {
    dispatch(spinnerCreate(true));
    let customerId = getCustomerId();
    let params = '(customerId:"' + customerId + '" ';
    if (birthDate) {
        params += 'birthdateFrom:"' + moment(birthDate.split('-')[0].trim(), "DD MMM YY").format('YYYY-MM-DD') + 'T00:00:00Z" birthdateTo:"' + moment(birthDate.split('-')[1].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T23:59:59Z"';
    }
    params += ')';
    try {
        const response = await requestFromServer
            .findStaffsFilters(params);
        dispatch(actions.staffsFitlersFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

function getStaffsQueryParams(queryParams, isForFile, pagination, sort, isChangedSort) {
    let searchText = queryParams.filter.SearchText;
    let titles = queryParams.filter.Titles;
    let districts = queryParams.filter.Districts;
    let birthDate = queryParams.filter.BirthDate;
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';

    if (!isForFile) {
        if (isChangedSort) {
            params += 'first:50';
        } else {
            if (pagination) {
                params += 'first:' + pagination.Row + '';
                if (pagination.EndCursor) {
                    params += ' after:"' + pagination.EndCursor + '"';
                }
            } else {
                params += 'first:50';
            }
        }
    }

    if (titles.length > 0 || districts.length > 0 || searchText || birthDate) {
        params += ' where:{';

        if (titles.length > 0) {
            titles.forEach((el, index) => {
                if (index === 0) {
                    params += ' title_in:["' + el + '"';
                } else {
                    params += ',"' + el + '"';
                }
            });
            params += ']';
        }

        if (districts.length > 0) {
            districts.forEach((el, index) => {
                if (index === 0) {
                    params += ' districtName_in:["' + el + '"';
                } else {
                    params += ',"' + el + '"';
                }
            });
            params += ']';
        }

        if (searchText) {
            params += ' OR:[{firstName_contains:"' + searchText + '"},{lastName_contains:"' + searchText + '"},{initials_contains:"' + searchText + '"},{identityDocument_contains:"' + searchText + '"}]';
        }

        if (birthDate) {
            params += 'birthDate_gte:"' + moment(birthDate.split('-')[0].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T00:00:00Z" birthDate_lte:"' + moment(birthDate.split('-')[1].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }

        params += '}';
    }

    if (!isForFile) {
        let field = [];

        if (sort.dataField === "Nominative") {
            field.push("lastName");
            field.push("firstName");
        } else if (sort.dataField === "Title") {
            field.push("title");
        } else if (sort.dataField === "BirthDate") {
            field.push("birthDate");
        }

        params += ' order_by: { ';

        field.forEach((el, index) => {
            if (index === 0) {
                params += el + ':' + sort.order.toUpperCase();
            } else {
                params += ',' + el + ':' + sort.order.toUpperCase();
            }
        });

        params += ' }';
    }
    return params;
}

export const fetchStaffs = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
    dispatch(spinnerCreate(true));
    let params = getStaffsQueryParams(queryParams, false, pagination, sort, isChangedSort);
    try {
        const response = await requestFromServer
            .findStaffs(params);
        dispatch(actions.staffsFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchStaffsForFile = (queryParams, callback) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getStaffsQueryParams(queryParams, true);

    try {
        const response = await requestFromServer
            .findStaffs(params);
        dispatch(actions.staffsForFileFetched({ entities: response.data, callback }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};