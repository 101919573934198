import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialInvoiceFilter } from "./InvoicesUIHelpers";
import moment from "moment";

const InvoicesUIContext = createContext();

export function useInvoicesUIContext() {
    return useContext(InvoicesUIContext);
}

export function InvoicesUIProvider({ invoicesUIEvents, children }) {
    let invoicesSelectedFilter = JSON.parse(localStorage.getItem('invoicesSelectedFilters-...'));

    if (!invoicesSelectedFilter) {
        invoicesSelectedFilter = {
            filter: {
                MonthYear: (moment().get("year") - 1) + "/" + (moment().get("month") + 1) + " - " + moment().get("year") + "/" + (moment().get("month") + 1)
            },
        };
    }

    const [queryInvoiceParams, setQueryInvoiceParamsBase] = useState(invoicesSelectedFilter);
    const setQueryInvoiceParams = useCallback(nextQueryParams => {
        setQueryInvoiceParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryInvoiceParams,
        setQueryInvoiceParamsBase,
        setQueryInvoiceParams
    };

    return <InvoicesUIContext.Provider value={value}>{children}</InvoicesUIContext.Provider>;
}
