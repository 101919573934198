export default function NoteContentLayoutV({
  dateTemplate,
  subjectTemplate,
  notesTemplate,
  footerTemplate,
  toolbarRightTemplate,
  listFileTemplate,
  isEditMode,
}: {
  dateTemplate: React.ReactChild
  subjectTemplate: React.ReactChild
  notesTemplate: React.ReactChild
  footerTemplate: React.ReactChild
  toolbarRightTemplate?: React.ReactNode
  listFileTemplate: React.ReactChild
  isEditMode: boolean
}) {
  return (
    <>
      <div className="note-item">
        <div className="left-col">
          {dateTemplate}
          {!isEditMode &&<div className="point-content">
            <div className="point"></div>
          </div>}
        </div>
        <div className="right-col">
          <div>
            <div className="content">
              <div className="subject">
                <div className="sub-left-col">{subjectTemplate}</div>
                <div
                  className="sub-right-col"
                  style={{ marginTop: !isEditMode ? '-20px' : undefined }}
                >
                  {toolbarRightTemplate}
                </div>
              </div>
              <div className="">{notesTemplate}</div>
              <div className="note-updated">{footerTemplate}</div>
              {listFileTemplate}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
