import React from "react";
import {

} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultProvisionDevicesSorted } from "./ProvisionInfoUIHelpers";
import { tableSortByDate } from "../../../component/table/tableSort";
import "../../../assets/css/table.css";
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ DevicesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionDevicesTable({ provisionDevices, history, provisionType }) {
    const {t} = useLanguageForShared()

    const columns = [
/*        {
            isDummyField: true,
            dataField: "Photo",
            text: "",


            //formatter: FotoColumnFormatter,
            formatter: DevicesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "DeviceType",
            text: t('lsi.table.tipologyDevice'),
            sort: true,



        },
        {
            dataField: "BrandAndModel",
            text: t('lsi.table.description'),
            sort: true,



        },
        {
            dataField: "Serial",
            text: t('lsi.table.serial'),
            sort: false,


        },
        {
            dataField: "ItemNumber",
            text: t('lsi.table.itemNumber'),
            sort: false,


        }

    ];

    //controllo se è di tipo assistenza
    if (provisionType >= 3 && provisionType <= 6) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: t('lsi.table.date'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            /*{
                isDummyField: true,
                dataField: "",
                text: "Documentazione",


                formatter: AssetsColumnFormatter,
            }*/
        );
    } else if (provisionType === 11) {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: t('lsi.table.deliveryDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            /*{
                isDummyField: true,
                dataField: "",
                text: "Documentazione",


                formatter: AssetsColumnFormatter,
            }*/
        );
    } else if (provisionType === 13) {
        columns.push(
            {
                dataField: "PickUpDate",
                text: t('lsi.table.pickUpDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            /*{
                isDummyField: true,
                dataField: "",
                text: "Documentazione",


                formatter: AssetsColumnFormatter,
            }*/
        );
    }
    else {
        columns.push(
            {
                dataField: "DeliveryDate",
                text: t('lsi.table.deliveryDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            {
                dataField: "PickUpDate",
                text: t('lsi.table.pickUpDate'),
                sort: true,


                sortFunc: tableSortByDate,

            },
            /*{
                isDummyField: true,
                dataField: "",
                text: "Documentazione",


                formatter: AssetsColumnFormatter,
            }*/
        );
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (e.target.localName === "td") {
                history.push(`/dispositivi/${row.Id}/dettaglio`);
            }
        }
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-hover table-vertical-center"
            bootstrap4
            keyField="Id"
            data={provisionDevices}
            columns={columns}
            defaultSorted={defaultProvisionDevicesSorted}
            noDataIndication={t('lsi.noDataAvailable')}
            selectRow={selectRow}
        >
        </BootstrapTable>
    );
}
