import React, {useEffect} from "react";
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import {DevicesTable} from "./DevicesTable";
import {DevicesFilters} from "./DevicesFilters";
import {KTScrollFilters} from "../../../component/scroll/Scroll";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const DevicesHome = () => {
    const {t} = useLanguageForShared()

    //inizializzo lo scroll nei filtri
    useEffect(() => {
        KTScrollFilters.init("devicesFilter")
    }, []);

    return (
        <div className="row layout-page">
            <div className="col-lg-2 filter-col">
                <Card className="card-sticky" id="devicesFilter">
                    {/*<CardHeader title="Filtri" className="fw-bolder"></CardHeader>*/}
                    <CardBody className="offcanvas-content">
                        <DevicesFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <h3 className={'title-page'}>{t('lsi.device.devices')}</h3>

                <DevicesTable/>
            </div>
        </div>
    );
};
