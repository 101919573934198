import React, { useMemo, useEffect, useRef } from "react";
import { useStaffsUIContext } from "../StaffsUIContext";
import { isEqual } from "lodash";
import { Formik, Form } from "formik";
import { LocaleDatePicker } from "../../../component/datepicker/DateRangePickerConfig";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import "../../../assets/css/datepicker.css"
import * as actions from "../../../actions/staffs/staffsActions";
import moment from "moment";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const prepareFilter = (queryParams, values) => {
    const {
        searchText,
        filterByBirthDate,
        filterByTitles,
        filterByDistricts,
    } = values;

    const newQueryParams = { ...queryParams };
    const filter = {
        SearchText: searchText,
        BirthDate: filterByBirthDate,
        Titles: filterByTitles,
        Districts: filterByDistricts
    };

    newQueryParams.filter = filter;

    localStorage.setItem("staffsSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function StaffsFilters() {
    const {t} = useLanguageForShared()

    const staffsUIContext = useStaffsUIContext();
    const staffsUIProps = useMemo(() => {
        return {
            queryParams: staffsUIContext.queryParams,
            setQueryParams: staffsUIContext.setQueryParams,
        };
    }, [staffsUIContext]);

    const dispatch = useDispatch();

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(staffsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, staffsUIProps.queryParams)) {
            staffsUIProps.setQueryParams(newQueryParams);
        }
    };

    useEffect(() => {
        dispatch(actions.fetchStaffsFilters(staffsUIProps.queryParams.filter.BirthDate));
    }, [staffsUIProps.queryParams.filter.BirthDate]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.staffs }),
        shallowEqual
    );

    const {
        filterTitles,
        filterDistrict
    } = currentState;

    const birthDateRef = useRef();

    const changeStartEndDate = (ref) => {
        ref.current.setStartDate(moment().format("DD MMM YY"));
        ref.current.setEndDate(moment().format("DD MMM YY"));
    }

    return (
        <Formik
            initialValues={{
                searchText: staffsUIProps.queryParams.filter.SearchText,
                filterByBirthDate: staffsUIProps.queryParams.filter.BirthDate,
                filterByTitles: staffsUIProps.queryParams.filter.Titles,
                filterByDistricts: staffsUIProps.queryParams.filter.Districts
            }}
            onSubmit={(values, formikBag) => {
                if (values.filterByBirthDate !== staffsUIProps.queryParams.filter.BirthDate) {
                    values = {
                        searchText: "",
                        filterByBirthDate: values.filterByBirthDate,
                        filterByTitles: [],
                        filterByDistricts: [],
                    }
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
            }) => (
                    <Form onSubmit={handleSubmit} className="form form-label-right filter">
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <input
                                    type="text"
                                    className="sh form-control"
                                    name="searchText"
                                    placeholder={t('lsi.search')}
                                    onBlur={handleBlur}
                                    value={values.searchText}
                                    onChange={(e) => {
                                        setFieldValue("searchText", e.target.value);
                                        setTimeout(() => {
                                            handleSubmit();
                                        }, 1000);
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <h6 className="fw-bolder mb-3">{t('lsi.registry.birthDate')}</h6>
                                <DateRangePicker
                                    ref={birthDateRef}
                                    initialSettings={{
                                        locale: LocaleDatePicker,
                                        showDropdowns: true,
                                        linkedCalendars: false,
                                        startDate: values.filterByBirthDate ? values.filterByBirthDate.split('-')[0] : moment().format("DD MMM YY"),
                                        endDate: values.filterByBirthDate ? values.filterByBirthDate.split('-')[1] : moment().format("DD MMM YY")
                                    }}
                                    onEvent={(e, picker) => {
                                        if (e.type === "cancel") {
                                            setFieldValue("filterByBirthDate", "");
                                            changeStartEndDate(birthDateRef);
                                            handleSubmit();
                                        } else if (e.type === "apply") {
                                            setFieldValue("filterByBirthDate", picker.startDate.format('DD MMM YY') + " - " + picker.endDate.format('DD MMM YY'));
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <div className="sh form-control customPickerDisplay">
                                         <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                                        <span>{values.filterByBirthDate}</span>
                                        {
                                            values.filterByBirthDate
                                                ? <button type="button" className="react-datepicker__close-icon" style={{ position: "inherit", marginLeft: "10px" }}
                                                    onClick={() => {
                                                        setFieldValue("filterByBirthDate", "");
                                                        changeStartEndDate(birthDateRef);
                                                        handleSubmit();
                                                    }}
                                                />
                                                : null
                                        }
                                        <div style={{ float: "right" }}>
                                           <IconSVG icon={'chevron-down'} size={'8px'}/>
                                        </div>
                                    </div>
                                </DateRangePicker>
                            </div>
                        </div>
                        {
                            filterTitles && filterTitles.length > 0
                                ? <>
                                    
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="fw-bolder mb-3">{t('lsi.registry.qualify')}</h6>
                                            {
                                                filterTitles.map((el) => {
                                                    return (
                                                        <div key={"div" + el}>
                                                            <label
                                                                className="checkbox"
                                                                key={"label" + el}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByTitles"
                                                                    id={el}
                                                                    key={el}
                                                                    value={el}
                                                                    checked={values.filterByTitles.includes(el) ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByTitles = [
                                                                                ...values.filterByTitles,
                                                                                el
                                                                            ];
                                                                        } else {
                                                                            values.filterByTitles = values.filterByTitles.filter((value) => {
                                                                                return value !== el;
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el}
                                                                <span />
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        {
                            filterDistrict && filterDistrict.length > 0
                                ? <>
                                    
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="fw-bolder mb-3">{t('lsi.registry.district')}</h6>
                                            {
                                                filterDistrict.map((el) => {
                                                    return (
                                                        <div key={"div" + el}>
                                                            <label
                                                                className="checkbox"
                                                                key={"label" + el}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByDistricts"
                                                                    id={el}
                                                                    key={el}
                                                                    value={el}
                                                                    checked={values.filterByDistricts.includes(el) ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByDistricts = [
                                                                                ...values.filterByDistricts,
                                                                                el
                                                                            ];
                                                                        } else {
                                                                            values.filterByDistricts = values.filterByDistricts.filter((value) => {
                                                                                return value !== el;
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el}
                                                                <span />
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </Form>
                )}
        </Formik>
    );
}