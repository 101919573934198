import React, {useEffect} from "react";
import {Card, CardBody, CardHeader,} from "../../../../_metronic/_partials/controls";
import {StaffsTable} from "./StaffsTable";
import {StaffsFilters} from "./StaffsFilters";
import {KTScrollFilters} from "../../../component/scroll/Scroll";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const StaffsHome = () => {
    const {t} = useLanguageForShared()

    useEffect(() => {
        KTScrollFilters.init("staffsFilter")
    }, []);

    return (
        <div className="row layout-page">
            <div className="col-lg-2 filter-col">
                <Card className="card-sticky" id="staffsFilter">
                    {/*<CardHeader title="Filtri" className="fw-bolder"></CardHeader>*/}
                    <CardBody className="offcanvas-content">
                        <StaffsFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <h3 className={'title-page'}>{t('lsi.staff')}</h3>

                <StaffsTable/>
            </div>
        </div>
    );
};
