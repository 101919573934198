import React from 'react'
import { mergeClass } from '../../functions/function'
import ToastPropsStyle from './models/ToastProps'

interface ToastProps {
  className?: string
  toastStyle: ToastPropsStyle
  title: string
  body?: string
  showToast?: boolean
  closeAction: () => void
}
const ToastMsg = ({
  className,
  toastStyle,
  title,
  body,
  showToast = true,
  closeAction,
}: ToastProps) => {
  return (
    <div className={mergeClass('shared-toast', className)}>
      <div
        className={`toast ${showToast?'show':''}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div
          className="toast-header"
          style={{
            backgroundColor: toastStyle.style.bgColor,
            color: toastStyle.style.color,
          }}
        >
          <strong className="me-auto">{title}</strong>
          <button
            type="button"
            className="ms-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            onClick={closeAction}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {body && <div className="toast-body">{body}</div>}
      </div>
      {/* <Toast
        show={showToast}
        onClose={closeAction}
        style={{
          backgroundColor: toastStyle.style.bgColor,
          color: toastStyle.style.color,
        }}
      >
        <Toast.Header
          style={{
            backgroundColor: toastStyle.style.bgColor,
            color: toastStyle.style.color,
          }}
        >
        <Icon
            icon={toastStyle.icon.ico}
            colorStyle={toastStyle.icon.color}
            size="26px"
          ></Icon> 
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        {body && <Toast.Body>{body}</Toast.Body>}
      </Toast> */}
    </div>
  )
}

export default ToastMsg
