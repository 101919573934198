import React from 'react'
import RowBtnEditV from './noteItemElement/RowBtnEditV'
import RowBtnReadV from './noteItemElement/RowBtnReadV'

export default function ToolbarRightV({
  isEditMode,
  editNote,
  deleteNote,
  uploadHandler,
  saveHandler,
  cancelHandler,
  isAddFile,
  notifyHandler
}: {
  isEditMode: boolean
  editNote: () => void
  deleteNote: () => void
  uploadHandler: () => void
  saveHandler: () => void
  cancelHandler: () => void
  isAddFile: boolean
  notifyHandler:()=>void
}) {
  return (
    <div className="toolbar-right">
      {isEditMode ? (
        <RowBtnEditV
          uploadHandler={uploadHandler}
          saveHandler={saveHandler}
          cancelHandler={cancelHandler}
          isAddFile={isAddFile}
        />
      ) : (
        <RowBtnReadV editHandler={editNote} deleteHandler={deleteNote} notifyHandler={notifyHandler} />
      )}
    </div>
  )
}
