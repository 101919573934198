import {createSlice} from "@reduxjs/toolkit";
import {CostModel} from "../../models/costModel";
import {InvoiceTableModel} from "../../models/invoiceTableModel";
import moment from "moment";
import {sortCaret, headerSortingClasses} from "../../../_metronic/_helpers";
import {documentTypeTitles} from "../../pages/invoices/InvoicesUIHelpers";
import {PaginationModel} from "../../models/paginationModel";
import {t} from "i18next";

const initialInvoicesState = {
    costsList: [],
    invoicesPagination: new PaginationModel(),
    costsPagination: new PaginationModel(),
    invoicesList: [],
    costsNotAccounted: [],
    costsNotAccountedColumns: [],
    costChartData: [],
    filterPrescriptors: [],
    filterPrescriptions: [],
    filterDocumentType: [],
    filterCostsDistricts: [],
    filterCostsMaxAmount: 0,
    filterCostsMinAmount: 0,
    filterInvoicesDistricts: [],
    filterInvoicesMaxAmount: 0,
    filterInvoicesMinAmount: 0,
    selectedCost: []
};

export const invoicesSlice = createSlice({
    name: "invoices",
    initialState: initialInvoicesState,
    reducers: {
        setFilterDistricts: (state, action) => {
            state.filterCostsDistricts = action.payload.map(ele => ele? ele : '-')
        },
        costsFetched: (state, action) => {
            try {
                const {entities, holdPagination, isChangedSort} = action.payload;
                if (entities.data.billingPatientsInvoicesAndCosts) {
                    let newCostsList = [];
                    entities.data.billingPatientsInvoicesAndCosts.nodes.forEach((el) => {
                        let model = new CostModel();
                        let cost = el;
                        model.ClientId = cost.id;
                        model.Amount = cost.amount;
                        model.PatientNumber = cost.patientNumber;
                        model.PatientFullName = !cost.patientFirstName && !cost.patientLastName ? "-" : cost.patientFirstName + " " + cost.patientLastName;
                        model.District = cost.districtName ? cost.districtName : "-";
                        model.Prescription = cost.prescriptionName;
                        model.Prescriptor = cost.prescriptorFullName;
                        model.TherapyDays = cost.therapyDays;
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + cost.monthNumber;
                        } else {
                            monthNumber = cost.monthNumber;
                        }
                        model.MonthNumber = cost.year + "" + monthNumber;

                        newCostsList.push(model);
                    });

                    let pageInfo = entities.data.billingPatientsInvoicesAndCosts.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.costsList = newCostsList;
                    } else {
                        state.costsList = state.costsList.length > 0 && holdPagination ? state.costsList.concat(newCostsList) : newCostsList;
                    }
                    state.costsPagination = newPagination;
                } else {
                    state.costsList = [];
                    state.costsPagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        costsForFileFetched: (state, action) => {
            try {
                const {entities, callback} = action.payload;
                let newCostsList = [];
                if (entities.data.billingPatientsInvoices) {
                    entities.data.billingPatientsInvoices.nodes.forEach((el) => {
                        let model = new CostModel();
                        let cost = el;
                        model.ClientId = cost.id;
                        model.Amount = cost.amount;
                        model.VatAmount = cost.vatAmount;
                        model.PatientNumber = cost.patientNumber;
                        model.PatientFullName = !cost.patientFirstName && !cost.patientLastName ? "-" : cost.patientFirstName + " " + cost.patientLastName;
                        model.District = cost.patientDistrict ? cost.patientDistrict : "-";

                        model.Prescription = cost.prescriptionName;
                        model.Prescriptor = cost.prescriptorFullName;
                        model.TherapyDays = cost.therapyDays;
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + cost.monthNumber;
                        } else {
                            monthNumber = cost.monthNumber;
                        }
                        model.MonthNumber = cost.year + "" + monthNumber;

                        newCostsList.push(model);
                    });
                }
                callback(newCostsList);
            } catch (error) {
                console.error(error);
            }
        },
        costsChartFetched: (state, action) => {
            //action.payload.perMonthAndDistrict.filter(el => el.month === ele.month && el.year === ele.year)
            const districts = action.payload.perMonthAndDistrict.reduce((arr, el) => [...arr, ...(arr.includes(el.district) ? [] : [el.district])], []);
            state.costChartData = action.payload.perMonth.map(ele => ({
                ...ele,
                districts: districts.map(d => {
                    const district = action.payload.perMonthAndDistrict.find(el => el.month === ele.month && el.year === ele.year && el.district === d);
                    return district ? district : {month: ele.month, year: ele.year, district: d}
                })
            }));
        },
        costsNotAccountedFetched: (state, action) => {
            try {
                const {entities} = action.payload;
                if (entities.data.billingMonthMatrix) {

                    let newCostsNotAccountedList = [];
                    let columns = [
                        {
                            dataField: 'districtname',
                            text: t('lsi.table.district'),
                            sort: true,

                        }
                    ];

                    action.payload.entities.data.billingMonthMatrix.nodes.forEach((el) => {

                        let prescription = el.prescriptionName;

                        let row = newCostsNotAccountedList.find((element) => {
                            return element.districtname === el.districtname
                        });

                        if (!row) {
                            row = {
                                districtname: el.districtname
                            };
                            newCostsNotAccountedList.push(row);
                        }
                        row[el.prescriptionName] = el.amount + " €";

                        if (columns.filter(element => {
                            return element.dataField === el.prescriptionName
                        }).length === 0) {
                            columns.push(
                                {
                                    dataField: el.prescriptionName,
                                    text: prescription,
                                    sort: true,

                                }
                            );
                        }

                    });

                    state.costsNotAccounted = newCostsNotAccountedList;
                    state.costsNotAccountedColumns = columns;
                } else {
                    state.costsNotAccounted = [];
                    state.costsNotAccountedColumns = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        invoicesFetched: (state, action) => {
            try {
                const {entities, holdPagination, isChangedSort} = action.payload;
                if (entities.data.billingCustomerInvoices) {
                    let newInvoicesList = [];
                    entities.data.billingCustomerInvoices.nodes.forEach((el, index) => {
                        let model = new InvoiceTableModel();
                        model.Id = el.id;
                        model.PostingDate = moment(el.postingDate).format("DD MMM YY");
                        model.CustomerNumber = el.customerNumber;
                        model.CustomerName = el.customerName;
                        model.Cig = el.cig;
                        model.CustomerOrderDate = moment(el.customerOrderDate).format("DD MMM YY");
                        model.CustomerOrderNumber = el.customerOrderNumber;

                        model.InvoiceDate = moment(el.invoiceDate).format("DD MMM YY");
                        model.Amount = el.amount;

                        newInvoicesList.push(model);
                    });

                    let pageInfo = entities.data.billingCustomerInvoices.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.invoicesList = newInvoicesList;
                    } else {
                        state.invoicesList = state.invoicesList.length > 0 && holdPagination ? state.invoicesList.concat(newInvoicesList) : newInvoicesList;
                    }
                    state.invoicesPagination = newPagination;
                } else {
                    state.invoicesList = [];
                    state.invoicesPagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        invoicesForFileFetched: (state, action) => {
            try {
                const {entities, callback} = action.payload;
                let newInvoicesList = [];
                if (entities.data.billingCustomerInvoices) {
                    entities.data.billingCustomerInvoices.nodes.forEach((el, index) => {
                        let model = new InvoiceTableModel();
                        model.Id = el.id;

                        model.OperationType = el.operationType;
                        model.PostingDate = moment(el.postingDate).format("DD MMM YY");
                        model.CustomerNumber = el.customerNumber;
                        model.CustomerName = el.customerName;
                        model.Cig = el.cig;
                        model.CustomerOrderDate = moment(el.customerOrderDate).format("DD MMM YY");
                        model.CustomerOrderNumber = el.customerOrderNumber;

                        model.InvoiceDate = moment(el.invoiceDate).format("DD MMM YY");
                        model.Amount = el.amount;
                        model.VatAmount = el.vatAmount;

                        newInvoicesList.push(model);
                    });
                }
                callback(newInvoicesList);
            } catch (error) {
                console.error(error);
            }
        },
        setCostRow: (state, action) => {
            try {
                state.selectedCost = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
        invoicesFitlersFetched: (state, action) => {
            try {
                const {entities} = action.payload;
                if (entities.data.billingReviewFilters) {
                    let districts = entities.data.billingReviewFilters.districts;
                    let documentTypes = entities.data.billingReviewFilters.documentTypes;
                    let maxAmount = entities.data.billingReviewFilters.maxAmount;
                    let minAmount = entities.data.billingReviewFilters.minAmount;

                    let districtsArray = [];
                    districts.forEach((e) => {
                        if (e) {
                            districtsArray.push(e);
                        } else {
                            districtsArray.push("-");
                        }
                    });

                    state.filterInvoicesDistricts = districtsArray.sort();

                    let documentTypesArray = [];
                    documentTypes.forEach((el) => {
                        if (el === "UNKNOWN") {
                            documentTypesArray.push({label: documentTypeTitles()[0], value: el});
                        } else if (el === "INVOICE") {
                            documentTypesArray.push({label: documentTypeTitles()[1], value: el});
                        } else {
                            documentTypesArray.push({label: documentTypeTitles()[2], value: el});
                        }
                    });

                    state.filterDocumentType = documentTypesArray;
                    state.filterInvoicesMaxAmount = maxAmount;
                    state.filterInvoicesMinAmount = minAmount;
                } else {
                    state.filterDocumentType = [];
                    state.filterInvoicesMaxAmount = 0;
                    state.filterInvoicesMinAmount = 0;
                }
            } catch (error) {
                console.error(error);
            }
        },
        costsFitlersFetched: (state, action) => {
            try {
                const {entities} = action.payload;
                    state.filterCostsDistricts = [];
                    state.filterCostsMaxAmount = 0;
                    state.filterCostsMinAmount = 0;
                    state.filterPrescriptions = [];
                    state.filterPrescriptors = [];
            } catch (error) {
                console.error(error);
            }
        }
    }
});


export const selectCostChartData = (state) => state.invoices.costChartData
export const selectFilterCostsDistricts = (state) => state.invoices.filterCostsDistricts
