import React from "react";
import RequestRow from "../prescription/request/RequestRow";
import ProductRow from "../prescription/product/ProductRow";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";
import {TemplateSelectProductRead} from "../../../../shared_lsi_sg/TemplateSelectProductRead";

export const PatientPrescription = ({patientRegistry}) => {

    const {data: dataFormatter} = useDateCustom();
    const orders = patientRegistry?.latestPrescriptions;
    console.log(orders, 'orders')
    // const order = patientRegistry?.lastActivePrescription;

    return (
        <>
            {
                orders.map(order =>
                    <>
                        <RequestRow orderDetail={order?.dettaglioOrdine}/>
                        {
                            order
                                ? (order?.products || order?.productCart)?.map((ord, i) => (<>
                                    <ProductRow ord={ord}/>
                                    {ord.detail && <TemplateSelectProductRead data={ord.detail} template={ord.template}/>}
                                </>))
                                : null
                        }
                    </>
                )
            }

        </>
    );
}
