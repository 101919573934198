export class ProvisionModel {
    constructor() {
        this.Id = "";
        this.PatientId = "";
        this.ProvisionType = 0;
        this.District = "";
        this.DocumentNumber = "";
        this.ProvisionDate = "";
        this.StaffInitials = "";
        this.ProvisionState = 0;
        this.Patient = {};
        this.Devices = [];
        this.Accessories = [];
        this.Consumables = [];
        this.Assets = [];
        this.Reporting = "";
        this.ProvisionNote = "";
        this.CustomerId = "";
    }
}