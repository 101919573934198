export function tableSortByDate(a, b, order, dataField, rowA, rowB) {
    const splitA = a.split(' ')
    const splitB = b.split(' ')
    const month = ['', 'gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
    const timeA = splitA.length === 3 ? new Date(`${month.findIndex(ele => ele === splitA[1])}-${splitA[0]}-${splitA[2]}`).getTime() : 0;
    const timeB = splitB.length === 3? new Date(`${month.findIndex(ele => ele === splitB[1])}-${splitB[0]}-${splitB[2]}`).getTime() : 0;
    if (order === 'asc') {
      return timeA > timeB? 1 : -1;
    } else if (order === 'desc') {
      return timeB > timeA? 1 : -1;
    }
}
