import React from "react";
import {Chart} from "react-google-charts";
import configuration from "../../../shared/configuration";

export function ChartCustom({
                                dataTable = [],
                                width,
                                height = 150,
                                hAxis = {},
                                vAxis = {},
                                options = {},
                                chartType = "ColumnChart",
                                formatters = []
                            }) {
    const chartOptions = {
        colors: ['#F2726F'],
        fontName: configuration.theme.fontFamily,
        fontSize: 12,
        titleTextStyle: {
            italic: false,
            fontSize: 18,
            bold: false,
            color: configuration.theme.primaryDark,
        },
        chartArea: {
            width: "100%",
            left: 100,
            right: 10,
            top: 50,
            bottom: 50
        },
        bar: {
            groupWidth: 40
        },
        hAxis: {
            direction: -1,
            slantedText: true,
            slantedTextAngle: 45,
            titleTextStyle: {
                italic: false,
                color: configuration.theme.primaryDark,
                bold: false,

            },
            textStyle: {
                italic: false,
                color: configuration.theme.primaryDark,
                bold: false,
            },
            ...hAxis
        },
        vAxis: {
            viewWindow: {
                min: 0
            },
            titleTextStyle: {
                italic: false,
                color: configuration.theme.primaryDark,
            },
            textStyle: {
                italic: false,
                color: configuration.theme.primaryDark,
            },
            baselineColor: "#F2F2F2",
            gridlines: {
                color: "#F2F2F2"
            },
            ...vAxis
        },
        legend: {
            maxLines: 10,
            position: 'top',
            alignment: 'end',
            textStyle: {
                fontSize: 10
            }
        },
        ...options
    }

    return (
        <Chart chartType={chartType} height={height} width={width} data={dataTable} options={chartOptions}
               formatters={formatters}
        />
    );
}
