import React from "react";
import PatientsTreatmentStatus from "./PatientsTreatmentStatus";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardPatientsTreatmentStatus = ({states}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator minHeight={"250px"} view={states && states.length} title={t('lsi.ventiloTreatmentActivates')}>
            <PatientsTreatmentStatus states={states}/>
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardPatientsTreatmentStatus);
