import React from "react";
import {Chart} from "react-google-charts";
import {getTicks} from "../../../utils/utility";
import {formatDateMonthYearYY} from "../../../utils/dateUtils";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientLoxChart({patientLoxChartData}) {
    const {t} = useLanguageForShared()

    function charts() {
        var graphs = [];

        var colors = [configuration.theme.series.s770_hex, configuration.theme.series.s170_hex, configuration.theme.series.alert170_hex, configuration.theme.series.s870_hex, configuration.theme.series.success170_hex];


        var colorIndex = 0;
        var chartIndex = 0;

        let dataTable = [[t('lsi.chart.monthYear'), t('lsi.chart.delivered'), t('lsi.chart.prescribed')]];
        let maxValue = 0;

        patientLoxChartData.forEach((el) => {
            let dataRow = formatDateMonthYearYY(parseInt(el.YearMonthNumber.substr(6, 2)), parseInt(el.YearMonthNumber.substr(0, 4)));
            dataTable.push([dataRow, el.Delivered, el.Prescribed]);

            if (Math.max(el.Delivered, el.Prescribed) > maxValue) {
                maxValue = Math.max(el.Delivered, el.Prescribed);
            }
        });

        var graphColors = [];

        if (colors[colorIndex]) {
            graphColors = [colors[colorIndex]];
        } else {
            colorIndex = 0;
            graphColors = [colors[colorIndex]];
        }

        let vticks = getTicks(maxValue, 4);

        const chartOptions = {
            colors: colors,
            fontName: configuration.theme.fontFamily,
            fontSize: 12,
            title: "",
            titleTextStyle: {
                italic: false,
                fontSize: 18,
                bold: false,
                color: configuration.theme.primaryDark,
            },
            chartArea: {
                width: "100%",
                left: 60,
                top: 40,
                bottom: 70
            },
            bar: {
                groupWidth: 40
            },
            seriesType: "bars",
            series: {1: {type: "line"}},
            hAxis: {
                viewWindowMode: "pretty",
                titleTextStyle: {
                    italic: false,
                    color: configuration.theme.primaryDark,
                },
                textStyle: {
                    italic: false,
                    color: configuration.theme.primaryDark,
                },
                slantedText: true,
                slantedTextAngle: 45,

            },
            vAxis: {
                title: "mc O2",
                ticks: vticks,
                format: "#",
                viewWindow: {
                    min: 0
                },
                titleTextStyle: {
                    italic: false,
                    color: configuration.theme.primaryDark,
                },
                textStyle: {
                    italic: false,
                    color: configuration.theme.primaryDark,
                },
                baselineColor: "#F2F2F2",
                gridlines: {
                    color: "#F2F2F2"
                }
            },
            legend: {
                maxLines: 10,
                position: 'top',
                alignment: 'end',
                textStyle: {
                    fontSize: 10
                }
            },
        };

        graphs.push(
            <Chart chartType="ComboChart" height={200} data={dataTable} options={chartOptions}
                   key={"chart" + chartIndex}/>
        );

        colorIndex++;
        chartIndex++;

        return graphs;
    }

    return (
        <>
            {
                patientLoxChartData
                    ? <>
                        {
                            charts()
                        }
                    </>
                    : <span>{t('lsi.noDataAvailable')}</span>
            }
        </>
    );
}
