import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {TemplateModel} from "../libraries/header/models/TemplateModel";



export interface MenuSliceModel {
  template?: TemplateModel
}

const initialState: MenuSliceModel = {}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setTemplate: (state, action: PayloadAction<TemplateModel>) => {
      state.template = action.payload
    },
    resetTemplate: (state) => {
      state.template = undefined
    },
  },
})

export const {
  setTemplate,
  resetTemplate
} = menuSlice.actions

export const setTemplateAction = (template: any): any => (dispatch: any) => {
    dispatch(setTemplate(template))
}
export const resetTemplateAction = (): any => (dispatch: any) => {
    dispatch(resetTemplate())
}


export const selectTemplate = (state: any) => state.menu.template


export default menuSlice.reducer
