import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Input
} from "../../../../_metronic/_partials/controls";
import {Button} from "react-bootstrap";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import {Formik, Form, Field} from "formik";
import {ProvisionsTypeTitles} from "../../provisions/ProvisionsUIHelpers";
import {DeviceLogBookTypeTitle} from "../DevicesUIHelpers";
import * as actions from "../../../actions/generic/editActions";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function DeviceLogBookEditHome({id, history}) {
    const {t} = useLanguageForShared()

    const suhbeader = useSubheader();

    //prendo il logbook dallo store
    const {currentState} = useSelector(
        (state) => ({
            currentState: state.devices
        }),
        shallowEqual
    );

    const {deviceDetail, selectedDeviceLogBook} = currentState;

    //setto il titolo del subheader e inizializzo il wizard utilizzato per l'edit
    useEffect(() => {
        suhbeader.setTitle(deviceDetail ? `${t('lsi.device.deviceAlertLogbook')} - '${deviceDetail.BrandAndModel}' ${t('lsi.device.serial')} - '${deviceDetail.Serial}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('deviceLogBookWizard', options);
        //inserendo qui sotto deviceDetail il componente cambia al variare di deviceDetail
    }, [deviceDetail]);

    const customLabel = (props) => {
        return (
            <span>
                {
                    props.field.name === "ProvisionType"
                        ? ProvisionsTypeTitles()[props.field.value]
                        : props.field.name === "Type"
                            ? DeviceLogBookTypeTitle()[props.field.value]
                            : props.field.value
                }
            </span>
        );
    }

    const customInput = (props) => {
        return (
            <>
                {
                    props.field.name === "ProvisionType"
                        ? <>
                            <label>{t('lsi.device.provisionType')}</label>
                            <input type="text" className="sh form-control" name="ProvisionType" disabled
                                   value={ProvisionsTypeTitles()[props.field.value]}/>
                        </>
                        : <>
                            <label>{t('lsi.device.type')}</label>
                            <input type="text" className="sh form-control" name="Type" disabled
                                   value={DeviceLogBookTypeTitle()[props.field.value]}/>
                        </>
                }
            </>
        );
    }

    const dispatch = useDispatch();

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-12 col-lg-8">

                        {
                            selectedDeviceLogBook
                                ? <Card>
                                    <CardBody>
                                        <div id="deviceLogBookWizard" className="wizard wizard-3"
                                             data-wizard-state="step-first" data-wizard-clickable="true">
                                            <div className="wizard-nav">
                                                <div className="wizard-steps">
                                                    <div className="wizard-step" data-wizard-type="step"
                                                         data-wizard-state="current">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.generality')} <IconSVG icon={'chevron-right'} size={'14px'}
                                                                                    tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.note')} <IconSVG icon={'chevron-right'} size={'14px'}
                                                                              tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.review')} <IconSVG icon={'chevron-right'} size={'14px'}
                                                                                   tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center div-custom">
                                                <div className="col-12">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={selectedDeviceLogBook}
                                                        onSubmit={(values) => {
                                                            const model = {
                                                                GenericObject: {
                                                                    Id: values.Id,
                                                                    Reporting: values.Reporting,
                                                                    CustomerId: values.CustomerId,
                                                                    Date: values.Date,
                                                                    Type: DeviceLogBookTypeTitle()[values.Type],
                                                                    District: values.District,
                                                                    StaffInitials: values.StaffInitials,
                                                                    ProvisionType: ProvisionsTypeTitles()[values.ProvisionType],
                                                                    ProvisionId: values.ProvisionId,
                                                                    PatientFullName: values.PatientFullName,
                                                                    Section: "Libro Macchina Del Dispositivo"
                                                                }
                                                            }
                                                            dispatch(actions.editEntity(model,t));
                                                        }}
                                                    >
                                                        {({handleSubmit}) => (
                                                            <>
                                                                <Form className="form" onSubmit={handleSubmit}>
                                                                    <div className="pb-5" data-wizard-type="step-content"
                                                                         data-wizard-state="current">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.generality')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="Date"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.date')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="Type"
                                                                                    component={customInput}
                                                                                    label={t('lsi.table.tipology')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="District"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.district')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="StaffInitials"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.staffId')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="ProvisionType"
                                                                                    component={customInput}
                                                                                    label={t('lsi.table.tipologyProvision')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="PatientFullName"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.nominativePatient')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.alert')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <Field
                                                                                    name="Reporting"
                                                                                    component="textarea"
                                                                                    rows="5"
                                                                                    className="sh form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.reviewData')}</h4>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.generality')}</h6>
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.date')} </span>
                                                                                        <Field
                                                                                            name="Date"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.typology')} </span>
                                                                                        <Field
                                                                                            name="Type"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.district')} </span>
                                                                                        <Field
                                                                                            name="District"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>{t('lsi.table.staffId')} </span>
                                                                                        <Field
                                                                                            name="StaffInitials"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.tipologyProvision')} </span>
                                                                                        <Field
                                                                                            name="ProvisionType"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.nominativePatient')} </span>
                                                                                        <Field
                                                                                            name="PatientFullName"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.requestEdit')}</h6>
                                                                                <div
                                                                                    className="text-dark-50 line-height-lg">
                                                                                    <div>
                                                                                        <Field
                                                                                            name="Reporting"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-row">
                                                                        <BtnSecondary
                                                                            onClick={() => {
                                                                                window.history.back();
                                                                            }}
                                                                        >
                                                                            {t('lsi.cancel')}
                                                                        </BtnSecondary>
                                                                        <BtnSecondary
                                                                            data-wizard-type="action-prev"
                                                                        >
                                                                            {t('lsi.prev')}
                                                                        </BtnSecondary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-submit"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            {t('lsi.alert')}
                                                                        </BtnPrimary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-next"
                                                                        >
                                                                            {t('lsi.next')}
                                                                        </BtnPrimary>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
