import ModalCustomVH from "../../../shared/bootstrap/modal/ModalCustomVH";
import {FormElements} from "../../../shared/bootstrap/form/FormElements";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";
import {useForm} from "../../../shared/utils/forms/useForm";
import BtnPrimary from "../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useFormStructureData} from "./useFormStructureData";
import {IconSVG} from "../../../shared/custom/icon/IconSVG";

export default function SuccessMessage({}: {}) {
    const {t} = useLanguageForShared()


    return (
        <>
            <div className={'w-100 d-flex justify-content-center'}>
                <img src={`${process.env.REACT_APP_LOAD_CONFIG}/assets/images/success.png`}/>
            </div>
        </>
    );
}
