import React, {useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import {KTScrollFilters} from "../../../component/scroll/Scroll";
import {InvoicesFilters} from "./InvoicesFilters";
import {InvoicesTable} from "./InvoicesTable";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const InvoicesHome = () => {
    const {t} = useLanguageForShared()

    /*    useEffect(() => {
        useEffect(() => {
            KTScrollFilters.init("invoicesFilters")
        }, []);
    */
    return (
        <div className="row layout-page" style={{zIndex: "1"}}>
            <div className="col-lg-2 filter-col">
                <Card className="card-sticky" id="invoicesFilters">
                    {/*<CardHeader title="Filtri" className="fw-bolder"/>*/}
                    <CardBody className="offcanvas-content">
                        <InvoicesFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <h3 className={'title-page'}>{t('lsi.provision.invoices')}</h3>
                <InvoicesTable/>
            </div>
        </div>
    );
};
