import { usersSlice } from "./usersSlice";
import { spinnerCreate } from "../spinner/spinnerActions";
import {companies, path} from "../../utils/constant";
import http from "../../../shared/utils/http";
import axios from "axios";

const { actions } = usersSlice;

export const fetchCustomers = (user) => async dispatch => {
    dispatch(spinnerCreate(true));




    try {
        axios.get(path+'/customers').then(res => {
            const customerList = (res.data || []).map(ele => ({
                CustomerAddress: "",
                CustomerId: ele.id,
                CustomerName: ele.name,
                CustomerFeatures: ele.customerFeatures
            }))
            dispatch(actions.usersCustomersFetched({ entities: customerList }));
            const customer = localStorage.getItem("customerId") ? (customerList.find(ele => ele.CustomerId === localStorage.getItem("customerId") ) || customerList[0])  : customerList[0]
            dispatch(setCustomerId(customer))
            dispatch(spinnerCreate(false));
        })
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const setCustomerId = customer => async dispatch => {
    dispatch(actions.setSelectedCustomer( customer ));
    dispatch(actions.clearAllFilters());
}
