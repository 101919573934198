import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as invoicesActions from "../../../actions/invoices/invoicesActions";
import {useCostsUIContext} from "../CostsUIContext";
import {selectCostChartData} from "../../../actions/invoices/invoicesSlice";
import {CostsChartGraph} from "./CostsChartGraph";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";


export function CostsChart() {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();
    const costChartData = useSelector(selectCostChartData)
    //prendo lo stato dei filtri dal context e lo setto in costsUIProps
    const costsUIContext = useCostsUIContext();
    const costsUIProps = useMemo(() => {
        return {
            queryCostParams: costsUIContext.queryCostParams
        };
    }, [costsUIContext]);


    //al caricamento del componente e ogni volta che cambiano i filtri chiamo la funzione per scaricarmi i dati dal db
    useEffect(() => {
        console.log(costsUIProps.queryCostParams)
        dispatch(invoicesActions.fetchCostsChart(costsUIProps.queryCostParams));
    }, [costsUIProps.queryCostParams]);

    useEffect(() => {
        dispatch(invoicesActions.loadDistricts());
    }, [])
    return (
        costChartData.length > 0
            ? <CostsChartGraph costChartData={costChartData} />
            : <span>{t('lsi.noDataAvailable')}</span>
    );
}
