/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/users/login-1.scss";
import moment from "moment";
import ChangeUser from "./ChangeUser";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function AuthPage({isLogged}) {
    const {t} = useLanguageForShared();
    const logo = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/logo/logo${"_login"}.png`;
    const img_login = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/login/bg_login.png`;


    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                     id="kt_login">
                    <div
                        className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">
                            <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
                                <img src={logo}
                                     className="text-center" alt="" style={{maxHeight: "100px"}}/>
                                {isLogged ? <Switch>
                                    <ContentRoute path="/auth/change-user" component={ChangeUser}/>
                                    <Redirect from="/auth" exact={true} to="/auth/change-user"/>
                                    <Redirect to="/auth/change-user"/>
                                </Switch> : <Switch>
                                    <ContentRoute path="/auth/login" component={Login}/>
                                    <Redirect from="/auth" exact={true} to="/auth/login"/>
                                    <Redirect to="/auth/login"/>
                                </Switch>}
                            </div>
                            <div className="d-flex flex-column-auto justify-content-center mt-15">
                                <div
                                    className="text-dark-50 fw-bold order-2 order-sm-1 my-2"> © {moment().format("YYYY")} {configuration?.external?.footerLabel || ""}</div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="bg-login-image order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7"
                        style={{backgroundImage: `url(${img_login})`}}>
                        <div className="d-flex flex-column-fluid flex-lg-center">
                            <div className="d-flex flex-column justify-content-center">
                                <h3 className="display-3 fw-bold my-7 text-white">{t("login.brandName")}</h3>
                                {/* <p className="fw-bold font-size-lg text-white opacity-80">The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme
    					<br />framework for next generation web apps.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
