import React from "react";
import {
    headerSortingClasses,
    sortCaret

} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPatientMedicinalsSorted} from "./PatientInfoUIHelpers"
import {
    AssetsColumnFormatter, /*FotoColumnFormatter,*/
    MedicinalsFotoColumnFormatter
} from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {tableSortByDate} from "../../../component/table/tableSort";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientMedicinalsTable({medicinals}) {
    const {t} = useLanguageForShared()

    const columns = [
/*        {
            isDummyField: true,
            dataField: "Foto",
            text: "",
            //formatter: FotoColumnFormatter,
            formatter: MedicinalsFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,
            attrs:{
                'data-test': 'patient-id'
            }
        },
        {
            dataField: "Description",
            text: t('lsi.table.description'),
            sort: false,
            attrs:{
                'data-test': 'patient-description'
            }
        },
        {
            dataField: "LotNumber",
            text: t('lsi.table.lotNumber'),
            sort: false,
            attrs:{
                'data-test': 'lot-number'
            }
        },
        {
            dataField: "Quantity",
            text: t('lsi.table.quantity'),
            sort: false,
            attrs:{
                'data-test': 'quantity'
            }
        },
        {
            dataField: "DeliveryDate",
            text: t('lsi.table.deliveryDate'),
            sort: true,
            sortFunc: tableSortByDate,
            attrs:{
                'data-test': 'delivery-date'
            }

        }
    ];
    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            keyField="Serial"
            data={medicinals === null ? [] : medicinals}
            columns={columns}
            defaultSorted={defaultPatientMedicinalsSorted}
            noDataIndication={t('lsi.noDataAvailable')}
        >
        </BootstrapTable>
    );
}
