import React from "react";
import {ProgressChart} from "../../../component/progressChart/ProgressChart";
import {DetailProgress} from "../../../component/progressChart/DetailProgress";

const ProgressStats = ({labelUsed, labelActive, labelTitle, active, used}) => {
    const percentage = Math.ceil((+used / +active) * 100)

    return <div className={'d-flex align-items-center'} style={{minHeight: '150px'}}>
        <div className={'w-100'}>
            <ProgressChart percentage={percentage} title={labelTitle}/>
            <div className={'mt-2'}>
                <DetailProgress active={active} used={used} labelActive={labelActive} labelUsed={labelUsed}/>
            </div>
        </div>
    </div>
}

export default React.memo(ProgressStats);
