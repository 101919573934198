import axios from "axios";
import { path } from "../../utils/constant";

export function findStaffs(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{staffs(' + queryParams + '){pageInfo{endCursor hasNextPage}nodes{id initials firstName lastName birthDate districtName title identityDocument assets}}}'
        });
}

export function findStaffsFilters(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{staffFilters' + queryParams + ' {districts titles}}'
        });
}
