export default function (isPatientLoxGraph?: boolean, isRequestPrescription?: boolean) {
    const sections = [{
        name: 'patient',
        voices: [
            {
                name: 'patientGraph',
                urlPage: '/#patientGraph',
            },
            {
                name: 'patientRegistry',
                urlPage: '/#patientRegistry',
            },
            ...(isRequestPrescription ? [{
                name: 'patientRequestPrescription',
                urlPage: '/#patientRequestPrescription',
            }] : []),
            {
                name: 'patientHistory',
                urlPage: '/#patientHistory',
            },
            ...(isPatientLoxGraph ? [{
                name: 'patientLoxGraph',
                urlPage: '/#patientLoxGraph',
            }] : []),
            {
                name: 'patientPrescriptions',
                urlPage: '/#patientPrescriptions',
            },
            {
                name: 'patientProvisions',
                urlPage: '/#patientProvisions',
            },
            {
                name: 'patientDevices',
                urlPage: '/#patientDevices',
            },
            {
                name: 'patientAccessories',
                urlPage: '/#patientAccessories',
            },
            {
                name: 'patientConsumable',
                urlPage: '/#patientConsumable',
            },
            {
                name: 'patientMedicinals',
                urlPage: '/#patientMedicinals',
            },
            {
                name: 'patientInvoices',
                urlPage: '/#patientInvoices',
            },
            {
                name: 'patientCosts',
                urlPage: '/#patientCosts',
            },


        ]
    }]
    return {
        sections
    }
}
