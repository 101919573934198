import React from "react";
import {IconSVG} from "../../../shared/custom/icon/IconSVG";
import {components} from "react-select";


export function ClearIndicator(props) {
    const ClearIndicatorIcon = () => {
        return <IconSVG icon={'cross'} size={'8px'}/>
    };


    return (
        <components.ClearIndicator {...props}>
            <ClearIndicatorIcon/>
        </components.ClearIndicator>
    );
}
