import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import {Formik, Form, Field} from "formik";
import {
    Card,
    CardBody,
    Input
} from "../../../../_metronic/_partials/controls";
import {getProvisionStateTitles, getProvisionTypeTitles} from "../ProvisionsUIHelpers";
import * as actions from "../../../actions/generic/editActions";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionEditHome({id}) {
    const {t} = useLanguageForShared()

    const suhbeader = useSubheader();

    const {provisionMainSection} = useSelector(
        (state) => ({
            provisionMainSection: state.provisions.provisionMainSection
        }),
        shallowEqual
    );

    useEffect(() => {
        suhbeader.setTitle(provisionMainSection ? `${t('lsi.provision.provisionAlert')} - '${id}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('provisionMainInfoWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provisionMainSection]);

    const customLabel = (props) => {
        return (
            <span>
                {props.field.name === "ProvisionState" ? getProvisionStateTitles(props.field.value) : props.field.value}
            </span>
        );
    }

    const customInput = (props) => {
        return (
            <>
                <label>${t('lsi.provision.stateProvision')}</label>
                <input type="text" className="sh form-control" name="ProvisionState" disabled
                       value={getProvisionStateTitles(props.field.value)}/>
            </>
        );
    }

    const dispatch = useDispatch();

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-12 col-lg-8">
                        {
                            provisionMainSection
                                ? <Card>
                                    <CardBody>
                                        <div id="provisionMainInfoWizard" className="wizard wizard-3"
                                             data-wizard-state="step-first"
                                             data-wizard-clickable="true">
                                            <div className="wizard-nav">
                                                <div className="wizard-steps">
                                                    <div className="wizard-step" data-wizard-type="step"
                                                         data-wizard-state="current">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.generality')} <IconSVG icon={'chevron-right'} size={'14px'}
                                                                                    tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.note')} <IconSVG icon={'chevron-right'} size={'14px'}
                                                                              tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.review')} <IconSVG icon={'chevron-right'} size={'14px'}
                                                                                   tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center  div-custom">
                                                <div className="col-12">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={provisionMainSection}
                                                        onSubmit={(values) => {
                                                            const model = {
                                                                GenericObject: {
                                                                    Id: values.Id,
                                                                    DocumentNumber: values.DocumentNumber,
                                                                    ProvisionType: getProvisionTypeTitles(values.ProvisionType),
                                                                    PatientName: values.PatientName,
                                                                    ProvisionDate: values.ProvisionDate,
                                                                    StaffInitials: values.StaffInitials,
                                                                    ProvisionState: getProvisionStateTitles(values.ProvisionState),
                                                                    Reporting: values.Reporting,
                                                                    CustomerId: values.CustomerId,
                                                                    Section: "Prestazione"
                                                                }
                                                            }
                                                            dispatch(actions.editEntity(model,t));
                                                        }}
                                                    >
                                                        {({handleSubmit}) => (
                                                            <>
                                                                <Form className="form" onSubmit={handleSubmit}>
                                                                    <div className="pb-5" data-wizard-type="step-content"
                                                                         data-wizard-state="current">
                                                                        <h4 className="mb-10 fw-bold text-dark"> {t('lsi.edit.generality')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="ProvisionType"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.tipologyProvision')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="PatientName"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.nominative')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-4">
                                                                                <Field
                                                                                    name="ProvisionDate"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.date')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <Field
                                                                                    name="ProvisionState"
                                                                                    component={customInput}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <Field
                                                                                    name="StaffInitials"
                                                                                    component={Input}
                                                                                    label={t('lsi.table.staffId')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.alert')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <Field
                                                                                    name="Reporting"
                                                                                    component="textarea"
                                                                                    rows="5"
                                                                                    className="sh form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.reviewData')}</h4>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.generality')}</h6>
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.edit.tipologyProvision')} </span>
                                                                                        <Field
                                                                                            name="ProvisionType"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.edit.nominativePatient')} </span>
                                                                                        <Field
                                                                                            name="PatientName"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.date')} </span>
                                                                                        <Field
                                                                                            name="ProvisionDate"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.table.staffId')} </span>
                                                                                        <Field
                                                                                            name="StaffInitials"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.stateProvision')} </span>
                                                                                        <Field
                                                                                            name="ProvisionState"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.requestEdit')} </h6>
                                                                                <div
                                                                                    className="text-dark-50 line-height-lg">
                                                                                    <div>
                                                                                        <Field
                                                                                            name="Reporting"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-row">
                                                                        <BtnSecondary
                                                                            onClick={() => {
                                                                                window.history.back();
                                                                            }}
                                                                        >
                                                                            {t('lsi.cancel')}
                                                                        </BtnSecondary>
                                                                        <BtnSecondary
                                                                            data-wizard-type="action-prev"
                                                                        >
                                                                            {t('lsi.prev')}
                                                                        </BtnSecondary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-submit"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            {t('lsi.notify')}
                                                                        </BtnPrimary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-next"
                                                                        >
                                                                            {t('lsi.next')}
                                                                        </BtnPrimary>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
