 import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import {ProvisionsTable} from "./ProvisionsTable";
import {ProvisionsChart} from "./ProvisionsChart";
import {ProvisionsFilters} from "./ProvisionsFilters";
import {PlannedProvisions} from "./PlannedProvisions";
import {ProvisionsInProgressTable} from "./ProvisionsInProgressTable";
import {useProvisionsUIContext} from "../ProvisionsUIContext";
import {KTScrollFilters} from "../../../component/scroll/Scroll";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const ProvisionsHome = () => {
    const {t} = useLanguageForShared()

    const provisionsUIContext = useProvisionsUIContext();

    return (
        <div className="row layout-page">
            <div className="col-lg-2 filter-col">
                <Card className="card-sticky" id="provisionsFilter">
                    {/*<CardHeader title="Filtri" className="fw-bolder"></CardHeader>*/}
                    <CardBody className="offcanvas-content">
                        <ProvisionsFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <h3 className={'title-page'}>{t('lsi.provision.provisions')}</h3>

                <Card>
                    <CardHeader title={t('lsi.provision.plannedProvisions')} className="fw-bolder"/>
                    <CardBody>
                        <PlannedProvisions/>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader title={t('lsi.provision.graphProvisions')} className="fw-bolder"/>
                    <CardBody>
                        <ProvisionsChart/>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader title={t('lsi.provision.provisionFrom') + ' ' + provisionsUIContext.queryParams.filter.ProvisionsDate.split('-')[0].trim() + ' ' + t('lsi.provision.to') + ' ' + provisionsUIContext.queryParams.filter.ProvisionsDate.split('-')[1].trim()} className="fw-bolder"/>
                    <CardBody>
                        <ProvisionsInProgressTable/>
                    </CardBody>
                </Card>
                <ProvisionsTable/>
            </div>
        </div>
    );
};
