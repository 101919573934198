import React, {useEffect} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as deviceActions from "../../../actions/devices/devicesActions";
import {DeviceCard} from "./DeviceCard";
import {DeviceLastPatientAssignedTable} from "./DeviceLastPatientAssignedTable";
import {DeviceInfoMenu} from "./DeviceInfoMenu";
import {DeviceLogBookTable} from "./DeviceLogBookTable";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import {resetTemplateAction, setTemplateAction} from "../../../../redux/menuSlice";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

//import { DeviceAccessoriesTable } from "./DeviceAccessoriesTable";

export function DeviceInfoHome({history, id}) {
    const {t} = useLanguageForShared()


    const dispatch = useDispatch();

    //prendo il dettaglio dispositivo dallo store
    const {deviceDetail} = useSelector(
        (state) => ({
            deviceDetail: state.devices.deviceDetail
        }),
        shallowEqual
    );

    //al caricamento del componente parte la query per prendere il dispositivo dal db
    useEffect(() => {
        dispatch(deviceActions.fetchDevice(id));
    }, [id, dispatch]);

    useEffect(() => {
        deviceDetail && dispatch(setTemplateAction({name: 'device', data: deviceDetail}))
        return () => {
            deviceDetail && dispatch(resetTemplateAction())
        }
    }, [deviceDetail])

    return (
        <div className="row ae-page-content-container">
            <div className="col-lg-2 ae-sidebar-content">
                <Card className="card-sticky">
                    <CardBody>
                        <DeviceInfoMenu/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10">
                <h3 className={'title-page'}>{deviceDetail && `${t('lsi.table.device')}`}</h3>

                <a name="deviceCard"/>
                <Card>
                    <CardHeader title={t('lsi.device.deviceSheet')} className="fw-bolder">
                        <CardHeaderToolbar>
                            <BtnDefault
                                className="border-0 p-2 h-auto text-capitalize"
                                onClick={() => history.push(`/dispositivi/${deviceDetail.Id}/segnalazione`)}
                                icon={'priority_message'}
                                tintColor={configuration.theme.primary}
                                iconSize={'24px'}
                            >
                                {t('lsi.sendNotification')}
                            </BtnDefault>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <DeviceCard deviceDetail={deviceDetail} history={history}/>
                    </CardBody>
                </Card>
                <a name="deviceLastPatientAssigned"/>
                <Card>
                    <CardHeader title={t('lsi.device.lastPatientAssigned')} className="fw-bolder"/>
                    <CardBody>
                        <DeviceLastPatientAssignedTable
                            deviceLastPatient={deviceDetail ? deviceDetail.UltimatePatientAssigned : []}
                            history={history}/>
                    </CardBody>
                </Card>
                <a name="deviceLogBook"/>
                <Card>
                    <CardHeader title={t('lsi.device.logbook')} className="fw-bolder"/>
                    <CardBody>
                        <DeviceLogBookTable deviceLogBook={deviceDetail ? deviceDetail.LogBook : []} history={history}/>
                    </CardBody>
                </Card>
                {/* <a name="deviceAccessories" />
                <Card>
                    <CardHeader title="Accessori" className="fw-bolder"/>
                    <CardBody>
                        <DeviceAccessoriesTable devicesAccessories={deviceDetail ? deviceDetail.Accessories : []} history={history} />
                    </CardBody>
                </Card> */}
            </div>
        </div>
    );
}
