import {ClinicalDiaryItemResponse} from '../../models/ClinicalDiaryItemModel'

import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

function InfoData({selectEvent, subjectTypes}: { selectEvent: ClinicalDiaryItemResponse, subjectTypes: any[] }) {
    const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()
    const {t} = useLanguageForShared()

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="row align-items-baseline">
                        <label className="control-label col-md-5">{t('note.newNote.date')}</label>
                        <div className="col-md-7">
                            <p className="form-control-static">
                                {dateFormatGGmmmYYYYhhMM(selectEvent?.Date)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row align-items-baseline">
                        <label className="control-label col-md-5">
                            {t('note.newNote.subject')}
                        </label>
                        <div className="col-md-7">
                            <p className="form-control-static">
                                {t(
                                    'note.object.' +
                                    (subjectTypes.find((el) => el.value === +selectEvent?.Subject)
                                        ?.label || 'no_object')
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row align-items-baseline">
                        <label className="control-label col-md-5">{t('note.newNote.note')}</label>
                        <div className="col-md-7">
                            <p className="form-control-static">{selectEvent?.Note}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row align-items-baseline">
                        <label className="control-label col-md-5">
                            {t('note.newNote.operator')}
                        </label>
                        <div className="col-md-7">
                            <p className="form-control-static">{selectEvent?.Operator}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoData
