import {useEffect, useState} from 'react'
import {ClinicalDiaryItem} from '../../../models/ClinicalDiaryItemModel'
import ListFilesV from './ListFilesV'
import NoteContentLayoutV from './NoteContentLayoutV'
import ToolbarRightV from './ToolbarRightV'
import DateManageV from './DateManageV'
import NoteManage from './NoteManage'
import FooterManageV from './FooterManageV'
import SubjectManage from './SubjectManage'

import {formDataDateV, formDataNoteV, formDataOperatorV} from '../formDataV'
import {useDispatch} from 'react-redux'

import AddFileV from '../../event-store/AddFileV'
import {FileDataModel} from '../../../models/FileDataModel'
import ModalCustom from '../../../../../shared/bootstrap/modal/ModalCustom'
import BtnSecondary from '../../../../../shared/bootstrap/button/secondary/BtnSecondary'
import BtnPrimary from '../../../../../shared/bootstrap/button/primary/BtnPrimary'
import {UseFormSubmitModel} from "../../../../../shared/utils/forms/models/UseFormModel";
import {useForm} from "../../../../../shared/utils/forms/useForm";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../shared/configuration";
import {DeleteFileActionType, EditNoteActionType, UploadFileActionType} from "../../../models/ActionModel";
import {useDateCustom} from "../../../../../shared/custom_use/useDateCustom";
import {addSuccessMessage} from "../../../../../shared/redux/errors/errorSlice";

export default function NoteItemV({
                                      note,
                                      editNoteAction,
                                      uploadFileHandler,
                                      notifyHandler,
                                        downloadAction,
                                      readonly,
                                      deleteFileAction,
                                      subjectTypes
                                  }: {
    note: ClinicalDiaryItem
    subjectTypes: any[]
    editNoteAction: EditNoteActionType
    uploadFileHandler: UploadFileActionType
    deleteFileAction: DeleteFileActionType
    notifyHandler: (data: any) => void
    downloadAction: (data: any) => void
    readonly: boolean
}) {
    const dispatch = useDispatch()
    const [isAddFile, setIsAddFile] = useState(false)
    const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()
    const [confirmModal, setConfirmModal] = useState<any | undefined>(undefined)

    const [isEditMode, setIsEditMode] = useState(false)
    const {t} = useLanguageForShared()
    const editNote = () => {
        setIsEditMode(true)
    }

    const confirmDeleteNote = () => {
        editNoteAction({...note, Deleted: true}, () => {
            dispatch(addSuccessMessage({title: t('note.success.successMsg.deleteNote')}))
            setConfirmModal(undefined)
        })
    }
    const confirmDeleteFile = (id: string) => {
        note && deleteFileAction(id, note.ItemID, note, () => {
            dispatch(
                addSuccessMessage({title: t('note.success.successMsg.deleteFile')})
            )
            setConfirmModal(undefined)
        })
    }
    const deleteNote = () => {
        setConfirmModal({
            title: t('note.msg.confirmDeleteTitle'),
            text: t('note.msg.deleteNoteText'),
            action: confirmDeleteNote,
        })
    }
    const deleteFile = (id: string) => {
        setConfirmModal({
            title: t('note.msg.confirmDeleteTitle'),
            text: t('note.msg.deleteFileText'),
            action: () => confirmDeleteFile(id),
        })
    }

    const uploadFile = (file: FileDataModel) => {
        note.ItemID && uploadFileHandler(file, note, () => {
            setIsAddFile(false)
        })
    }
    const saveNote = () => {
        setIsEditMode(false)
        submitAction()
    }
    const cancelEdit = () => {
        setIsEditMode(false)
        setIsAddFile(false)
    }

    const submitHandler: UseFormSubmitModel = (event, data: any) => {
        editNoteAction(data, () => {
            dispatch(addSuccessMessage({title: t('note.success.successMsg.editNote')}))
        })
    }
    const {dataForm, changeValue, submitAction, errors, setData} = useForm(
        submitHandler
    )
    useEffect(() => {
        setData(note)
    }, [note, isEditMode])

    const formDataSubject: any = [
        {
            name: 'Subject',
            type: 'select',
            options: subjectTypes.map((ele) => ({
                ...ele,
                label: t('note.object.' + ele.label),
            })),
            dataElement: {
                type: 'text',
            },
        },
    ]
    return (
        <>
            <NoteContentLayoutV
                dateTemplate={
                    <DateManageV
                        date={note?.Date}
                        isEditMode={isEditMode}
                        changeValue={changeValue}
                        dataForm={dataForm}
                        errors={errors}
                        formData={formDataDateV(t)}
                    />
                }
                subjectTemplate={
                    <SubjectManage
                        subject={note.SubjectLabel}
                        isEditMode={isEditMode}
                        changeValue={changeValue}
                        dataForm={dataForm}
                        errors={errors}
                        formData={formDataSubject}
                    />
                }
                toolbarRightTemplate={
                   (!note.Deleted && !readonly) && (
                        <ToolbarRightV
                            deleteNote={deleteNote}
                            editNote={editNote}
                            uploadHandler={() => setIsAddFile(true)}
                            notifyHandler={() => notifyHandler(note)}
                            saveHandler={saveNote}
                            cancelHandler={cancelEdit}
                            isEditMode={isEditMode}
                            isAddFile={isAddFile}
                        />
                    )
                }
                notesTemplate={
                    <NoteManage
                        note={note.Note}
                        isEditMode={isEditMode}
                        changeValue={changeValue}
                        dataForm={dataForm}
                        errors={errors}
                        formData={formDataNoteV(t)}
                    />
                }
                footerTemplate={
                    <FooterManageV
                        operator={note.Operator}
                        lastUpdate={dateFormatGGmmmYYYYhhMM(note.LastUpdate)}
                        isEditMode={isEditMode}
                        changeValue={changeValue}
                        dataForm={dataForm}
                        errors={errors}
                        formData={formDataOperatorV(t)}
                    />
                }
                listFileTemplate={
                    <>
                        <ListFilesV
                            downloadAction={downloadAction}
                            attachments={note.Attachments}
                            deleteItemHandler={deleteFile}
                            isEditMode={isEditMode}
                        />
                        {isAddFile && (
                            <AddFileV
                                addFile={(file) => uploadFile(file)}
                                onClose={() => setIsAddFile(false)}
                            />
                        )}
                    </>
                }
                isEditMode={isEditMode}
            />
            {confirmModal && (
                <ModalCustom
                    size="md"
                    title={confirmModal.title}
                    closeModal={() => setConfirmModal(false)}
                    footerTemplate={
                        <div className={'btn-group-space'}>
                            <BtnSecondary
                                onClick={() => setConfirmModal(false)}
                                title={t('action.cancel')}
                            >
                                {t('action.cancel')}
                            </BtnSecondary>

                            <BtnPrimary
                                onClick={confirmModal.action}
                                title={t('action.confirm')}
                                tintColor={configuration.theme.primary}
                            >
                                {t('action.confirm')}
                            </BtnPrimary>
                        </div>
                    }
                >
                    {confirmModal.text}
                </ModalCustom>
            )}
        </>
    )
}



