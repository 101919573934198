import axios from "axios";
import { path } from "../../utils/constant";

export function getProvisionsInProgress(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{provisionsReviewV2(' + queryParams +  '){nodes{state count}}}'
        });
}

export function getProvisionsPlanned(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{provisionsScheduledMatrix(' + queryParams + '){nodes{week firstWeekDay lastWeekDay provisionType count}}}'
        });
}

export function findProvisionsFilters(queryParams) {
    return axios.post(
        path,
        {
            query: 'query {provisionTableFilters(' + queryParams + ') {districts provisionStates provisionTypes}}'
        });
}

export function findProvisions(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{' +
                'provisionTable(' + queryParams + '){pageInfo{hasNextPage endCursor}nodes{provisionId documentNumber patientId provisionType patientFirstName patientLastName district provisionDate staffInitials provisionState}}}'
        });
}

export function findProvisionsChart(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{provisionHistoryGraph(' + queryParams + '){nodes{year monthNumber monthName provisionType provisionState count}}}'
        });
}

export function getProvisionMainInfoById(id) {
    return axios.post(
        path,
        {
            query: 'query{provisionTableById(provisionId:"' + id + '"){nodes{documentNumber reasonCode provisionId provisionType patientFirstName patientLastName provisionDate staffInitials provisionState}}}'
        }
    );
}

export function getProvisionById(id) {
    return axios.post(
        path,
        {
            query: 'query{provisionById(provisionId:"' + id + '"){nodes{assets note id customerId provisionType patient{id careGiver{firstName lastName} addresses{address city zipCode province startDate endDate} patientNumbers phone gender firstName lastName birthDate cF patientState residenceAddress homeAddress district{description}}devices{id itemCode deviceType brand model description serial lastPatientAssigned{firstName lastName}provisionDeliveryDate provisionPickupDate assets}accessories{accessoryType itemCode brand model description serial lot provisionDeliveryDate provisionPickupDate assets}consumables{consumableType brand model description serial provisionQuantity lot provisionDeliveryDate assets}}}}'
        }
    );
}
