import {t} from 'i18next'
import React from 'react'
import {DropzoneOptions} from 'react-dropzone'
import Dropzone from '../../../../shared/external_lib/dropzone/Dropzone'

export default function DropzoneComponent({
                                              onDrop,
                                              options,
                                              title = 'note.action.dropzone.title',
                                              titleInvalidType = 'note.action.dropzone.errors.fileInvalidType',
                                              acceptFile = ['.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg'],
                                          }: {
    onDrop: (file: any) => void
    options?: DropzoneOptions
    title?: string
    titleInvalidType?: string
    acceptFile?: string[]
}) {
    const translationError = {
        'file-invalid-type': titleInvalidType,
        'file-too-large': t(`note.action.dropzone.errors.fileTooLarge`),
    }
    return (
        <div>
            <Dropzone
                onDrop={onDrop}
                translationError={translationError}
                options={{
                    maxFiles: 1,
                    maxSize: 52428800.02,
                    accept: acceptFile,
                    multiple: false,
                    ...options,
                }}
            >
                <div className="text-center">
                    <div className="">
                        <h4>{t(title)}</h4>
                    </div>
                    <div className="mb-2">
                        {t('note.action.dropzone.info')}
                        <b className="ms-2">{t('note.action.dropzone.maxSize')}</b>
                    </div>
                </div>
            </Dropzone>
        </div>
    )
}
