import {required} from "../../../../shared/utils/forms/utils/validationType";

export const formDataDateL: any = (t: any) => [
    {
        name: 'Date',
        label: t('note.newNote.date'),
        type: 'input',
        dataElement: {
            type: 'date',
            placeholder: t('note.newNote.date'),
        },
        extra: {
            type: 'datePicker',
            settings: {
                timePicker: true
            }
        },
        display: {
            form: 'row',
            label: 'col-12 col-md-4',
            child: 'col-12 col-md-8'
        },
    },
]

export const formDataNoteL: any = (t: any) => [
    {
        name: 'Note',
        label: t('note.newNote.note'),
        type: 'textarea',
        dataElement: {
            type: 'text',
            placeholder: t('note.newNote.note'),
        },
        display: {
            form: 'row',
            label: 'col-12 col-md-4 col-lg-2',
            child: 'col-12 col-md-8 col-lg-10'
        },
    },
]
export const formDataOperatorL: any = (t: any) => [
    {
        name: 'Operator',
        label: t('note.newNote.operator'),
        type: 'input',
        dataElement: {
            type: 'text',
            placeholder: t('note.newNote.operator'),
        },
        display: {
            form: 'row',
            label: 'col-12 col-md-4 col-lg-3',
            child: 'col-12 col-md-8 col-lg-9'
        }
    },
]

export const validationNewNoteL = (t: any) => [
    {
        name: 'Date',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.date'),
                },
            },
        ],
    },
    {
        name: 'SubjectText',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.subject'),
                },
            },
        ],
    },
    {
        name: 'Note',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.note'),
                },
            },
        ],
    },
    {
        name: 'Operator',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.operator'),
                },
            },
        ],
    },
]
export const validationNewReportL = (t: any) => [
    {
        name: 'Date',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.date'),
                },
            },
        ],
    },
    {
        name: 'Note',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.note'),
                },
            },
        ],
    },
]
