import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import React from "react";
import DiaryTemplateV from "../../DiaryTemplateV";
import DefaultLayoutL from "../DefaultLayoutL";
import ReportLayoutL from "../ReportLayoutL";

export default function NoteContentLayoutL({
                                               dateTemplate,
                                               subjectTemplate,
                                               notesTemplate,
                                               operatorTemplate,
                                               lastUpdateTemplate,
                                               toolbarRightTemplate,
                                               listFileTemplate,
                                               isEditMode,
                                               diaryTemplate = 'default'
                                           }: {
    dateTemplate: React.ReactChild
    subjectTemplate: React.ReactChild
    notesTemplate: React.ReactChild
    operatorTemplate: React.ReactChild
    lastUpdateTemplate: React.ReactChild
    toolbarRightTemplate?: React.ReactNode
    listFileTemplate: React.ReactChild
    isEditMode: boolean
    diaryTemplate?: string
}) {
    const {t} = useLanguageForShared()

    const templates: any = {
        default: () => <DefaultLayoutL
            dateTemplate={dateTemplate}
            subjectTemplate={subjectTemplate}
            notesTemplate={notesTemplate}
            operatorTemplate={operatorTemplate}
            lastUpdateTemplate={lastUpdateTemplate}
            toolbarRightTemplate={toolbarRightTemplate}
            listFileTemplate={listFileTemplate}
            isEditMode={isEditMode}
        />,
        report: () => <ReportLayoutL
            dateTemplate={dateTemplate}
            subjectTemplate={subjectTemplate}
            notesTemplate={notesTemplate}
            operatorTemplate={operatorTemplate}
            lastUpdateTemplate={lastUpdateTemplate}
            toolbarRightTemplate={toolbarRightTemplate}
            listFileTemplate={listFileTemplate}
            isEditMode={isEditMode}
        />
    }

    return (
        <>
            {isEditMode && <h4>
                {t('note.newNote.insertNote')}
            </h4>}
            <div className="note-item w-100 gap-3 border-bottom border-gray pb-2">
                {!isEditMode && <div className="">
                    <IconSVG icon={'note-item'} size={'40px'}/>
                </div>}
                <div className="w-100">
                    <div className="content">
                        <div className="row">
                            {templates && templates[diaryTemplate]()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
