import React from 'react'
import {ClinicalDiaryItem} from '../models/ClinicalDiaryItemModel'
import DiaryFilter from './filter/DiaryFilter'
import ListNotesV from './notes/ListNotesV'
import {useDiariesFilter} from './useDiariesFilter'
import {isOldestDate} from "../../../shared/utils/dateUtils";
import {DeleteFileActionType, EditNoteActionType, UploadFileActionType} from "../models/ActionModel";

const DiariesV: React.FC<{
    diaries: ClinicalDiaryItem[]
    readonly: boolean
    subjectTypes: any[]
    editNoteAction: EditNoteActionType
    uploadFileHandler: UploadFileActionType
    deleteFileAction: DeleteFileActionType
    notifyHandler: (data: any) => void
    downloadAction: (data: any) => void
}> = ({
          diaries = [],
          editNoteAction,
          uploadFileHandler,
          notifyHandler,
          downloadAction,
    deleteFileAction,
          subjectTypes,
          readonly
      }) => {
    const {filters, setFilters, diariesFilter} = useDiariesFilter(diaries)

    const lastAvailableMeasureDate: string = diaries?.reduce((acc: any, ele: any) => (acc && isOldestDate(acc, ele.Date) ? acc : ele.Date), undefined)

    return (
        <div className="container-fluid">
            {filters &&
                <DiaryFilter filters={filters} setFilters={setFilters}
                             lastAvailableMeasureDate={lastAvailableMeasureDate}/>}
            <ListNotesV
                deleteFileAction={deleteFileAction}
                subjectTypes={subjectTypes}
                readonly={readonly}
                downloadAction={downloadAction}
                notes={diariesFilter}
                editNoteAction={editNoteAction}
                uploadFileHandler={uploadFileHandler}
                notifyHandler={notifyHandler}
            />

        </div>
    )
}

export default DiariesV
