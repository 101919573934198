import {getTicks} from "../../../utils/utility";

export default function (maxValue, tickCount = 4, format = 'number') {
    const vticks = getTicks(maxValue, tickCount);
    const formatData = {
        number: (ele) => Intl.NumberFormat("it-IT").format(ele),
        currency: (ele) => Intl.NumberFormat("it-IT", {
            style: "currency",
            currency: "EUR",
        }).format(ele)
    }

    const dataFormatter = (format, data) => {
        return formatData[format](data)
    }

    const dateTicks = formatData[format] ? vticks.map(ele => ({
        v: ele,
        f: dataFormatter(format, ele)
    })) : vticks;

    return {
        vticks,
        dateTicks,
        dataFormatter
    }
}
