import React from 'react'
import { ClinicalDiaryItem } from '../../models/ClinicalDiaryItemModel'
import { FileDataModel } from '../../models/FileDataModel'
import NoteItemV from './components/NoteItemV'
import {DeleteFileActionType, EditNoteActionType, UploadFileActionType} from "../../models/ActionModel";

export default function ListNotesV({
  notes,
  editNoteAction,
  uploadFileHandler,
  notifyHandler,
    downloadAction,
    deleteFileAction,
   readonly,
   subjectTypes
}: {
  notes: ClinicalDiaryItem[]
  editNoteAction: EditNoteActionType
  deleteFileAction: DeleteFileActionType
  uploadFileHandler: UploadFileActionType
  notifyHandler:(data:any)=>void
  downloadAction:(data:any)=>void
  readonly: boolean
  subjectTypes: any[]
}) {
  return (
    <div className={'d-flex flex-column row-gap-5 mt-5'}>
      {notes.map((note) => (
        <div className="" key={note.ItemID}>
          <NoteItemV
              deleteFileAction={deleteFileAction}
              subjectTypes={subjectTypes}
              readonly={readonly}
              downloadAction={downloadAction}
            note={note}
            editNoteAction={editNoteAction}
            uploadFileHandler={uploadFileHandler}
            notifyHandler={notifyHandler}
          />
        </div>
      ))}
    </div>
  )
}
