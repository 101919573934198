import React, {useEffect, useState} from 'react'
import {ClinicalDiaryItem} from '../../../models/ClinicalDiaryItemModel'
import {useDispatch} from 'react-redux'

import {FileDataModel} from '../../../models/FileDataModel'
import ModalCustom from '../../../../../shared/bootstrap/modal/ModalCustom'
import BtnSecondary from '../../../../../shared/bootstrap/button/secondary/BtnSecondary'
import BtnPrimary from '../../../../../shared/bootstrap/button/primary/BtnPrimary'
import {UseFormSubmitModel} from "../../../../../shared/utils/forms/models/UseFormModel";
import {useForm} from "../../../../../shared/utils/forms/useForm";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../shared/configuration";
import NoteItemTemplateL from "./NoteItemTemplateL";
import {
    DeleteFileActionType,
    EditNoteActionType,
    UploadFileActionType
} from "../../../models/ActionModel";
import {addSuccessMessage} from "../../../../../shared/redux/errors/errorSlice";

export default function NoteItemL({
                                      note,
                                      editNoteAction,
                                      uploadFileHandler,
                                      subjectTypes,
                                      formDataSubject,
                                      deleteFileAction,
                                      readonly,
                                      downloadAction,
                                      diaryTemplate = 'default'
                                  }: {
    note: ClinicalDiaryItem
    editNoteAction: EditNoteActionType
    deleteFileAction: DeleteFileActionType
    uploadFileHandler: UploadFileActionType
    subjectTypes?: any[]
    formDataSubject: any
    readonly: boolean
    downloadAction: (data: any) => void
    diaryTemplate?: string
}) {
    const dispatch = useDispatch()
    const [isAddFile, setIsAddFile] = useState(false)

    const [confirmModal, setConfirmModal] = useState<any | undefined>(undefined)

    const [isEditMode, setIsEditMode] = useState(false)
    const {t} = useLanguageForShared()
    const editNote = () => {
        setIsEditMode(true)
    }

    const confirmDeleteNote = () => {
        editNoteAction({...note, Deleted: true}, () => {
            dispatch(addSuccessMessage({title: t('note.success.successMsg.deleteNote')}))
            setConfirmModal(undefined)
        })
    }
    const confirmDeleteFile = (id: string) => {
        note && deleteFileAction(id, note.ItemID, note, () => {
            dispatch(
                addSuccessMessage({title: t('note.success.successMsg.deleteFile')})
            )
            setConfirmModal(undefined)
        })
    }
    const deleteNote = () => {
        setConfirmModal({
            title: t('note.msg.confirmDeleteTitle'),
            text: t('note.msg.deleteNoteText'),
            action: confirmDeleteNote,
        })
    }
    const deleteFile = (id: string) => {
        setConfirmModal({
            title: t('note.msg.confirmDeleteTitle'),
            text: t('note.msg.deleteFileText'),
            action: () => confirmDeleteFile(id),
        })
    }

    const uploadFile = (file: FileDataModel) => {
        note.ItemID && uploadFileHandler(file, note, () => {
            setIsAddFile(false)
        })
    }
    const saveNote = () => {
        setIsEditMode(false)
        submitAction()
    }
    const cancelEdit = () => {
        setIsEditMode(false)
        setIsAddFile(false)
    }

    const submitHandler: UseFormSubmitModel = (event, data: any) => {
        editNoteAction(data, () => {
            dispatch(addSuccessMessage({title: t('note.success.successMsg.editNote')}))
        })
    }
    const {dataForm, changeValue, submitAction, errors, setData} = useForm(
        submitHandler
    )
    useEffect(() => {
        setData(note)
    }, [note, isEditMode])


    return (
        <>
            <NoteItemTemplateL
                downloadAction={downloadAction}
                readonly={readonly}
                note={note}
                isEditMode={isEditMode}
                changeValue={changeValue}
                dataForm={dataForm}
                errors={errors}
                formDataSubject={formDataSubject}
                deleteNote={deleteNote}
                editNote={editNote}
                setIsAddFile={setIsAddFile}
                saveNote={saveNote}
                cancelEdit={cancelEdit}
                isAddFile={isAddFile}
                deleteFile={deleteFile}
                uploadFile={uploadFile}
                diaryTemplate={diaryTemplate}
            />
            {confirmModal && (
                <ModalCustom
                    size="md"
                    title={confirmModal.title}
                    closeModal={() => setConfirmModal(false)}
                    footerTemplate={
                        <div className={'btn-group-space'}>
                            <BtnSecondary
                                onClick={() => setConfirmModal(false)}
                                title={t('action.cancel')}
                            >
                                {t('action.cancel')}
                            </BtnSecondary>

                            <BtnPrimary
                                onClick={confirmModal.action}
                                title={t('action.confirm')}
                                tintColor={configuration.theme.primary}
                            >
                                {t('action.confirm')}
                            </BtnPrimary>
                        </div>
                    }
                >
                    {confirmModal.text}
                </ModalCustom>
            )}
        </>
    )
}



