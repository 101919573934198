import React from 'react'
import {Attachment} from '../../../models/ClinicalDiaryItemModel'
import FileItemManageL from "./FileItemManageL";
import ListFilesReadL from "../../event-store/steps/components/ListFilesReadL";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

export default function ListFilesL({
                                       attachments,
                                       deleteItemHandler,
                                       isEditMode,
                                       newNote,
                                       files,
                                       setIsAddFile,
                                       isAddFile,
                                       downloadAction,
                                       removeFileInListHandler,
                                       note
                                   }: {
    attachments: Attachment[]
    deleteItemHandler?: (id: string) => void
    isEditMode: boolean
    newNote: boolean
    files: any[]
    setIsAddFile: any
    isAddFile: any
    removeFileInListHandler?: (file: string) => void
    downloadAction: (data: any) => void
    note: any
}) {
    const {t} = useLanguageForShared()

    return (
        <div className={`list-files mt-2 ${isEditMode ? 'column' : ''}`}>
            {isEditMode && <h4 className="">{t('note.newNote.filesList')}</h4>}
            {attachments?.map((file) => (
                <FileItemManageL
                    downloadAction={downloadAction}
                    attachment={file}
                    key={file.Id}
                    deleteHandler={() => deleteItemHandler && deleteItemHandler(file.Id)}
                    isEditMode={isEditMode}
                    note={note}
                />
            ))}

            {files && files.length > 0 && <ListFilesReadL
                files={files}
                removeFileInList={(file) => removeFileInListHandler && removeFileInListHandler(file)}
            />}
        </div>
    )
}
