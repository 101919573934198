import React from "react";
import {Dropdown} from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {PatientGenderTitles, PatientStatusTitles} from '../../pages/patients/PatientsUIHelpers';
import {ProvisionStatusTitles, ProvisionsTypeTitles} from '../../pages/provisions/ProvisionsUIHelpers';
import {documentTypeTitles} from "../../pages/invoices/InvoicesUIHelpers";
import moment from "moment";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import {IconSVG} from "../../../shared/custom/icon/IconSVG";
import configuration from "../../../shared/configuration";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";

export function ExportButton({data, columns, fileName, loadData}) {
    const {t} = useLanguageForShared()

    const unixMilliseconds = +moment();

    function getColumnForFiles() {
        let tableColumns = [];
        columns.forEach((el) => {
            if (el.text !== "CV" && el.text !== "Azioni" && el.text !== "Download") {
                tableColumns.push(el.text);
            }
        });
        return tableColumns;
    }

    function getDataForFiles(dataFile) {
        let dataColomns = []

        dataFile.forEach((el) => {
            let values = [];
            columns.forEach(col => {
                const field = col.dataField
                let fieldPresent = 1;
                if (fileName === "trattamenti") {
                    if (field === "Status") {
                        values.push(PatientStatusTitles()[el[field]]);
                    } else if (field === "Gender") {
                        values.push(PatientGenderTitles()[el[field]]);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "prestazioni") {
                    if (field === "Status") {
                        values.push(ProvisionStatusTitles()[el[field]]);
                    } else if (field === "ProvisionType") {
                        values.push(ProvisionsTypeTitles()[el[field]]);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (['dispositivi', 'personale', 'fatture'].includes(fileName)) {
                    if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "costi") {
                    if (field === "MonthNumber") {
                        const monthTitle = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
                        let year = el.MonthNumber.substring(0, 4);
                        let month = monthTitle[(el.MonthNumber.substring(4) - 1)];
                        let monthYear = month + "/" + year;
                        values.push(monthYear);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else if (fileName === "indicatori-di-servizio") {
                    if (field === "MonthNumber") {
                        const monthTitle = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
                        let year = el.MonthNumber.substring(0, 4);
                        let month = monthTitle[(el.MonthNumber.substring(4) - 1)];
                        let monthYear = month + "/" + year;
                        values.push(monthYear);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                } else {
                    if (field === "DocumentType") {
                        values.push(documentTypeTitles()[el[field]]);
                    } else if (fieldPresent > 0) {
                        values.push(el[field]);
                    }
                }
            })
            dataColomns.push(values);
        });

        return dataColomns;
    }

    function downloadCSV() {
        if (data && data.length > 0) {
            downloadCSVCallback(data);
        } else {
            loadData(downloadCSVCallback);
        }
    }

    async function downloadCSVCallback(dataCSV) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(fileName);
        var sheetColumns = [];
        columns.forEach((el) => {
            sheetColumns.push(
                {
                    header: el.text,
                    key: el.dataField
                }
            );
        });
        worksheet.columns = sheetColumns;
        worksheet.addRows(getDataForFiles(dataCSV));

        var CSVFilename = 'elenco-' + fileName + '-' + unixMilliseconds + '.csv';

        const buffer = await workbook.csv.writeBuffer();
        const fileType = 'text/plain;charset=utf-8';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, CSVFilename);
    }

    function downloadExcel() {
        if (data && data.length > 0) {
            downloadExcelCallback(data);
        } else {
            loadData(downloadExcelCallback);
        }
    }

    async function downloadExcelCallback(dataExcel) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(fileName);
        var sheetColumns = [];
        columns.forEach((el) => {
            sheetColumns.push(
                {
                    header: el.text,
                    key: el.dataField
                }
            );
        });
        worksheet.columns = sheetColumns;
        worksheet.addRows(getDataForFiles(dataExcel));

        var excelFilename = 'elenco-' + fileName + '-' + unixMilliseconds + '.xlsx';

        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFilename);
    }

    function downloadPDF() {
        if (data && data.length > 0) {
            downloadPdfCallback(data);
        } else {
            loadData(downloadPdfCallback);
        }
    }

    function downloadPdfCallback(dataPdf) {
        const doc = new jsPDF("l");

        doc.autoTable({
            head: [getColumnForFiles()],
            body: getDataForFiles(dataPdf)
        });

        doc.save('elenco-' + fileName + '-' + unixMilliseconds + '.pdf');
    }

    const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
        <button
            className="dropdown-toggle custom-dropdown"
            id="dropdown-basic-button"
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <IconSVG icon={'download'} size={'24px'} className={'me-2'} tintColor={configuration.theme.primary}/>
            {children}
        </button>
    ));

    const CustomMenu = React.forwardRef(
        ({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <ul className="navi flex-column navi-hover py-2">
                        {
                            React.Children.toArray(children).map(
                                (el, index) =>
                                    el.props.children === "CSV"
                                        ? <li key={"liCSV" + index} className="navi-item" onClick={() => {
                                            downloadCSV();
                                        }}>
                                            <div className="navi-link">
                                                <span className="navi-icon">
                                                    <IconSVG icon={'csv'} size={'24px'} tintColor={configuration.theme.primary}/>
                                                </span>
                                                <span className="navi-text text-primary">{el.props.children}</span>
                                            </div>
                                        </li>
                                        : el.props.children === "Excel"
                                            ? <li key={"liExcel" + index} className="navi-item" onClick={() => {
                                                downloadExcel();
                                            }}>
                                                <div className="navi-link">
                                                    <span className="navi-icon ">
                                                    <IconSVG icon={'xls'} size={'24px'} tintColor={configuration.theme.primary}/>
                                                    </span>
                                                    <span className="navi-text text-primary">{el.props.children}</span>
                                                </div>
                                            </li>
                                            : <a key={"liPDF" + index} className="navi-item" onClick={() => {
                                                downloadPDF();
                                            }}>
                                                <div className="navi-link">
                                                    <span className="navi-icon">
                                                    <IconSVG icon={'pdf'} size={'24px'} tintColor={configuration.theme.primary}/>
                                                    </span>
                                                    <span className="navi-text text-primary">{el.props.children}</span>
                                                </div>
                                            </a>
                            )
                        }
                    </ul>
                </div>
            );
        },
    );

    return (
        <Dropdown className="dropdown-inline me-2">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {t('lsi.export')}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} className="dropdown-menu-sm dropdown-menu-right custom-dropdown-menu">
                <Dropdown.Item eventKey="1">Excel</Dropdown.Item>
                <Dropdown.Item eventKey="2">CSV</Dropdown.Item>
                <Dropdown.Item eventKey="3">PDF</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
