import React, {useEffect} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../../_metronic/_partials/controls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as provisionsActions from "../../../actions/provisions/provisionsActions";
import {ProvisionInfoMenu} from "./ProvisionInfoMenu";
import {ProvisionMainInfo} from "./ProvisionMainInfo";
import {ProvisionPatientInfo} from "./ProvisionPatientInfo";
import {ProvisionDevicesTable} from "./ProvisionDevicesTable";
import {ProvisionAccessoriesTable} from "./ProvisionAccessoriesTable";
import {ProvisionConsumablesTable} from "./ProvisionConsumablesTable";
import {ProvisionAssetsTable} from "./ProvisionAssetsTable";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import {resetTemplateAction, setTemplateAction} from "../../../../redux/menuSlice";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionInfoHome({history, id}) {
    const {t} = useLanguageForShared()


    const dispatch = useDispatch();

    const {provision} = useSelector(
        (state) => ({
            provision: state.provisions
        }),
        shallowEqual
    );

    const {provisionMainSection, provisionOtherInfo} = provision

    useEffect(() => {
        dispatch(provisionsActions.fetchProvisionMainSection(id));
        dispatch(provisionsActions.fetchProvision(id));
    }, [id, dispatch]);


    useEffect(() => {
        provision && dispatch(setTemplateAction({name: 'provision', data: provision.provisionMainSection}))
        return () => {
            provision && dispatch(resetTemplateAction())
        }
    }, [provision])


    return (
        <div className="row ae-page-content-container">
            <div className="col-lg-2 ae-sidebar-content">
                <Card className="card-sticky">
                    <CardBody>
                        <ProvisionInfoMenu/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10">
                <h3 className={'title-page'}>{provisionMainSection?.DocumentNumber !== "-" ? `${t('lsi.provision.provision')} - ${provisionMainSection?.DocumentNumber}` : t('lsi.provision.provisionDetail')}</h3>

                <a name="provisionSectionMain"/>
                <Card>
                    <CardHeader title={t('lsi.infoPrimary')}  className="fw-bolder">
                        <CardHeaderToolbar>
                            <BtnDefault
                                className="border-0 p-2 h-auto text-capitalize"
                                onClick={() => history.push(`/prestazioni/${provisionMainSection.Id}/segnalazione`)}
                                icon={'priority_message'}
                                tintColor={configuration.theme.primary}
                                iconSize={'24px'}
                            >
                                {t('lsi.sendNotification')}
                            </BtnDefault>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <ProvisionMainInfo provisionMainSection={provisionMainSection} history={history}/>
                    </CardBody>
                </Card>
                <a name="provisionPatientInfo"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.registry')} className="fw-bolder"/>
                    <CardBody>
                        <ProvisionPatientInfo provisionInfo={provisionOtherInfo}/>
                    </CardBody>
                </Card>
                <a name="provisionDevices"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.devices')} className="fw-bolder"/>
                    <CardBody>
                        <ProvisionDevicesTable provisionDevices={provisionOtherInfo ? provisionOtherInfo.Devices : []}
                                               history={history}
                                               provisionType={provisionOtherInfo ? provisionOtherInfo.ProvisionType : 0}/>
                    </CardBody>
                </Card>
                <a name="provisionAccessories"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.accessories')} className="fw-bolder"/>
                    <CardBody>
                        <ProvisionAccessoriesTable
                            provisionAccessories={provisionOtherInfo ? provisionOtherInfo.Accessories : []}
                            provisionType={provisionOtherInfo ? provisionOtherInfo.ProvisionType : 0}/>
                    </CardBody>
                </Card>
                <a name="provisionConsumables"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.consumables')} className="fw-bolder"/>
                    <CardBody>
                        <ProvisionConsumablesTable
                            provisionConsumables={provisionOtherInfo ? provisionOtherInfo.Consumables : []}/>
                    </CardBody>
                </Card>
                <a name="provisionInfoDetail"/>
                <Card>
                    <CardHeader title={t('lsi.provision.provisionDetail')} className="fw-bolder">
                        <CardHeaderToolbar>
                            <BtnDefault
                                className="border-0 p-2 h-auto text-capitalize"
                                onClick={() => history.push(`/prestazioni/${id}/segnalazione-informazioni-dettaglio`)}
                                icon={'priority_message'}
                                tintColor={configuration.theme.primary}
                                iconSize={'24px'}
                                disabled={provisionOtherInfo?.Assets && provisionOtherInfo?.Assets?.length <= 0}
                            >
                                {t('lsi.sendNotification')}
                            </BtnDefault>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <ProvisionAssetsTable
                            provisionAssets={provisionOtherInfo ? provisionOtherInfo.Assets : []}
                            provisionNote={provisionOtherInfo ? provisionOtherInfo?.Note : ""}
                            history={history}
                            id={id}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}
