import moment from "moment";

/**
 * 
 * @param {any} month Mese da 1 a 12
 * @param {any} year
 */
export const formatDateMonthYear = (month, year) => {
    return `${moment().month(month-1).format('MMM')} ${year}`
}
export const formatDateMonthYearYY = (month, year) => {
    return `${moment().month(month-1).format('MMM')} ${year?.toString().slice(-2)}`
}
