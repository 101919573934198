import {useCallback} from "react";
import {formatDateMonthYear, formatDateMonthYearYY} from "../../../../utils/dateUtils";
import useGraphServiceIndicator from "../useGraphServiceIndicator";

export default function (extraordinaryPerformance, t){

    const dataCallback = useCallback(() => {
        return [[
            t('lsi.chart.monthYear'),
            t('lsi.chart.scheduled'),
            t('lsi.chart.extraordinaries'),
        ], ...extraordinaryPerformance.map(ele => [formatDateMonthYearYY(ele.month, ele.year), ele.ordinary, ele.extraordinary ])]
    }, [extraordinaryPerformance])
    const dataTable = dataCallback();
    const {maxValue, dateTicks} = useGraphServiceIndicator(dataTable)
    return {
        dataTable,
        maxValue,
        dateTicks
    }
}
