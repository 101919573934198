import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/table.css";
import {useDispatch} from "react-redux";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {defaultPatientHistorySorted} from "./PatientInfoUIHelpers";
import {tableSortByDate} from "../../../component/table/tableSort";
import {StatusPatientsColumnFormatter} from "../../../component/table/column-formatters/StatusColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientHistory({statusHistory, history}) {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,
            attrs:{
                'data-test': 'patient-id'
            }
        },
        {
            dataField: "Status",
            text: t('lsi.table.status'),
            sort: true,
            formatter: StatusPatientsColumnFormatter,
            attrs:{
                'data-test': 'status'
            }
        },
        {
            dataField: "StartDate",
            text: t('lsi.table.startDate'),
            sort: true,
            attrs:{
                'data-test': 'start-date'
            },
            sortFunc: tableSortByDate,
        },
        {
            dataField: "EndDate",
            text: t('lsi.table.endDate'),
            sort: true,
            attrs:{
                'data-test': 'end-date'
            },
            sortFunc: tableSortByDate,
        }
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={statusHistory}
            columns={columns}
            noDataIndication={t('lsi.noDataAvailable')}
            defaultSorted={defaultPatientHistorySorted}
        >
        </BootstrapTable>
    );
}
