import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as actions from "../../../actions/provisions/provisionsActions";
import {useProvisionsUIContext} from "../ProvisionsUIContext"
import {} from "../../../../_metronic/_helpers";
import {InfiniteScrollTable} from "../../../component/table/InfiniteScrollTable";
import {defaultProvisionsTableSorted} from "../ProvisionsUIHelpers";
import {tableSortByDate} from "../../../component/table/tableSort";
import {ProvisionsStateColumnFormatter} from "../../../component/table/column-formatters/StatusColumnFormatter";
import {ProvisionsTypeColumnFormatter} from "../../../component/table/column-formatters/TypeColumnFormatter";
import {ExportButton} from "../../../component/exportbutton/ExportButton";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionsTable() {
    const {t} = useLanguageForShared()

    const provisionsUIContext = useProvisionsUIContext();
    const provisionsUIProps = useMemo(() => {
        return {
            queryParams: provisionsUIContext.queryParams,
            openInfoProvisionPage: provisionsUIContext.openInfoProvisionPage,
        };
    }, [provisionsUIContext]);

    const {currentState} = useSelector(
        (state) => ({currentState: state.provisions}),
        shallowEqual
    );

    const {provisionsList, pagination} = currentState;

    const [selectedRows, setSelectedRows] = useState([]);
    const [sortTable, setSortTable] = useState(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        if (sortTable) {
            dispatch(actions.fetchProvisionsTable(provisionsUIProps.queryParams, null, sortTable));
        }
    }, [provisionsUIProps.queryParams, dispatch]);

    const columns = [
        {
            dataField: "ProvisionType",
            text: t('lsi.table.tipologyProvision'),
            sort: true,


            formatter: ProvisionsTypeColumnFormatter
        },
        {
            dataField: "DocumentNumber",
            text: t('lsi.table.documentNumber'),
            sort: true,



        },
        {
            dataField: "PatientName",
            text: t('lsi.table.nominativePatient'),
            sort: true,



        },
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,



        },
        {
            dataField: "District",
            text: t('lsi.table.district'),
            sort: true,



        },
        {
            dataField: "ProvisionDate",
            text: t('lsi.table.date'),
            sort: true,

            sortFunc: tableSortByDate
        },
        {
            dataField: "StaffInitials",
            text: t('lsi.table.staffId'),
            sort: false,
        },
        {
            dataField: "Status",
            text: t('lsi.table.stateProvision'),
            sort: true,

            formatter: ProvisionsStateColumnFormatter
        }
    ];

    return (
        <Card className="customHeaderPadding">
            <CardHeader title={t('lsi.provision.listProvisions')}
            className="fw-bolder">
                <CardHeaderToolbar>
                    <ExportButton
                        data={selectedRows.length > 0 ? selectedRows : []}
                        loadData={(callback) => dispatch(actions.fetchProvisionsForFile(provisionsUIProps.queryParams, callback))}
                        columns={columns}
                        fileName={"prestazioni"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"Id"}
                    columns={columns}
                    data={provisionsList}
                    defaultSorted={defaultProvisionsTableSorted}
                    pagination={pagination}
                    onSelectRow={provisionsUIProps.openInfoProvisionPage}
                    onSelectCheckBox={(row, isSelect) => {
                        if (isSelect) {
                            setSelectedRows([...selectedRows, row]);
                        } else {
                            setSelectedRows(selectedRows.filter((el) => {
                                return el.Id !== row.Id
                            }));
                        }
                    }}
                    hoverEnable={true}
                    loadData={(event, tableInfo) => {
                        if (tableInfo) {
                            setSortTable({dataField: tableInfo.sortField, order: tableInfo.sortOrder});
                            dispatch(actions.fetchProvisionsTable(provisionsUIProps.queryParams, pagination, {
                                dataField: tableInfo.sortField,
                                order: tableInfo.sortOrder
                            }, true));
                        } else {
                            dispatch(actions.fetchProvisionsTable(provisionsUIProps.queryParams, pagination, sortTable, false));
                        }
                    }}
                    noDataIndication={t('lsi.noDataAvailable')}
                />
            </CardBody>
        </Card>
    );
}
