import {t} from 'i18next'
import React, {useState} from 'react'
import SubHeading from '../../../../shared/custom/headings/subheading/SubHeading'
import DropzoneComponent from '../dropzone/DropzoneComponent'
import RowInputFileV from './steps/components/RowInputFileV'
import {FileDataModel} from "../../models/FileDataModel";
import {getBase64ToString} from '../../../../shared/utils/fileUtils'
import RowInputFileL from "./steps/components/RowInputFileL";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";

function AddFileL({
                      addFile,
                      onClose,
                      diaryTemplate
                  }: {
    addFile: (file: FileDataModel) => void
    onClose?: () => void
    diaryTemplate?: string
}) {
    const [filesDropzone, setFilesDropzone] = useState<FileDataModel[] | undefined>(
        undefined
    )
    // const [files, setFiles] = useState<{file: any, base64?: string, name?: string, fileType?: string }[] | undefined>(undefined)
    // const fileArray = file.name.split('.');
    // getBase64(file).then(res => {
    //   setFiles([...(files || []), {file, base64: res, name: fileArray.filter((ele: string,index: number) => index < fileArray.length -1 ), fileType: fileArray[fileArray.length -1]}])
    // })

    const uploadFilesHandler = () => {
        filesDropzone && filesDropzone.length && addFile(filesDropzone[0])
    }
    const deleteHandler = (file: any) => {
        setFilesDropzone([
            ...(filesDropzone || []).filter((ele) => ele.file.path !== file.file.path),
        ])
    }

    const renameFile = (fileNew: any, name: string) => {
        setFilesDropzone(
            filesDropzone?.map((file) =>
                file.file.path === fileNew.file.path ? {...file, name} : file
            )
        )
    }
    const setDropzoneFile = (files: any[]) => {
        if (files.length) {
            const file = files[0];
            getBase64ToString(file).then((res: any) => {
                const fileArray = file.name.split('.');
                setFilesDropzone([{
                    file,
                    base64: res,
                    name: fileArray.filter((ele: string, index: number) => index < fileArray.length - 1).join(''),
                    FileType: fileArray[fileArray.length - 1]
                }])
            })
        } else {
            setFilesDropzone([]);
        }
    }
    return (
        <div className={'mt-3'}>
            <h4 className={'d-flex w-100 justify-content-between'}>
                {t('note.newNote.attachFile')}

                {onClose && <span className="pointer me-2" onClick={onClose}> <IconSVG icon={'close'}
                                                                                       tintColor={configuration.theme.primary}
                                                                                       size={'20px'}/></span>}
            </h4>

            <div className="row">
                <div className="col-md-12">
                    {!(filesDropzone && filesDropzone.length > 0) && (
                        <div className="col-md-12">
                            <DropzoneComponent onDrop={setDropzoneFile}
                                               title={diaryTemplate === 'report' ? 'note.action.dropzone.titleReport' : undefined}
                                               titleInvalidType={diaryTemplate === 'report' ? 'note.action.dropzone.errors.fileInvalidTypeReport' : undefined}
                                               acceptFile={diaryTemplate === 'report' ? ['.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg','.csv','.xls', '.xlsx'] : undefined}
                            />
                        </div>
                    )}
                </div>

                <div className="col-md-12">
                    <div className="col-md-12">
                        {filesDropzone?.map((file, i) => (
                            <div className="d-flex align-items-center" key={i}>
                                <span className="me-5 text-nowrap">{t('note.newNote.nameFile')}</span>
                                <RowInputFileL
                                    key={i}
                                    deleteHandler={deleteHandler}
                                    uploadFilesHandler={uploadFilesHandler}
                                    renameFileHandler={(name) => renameFile(file, name)}
                                    file={file}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddFileL
