import {formatDateMonthYear, formatDateMonthYearYY} from "../../../../utils/dateUtils";
import useCalcTick from "../useCalcTick";

export default function (costs = [], t){
    const treatments = costs.reduce((acc, ele) => {
        return [...acc, ...(acc.includes(ele.description)? [] : [ele.description])]
    }, [])
    const dates = costs.reduce((acc, ele) => {
        return [...acc, ...(acc.includes(ele.month+'-'+ele.year)? [] : [ele.month+'-'+ele.year])]
    }, [])

    const data = dates.map(ele => {
        const [month, year] = ele.split('-');
        const costForDate = costs.filter(c => c.month === +month && c.year === +year);
        return [formatDateMonthYearYY(month, year), ...treatments.map(treatment => costForDate.filter(c => c.description === treatment).reduce((acc, c) => {
            return acc + c.cost
        }, 0))]
    })
    const dataTable = [[t('lsi.provision.monthYear'), ...treatments], ...data]
    const maxValue = dataTable.filter((e, index) => index >0).reduce((acc, ele) => {
        return [...acc, ...ele.filter((el, index) => index > 0)]
    }, []).reduce((acc, ele) => {
        return acc < ele? ele : acc
    }, 0)
    const {dateTicks} = useCalcTick(maxValue, 10, 'currency')
    return {
        dataTable,
        treatments,
        maxValue,
        dateTicks
    }
}
