import {createSlice} from "@reduxjs/toolkit";
import {PatientStatusTitles, PatientGenderTitles} from "../../pages/patients/PatientsUIHelpers";
import {
    getProvisionStateTitles,
    getProvisionTypeTitles,
    getProvisionStateValue,
    getProvisionTypeValue
} from "../../pages/provisions/ProvisionsUIHelpers";
import {PatientsTableModel} from "../../models/patientsTableModel";
import {PatientModel} from "../../models/patientModel";
import moment from "moment";
import {PrescriptionModel} from "../../models/prescriptionModel";
import {HistoryModel} from "../../models/historyModel";
import {ProvisionModel} from "../../models/provisionModel";
import {PaginationModel} from "../../models/paginationModel";
import {DeviceModel} from "../../models/deviceModel";
import {AccessorieModel} from "../../models/accessorieModel";
import {ConsumableModel} from "../../models/consumableModel";
import {MedicinalModel} from "../../models/medicinalModel";
import {InvoiceModel} from "../../models/invoiceModel";
import {PatientProvisionsNearModel} from "../../models/patientProvisionNearModel";
import {formatCapitalizeString, groupBy} from "../../utils/utility";

const initialPatientsState = {
    selectedPatientInvoice: [],
    pagination: new PaginationModel(),
    totalCount: undefined,
    patientsList: [],
    patientInvoicesList: [],
    entitiesChart: undefined,
    patientById: undefined,
    patientChartData: undefined,
    patientLoxChartData: undefined,
    patientProvisionsNear: undefined,
    filterPatientsPrescriptions: [],
    filterPatientStatus: [],
    filterPatientGender: [],
    filterPatientProviders: [],
    filterDistrict: [],
    openedPrescriptionAccordion: false,
    selectedPrescription: []
};

export const patientsSlice = createSlice({
    name: "patients",
    initialState: initialPatientsState,
    reducers: {
        patientFetched: (state, action) => {
            try {
                let patient = action.payload.patientById.data.patientById.nodes[0];
                let model = new PatientModel();
                let patientDistrict = patient.district?.description;
                let patientAlternativeAddresses = patient.addresses;
                model.latestPrescriptions = patient.latestPrescriptions.map(ele => ({...ele, productCart: JSON.parse(ele.productCart)}))
                model.hasPendingOrders = patient.hasPendingOrders;
                model.FirstName = patient.firstName;
                model.LastName = patient.lastName;
                model.FullName = patient.fullName;
                model.Id = patient.id;
                model.PatientNumbers = patient.patientNumbers.split(',');
                model.CustomerId = patient.customerId;

                for (var i = 0; i < model.PatientNumbers.length; i++) {
                    if (model.PatientNumbers[i] === model.Id) {
                        model.PatientNumbers.splice(i, 1);
                    }
                }

                model.PatientNumbers.unshift(model.Id);

                model.BirthDate = patient.birthDate ? moment(patient.birthDate).format("DD MMM YY") : "-";
                if (patient.gender === "UNKNOWN") {
                    model.Gender = 0;
                } else if (patient.gender === "M") {
                    model.Gender = 1;
                } else {
                    model.Gender = 2;
                }
                if (patient.patientState === "UNKNOWN") {
                    model.PatientState = 0;
                } else if (patient.patientState === "INATTIVAZIONE") {
                    model.PatientState = 1;
                } else if (patient.patientState === "ATTIVO") {
                    model.PatientState = 2;
                } else if (patient.patientState === "SOSPESO") {
                    model.PatientState = 3;
                } else if (patient.patientState === "DISATTIVO") {
                    model.PatientState = 4;
                } else if (patient.patientState === "INDISATTIVAZIONE") {
                    model.PatientState = 5;
                }
                model.FiscalCode = patient.cF ? patient.cF : "-";
                model.District = patientDistrict ? patientDistrict : "-";
                model.RegionalCode = patient.regionalCode ? patient.regionalCode : "-";
                model.Email = "-";
                model.Phone = patient.phone ? patient.phone : "-";
                model.HomeAddress = patient.homeAddress ? patient.homeAddress : "-";
                model.ResidenceAddress = patient.residenceAddress ? patient.residenceAddress : "-";
                model.CareGiverNominative = patient.careGiver ? patient.careGiver.lastName + " " + patient.careGiver.firstName : "-";

                if (patientAlternativeAddresses && patientAlternativeAddresses.length > 0) {
                    patientAlternativeAddresses.forEach((el) => {
                        let startDate = el.startDate ? moment(el.startDate).format("DD MMM YY") : "-";
                        let endDate = el.endDate ? moment(el.endDate).format("DD MMM YY") : "-";
                        model.Addresses.push(el.address + " " + el.city + " " + el.province + " " + el.zipCode + " dal " + startDate + " al " + endDate);
                    });
                }

                let prescriptionsArray = [];

                patient.prescriptionDetails.forEach((el) => {
                    let prescriptionModel = new PrescriptionModel();
                    prescriptionModel.Id = el.id;
                    prescriptionModel.PatientId = el.patientId;
                    prescriptionModel.CustomerId = patient.customerId;
                    prescriptionModel.Code = el.code;
                    prescriptionModel.Description = el.description;
                    prescriptionModel.PrescriptionDate = el.prescriptionDate ? moment(el.prescriptionDate).format("DD MMM YY") : "-";
                    prescriptionModel.StartDate = el.startDate ? moment(el.startDate).format("DD MMM YY") : "-";
                    prescriptionModel.EndDate = el.endDate ? moment(el.endDate).format("DD MMM YY") : "-";
                    prescriptionModel.actionsProduct = el.actionsProduct;
                    prescriptionModel.orderNumber = el.orderNumber;
                    prescriptionsArray.push(prescriptionModel);
                });

                model.Prescriptions = prescriptionsArray;

                let historyArray = [];

                patient.history.forEach((el) => {
                    let historyModel = new HistoryModel();

                    historyModel.PatientId = el.patientId;

                    if (el.statusEnum === "UNKNOWN") {
                        historyModel.Status = 0;
                    } else if (el.statusEnum === "INATTIVAZIONE") {
                        historyModel.Status = 1;
                    } else if (el.statusEnum === "ATTIVO") {
                        historyModel.Status = 2;
                    } else if (el.statusEnum === "SOSPESO") {
                        historyModel.Status = 3;
                    } else if (el.statusEnum === "DISATTIVO") {
                        historyModel.Status = 4;
                    } else if (el.statusEnum === "INDISATTIVAZIONE") {
                        historyModel.Status = 5;
                    }

                    historyModel.StartDate = el.startDate ? moment(el.startDate).format("DD MMM YY") : "-";
                    historyModel.EndDate = el.endDate ? moment(el.endDate).format("DD MMM YY") : "-";

                    historyArray.push(historyModel);
                });

                model.History = historyArray;

                let provisionsArray = [];

                patient.provisions.nodes.forEach((el) => {
                    let provisionModel = new ProvisionModel();
                    provisionModel.PatientId = el.patientId;
                    provisionModel.ProvisionState = getProvisionStateValue(el.provisionState);
                    provisionModel.ProvisionType = getProvisionTypeValue(el.provisionType);
                    provisionModel.District = patientDistrict ? patientDistrict.description : "-";
                    provisionModel.ProvisionDate = el.provisionDate ? moment(el.provisionDate).format("DD MMM YY") : "-";
                    provisionModel.DocumentNumber = el.documentNumber ? el.documentNumber : "-";
                    provisionModel.orderNumber = el.orderNumber ? el.orderNumber : "-";

                    if (el.staff && el.staff.length > 0) {
                        el.staff.forEach((el, index) => {
                            if (index === 0) {
                                provisionModel.StaffInitials = el.initials;
                            } else {
                                provisionModel.StaffInitials += ', ' + el.initials;
                            }
                        });
                    } else {
                        provisionModel.StaffInitials = "-";
                    }
                    provisionModel.Id = el.id.replace("/", "-");
                    provisionsArray.push(provisionModel);
                });

                model.Provisions = provisionsArray;

                let devicesArray = [];

                if (patient.devices && patient.devices.length > 0) {
                    patient.devices.forEach((el) => {
                        let deviceModel = new DeviceModel();
                        deviceModel.PatientId = el.patientId;
                        deviceModel.Id = el.id;
                        deviceModel.DeviceType = el.deviceType ? el.deviceType : "-";
                        deviceModel.Serial = el.serial ? el.serial : "-";
                        deviceModel.ItemNumber = el.itemCode ? el.itemCode : "-";
                        deviceModel.BrandAndModel = /*el.brand ? el.brand + " " + el.model : ""*/el.description;
                        deviceModel.DeliveryDate = el.patientDeliveryDate ? moment(el.patientDeliveryDate).format("DD MMM YY") : "-";
                        deviceModel.PickUpDate = el.patientPickupDate ? moment(el.patientPickupDate).format("DD MMM YY") : "-";
                        if (el.assets) {
                            let assets = JSON.parse(el.assets);
                            assets.forEach((el) => {
                                if (el.AssetType === 0) {
                                    deviceModel.Photo = el.AssetLink;
                                } else {
                                    deviceModel.Assets.push(el);
                                }
                            });
                        }
                        devicesArray.push(deviceModel)
                    });
                }

                model.Devices = devicesArray;

                let accessoriesArray = [];

                if (patient.accessories && patient.accessories.length > 0) {
                    patient.accessories.forEach((el) => {
                        let accessorieModel = new AccessorieModel();
                        accessorieModel.PatientId = el.patientId;
                        accessorieModel.AccessoryType = el.accessoryType ? el.accessoryType : "-";
                        accessorieModel.ItemNumber = el.itemCode ? el.itemCode : "-";
                        accessorieModel.Description = el.description ? el.description : "-";
                        accessorieModel.Serial = el.serial ? el.serial : "-";
                        accessorieModel.DeliveryDate = el.patientDeliveryDate ? moment(el.patientDeliveryDate).format("DD MMM YY") : "-";
                        accessorieModel.PickUpDate = el.patientPickupDate ? moment(el.patientPickupDate).format("DD MMM YY") : "-";
                        accessorieModel.LotNumber = el.lot ? el.lot : "-";
                        accessorieModel.DeclarationOfConformity = "-";

                        if (el.assets) {
                            let assets = JSON.parse(el.assets);
                            assets.forEach((el) => {
                                if (el.AssetType === 0) {
                                    accessorieModel.Photo = el.AssetLink;
                                } else {
                                    accessorieModel.Assets.push(el);
                                }
                            });
                        }

                        accessoriesArray.push(accessorieModel);
                    });
                }

                model.Accessories = accessoriesArray;

                let consumablesArray = [];

                if (patient.consumables && patient.consumables.length > 0) {
                    patient.consumables.forEach((el) => {
                        let consumableModel = new ConsumableModel();
                        consumableModel.PatientId = el.patientId;
                        consumableModel.ConsumableType = el.consumableType ? el.consumableType : "-";
                        consumableModel.Description = el.description ? el.description : "-";
                        consumableModel.Serial = el.serial ? el.serial : "-";
                        consumableModel.DeliveryDate = el.patientDeliveryDate ? moment(el.patientDeliveryDate).format("DD MMM YY") : "-";
                        consumableModel.LotNumber = el.lot ? el.lot : "-";
                        consumableModel.Quantity = el.patientQuantity;
                        consumableModel.DeclarationOfConformity = "-";

                        if (el.assets) {
                            let assets = JSON.parse(el.assets);
                            assets.forEach((el) => {
                                if (el.AssetType === 0) {
                                    consumableModel.Photo = el.AssetLink;
                                } else {
                                    consumableModel.Assets.push(el);
                                }
                            });
                        }

                        consumablesArray.push(consumableModel);
                    });
                }

                model.Consumables = consumablesArray;

                let medicinalsArray = [];

                if (patient.medicinals && patient.medicinals.length > 0) {
                    patient.medicinals.forEach((el) => {
                        let medicinalModel = new MedicinalModel();
                        medicinalModel.PatientId = el.patientId;
                        medicinalModel.Brand = el.brand ? el.brand : "-";
                        medicinalModel.Name = el.name;
                        medicinalModel.Description = el.description;
                        medicinalModel.Quantity = el.quantity;
                        medicinalModel.Serial = el.serial;
                        medicinalModel.DeliveryDate = el.deliveryDate ? moment(el.deliveryDate).format("DD MMM YY") : "-";
                        medicinalModel.LotNumber = el.lot ? el.lot : "-";
                        medicinalsArray.push(medicinalModel);
                    });
                }

                model.Medicinals = medicinalsArray;

                model.medicalPrescriptions = patient.medicalPrescriptions;

                let prescriptionDetailsArray = [];

                if (patient.prescriptionDetails && patient.prescriptionDetails.length > 0) {
                    patient.prescriptionDetails.forEach(prescriptionDetailsModel => {
                        prescriptionDetailsModel.treatmentFamily = prescriptionDetailsModel.treatmentFamily?.trim();

                        prescriptionDetailsArray.push(prescriptionDetailsModel);
                    });
                }

                model.prescriptionDetails = prescriptionDetailsArray;
                model.patientInvoice = patient.patientInvoice;
                model.patientCosts = patient.patientCosts;
                state.patientById = model;
            } catch (error) {
                console.error(error);
            }
        },
        patientsFitlersFetched: (state, action) => {
            try {
                const {entities} = action.payload;
                if (entities.data.patientsReviewFilters) {
                    let districts = entities.data.patientsReviewFilters.districts;
                    let genders = entities.data.patientsReviewFilters.patientGenders;
                    let status = entities.data.patientsReviewFilters.patientStates;
                    let prescriptions = entities.data.patientsReviewFilters.prescriptions;
                    let providers = entities.data.patientsReviewFilters.providers;

                    let districtsArray = [];
                    districts.forEach((e) => {
                        if (e) {
                            districtsArray.push(e);
                        } else {
                            districtsArray.push("-");
                        }
                    });

                    state.filterDistrict = districtsArray.sort();

                    let gendersArray = [];
                    genders.forEach((el) => {
                        if (el === "UNKNOWN") {
                            gendersArray.push({label: PatientGenderTitles()[0], value: el});
                        } else if (el === "M") {
                            gendersArray.push({label: PatientGenderTitles()[1], value: el});
                        } else {
                            gendersArray.push({label: PatientGenderTitles()[2], value: el});
                        }
                    });

                    state.filterPatientGender = gendersArray;

                    let statusArray = [];
                    status.forEach((el) => {
                        if (el === "UNKNOWN") {
                            statusArray.push({label: PatientStatusTitles()[0], value: el});
                        } else if (el === "INATTIVAZIONE") {
                            statusArray.push({label: PatientStatusTitles()[1], value: el});
                        } else if (el === "ATTIVO") {
                            statusArray.push({label: PatientStatusTitles()[2], value: el});
                        } else if (el === "SOSPESO") {
                            statusArray.push({label: PatientStatusTitles()[3], value: el});
                        } else if (el === "DISATTIVO") {
                            statusArray.push({label: PatientStatusTitles()[4], value: el});
                        } else if (el === "INDISATTIVAZIONE") {
                            statusArray.push({label: PatientStatusTitles()[5], value: el});
                        }
                    });

                    state.filterPatientStatus = statusArray;

                    state.filterPatientsPrescriptions = prescriptions.sort();
                    state.filterPatientProviders = providers;

                } else {
                    state.filterPatientStatus = [];
                    state.filterPatientsPrescriptions = [];
                    state.filterPatientGender = [];
                    state.filterDistrict = [];
                    state.filterPatientProviders = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        patientsFetched: (state, action) => {
            try {
                const {entities, holdPagination, isChangedSort} = action.payload;
                if (entities.data.patientsReview) {
                    let newPatientsList = [];
                    entities.data.patientsReview.nodes.forEach((el) => {
                        let model = new PatientsTableModel();
                        let patient = el;
                        model.Id = patient.id;
                        model.Nominative = patient.lastName?.toUpperCase() + " " + formatCapitalizeString(patient.firstName);

                        if (patient.gender === "UNKNOWN") {
                            model.Gender = 0;
                        } else if (patient.gender === "M") {
                            model.Gender = 1;
                        } else {
                            model.Gender = 2;
                        }

                        model.FiscalCode = patient.cF ? patient.cF : "-";
                        model.District = patient.districtName ? patient.districtName : "-";

                        if (patient.latestPatientStateInDateRange === "UNKNOWN") {
                            model.Status = 0;
                        } else if (patient.latestPatientStateInDateRange === "INATTIVAZIONE") {
                            model.Status = 1;
                        } else if (patient.latestPatientStateInDateRange === "ATTIVO") {
                            model.Status = 2;
                        } else if (patient.latestPatientStateInDateRange === "SOSPESO") {
                            model.Status = 3;
                        } else if (patient.latestPatientStateInDateRange === "DISATTIVO") {
                            model.Status = 4;
                        } else if (patient.latestPatientStateInDateRange === "INDISATTIVAZIONE") {
                            model.Status = 5;
                        }

                        model.Prescriptions = patient.prescriptionDetailDescription;
                        model.PrescriptionId = patient.prescriptionDetailId;
                        model.Provision = el.provisionsCount;
                        model.Provider = el.provider;

                        model.InchargeDate = patient.prescriptionDetailStartDate ? moment(patient.prescriptionDetailStartDate).format("DD MMM YY") : "-";
                        model.DischargeDate = patient.prescriptionDetailEndDate ? moment(patient.prescriptionDetailEndDate).format("DD MMM YY") : "-";

                        model.TherapyDays = el.therapyDays;

                        newPatientsList.push(model);
                    });

                    let pageInfo = entities.data.patientsReview.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.patientsList = newPatientsList;
                    } else {
                        state.patientsList = state.patientsList.length > 0 && holdPagination ? state.patientsList.concat(newPatientsList) : newPatientsList;
                    }
                    state.pagination = newPagination;
                    state.totalCount = entities?.data?.patientsReview?.totalCount;
                } else {
                    state.patientsList = [];
                    state.totalCount = undefined;
                    state.pagination = new PaginationModel();
                }
            } catch (error) {
                console.error(error);
            }
        },
        patientsForFileFetched: (state, action) => {
            try {
                const {entities, callback} = action.payload;
                let newPatientsList = [];
                if (entities.data.patientsReview) {
                    entities.data.patientsReview.nodes.forEach((el) => {
                        let model = new PatientsTableModel();
                        let patient = el;
                        model.Id = patient.id;
                        model.Nominative = patient.lastName?.toUpperCase() + " " + formatCapitalizeString(patient.firstName);

                        if (patient.gender === "UNKNOWN") {
                            model.Gender = 0;
                        } else if (patient.gender === "M") {
                            model.Gender = 1;
                        } else {
                            model.Gender = 2;
                        }

                        model.FiscalCode = patient.cF ? patient.cF : "-";
                        model.District = patient.districtName ? patient.districtName : "-";

                        if (patient.latestPatientStateInDateRange === "UNKNOWN") {
                            model.Status = 0;
                        } else if (patient.latestPatientStateInDateRange === "INATTIVAZIONE") {
                            model.Status = 1;
                        } else if (patient.latestPatientStateInDateRange === "ATTIVO") {
                            model.Status = 2;
                        } else if (patient.latestPatientStateInDateRange === "SOSPESO") {
                            model.Status = 3;
                        } else if (patient.latestPatientStateInDateRange === "DISATTIVO") {
                            model.Status = 4;
                        } else if (patient.latestPatientStateInDateRange === "INDISATTIVAZIONE") {
                            model.Status = 5;
                        }

                        model.Prescriptions = patient.prescriptionDetailDescription;
                        model.PrescriptionId = patient.prescriptionDetailId;
                        model.Provision = el.provisionsCount;
                        model.Provider = el.provider;

                        model.InchargeDate = patient.prescriptionDetailStartDate ? moment(patient.prescriptionDetailStartDate).format("DD MMM YY") : "-";
                        model.DischargeDate = patient.prescriptionDetailEndDate ? moment(patient.prescriptionDetailEndDate).format("DD MMM YY") : "-";

                        model.TherapyDays = el.therapyDays;

                        newPatientsList.push(model);
                    });
                }
                callback(newPatientsList);
            } catch (error) {
                console.error(error);
            }
        },
        patientsChartFetched: (state, action) => {
            try {
                const {entities} = action.payload;

                if (entities.data.patientsReviewDaysGraphV2 && entities.data.patientsReviewDaysGraphV2.nodes && entities.data.patientsReviewDaysGraphV2.nodes.length > 0) {
                    let entitiesChart = entities.data.patientsReviewDaysGraphV2.nodes;

                    let patientsChartArray = [];

                    for (var i = 0; i < entitiesChart.length; i++) {
                        let existingIndex = patientsChartArray.findIndex(ec => ec.year === entitiesChart[i].year && ec.monthNumber === entitiesChart[i].monthNumber && ec.prescriptionName == entitiesChart[i].prescriptionName);

                        if (existingIndex === -1) {
                            // non esiste ancora un elemento con chiave (year, month, prescriptionName), lo aggiungo e con l'occasione localizzo anche il mese
                            let monthNumber = entitiesChart[i].monthNumber.toString().padStart(4, "0");

                            entitiesChart[i].count = 1;
                            entitiesChart[i].monthName = moment().set('month', entitiesChart[i].monthNumber - 1).format('MMMM').toUpperCase();
                            entitiesChart[i].YearMonthNumber = entitiesChart[i].year + "" + monthNumber;

                            patientsChartArray.push(entitiesChart[i]);
                        } else {
                            // esiste già un elemento con chiave (year, month, prescriptionName), incremento il count
                            patientsChartArray[existingIndex].count += 1;
                        }
                    }

                    patientsChartArray.sort(function (a, b) {
                        if (a.YearMonthNumber < b.YearMonthNumber) {
                            return 1;
                        } else if (a.YearMonthNumber > b.YearMonthNumber) {
                            return -1;
                        }

                        // Else go to the 2nd item
                        if (a.prescriptionName < b.prescriptionName) {
                            return -1;
                        } else if (a.prescriptionName > b.prescriptionName) {
                            return 1
                        } else { // nothing to split them
                            return 0;
                        }
                    });

                    //faccio questo perchè il groupby me li ordina in modo inverso non riesco a capire perchè
                    var object = groupBy(patientsChartArray, "YearMonthNumber");
                    var array = [];
                    for (const key in object) {
                        array.unshift({data: key, values: object[key]});
                    }

                    state.entitiesChart = array;
                } else {
                    state.entitiesChart = undefined;
                }
            } catch (error) {
                console.error(error);
            }
        },
        patientChartFetched: (state, action) => {
            try {
                let patientChart = action.payload.patientChart.data.patientTherapyDaysGraph ? action.payload.patientChart.data.patientTherapyDaysGraph.nodes : null;
                if (patientChart && patientChart.length > 0) {

                    let patientChartArray = [];
                    patientChart.forEach((el, index) => {
                        let patientChartObject = {};
                        let monthNumber = el.monthNumber.toString().padStart(4, "0");

                        el.monthName = moment().set('month', el.monthNumber - 1).format('MMMM').toUpperCase();

                        patientChartObject.YearMonthNumber = el.year + "" + monthNumber;
                        patientChartObject.MonthYearName = el.monthName + " " + el.year;
                        patientChartObject.PrescriptionName = el.prescriptionName;
                        patientChartObject.Count = el.count
                        patientChartArray.push(patientChartObject);
                    });

                    patientChartArray.sort(function (a, b) {
                        var x = a.YearMonthNumber, y = b.YearMonthNumber;
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                    state.patientChartData = groupBy(patientChartArray, "PrescriptionName");

                } else {
                    state.patientChartData = undefined;
                }
            } catch (error) {
                console.error(error);
            }
        },
        patientLoxChartFetched: (state, action) => {
            try {
                let patientLoxChart = action.payload.patientLoxChart.data.patientLoxGraph ? action.payload.patientLoxChart.data.patientLoxGraph.nodes : null;

                if (patientLoxChart && patientLoxChart.length > 0) {
                    let patientLoxChartArray = [];

                    patientLoxChart.forEach((el, index) => {
                        let patientLoxChartObject = {};
                        let monthNumber = el.month.toString().padStart(4, "0");

                        el.monthName = moment().set('month', el.month - 1).format('MMMM').toUpperCase();

                        patientLoxChartObject.YearMonthNumber = el.year + "" + monthNumber;
                        patientLoxChartObject.MonthYearName = el.monthName + " " + el.year;
                        patientLoxChartObject.Prescribed = el.prescribed;
                        patientLoxChartObject.Delivered = el.delivered;
                        patientLoxChartArray.push(patientLoxChartObject);
                    });

                    patientLoxChartArray.sort(function (a, b) {
                        var x = a.YearMonthNumber, y = b.YearMonthNumber;
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                    state.patientLoxChartData = patientLoxChartArray; //groupBy(patientLoxChartArray, "PrescriptionName");
                } else {
                    state.patientLoxChartData = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        /*patientInvoicesFetched: (state, action) => {
            try {
                let patientInvoices = action.payload.patientInvoices.data.patientTherapyDaysAmounts ? action.payload.patientInvoices.data.patientTherapyDaysAmounts.nodes : null;

                if (patientInvoices && patientInvoices.length > 0) {
                    let patientInvoicesArray = [];
                    patientInvoices.forEach((el, index) => {
                        let patientInvoiceModel = new InvoiceModel();

                        patientInvoiceModel.Id = index;
                        patientInvoiceModel.CustomerId = localStorage.getItem("customerId");
                        patientInvoiceModel.MonthYear = el.monthName + "/" + el.year;
                        let monthNumber = "";
                        if (el.monthNumber < 10) {
                            monthNumber = "0" + el.monthNumber;
                        } else {
                            monthNumber = el.monthNumber;
                        }
                        patientInvoiceModel.MonthNumber = el.year + "" + monthNumber;
                        patientInvoiceModel.PrescriptionName = el.prescriptionName;
                        patientInvoiceModel.InchargeDate = el.inchargeDate ? moment(el.inchargeDate).format("DD/MM/YYYY") : "-";
                        patientInvoiceModel.DischargeDate = el.dischargeDate ? moment(el.dischargeDate).format("DD/MM/YYYY") : "-";
                        patientInvoiceModel.TherapyDays = el.therapyDays;
                        patientInvoiceModel.TotalAmount = el.totalAmount + " €";
                        patientInvoicesArray.push(patientInvoiceModel)
                    });
                    state.patientInvoicesList = patientInvoicesArray;
                } else {
                    state.patientInvoicesList = [];
                }
            } catch (error) {
                console.error(error);
            }
        },*/
        patientProvisionsNearFetched: (state, action) => {
            try {
                let data = action.payload.patientProvisionsNear.data.patientsProvisionsNear;
                let provisionNear = new PatientProvisionsNearModel();

                provisionNear.LastProvisionType = data.lastProvision ? getProvisionTypeTitles(data.lastProvision.provisionType) : "-";
                provisionNear.LastProvisionState = data.lastProvision ? getProvisionStateTitles(data.lastProvision.provisionState) : "-";
                provisionNear.LastProvisionDate = data.lastProvision ? moment(data.lastProvision.provisionDate).format("DD MMM YY") : "-";
                provisionNear.NextProvisionType = data.nextProvision ? getProvisionTypeTitles(data.nextProvision.provisionType) : "-";
                provisionNear.NextProvisionDate = data.nextProvision ? moment(data.nextProvision.provisionDate).format("DD MMM YY") : "-";
                state.patientProvisionsNear = provisionNear;
            } catch (error) {
                console.error(error);
            }
        },
        setInvoiceRow: (state, action) => {
            try {
                state.selectedPatientInvoice = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
        setOpenedPrescriptionAccordion: (state, action) => {
            try {
                state.openedPrescriptionAccordion = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
        setPrescriptionRow: (state, action) => {
            try {
                state.selectedPrescription = action.payload.data;
            } catch (error) {
                console.error(error);
            }
        },
    }
});
