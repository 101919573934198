import React from "react";
import {ChartCustom} from "../../../component/chartcustom/ChartCustom";
import {formatDateMonthYearYY} from "../../../utils/dateUtils";
import {getMaxValue, getTicks, groupBy} from "../../../utils/utility";
import useCalcTick from "../../serviceIndicators/ServiceIndicatorsHome/useCalcTick";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientChart({patientChartData, patientProvisionsNear}) {
    const {t} = useLanguageForShared()

    let dataArray = [];
    let dataTable = [];

    let prescriptions = [];

    for (const prescription in patientChartData) {
        prescriptions.push(prescription);

        dataArray.push(...patientChartData[prescription]);
    }

    let header = [t('lsi.patientProfile.chart.monthYear'), ...prescriptions];

    dataTable = [];

    let dataByYearMonthNumber = groupBy(dataArray, "YearMonthNumber");

    for (const yearMonthNumber in dataByYearMonthNumber) {
        let dataRow = [formatDateMonthYearYY(parseInt(yearMonthNumber.substr(6, 2)), parseInt(yearMonthNumber.substr(0, 4)))];

        for (var i = 0; i < prescriptions.length; i++) {
            dataRow.push((dataByYearMonthNumber[yearMonthNumber].find(x => x.PrescriptionName == prescriptions[i]) || {Count: 0}).Count);
        }

        dataTable.unshift(dataRow);
    }

    dataTable.unshift(header);

    const maxValue = getMaxValue(dataTable);
    const dateTicks = useCalcTick(maxValue, 4);

    const optionAdd = {
        colors: [configuration.theme.series.s770_hex, configuration.theme.series.s870_hex, configuration.theme.series.s170_hex, configuration.theme.series.s470_hex],
        seriesType: "bars"
    };

    const vAxisAdd = {
        ticks: dateTicks
    };

    const formatters = dataTable.length === 0 ? [] : dataTable[0].slice(1).map((ele, i) => (
        {
            type: "NumberFormat",
            column: i + 1,
            options: {
                decimalSymbol: ',',
                groupingSymbol: '.'
            }
        }
    ));

    return (
        <>
            {
                patientChartData
                    ? <>
                        {
                            <ChartCustom dataTable={dataTable}
                                         options={optionAdd} chartType="ComboChart" vAxis={vAxisAdd}
                                         formatters={formatters}/>
                        }
                        <div className="mt-8">
                            <div className="d-flex align-items-center list-key-value">
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">{t('lsi.chart.lastProvision')}</span>
                                        <span
                                            className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.LastProvisionType : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">{t('lsi.chart.date')}</span>
                                        <span
                                            className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.LastProvisionDate : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">{t('lsi.chart.result')}</span>
                                        <span
                                            className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.LastProvisionState : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">{t('lsi.chart.nextProvision')}</span>
                                        <span
                                            className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.NextProvisionType : ""}</span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 d-flex align-items-center mr-5 my-1">
                                    <div className="d-flex flex-column">
                                        <span className="font-size-h6">{t('lsi.chart.date')}</span>
                                        <span
                                            className="font-size-sm">{patientProvisionsNear ? patientProvisionsNear.NextProvisionDate : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <span>{t('lsi.noDataAvailable')}</span>
            }
        </>
    );
}
