import {useState} from "react";
import {useEffectOnlyUpdate} from "../../utils/use_custom/useEffectCustom";
import moment from 'moment';
export default function useLastMeasure(lastAvailableMeasureDate: null | string) {
    const [show, setShow] = useState(true);
    useEffectOnlyUpdate(() => {
        setShow(false);
        setTimeout(() =>{setShow(true)}, 1)
    }, [lastAvailableMeasureDate])
    return {
        show,
        lastDateAvailable: lastAvailableMeasureDate ? moment(lastAvailableMeasureDate) : moment(),
        lastDateAvailableWeek: (lastAvailableMeasureDate ? moment(lastAvailableMeasureDate) : moment()).subtract(6, 'days'),
        lastDateAvailableMonth: (lastAvailableMeasureDate ? moment(lastAvailableMeasureDate) : moment()).subtract(29, 'days')
    }
}
