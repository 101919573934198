import axios from "axios";
import { path } from "../../utils/constant";

export function getPatientById(patientId, historyLimitDate) {
    return axios.post(
        path,
        {
            query: 'query{patientById(patientId: "' + patientId + '"){nodes{id hasPendingOrders patientNumbers firstName lastName fullName customerId birthDate gender cF patientState regionalCode district{description}residenceAddress homeAddress addresses{address city zipCode province startDate endDate} history{patientId statusEnum startDate endDate} latestPrescriptions{productCart dettaglioOrdine{diagnosis applicant{surname name data} prescriber{surname name code} prescription{code date}}} medicalPrescriptions{patientNumber code prescriptionDate physician{id lastName firstName taxCode} genericFlow genericHours nightlyFlow nightlyHours dailyFlow dailyHours} phone careGiver{firstName lastName} prescriptionDetails{patientId orderNumber actionsProduct id code description prescriptionDate startDate endDate note treatmentFamily prescriptor{firstName lastName}} provisions(first:60 order_by:{provisionDate:DESC}){nodes{id patientId documentNumber orderNumber provisionState provisionType provisionDate staff{initials}}} devices{id patientId itemCode deviceType brand serial model description patientDeliveryDate patientPickupDate assets} accessories{lot patientId itemCode description accessoryType serial patientDeliveryDate patientPickupDate assets} consumables{lot patientId consumableType brand model description serial patientQuantity patientDeliveryDate assets} medicinals{patientId description lot quantity deliveryDate} patientInvoice{patientNumber documentNumber treatmentDescription shipmentDate amount vatAmount} patientCosts{ competenceDate serviceAmount competenceDays  amount}}}}'
        });
}



export function getPatientInvoices(patientId) {
    return axios.post(
        path,
        {
            query: 'query{patientTherapyDaysAmounts(patientId:"' + patientId + '" startDate:"2000-01-01T00:00:00Z"){nodes{dischargeDate inchargeDate year monthName monthNumber prescriptionName therapyDays totalAmount}}}'
        }
    );
}

export function findPatients(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{patientsReview(' + queryParams + '){nodes{id firstName lastName fullName provider cF gender districtName patientState latestPatientStateInDateRange prescriptionDetailDescription prescriptionDetailId prescriptionDetailStartDate prescriptionDetailEndDate therapyDays provisionsCount}pageInfo{endCursor hasNextPage} totalCount}}'
        });
}

export function findPatientsChart(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{patientsReviewDaysGraphV2(' + queryParams + '){nodes{prescriptionName year monthNumber count}}}'
        });
}

export function findPatientChart(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{patientTherapyDaysGraph(' + queryParams + '){nodes{prescriptionName year monthName monthNumber count}}}'
        });
}

export function findPatientLoxChart(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{patientLoxGraph(' + queryParams + '){nodes{year month prescribed delivered}}}'
        });
}

export function findPatientProvisionsNear(patientId) {
    return axios.post(
        path,
        {
            query: 'query{patientsProvisionsNear(patientId: "' + patientId + '"){lastProvision{provisionType provisionDate provisionState}nextProvision{provisionType provisionDate}}}'
        });
}

export function findPatientsFilters(queryParams) {
    return axios.post(
        path,
        {
            query: 'query {patientsReviewFilters(' + queryParams + ') {districts patientGenders patientStates prescriptions providers}}'
        });
}
