import React from 'react';
import EventStoreV from "./event-store/EventStoreV";
import DiariesV from "./DiariesV";
import NotifyLibrary from "../../notify/NotifyLibrary";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";
import Heading from "../../../shared/custom/headings/heading/Heading";
import TitleH1 from "../../../shared/custom/headings/title/TitleH1";
import BtnSecondary from "../../../shared/bootstrap/button/secondary/BtnSecondary";

const DiaryTemplateV = ({
                            newNote,
                            setNewNote,
                            addNote,
                            setShowNotify,
                            diaries,
                            editNoteAction,
                            uploadFileHandler,
                            showNotify,
                            subjectTypes,
                            downloadAction,
    deleteFileAction,
                            readonly
                        }: any) => {
    const {t} = useLanguageForShared()

    return (
        <>
            <Heading
                left={<TitleH1 title={t('note.title')}/>}
                rightTop={<>{!readonly && <BtnSecondary className={'mb-2'}
                                                        onClick={() => setNewNote(true)}
                                                        title={t('note.action.add')}
                >
                    {t('note.action.add')}
                </BtnSecondary>}</>}
            />
            <div className="row mt-3">
                <>
                    {newNote && (
                        <EventStoreV
                            subjectTypes={subjectTypes}
                            closeHandler={() => setNewNote(false)}
                            addNote={addNote}
                            showNotifyComponentHandler={(data) => setShowNotify(data)}
                        />
                    )}
                    <DiariesV
                        deleteFileAction={deleteFileAction}
                        subjectTypes={subjectTypes}
                        readonly={readonly}
                        downloadAction={downloadAction}
                        diaries={diaries}
                        editNoteAction={editNoteAction}
                        uploadFileHandler={uploadFileHandler}
                        notifyHandler={(data) => setShowNotify(data)}
                    />
                    {showNotify &&
                        <NotifyLibrary events={[1]}
                                       closeHandler={() => setShowNotify(false)}
                                       extraData={{
                                           Subject: t('note.object.' + subjectTypes?.find((ele: any) => ele.value === showNotify.Subject)?.label) || '-',
                                           Date: showNotify.Date,
                                       }}
                        />}
                </>
            </div>
        </>
    );
};

export default DiaryTemplateV;
