import React, {useMemo, useState, useEffect, useRef} from "react";
import {Formik, Form} from "formik";
import {isEqual} from "lodash";
import {useDevicesUIContext} from "../DevicesUIContext";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import "../../../assets/css/datepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {LocaleDatePicker, filterRanges} from "../../../component/datepicker/DateRangePickerConfig";
import * as actions from "../../../actions/devices/devicesActions";
import Select, {components} from 'react-select';
import moment from "moment";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import {DropdownIndicator} from "../../../component/select/DropdownIndicator";
import {ClearIndicator} from "../../../component/select/ClearIndicator";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const prepareFilter = (queryParams, values) => {
    const {
        searchText,
        filterByDeliveryDate,
        filterByPickUpDate,
        filterByDeviceType,
        filterByDeviceDistrict,
        //filterByDeviceLot
    } = values;

    const newQueryParams = {...queryParams};

    const filter = {
        SearchText: searchText,
        DeliveryDate: filterByDeliveryDate,
        PickUpDate: filterByPickUpDate,
        DeviceType: filterByDeviceType,
        DeviceDistrict: filterByDeviceDistrict,
        //DeviceLot: filterByDeviceLot
    };

    newQueryParams.filter = filter;

    //setto nel local storage i filtri selezionati
    localStorage.setItem("devicesSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function DevicesFilters() {
    const {t} = useLanguageForShared()

    //prendo lo stato iniziale dei filtri dal context e li memorizzo in devicesUIProps
    const devicesUIContext = useDevicesUIContext();
    const devicesUIProps = useMemo(() => {
        return {
            queryParams: devicesUIContext.queryParams,
            setQueryParams: devicesUIContext.setQueryParams,
        };
    }, [devicesUIContext]);

    const dispatch = useDispatch();

    //funzione che parte alla selezione di un filtro
    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(devicesUIProps.queryParams, values);
        if (!isEqual(newQueryParams, devicesUIProps.queryParams)) {
            devicesUIProps.setQueryParams(newQueryParams);
        }
    };

    //all'avvio del componente parte la query che mi restituisce l'elenco filtri, questa query parte al variare di DeliveryDate e PickUpDate
    useEffect(() => {
        dispatch(actions.fetchDevicesFilters(devicesUIProps.queryParams.filter.DeliveryDate, devicesUIProps.queryParams.filter.PickUpDate));
    }, [devicesUIProps.queryParams.filter.DeliveryDate, devicesUIProps.queryParams.filter.PickUpDate]);

    //prendo i filtri dallo store restituiti dalla query
    const {currentState} = useSelector(
        (state) => ({currentState: state.devices}),
        shallowEqual
    );

    const {
        filterDevicesType,
        //filterDevicesLot,
        filterDevicesDistrict
    } = currentState;

    //qui viene settato il valore della select del tipo dispositivo, serve per fare vedere cosa è selezionato
    const [filterTypeSelectedOption, setfilterTypeSelectedOption] = useState(() => {
        let typeSelected = [];
        devicesUIProps.queryParams.filter.DeviceType.forEach(el => {
            typeSelected.push({value: el, label: el});
        });
        return typeSelected;
    });

    //qui viene settato il valore della select del lotto dispositivo, serve per fare vedere cosa è selezionato
    // const [filterLotSelectedOption, setfilterLotSelectedOption] = useState(() => {
    //     let lotSelected = [];
    //     devicesUIProps.queryParams.filter.DeviceLot.forEach(el => {
    //         lotSelected.push({ value: el, label: el });
    //     });
    //     return lotSelected;
    // });

    const customSelectStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#15bbff' : base.borderColor,
            '&:hover': {borderColor: ''},
            boxShadow: "none",
            borderRadius: "0"
        })
    };

    const deliveryDateRef = useRef();
    const pickUpDateRef = useRef();

    const changeStartEndDate = (ref) => {
        ref.current.setStartDate(moment().format("DD MMM YY"));
        ref.current.setEndDate(moment().format("DD MMM YY"));
    }


    return (
        <Formik
            initialValues={{
                searchText: devicesUIProps.queryParams.filter.SearchText,
                filterByDeliveryDate: devicesUIProps.queryParams.filter.DeliveryDate,
                filterByPickUpDate: devicesUIProps.queryParams.filter.PickUpDate,
                filterByDeviceType: devicesUIProps.queryParams.filter.DeviceType,
                filterByDeviceDistrict: devicesUIProps.queryParams.filter.DeviceDistrict,
                //filterByDeviceLot: devicesUIProps.queryParams.filter.DeviceLot
            }}
            onSubmit={(values, formikBag) => {
                //se sono state selezionate le date, tutti i filtri vengono resettati
                if (values.filterByDeliveryDate !== devicesUIProps.queryParams.filter.DeliveryDate || values.filterByPickUpDate !== devicesUIProps.queryParams.filter.PickUpDate) {
                    values = {
                        searchText: "",
                        filterByDeliveryDate: values.filterByDeliveryDate,
                        filterByPickUpDate: values.filterByPickUpDate,
                        filterByDeviceType: [],
                        filterByDeviceDistrict: [],
                        //filterByDeviceLot: [],
                    }
                    setfilterTypeSelectedOption([]);
                    //setfilterLotSelectedOption([]);
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                  values,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="form form-label-right filter">
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <input
                                type="text"
                                className="sh form-control"
                                name="searchText"
                                placeholder={t('lsi.search')}
                                onBlur={handleBlur}
                                value={values.searchText}
                                onChange={(e) => {
                                    setFieldValue("searchText", e.target.value);
                                    setTimeout(() => {
                                        handleSubmit();
                                    }, 1000);
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.table.deliveryDate')}</h6>
                            <DateRangePicker
                                ref={deliveryDateRef}
                                initialSettings={{
                                    showDropdowns: true,
                                    ranges: filterRanges,
                                    locale: LocaleDatePicker,
                                    linkedCalendars: false,
                                    startDate: values.filterByDeliveryDate ? values.filterByDeliveryDate.split('-')[0] : moment().format("DD MMM YY"),
                                    endDate: values.filterByDeliveryDate ? values.filterByDeliveryDate.split('-')[1] : moment().format("DD MMM YY")
                                }}
                                onEvent={(e, picker) => {
                                    if (e.type === "cancel") {
                                        setFieldValue("filterByDeliveryDate", "");
                                        changeStartEndDate(deliveryDateRef);
                                        handleSubmit();
                                    } else if (e.type === "apply") {
                                        setFieldValue("filterByDeliveryDate", picker.startDate.format('DD MMM YY') + " - " + picker.endDate.format('DD MMM YY'));
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="sh form-control customPickerDisplay">
                                    <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                                    <span>{values.filterByDeliveryDate}</span>
                                    {
                                        values.filterByDeliveryDate
                                            ? <button type="button" className="react-datepicker__close-icon"
                                                      style={{position: "inherit", marginLeft: "10px"}}
                                                      onClick={() => {
                                                          setFieldValue("filterByDeliveryDate", "");
                                                          changeStartEndDate(deliveryDateRef);
                                                          handleSubmit();
                                                      }}
                                            />
                                            : null
                                    }
                                    <div style={{float: "right"}}>
                                        <IconSVG icon={'chevron-down'} size={'8px'}/>
                                    </div>
                                </div>
                            </DateRangePicker>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.table.pickUpDate')}</h6>
                            <DateRangePicker
                                ref={pickUpDateRef}
                                initialSettings={{
                                    showDropdowns: true,
                                    ranges: filterRanges,
                                    locale: LocaleDatePicker,
                                    linkedCalendars: false,
                                    startDate: values.filterByPickUpDate ? values.filterByPickUpDate.split('-')[0] : moment().format("DD MMM YY"),
                                    endDate: values.filterByPickUpDate ? values.filterByPickUpDate.split('-')[1] : moment().format("DD MMM YY")
                                }}
                                onEvent={(e, picker) => {
                                    if (e.type === "cancel") {
                                        setFieldValue("filterByPickUpDate", "");
                                        changeStartEndDate(pickUpDateRef);
                                        handleSubmit();
                                    } else if (e.type === "apply") {
                                        setFieldValue("filterByPickUpDate", picker.startDate.format('DD MMM YY') + " - " + picker.endDate.format('DD MMM YY'));
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="sh form-control customPickerDisplay">
                                    <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                                    <span>{values.filterByPickUpDate}</span>
                                    {
                                        values.filterByPickUpDate
                                            ? <button type="button" className="react-datepicker__close-icon"
                                                      style={{position: "inherit", marginLeft: "10px"}}
                                                      onClick={() => {
                                                          setFieldValue("filterByPickUpDate", "");
                                                          changeStartEndDate(pickUpDateRef);
                                                          handleSubmit();
                                                      }}
                                            />
                                            : null
                                    }
                                    <div style={{float: "right"}}>
                                        <IconSVG icon={'chevron-down'} size={'8px'}/>
                                    </div>
                                </div>
                            </DateRangePicker>
                        </div>
                    </div>
                    {
                        filterDevicesType && filterDevicesType.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <h6 className="fw-bolder mb-3">{t('lsi.table.tipologyDevice')}</h6>
                                        {
                                            <Select
                                                components={{DropdownIndicator, ClearIndicator}}
                                                value={filterTypeSelectedOption}
                                                onChange={(selectedOption, action) => {
                                                    setfilterTypeSelectedOption(selectedOption);
                                                    if (action.option) {
                                                        values.filterByDeviceType = [
                                                            ...values.filterByDeviceType,
                                                            action.option.value
                                                        ];
                                                    } else if (action.removedValue) {
                                                        values.filterByDeviceType = values.filterByDeviceType.filter((value) => {
                                                            return value !== action.removedValue.value;
                                                        });
                                                    } else {
                                                        values.filterByDeviceType = [];
                                                    }
                                                    handleSubmit();
                                                }}
                                                hideSelectedOptions
                                                placeholder=""
                                                styles={customSelectStyles}
                                                closeMenuOnSelect={false}
                                                options={filterDevicesType}
                                                isMulti
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {
                        filterDevicesDistrict && filterDevicesDistrict.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <h6 className="fw-bolder mb-3">{t('lsi.table.district')}</h6>
                                        {
                                            filterDevicesDistrict.map((el) => {
                                                return (
                                                    <div key={"div" + el}>
                                                        <label
                                                            className="checkbox"
                                                            key={"label" + el}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="filterByDeviceDistrict"
                                                                id={el}
                                                                key={el}
                                                                value={el}
                                                                checked={values.filterByDeviceDistrict.includes(el) ? true : false}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        values.filterByDeviceDistrict = [
                                                                            ...values.filterByDeviceDistrict,
                                                                            el
                                                                        ];
                                                                    } else {
                                                                        values.filterByDeviceDistrict = values.filterByDeviceDistrict.filter((value) => {
                                                                            return value !== el;
                                                                        });
                                                                    }
                                                                    handleSubmit();
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            {el}
                                                            <span/>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {/* {
                            filterDevicesLot && filterDevicesLot.length > 0
                                ? <>
                                    
                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <h6 className="fw-bolder mb-3">Lotto</h6>
                                            {
                                                <Select
                                                    value={filterLotSelectedOption}
                                                    onChange={(selectedOption, action) => {
                                                        setfilterLotSelectedOption(selectedOption);
                                                        if (action.option) {
                                                            values.filterByDeviceLot = [
                                                                ...values.filterByDeviceLot,
                                                                action.option.value
                                                            ];
                                                        } else if (action.removedValue) {
                                                            values.filterByDeviceLot = values.filterByDeviceLot.filter((value) => {
                                                                return value !== action.removedValue.value;
                                                            });
                                                        } else {
                                                            values.filterByDeviceLot = [];
                                                        }
                                                        handleSubmit();
                                                    }}
                                                    hideSelectedOptions
                                                    placeholder=""
                                                    styles={customSelectStyles}
                                                    closeMenuOnSelect={false}
                                                    options={filterDevicesLot}
                                                    isMulti
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                                : null
                        } */}
                </Form>
            )
            }
        </Formik>
    );
}
