import {ClinicalDiaryItemResponse, subjectType} from '../../../models/ClinicalDiaryItemModel'
import Heading from '../../../../../shared/custom/headings/heading/Heading'
import InfoData from '../../event-view/InfoData'
import AddFileV from '../AddFileV'
import ListFilesReadV from './components/ListFilesReadV'
import {FileDataModel} from '../../../models/FileDataModel'
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

function EventStepSummary({
                              selectEvent,
                              subjectTypes,
                              addFileInListHandler,
                              removeFileInListHandler,
                              setIsAddFile,
                              isAddFile,
                              files,
                          }: {
    subjectTypes: any[]
    selectEvent: ClinicalDiaryItemResponse
    addFileInListHandler: (file: FileDataModel) => void
    removeFileInListHandler: (file: FileDataModel) => void
    setIsAddFile: (state: boolean) => void
    isAddFile: boolean
    files?: any[]
}) {
    const {t} = useLanguageForShared()

    const addFileInList = (file: FileDataModel) => {
        addFileInListHandler(file)
        setIsAddFile(false)
    }
    const removeFileInList = (file: FileDataModel) => {
        removeFileInListHandler(file)
        setIsAddFile(false)
    }

    return (
        <div>
            <div
                className={"heading-title"}>{t('note.newNote.confirmDatas')}</div>
            <div className="body-event">
                <InfoData subjectTypes={subjectTypes} selectEvent={selectEvent}/>

                <ListFilesReadV
                    files={files}
                    removeFileInList={removeFileInList}
                    setIsAddFileHandler={() => setIsAddFile(true)}
                    isAddFile={isAddFile}
                />
                <div className="row">
                    <div className="col-md-12">
                        {isAddFile && (
                            <AddFileV
                                addFile={(file) => addFileInList(file)}
                                onClose={() => setIsAddFile(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventStepSummary
