import {t} from 'i18next'
import React from 'react'
import configuration from "../../../../../../shared/configuration";
import BtnDefault from "../../../../../../shared/bootstrap/button/default/BtnDefault";

export default function RowBtnReadL({
                                        editHandler,
                                        deleteHandler,
                                        note
                                    }: {
    editHandler: () => void
    deleteHandler: () => void
    note: any
}) {
    return (
        <div className={'toolbar-btn btn-group-distance'}>
            {(!note?.OperationType || note?.OperationType?.includes('edit')) && <BtnDefault
                onClick={editHandler}
                title={t('action.edit')}
                icon={'edit'}
                tintColor={configuration.theme.primary}
                iconSize={'28px'}
            >
            </BtnDefault>}
            {(!note?.OperationType || note?.OperationType?.includes('delete')) && <BtnDefault
                onClick={deleteHandler}
                title={t('action.delete')}
                icon={'trash'}
                tintColor={configuration.theme.primary}
                iconSize={'22px'}
            >
            </BtnDefault>}
        </div>
    )
}
