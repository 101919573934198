import React from 'react';
import {useDateCustom} from "../../../../../shared/custom_use/useDateCustom";
import LabelValue from "../../../../../shared/custom/labelValue/LabelValue";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const RequestRow = ({orderDetail}: any) => {
    const {t} = useLanguageForShared()

    const {data: dataFormatter} = useDateCustom();

    return (
        <div className="mt-2 d-flex flex-wrap gap-5">
            <LabelValue label={t('lsi.applicant')}
                        value={`${orderDetail?.applicant?.surname?.toUpperCase() || '-'} ${orderDetail?.applicant?.name || ''}`}/>
            <LabelValue label={t('lsi.requestDate')}
                        value={orderDetail?.applicant?.data ? dataFormatter.dateFormatGGmmmYYYY(orderDetail?.applicant?.data) : "-"}/>
            <LabelValue label={t('lsi.prescriber')}
                        value={`${orderDetail?.prescriber?.surname?.toUpperCase() || '-'} ${orderDetail?.prescriber?.name || ''}`}/>
            <LabelValue label={t('lsi.prescriberId')}
                        value={orderDetail?.prescriber?.code || "-"}/>
            <LabelValue label={t('lsi.prescriptionId')}
                        value={orderDetail?.prescription?.code || "-"}/>
            <LabelValue label={t('lsi.prescriptionOf')}
                        value={orderDetail?.prescription?.date ? dataFormatter.dateFormatGGmmmYYYY(orderDetail?.prescription?.date) : '-'}/>
            <LabelValue label={t('lsi.diagnosis')}
                        value={orderDetail?.diagnosis || "-"}/>

        </div>
    );
};

export default RequestRow;
