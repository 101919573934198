import {ValueName} from "../../../../../shared/utils/forms/models/DataForm";
import {FormElements} from "../../../../../shared/bootstrap/form/FormElements";


export default function NoteManage({
                                       note,
                                       isEditMode,
                                       formData,
                                       dataForm,
                                       changeValue,
                                       errors,
                                       isSubmit
                                   }: {
    note: string
    isEditMode: boolean
    formData: any
    dataForm: any
    changeValue: ({value, name}: ValueName) => void
    errors: any
    isSubmit?: boolean
}) {
    return (
        <>
            {isEditMode ? (
                <>
                    <FormElements
                        structure={formData}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}
                        isSubmit={isSubmit}
                    />
                </>
            ) : (
                <span className="heading5">{note}</span>
            )}
        </>
    )
}
