export function useFormStructureData({t, emails}: { t: any, emails: string[] }) {
    const formStructureType: any = [
        {
            name: 'eventType',
            type: 'input',
            label: '',
            dataElement: {
                placeholder: '',
                type: 'text',
            },
            col: {xs: "6", sm: "4", lg: "3", xl: "3"}
        },
    ];


    const formStructure: any = [
        {
            name: 'emailAddresses',
            type: 'select-create',
            label: t('notify.sendTo'),
            extra: {multi: true, noShowCaret: true},
            options: emails.map((ele) => (
                {
                    label: ele,
                    value: ele
                }),
            ),
            dataElement: {
                placeholder: t('notify.sendToPlaceholder'),
                type: 'text',
                multi: true,
            },
            col: {xs: "12"}
        },
        {
            name: 'emailBody',
            type: 'textarea',
            label: t('notify.message'),
            subLabel: t('notify.messageSub'),
            dataElement: {
                placeholder: '',
                type: 'input',
            },
            col: {xs: "12"}
        },
    ];


    const validationStructure = () => (
        {
            emailAddresses: {
                required: {
                    params: {name: t('notify.sendTo')}
                },
            },
            emailBody: {
                required: {
                    params: {name: t('notify.message')}
                },
            },
        }
    )
    return {
        formStructureType, formStructure, validationStructure
    }
}