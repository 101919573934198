import {FileDataModel} from '../../../../models/FileDataModel'
import BtnDanger from '../../../../../../shared/bootstrap/button/danger/BtnDanger'
import InputCustom from '../../../../../../shared/bootstrap/form/input/InputCustom'
import BtnSecondary from "../../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../../shared/configuration";
import BtnDefault from "../../../../../../shared/bootstrap/button/default/BtnDefault";

export default function RowInputFileL({
                                          deleteHandler,
                                          uploadFilesHandler,
                                          file,
                                          renameFileHandler,
                                      }: {
    deleteHandler: (file: FileDataModel) => void
    uploadFilesHandler: () => void
    file: FileDataModel
    renameFileHandler: (name: string) => void
}) {
    const {t} = useLanguageForShared()

    return (
        <div className="d-flex align-items-center gap-4 w-100">
            <InputCustom
                value={file.name}
                changeValue={(e) => {
                    renameFileHandler(e)
                }}
                name="fileName"
                type="text"
            />
            <div className={'toolbar-btn btn-group-distance'}>
                <BtnDefault
                    icon="trash"
                    onClick={() => deleteHandler(file)}
                    title={t('action.delete')}
                    tintColor={configuration.theme.primary}
                    iconSize={'20px'}
                ></BtnDefault>
                <BtnDefault
                    icon="check"
                    onClick={uploadFilesHandler}
                    title={t('action.confirm')}
                    tintColor={configuration.theme.primary}
                    iconSize={'20px'}
                ></BtnDefault>
            </div>
        </div>
    )
}
