import React from "react";
import configuration from "../../../shared/configuration";


export function ProgressChart({percentage, title}) {

    return (
        <>
            <div>
                <div className={'d-flex align-items-baseline'}>
                    <div style={{minWidth: '64px'}} className={'d-flex align-items-baseline'}>
                        <h1 className={'mb-0 text-primary-dark display-3'}>{percentage || '-'}</h1>
                        <h3 className={'text-primary-dark'}>%</h3>
                    </div>
                    <div className={'ms-2'}>
                        <h5 className={"text-gray"}>{title}</h5>
                    </div>
                </div>
                <div className="progress mt-3">
                    <div className={"progress-bar"} role={"progressbar"}
                         style={{width: `${percentage}%`, backgroundColor: configuration.theme.series.s770_hex}}
                         aria-valuenow={percentage}
                         aria-valuemin={"0"} aria-valuemax={"100"}></div>
                </div>
            </div>
        </>
    );
}
