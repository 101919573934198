import {t} from 'i18next'
import React from 'react'
import configuration from "../../../../../../shared/configuration";
import BtnDefault from "../../../../../../shared/bootstrap/button/default/BtnDefault";

export default function RowBtnEditL({
                                        uploadHandler,
                                        saveHandler,
                                        cancelHandler,
                                        isAddFile,
                                    }: {
    uploadHandler: () => void
    saveHandler: () => void
    cancelHandler: () => void
    isAddFile: boolean
}) {
    return (
        <div className={'toolbar-btn btn-group-distance'}>
            <BtnDefault
                onClick={uploadHandler}
                disabled={isAddFile}
                title={t('note.action.uploadFile')}
                icon="upload"
                tintColor={configuration.theme.primary}
                iconSize={'20px'}
            ></BtnDefault>
            <BtnDefault
                onClick={saveHandler}
                title={t('action.confirm')}
                icon="save"
                tintColor={configuration.theme.primary}
                iconSize={'26px'}
            ></BtnDefault>
            <BtnDefault
                onClick={cancelHandler}
                title={t('action.cancel')}
                icon="close"
                tintColor={configuration.theme.primary}
                iconSize={'20px'}
            ></BtnDefault>
        </div>
    )
}
