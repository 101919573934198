import React from "react";
import CarriedOutPerformance from "./CarriedOutPerformance";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardCarriedOutPerformance = ({extraordinaryPerformance}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator minHeight={"250px"} title={t('lsi.ventiloTreatmentProvisionPlanned')} view={extraordinaryPerformance && extraordinaryPerformance.length}>
            <CarriedOutPerformance extraordinaryPerformance={extraordinaryPerformance}/>
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardCarriedOutPerformance);
