import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Input
} from "../../../../_metronic/_partials/controls";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import {Formik, Form, Field} from "formik";
import * as actions from "../../../actions/generic/editActions";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";

import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function DeviceEditHome() {
    const {t} = useLanguageForShared()

    const suhbeader = useSubheader();

    //prendo il dettaglio del dispositivo dallo store
    const {currentState} = useSelector(
        (state) => ({
            currentState: state.devices
        }),
        shallowEqual
    );

    const {deviceDetail} = currentState;

    //setto il titolo del subheader e inizializzo il wizard utilizzato per l'edit
    useEffect(() => {
        suhbeader.setTitle(deviceDetail ? `${t('lsi.device.deviceAlert')} - '${deviceDetail.BrandAndModel}' ${t('lsi.device.serial')} - '${deviceDetail.Serial}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('deviceWizard', options);
        //inserendo qui sotto deviceDetail il componente cambia al variare di deviceDetail
    }, [deviceDetail]);

    const customInput = (props) => {
        return (
            <>
                <label>{t('lsi.description')}</label>
                <div style={{backgroundColor: "#F3F6F9", opacity: 1, padding: "0.65rem 1rem"}}>
                    {/* {props.field.value} */}-
                </div>
            </>
        );
    }

    const customLabel = (props) => {
        return (
            <span>
                {props.field.value}
            </span>
        );
    }

    const dispatch = useDispatch();

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-12 col-lg-8">
                        {
                            deviceDetail
                                ? <Card>
                                    <CardBody>
                                        <div id="deviceWizard" className="wizard wizard-3" data-wizard-state="step-first"
                                             data-wizard-clickable="true">
                                            <div className="wizard-nav">
                                                <div className="wizard-steps">
                                                    <div className="wizard-step" data-wizard-type="step"
                                                         data-wizard-state="current">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.generality')}<IconSVG icon={'chevron-right'}
                                                                                                   size={'14px'}
                                                                                                   tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.note')}<IconSVG icon={'chevron-right'}
                                                                                             size={'14px'}
                                                                                             tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.review')}<IconSVG icon={'chevron-right'}
                                                                                               size={'14px'}
                                                                                               tintColor={configuration.theme.primaryDark}/>
                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center  div-custom">
                                                <div className="col-12">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={deviceDetail}
                                                        onSubmit={(values) => {
                                                            const model = {
                                                                GenericObject: {
                                                                    CustomerId: values.CustomerId,
                                                                    Id: values.Id,
                                                                    Reporting: values.Reporting,
                                                                    DeviceType: values.DeviceType,
                                                                    BrandAndModel: values.BrandAndModel,
                                                                    Serial: values.Serial,
                                                                    //Lot: values.Lot,
                                                                    Section: "Dispositivo"
                                                                }
                                                            }
                                                            dispatch(actions.editEntity(model, t));
                                                        }}
                                                    >
                                                        {({handleSubmit}) => (
                                                            <>
                                                                <Form className="form" onSubmit={handleSubmit}>
                                                                    <div className="pb-5" data-wizard-type="step-content"
                                                                         data-wizard-state="current">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.generality')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="DeviceType"
                                                                                    component={Input}
                                                                                    label={t('lsi.device.typology')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="BrandAndModel"
                                                                                    component={Input}
                                                                                    label={t('lsi.device.model')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            {/* <div className="col-lg-4">
                                                                    <Field
                                                                        name="GroupCode"
                                                                        component={Input}
                                                                        label="Codice Gruppo"
                                                                        disabled
                                                                        value={"L01A98"}
                                                                    />
                                                                </div> */}
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="Serial"
                                                                                    component={Input}
                                                                                    label={t('lsi.device.serial')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            {/* <div className="col-lg-4">
                                                                    <Field
                                                                        name="Lot"
                                                                        component={Input}
                                                                        label="Lotto"
                                                                        disabled
                                                                    />
                                                                </div> */}
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="Description"
                                                                                    component={customInput}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="form-group row">
                                                                <div className="col-lg-12">
                                                                    <Field
                                                                        name="Description"
                                                                        component={customInput}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div> */}
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.alert')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <Field
                                                                                    name="Reporting"
                                                                                    component="textarea"
                                                                                    rows="5"
                                                                                    className="sh form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.reviewData')}</h4>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.generality')}</h6>
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.device.typology')} </span>
                                                                                        <Field
                                                                                            name="DeviceType"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.device.model')} </span>
                                                                                        <Field
                                                                                            name="BrandAndModel"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <div>
                                                                            <span>Codice Gruppo: </span>
                                                                            <Field
                                                                                name="GroupCode"
                                                                                component={customLabel}
                                                                            />
                                                                        </div> */}
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.device.serial')} </span>
                                                                                        <Field
                                                                                            name="Serial"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <div>
                                                                            <span>Lotto: </span>
                                                                            <Field
                                                                                name="Lot"
                                                                                component={customLabel}
                                                                            />
                                                                        </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.requestEdit')}
                                                                                </h6>
                                                                                <div
                                                                                    className="text-dark-50 line-height-lg">
                                                                                    <div>
                                                                                        <Field
                                                                                            name="Reporting"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-row">
                                                                        <BtnSecondary
                                                                            onClick={() => {
                                                                                window.history.back();
                                                                            }}
                                                                        >
                                                                            {t('lsi.cancel')}

                                                                        </BtnSecondary>
                                                                        <BtnSecondary
                                                                            data-wizard-type="action-prev"
                                                                        >
                                                                            {t('lsi.prev')}
                                                                        </BtnSecondary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-submit"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            {t('lsi.alert')}
                                                                        </BtnPrimary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-next"
                                                                        >
                                                                            {t('lsi.next')}
                                                                        </BtnPrimary>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
