import configuration from "../../../shared/configuration";
import {t} from "i18next";


export const PatientStatusCssClasses = ["secondary", "primary", "success", "warning", "danger", "info"];
export const PatientStatusTitles = () => [t('lsi.state.unknown'), t('lsi.state.activating'), t('lsi.state.active'), t('lsi.state.suspended'), t('lsi.state.deactivated'), t('lsi.state.deactivation')];

export const PatientGenderTitles = () => ["-", t('lsi.gender.male'), t('lsi.gender.female')];
export const defaultPatientSorted = [{dataField: "Id", order: "desc"}];


export const mapStatusColor = () => ({
    secondary: {
        bg: configuration.theme.gray,
        color: configuration.theme.grayDark,
    },
    primary: {
        bg: configuration.theme.grayLight,
        color: configuration.theme.light,
    },
    success: {
        bg: configuration.theme.success,
        color: configuration.theme.success_color,
    },
    warning: {
        bg: configuration.theme.alert,
        color: configuration.theme.alert_color,
    },
    danger: {
        bg: configuration.theme.inactive,
        color: configuration.theme.inactive_color,
    },
    info: {
        bg: configuration.theme.info,
        color: configuration.theme.info_color,
    },
})
