import * as requestFromServer from "./invoicesCrud";
import { invoicesSlice } from "./invoicesSlice";
import { spinnerCreate } from "../spinner/spinnerActions";
import moment from "moment";

const { actions } = invoicesSlice;

function getCustomerId() {
  return localStorage.getItem("customerId");
}

function getCostsQueryParams(queryParams, isForChart, pagination, sort, isChangedSort, isDistricts = true) {
  let monthYear = queryParams.filter.MonthYear;
  let customerId = getCustomerId();
  let districts = queryParams.filter.District;
  let firstDateOfTheFromMonth = "";
  let lastDateOfTheToMonth = "";
  let params = 'customerId:"' + customerId + '" ';
  //params = 'customerId:"M034787" ';

  if (!isForChart) {
    if (isChangedSort) {
      params += 'first:50';
    } else {
      if (pagination) {
        params += 'first:' + pagination.Row + '';
        if (pagination.EndCursor) {
          params += ' after:"' + pagination.EndCursor + '"';
        }
      } else {
        params += 'first:50';
      }
    }
  }

  if ( monthYear) {
    if (monthYear) {
      let dates = monthYear.split(" - ");
      let dateFrom = dates[0];
      let dateTo = dates[1];
      let splitDateFrom = dateFrom.split("/");
      let yearFrom = splitDateFrom[0];
      let monthFrom = splitDateFrom[1];
      let splitDateTo = dateTo.split("/");
      let yearTo = splitDateTo[0];
      let monthTo = splitDateTo[1];
      firstDateOfTheFromMonth = moment([yearFrom, (monthFrom - 1)]).format("YYYY-MM-DD") + "T00:00:00Z";
      lastDateOfTheToMonth = moment([yearTo, (monthTo - 1)]).endOf("month").format("YYYY-MM-DD") + "T23:59:59Z";
    }
  }

  params += ' startDate:"' + firstDateOfTheFromMonth + '" endDate:"' + lastDateOfTheToMonth + '"';
  if( districts.length && isDistricts){
    params += ' where:{';
    districts.forEach((el, index) => {
      if (index === 0) {
        if (el === "-") {
          params += ' districtName_in:[' + null + '';
        } else {
          params += ' districtName_in:["' + el + '"';
        }
      } else {
        if (el === "-") {
          params += ',' + null + '';
        } else {
          params += ',"' + el + '"';
        }
      }
    });
    params += ']';
    params += '}';
  }

  if(sort && sort.dataField){
    let field = [];
    if (sort.dataField === "PatientNumber") {
      field.push("patientNumber");
    } else if (sort.dataField === "PatientFullName") {
      field.push("patientFirstName");
      field.push("patientLastName");
    } else if (sort.dataField === "PatientDistrict") {
      field.push("patientDistrict");
    }  else if (sort.dataField === "Amount") {
      field.push("amount");
    }

    params += ' order_by: { ';

    field.forEach((el, index) => {
      if (index === 0) {
        params += el + ':' + sort.order.toUpperCase();
      } else {
        params += ',' + el + ':' + sort.order.toUpperCase();
      }
    });

    params += ' }';
  }
  return params;
}

function getInvoicesQueryParams(queryParams, isForFile, pagination, sort, isChangedSort) {
  let monthYear = queryParams.filter.MonthYear;
  let customerId = getCustomerId();
  let firstDateOfTheFromMonth = "";
  let lastDateOfTheToMonth = "";
  let params = 'customerId:"' + customerId + '" ';
  // params = 'customerId:"M034787" ';

  if (!isForFile) {
    if (isChangedSort) {
      params += 'first:50';
    } else {
      if (pagination) {
        params += 'first:' + pagination.Row + '';
        if (pagination.EndCursor) {
          params += ' after:"' + pagination.EndCursor + '"';
        }
      } else {
        params += 'first:50';
      }
    }
  }

  if ( monthYear) {
    if (monthYear) {
      let dates = monthYear.split(" - ");
      let dateFrom = dates[0];
      let dateTo = dates[1];
      let splitDateFrom = dateFrom.split("/");
      let yearFrom = splitDateFrom[0];
      let monthFrom = splitDateFrom[1];
      let splitDateTo = dateTo.split("/");
      let yearTo = splitDateTo[0];
      let monthTo = splitDateTo[1];
      firstDateOfTheFromMonth = moment([yearFrom, (monthFrom - 1)]).format("YYYY-MM-DD") + "T00:00:00Z";
      lastDateOfTheToMonth = moment([yearTo, (monthTo - 1)]).endOf("month").format("YYYY-MM-DD") + "T23:59:59Z";
    }
  }

    params += ' startDate:"' + firstDateOfTheFromMonth + '" endDate:"' + lastDateOfTheToMonth + '"';
    if(sort && sort.dataField){
      let field = [];
      if (sort.dataField === "Id") {
        field.push("id");
      } else if (sort.dataField === "PostingDate") {
        field.push("postingDate");
      } else if (sort.dataField === "Cig") {
        field.push("cig");
      }  else if (sort.dataField === "CustomerOrderDate") {
        field.push("customerOrderDate");
      } else if (sort.dataField === "CustomerOrderNumber") {
        field.push("customerOrderNumber");
      } else if (sort.dataField === "ConventionNumber") {
        field.push("conventionNumber");
      }  else if (sort.dataField === "Amount") {
        field.push("amount");
      }

      params += ' order_by: { ';

      field.forEach((el, index) => {
        if (index === 0) {
          params += el + ':' + sort.order.toUpperCase();
        } else {
          params += ',' + el + ':' + sort.order.toUpperCase();
        }
      });

      params += ' }';
    }
    return params;
}

export const fetchCosts = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
  dispatch(spinnerCreate(true));
  let params = getCostsQueryParams(queryParams, false, pagination, sort, isChangedSort);

  try {
    const response = await requestFromServer.findCosts(params);
    dispatch(actions.costsFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
};

export const fetchCostsForFile = (queryParams, callback) => async dispatch => {
  dispatch(spinnerCreate(true));

  let params = getCostsQueryParams(queryParams, true);

  try {
    const response = await requestFromServer.findCosts(params);
    dispatch(actions.costsForFileFetched({ entities: response.data, callback }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
};

export const loadDistricts = () => async dispatch => {
  let customerId = getCustomerId();
  dispatch(spinnerCreate(true));
  let params = `query{billingCostsFilters(customerId:"${customerId}"){districts}}`;
  try {
    const response = await requestFromServer.loadDistrictsChart(params);
    dispatch(actions.setFilterDistricts(response.data.data.billingCostsFilters.districts));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
}

export const fetchCostsChart = (queryParams) => async dispatch => {
  dispatch(spinnerCreate(true));

  let originalParams = getCostsQueryParams(queryParams, true, undefined, undefined, undefined, true);
  let originalParamsBase = getCostsQueryParams(queryParams, true, undefined, undefined, undefined, false);

  //let originalParams = 'customerId:"M034787" startDate:"2021-04-01T00:00:00Z" endDate:"2022-04-26T00:00:00Z"';
  let params = `query{billingCostsGraphV2(${originalParamsBase} patientsInvoicesQuery:"query{billingPatientsInvoicesAndCosts(${originalParams.replace(/"/g, '\\"')}){nodes{id patientNumber patientFirstName patientLastName districtName amount}}}"){perMonth{year month cost} perMonthAndDistrict{year month district cost}}}`;
  try {
    const response = await requestFromServer.findCostsChart(params);
    dispatch(actions.costsChartFetched( response.data.data.billingCostsGraphV2[0] ));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
};

export const fetchCostsNotAccounted = (queryParams) => async dispatch => {
  dispatch(spinnerCreate(true));
  let arr = queryParams.split("/");
  let month = arr[0];
  let year = arr[1];
  let customerId = getCustomerId();
  let params = 'customerId:"' + customerId + '" ';

  params += 'where:{monthNumber: "' + month + '" year:"' + year + '"}';
  try {
    const response = await requestFromServer
      .findCostsNotAccounted(params);
    dispatch(actions.costsNotAccountedFetched({ entities: response.data }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
}

export const fetchInvoices = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
  dispatch(spinnerCreate(true));

  let params = getInvoicesQueryParams(queryParams, false, pagination, sort, isChangedSort);

  try {
    const response = await requestFromServer
      .findInvoices(params);
    dispatch(actions.invoicesFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
};

export const fetchInvoicesForFile = (queryParams, callback) => async dispatch => {
  dispatch(spinnerCreate(true));

  let params = getInvoicesQueryParams(queryParams, true);

  try {
    const response = await requestFromServer
      .findInvoices(params);
    dispatch(actions.invoicesForFileFetched({ entities: response.data, callback }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
};

export const setCostRow = cost => dispatch => {
  dispatch(actions.setCostRow({ data: cost }));
}

export const fetchInvoicesFilters = InvoiceDate => async dispatch => {
  dispatch(spinnerCreate(true));
  let customerId = getCustomerId();
  let params = 'customerId:"' + customerId + '" ';

  params += 'dateFrom:"' + moment(InvoiceDate.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" dateTo:"' + moment(InvoiceDate.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';

  try {
    const response = await requestFromServer
      .findInvoicesFilters(params);
    dispatch(actions.invoicesFitlersFetched({ entities: response.data }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
}

export const fetchCostsFilters = MonthYear => async dispatch => {
  dispatch(spinnerCreate(true));
  let customerId = getCustomerId();
  let params = 'customerId:"' + customerId + '" ';

  params += 'dateFrom:"' + moment(MonthYear.split('-')[0].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T00:00:00Z" dateTo:"' + moment(MonthYear.split('-')[1].trim(), "DD/MM/YYYY").format("YYYY-MM-DD") + 'T23:59:59Z"';

  try {
    const response = await requestFromServer
      .findCostsFilters(params);
    dispatch(actions.costsFitlersFetched({ entities: response.data }));
    dispatch(spinnerCreate(false));
  }
  catch (error) {
    dispatch(spinnerCreate(false));
    console.error(error);
  }
}
