import React, {useEffect} from "react";
import {Card, CardBody, CardHeader,} from "../../../../_metronic/_partials/controls";
import {PatientsTable} from "./PatientsTable";
import {PatientsChart} from "./PatientsChart";
import {PatientsFilters} from "./PatientsFilters";
import {KTScrollFilters} from "../../../component/scroll/Scroll";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const PatientsHome = () => {
    const {t} = useLanguageForShared()

    useEffect(() => {
        KTScrollFilters.init("patientsFilter")
    }, []);

    return (
        <div className="row layout-page" style={{zIndex: "1"}}>
            <div className="col-lg-2 filter-col">
                <Card className="card-sticky" id="patientsFilter">
                    <CardBody className="offcanvas-content">
                        <PatientsFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <h3 className={'title-page'}>{t('lsi.patients')}</h3>
                <Card>
                    <CardHeader title={t('lsi.tipologyTreatments')} className="fw-bolder"/>
                    <CardBody>
                        <PatientsChart/>
                    </CardBody>
                </Card>
                <PatientsTable/>
            </div>
        </div>
    );
};
