//import moment from "moment";

export const defaultStaffSorted = [{ dataField: "Nominative", order: "asc" }];
// export const initialStaffFilter = {
//   filter: {
//     SearchText: "",
//     BirthDate: "",
//     Titles: undefined,
//     Districts: undefined
//   },
//   sortOrder: "desc", // asc||desc
//   sortField: "InchargeDate",
// };
