import React, {useState} from 'react';
import InfoIcon from "../../../../../shared/UI components/InfoIcon";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const SubheaderDeviceTemplate = ({data}: { data: any }) => {
    const {t} = useLanguageForShared()

    return (
        <>
            {data &&
                <>
                    {data &&
                        <InfoIcon label={data.BrandAndModel} value={t('lsi.table.device')} icon={'device'}/>
                    }
                    <div></div>
                </>
            }
        </>
    )
};

export default SubheaderDeviceTemplate;
