// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import * as patientActions from "../../../actions/patients/patientsActions";
import * as deviceActions from "../../../actions/devices/devicesActions";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import Dropdown from "../../../../shared/bootstrap/dropdown/Dropdown";
import configuration from "../../../../shared/configuration";

const CustomToggle = React.forwardRef((props, ref) => {
    return (
        <a
            ref={ref}
            className="btn btn-sm btn-clean btn-icon"
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
      <span className="svg-icon svg-icon-md">
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <circle fill="#000000" cx="12" cy="5" r="2"/>
            <circle fill="#000000" cx="12" cy="12" r="2"/>
            <circle fill="#000000" cx="12" cy="19" r="2"/>
          </g>
        </svg>
      </span>
        </a>
    );
});

// const CustomMenu = React.forwardRef(
//   ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
//     return (
//       <div
//         ref={ref}
//         style={style}
//         className={className}
//         aria-labelledby={labeledBy}
//       >
//         <ul className="navi flex-column navi-hover py-2">
//           {
//             React.Children.toArray(children).map(
//               (el, index) =>
//                 <li key={"li" + index} className="navi-item" onClick={() => { "" }}>
//                   <a className="navi-link">
//                     <span className="navi-text">{el.props.children}</span>
//                   </a>
//                 </li>
//             )
//           }
//         </ul>
//       </div>
//     );
//   },
// );

export function AssetsColumnFormatter(cellContent, row, rowIndex) {
    return (
        <>
            {row.Assets && row.Assets.length > 0
                ? <Dropdown style={{display: "contents"}}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"/>
                    <Dropdown.Menu className="dropdown-menu-sm dropdown-menu-right" style={{width: "fit-content"}}>
                        {
                            row.Assets.map((el, index) => {
                                let assetsTag = [];
                                switch (el.AssetType) {
                                    case 1:
                                        assetsTag.push(
                                            <Dropdown.Item href={el.AssetLink} target="_blank">Scheda
                                                Tecnica</Dropdown.Item>
                                        )
                                        break;
                                    case 2:
                                        assetsTag.push(
                                            <Dropdown.Item href={el.AssetLink} target="_blank">Manuale</Dropdown.Item>
                                        )
                                        break;
                                    case 4:
                                        assetsTag.push(
                                            <Dropdown.Item href={el.AssetLink} target="_blank">Dichiarazione di
                                                Conformità</Dropdown.Item>
                                        )
                                        break;
                                    default:
                                        break;
                                }
                                return assetsTag;
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                : <span>-</span>
            }
        </>

    );
}

export function DownloadProvisionAssetsTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
) {

    return (
        <a
            title="Scarica il documento"
            className="btn btn-sm btn-clean btn-icon"
            href={row.AssetLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            <IconSVG icon={'download'} tintColor={configuration.theme.primary} size={'20px'}/>
        </a>
    );
}

export function DownloadInvoicesTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
) {

    return (
        <a
            title="Scarica il documento"
            className="btn btn-sm btn-clean btn-icon"
            href={row.DocumentLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            <IconSVG icon={'download'} tintColor={configuration.theme.primary} size={'20px'}/>

        </a>
    );
}

export function DownloadStaffTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
) {

    return (
        <a
            title="Scarica il documento"
            className="btn btn-sm btn-clean btn-icon"
            href={row.AssetLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            <IconSVG icon={'download'} tintColor={configuration.theme.primary} size={'20px'}/>

        </a>
    );
}

export function DeviceAccessorieActionTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {history, dispatch, title}
) {
    return (
        <a
            title={title}
            className="btn btn-sm btn-clean btn-icon"
            onClick={
                () => {
                    dispatch(deviceActions.setAccessorieRow(row));
                    history.push(`/dispositivi/${row.Id}/segnalazione-accessorio`);
                }
            }
        >
            <IconSVG icon={'priority_message'} tintColor={configuration.theme.primary} size={'20px'}/>
        </a>
    );
}

export function DeviceLastPatientAssignedActionTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {history, dispatch, title}
) {
    return (
        <a
            title={title}
            className="btn btn-sm btn-clean btn-icon"
            onClick={
                () => {
                    dispatch(deviceActions.setLastPatientAssignedRow(row));
                    history.push(`/dispositivi/${row.Id}/segnalazione-ultimo-paziente`);
                }
            }
        >
            <IconSVG icon={'priority_message'} tintColor={configuration.theme.primary} size={'20px'}/>
        </a>
    );
}

export function DeviceLogBookActionTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {history, dispatch, title}
) {
    return (
        <a
            title={title}
            className="btn btn-sm btn-clean btn-icon"
            onClick={
                () => {
                    dispatch(deviceActions.setLogBookRow(row));
                    history.push(`/dispositivi/${row.Id}/segnalazione-libro-macchina`);
                }
            }
        >
            <IconSVG icon={'priority_message'} tintColor={configuration.theme.primary} size={'20px'}/>
        </a>
    );
}

export function PatientInvoiceActionTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {history, dispatch, link, title}
) {
    return (
        <a
            title={title}
            className="btn btn-sm btn-clean btn-icon"
            onClick={
                () => {
                    dispatch(patientActions.setInvoiceRow(row));
                    history.push(link);
                }
            }
        >
            <IconSVG icon={'priority_message'} tintColor={configuration.theme.primary} size={'20px'}/>
        </a>
    );
}

export function FotoColumnFormatter(cellContent, row, rowIndex) {
    return (
        <div className="symbol symbol-40 symbol-sm flex-shrink-0">
            <img src={row.Photo ? row.Photo : "https://aelindelsi.blob.core.windows.net/assets/img/192378.svg"} alt=""/>
        </div>
    );
}

export function DevicesFotoColumnFormatter(cellContent, row, rowIndex) {
    return (
        <span className="navi-icon me-4">
      <span className="svg-icon svg-icon-lg-table">
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path
                d="M15,15 L15,10 C15,9.44771525 15.4477153,9 16,9 C16.5522847,9 17,9.44771525 17,10 L17,15 L20,15 C21.1045695,15 22,15.8954305 22,17 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,17 C2,15.8954305 2.8954305,15 4,15 L15,15 Z M5,17 C4.44771525,17 4,17.4477153 4,18 C4,18.5522847 4.44771525,19 5,19 L10,19 C10.5522847,19 11,18.5522847 11,18 C11,17.4477153 10.5522847,17 10,17 L5,17 Z"
                fill="#000000"/>
            <path
                d="M20.5,7.7155722 L19.2133304,8.85714286 C18.425346,7.82897283 17.2569914,7.22292937 15.9947545,7.22292937 C14.7366498,7.22292937 13.571742,7.82497398 12.7836854,8.84737587 L11.5,7.70192243 C12.6016042,6.27273291 14.2349886,5.42857143 15.9947545,5.42857143 C17.7603123,5.42857143 19.3985009,6.27832502 20.5,7.7155722 Z M23.5,5.46053062 L22.1362873,6.57142857 C20.629466,4.78945909 18.4012066,3.73944576 15.9963045,3.73944576 C13.5947271,3.73944576 11.3692392,4.78653417 9.8623752,6.56427829 L8.5,5.45180053 C10.340077,3.28094376 13.0626024,2 15.9963045,2 C18.934073,2 21.6599771,3.28451636 23.5,5.46053062 Z"
                fill="#000000" fillRule="nonzero" opacity="0.3"/>
          </g>
        </svg>
      </span>
    </span>
    );
}

export function AccessoriesFotoColumnFormatter(cellContent, row, rowIndex) {
    return (
        <span className="navi-icon me-4">
      <span className="svg-icon svg-icon-lg-table">
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"/>
            <path
                d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                fill="#000000" opacity="0.3"/>
            <path
                d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                fill="#000000" opacity="0.3"/>
            <path
                d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                fill="#000000"/>
          </g>
        </svg>
      </span>
    </span>
    );
}

export function ConsumablesFotoColumnFormatter(cellContent, row, rowIndex) {
    return (
        <span className="navi-icon me-4">
      <span className="svg-icon svg-icon-lg-table">
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path
                d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
                fill="#000000" opacity="0.3"/>
            <polygon fill="#000000"
                     points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"/>
          </g>
        </svg>
      </span>
    </span>
    );
}

export function MedicinalsFotoColumnFormatter(cellContent, row, rowIndex) {
    return (
        <span className="navi-icon me-4">
      <span className="svg-icon">
        <i className="fas fa-pills" style={{fontSize: "25px"}}/>
      </span>
    </span>
    );
}

export function PatientPrescriptionsActionTableColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {history, dispatch}
) {
    const goSGfeAction = (id, type) => {
        window.location.href = `${process.env.REACT_APP_URL_SG}order/${id.replace('/', '-')}/actions/${type}`
    }
    const goSGfeShow = (id) => {
        window.location.href = `${process.env.REACT_APP_URL_SG}ordine/${id.replace('/', '-')}`
    }
    const dropdownList = <>
        <li data-test="dropdown-item" className={"dropdown-item"}>
            <a
                title={'Invia segnalazione'}
                className="d-flex gap-2"
                onClick={
                    () => {
                        dispatch(patientActions.setPrescriptionRow(row));
                        history.push(`/pazienti/${row.Id}/segnalazione-prescrizione`);
                    }
                }
            >
                <IconSVG icon={'priority_message'} size={'20px'} tintColor={configuration.theme.primary}/>
                Invia segnalazione
            </a>
        </li>
        {row.actionsProduct?.includes('suspension') && <li data-test="dropdown-item" className={"dropdown-item"}>
            <a
                title={'Sospendi'}
                className="d-flex gap-2"
                onClick={() => {
                    goSGfeShow(row.orderNumber)
                }}
            >
                <IconSVG icon={'suspend_treatment'} size={'22px'} tintColor={configuration.theme.primary}/>
                Sospendi trattamento
            </a>
        </li>}
        {row.actionsProduct?.includes('renewal') && <li data-test="dropdown-item" className={"dropdown-item"}>
            <a
                title={'Rinnova Trattamento'}
                className="d-flex gap-2"
                onClick={() => {
                    goSGfeAction(row.orderNumber, 'Renewal')
                }}
            >
                <IconSVG icon={'refresh_treatment'} size={'22px'} tintColor={configuration.theme.primary}/>
                Rinnova trattamento
            </a>
        </li>}
        {
            row.actionsProduct?.includes('variation') && <li data-test="dropdown-item" className={"dropdown-item"}>
                <a
                    title={'Modifica Richiesta trattamento'}
                    className="d-flex gap-2"
                    onClick={() => {
                        goSGfeAction(row.orderNumber, 'Variation')
                    }}
                >
                    <IconSVG icon={'edit'} size={'24px'} tintColor={configuration.theme.primary}/>
                    Modifica richiesta trattamento
                </a>
            </li>
        }

    </>

    return (
        <div className={'dropdown-box d-flex gap-3'}>

            <Dropdown dataTest={'action-drop'} className={'nav-item-menu nav-item dropdown action-drop'}
                      position={'right'}
                      list={dropdownList}>
                <div className={'dropdown-toggle'} onClick={() => null}>
                    <IconSVG icon={'more'} size={'20px'} tintColor={configuration.theme.primary}/>
                </div>
            </Dropdown>
        </div>
    );
}
