import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    Input
} from "../../../../_metronic/_partials/controls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import {Formik, Form, Field} from "formik";
import {PatientGenderTitles, PatientStatusTitles} from "../PatientsUIHelpers";
import * as actions from "../../../actions/generic/editActions";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientEditHome() {
    const {t} = useLanguageForShared()

    const suhbeader = useSubheader();
    const dispatch = useDispatch();

    const {patientById} = useSelector(
        (state) => ({
            patientById: state.patients.patientById
        }),
        shallowEqual
    );

    useEffect(() => {
        suhbeader.setTitle(patientById ? `${t('lsi.edit.patientAlert')} - '${patientById.FullName}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('patientWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientById]);

    const customLabel = (props) => {
        return (
            props.field.name === "Addresses"
                ? <>
                    {
                        props.field.value.map((el, index) => {
                            return (
                                <div className="my-1" key={index}>
                                    <span>{el}</span>
                                </div>
                            )
                        })
                    }
                </>
                : <span>
                    {props.field.name === "Gender" ? PatientGenderTitles()[props.field.value] : props.field.value}
                </span>
        );
    }

    const customInput = (props) => {
        let textAreaValue = "";
        if (props.field.name === "Addresses" && props.field.value) {
            props.field.value.forEach((el) => {
                textAreaValue += el + "\r\n\r\n";
            });
        }
        return (
            props.field.name === "Gender"
                ? <>
                    <label>{t('lsi.registry.gender')}</label>
                    <input type="text" className="sh form-control" name="Gender" disabled
                           value={PatientGenderTitles()[props.field.value]}/>
                </>
                : <>
                    <label>{t('lsi.registry.otherAddresses')}</label>
                    <textarea name="Addresses" className="sh form-control" disabled rows={6} value={textAreaValue}/>
                </>
        );
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xs-12 col-md-12 col-lg-8">
                    <Card>
                        <CardBody>
                            <div id="patientWizard" className="wizard wizard-3" data-wizard-state="step-first"
                                 data-wizard-clickable="true">
                                <div className="wizard-nav">
                                    <div className="wizard-steps">
                                        <div className="wizard-step" data-wizard-type="step"
                                             data-wizard-state="current">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    {t('lsi.edit.generality')}
                                                    <IconSVG icon={'chevron-right'} size={'14px'}
                                                             tintColor={configuration.theme.primaryDark}/>
                                                </h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    {t('lsi.edit.address')}
                                                    <IconSVG icon={'chevron-right'} size={'14px'}
                                                             tintColor={configuration.theme.primaryDark}/>
                                                </h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    {t('lsi.edit.contacts')}
                                                    <IconSVG icon={'chevron-right'} size={'14px'}
                                                             tintColor={configuration.theme.primaryDark}/>
                                                </h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    {t('lsi.edit.note')}
                                                    <IconSVG icon={'chevron-right'} size={'14px'}
                                                             tintColor={configuration.theme.primaryDark}/>
                                                </h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                        <div className="wizard-step" data-wizard-type="step">
                                            <div className="wizard-label">
                                                <h3 className="wizard-title">
                                                    {t('lsi.edit.review')}
                                                    <IconSVG icon={'chevron-right'} size={'14px'}
                                                             tintColor={configuration.theme.primaryDark}/>
                                                </h3>
                                                <div className="wizard-bar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center div-custom">
                                    <div className="col-12">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={patientById}
                                            onSubmit={(values) => {
                                                const model = {
                                                    GenericObject: {
                                                        Id: values.Id,
                                                        FirstName: values.FirstName,
                                                        LastName: values.LastName,
                                                        BirthDate: values.BirthDate,
                                                        Gender: PatientGenderTitles()[values.Gender],
                                                        FiscalCode: values.FiscalCode,
                                                        District: values.District,
                                                        PatientState: PatientStatusTitles()[values.PatientState],
                                                        Email: values.Email,
                                                        Phone: values.Phone,
                                                        HomeAddress: values.HomeAddress,
                                                        ResidenceAddress: values.ResidenceAddress,
                                                        Addresses: values.Addresses,
                                                        Reporting: values.Reporting,
                                                        CustomerId: values.CustomerId,
                                                        Section: "Paziente"
                                                    }
                                                }
                                                dispatch(actions.editEntity(model,t));
                                            }}
                                        >
                                            {({handleSubmit}) => (
                                                <>
                                                    <Form className="form" onSubmit={handleSubmit}>
                                                        <div className="pb-5" data-wizard-type="step-content"
                                                             data-wizard-state="current">
                                                            <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.patientGenerality')}</h4>
                                                            <div className="form-group row">
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="LastName"
                                                                        component={Input}
                                                                        label={t('lsi.registry.lastName')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="FirstName"
                                                                        component={Input}
                                                                        label={t('lsi.registry.firstName')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-xl-4">
                                                                    <Field
                                                                        name="BirthDate"
                                                                        component={Input}
                                                                        label={t('lsi.registry.birthDate')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-xl-4">
                                                                    <Field
                                                                        name="Gender"
                                                                        component={customInput}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-xl-4">
                                                                    <Field
                                                                        name="FiscalCode"
                                                                        component={Input}
                                                                        label={t('lsi.registry.fiscalCode')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="District"
                                                                        component={Input}
                                                                        label={t('lsi.registry.medicalDistrict')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="RegionalCode"
                                                                        component={Input}
                                                                        label={t('lsi.registry.regionalCodePatient')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 fw-bold text-dark">{t('lsi.registry.addresses')}</h4>
                                                            <div className="form-group row">
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="ResidenceAddress"
                                                                        component={Input}
                                                                        label={t('lsi.registry.residenceAddress')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="HomeAddress"
                                                                        component={Input}
                                                                        label={t('lsi.registry.homeAddress')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-xl-12">
                                                                    <Field
                                                                        name="Addresses"
                                                                        component={customInput}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 fw-bold text-dark">{t('lsi.registry.contactsPatient')}</h4>
                                                            <div className="form-group row">
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="Phone"
                                                                        component={Input}
                                                                        label={t('lsi.registry.phone')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <Field
                                                                        name="Email"
                                                                        component={Input}
                                                                        label={t('lsi.registry.email')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-xl-12">
                                                                    <Field
                                                                        name="CareGiverNominative"
                                                                        component={Input}
                                                                        label={t('lsi.registry.caregiver')}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.alert')}</h4>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="Reporting"
                                                                    component="textarea"
                                                                    rows="5"
                                                                    className="sh form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="pb-5" data-wizard-type="step-content">
                                                            {
                                                                patientById
                                                                    ? <>
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.reviewData')}</h4>
                                                                        <div className="row">
                                                                            <h6 className="fw-bolder mb-3">{t('lsi.edit.generality')}</h6>
                                                                            <div className="col-lg-12">
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.lastName')}</span>
                                                                                        <Field
                                                                                            name="LastName"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.firstName')} </span>
                                                                                        <Field
                                                                                            name="FirstName"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.birthDate')} </span>
                                                                                        <Field
                                                                                            name="BirthDate"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.gender')} </span>
                                                                                        <Field
                                                                                            name="Gender"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.fiscalCode')} </span>
                                                                                        <Field
                                                                                            name="FiscalCode"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.district')}  </span>
                                                                                        <Field
                                                                                            name="District"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.regionalCode')} </span>
                                                                                        <Field
                                                                                            name="RegionalCode"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row col-lg-12">
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div
                                                                                        className="fw-bolder mb-3">{t('lsi.registry.residenceAddress')}
                                                                                    </div>
                                                                                    <div
                                                                                        className="text-dark-50 line-height-lg">
                                                                                        <div>
                                                                                            <Field
                                                                                                name="ResidenceAddress"
                                                                                                component={customLabel}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 col-lg-4">
                                                                                    <div
                                                                                        className="fw-bolder mb-3 h6-custom">{t('lsi.registry.homeAddress')}
                                                                                    </div>
                                                                                    <div
                                                                                        className="text-dark-50 line-height-lg">
                                                                                        <div>
                                                                                            <Field
                                                                                                name="HomeAddress"
                                                                                                component={customLabel}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 col-lg-4">

                                                                                    <div
                                                                                        className="fw-bolder mb-3 h6-custom">{t('lsi.registry.otherAddresses')}
                                                                                    </div>
                                                                                    <div
                                                                                        className="text-dark-50 line-height-lg">
                                                                                        <div>
                                                                                            <Field
                                                                                                name="Addresses"
                                                                                                component={customLabel}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-3">

                                                                            <h6 className="fw-bolder mb-3">{t('lsi.edit.contacts')}</h6>
                                                                            <div className="col-lg-12">
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.phone')} </span>
                                                                                        <Field
                                                                                            name="Phone"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.email')} </span>
                                                                                        <Field
                                                                                            name="Email"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.registry.caregiver')} </span>
                                                                                        <Field
                                                                                            name="CareGiverNominative"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <div className="fw-bolder mb-3">{t('lsi.registry.note')}</div>
                                                                                <div
                                                                                    className="text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <Field
                                                                                            name="Reporting"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div className="btn-row">
                                                            <BtnSecondary
                                                                onClick={() => {
                                                                    window.history.back();
                                                                }}
                                                            >
                                                                {t('lsi.cancel')}
                                                            </BtnSecondary>
                                                            <BtnSecondary
                                                                data-wizard-type="action-prev"
                                                            >
                                                                {t('lsi.prev')}
                                                            </BtnSecondary>
                                                            <BtnPrimary
                                                                data-wizard-type="action-submit"
                                                                onClick={handleSubmit}
                                                            >
                                                                {t('lsi.notify')}
                                                            </BtnPrimary>
                                                            <BtnPrimary
                                                                data-wizard-type="action-next"
                                                            >
                                                                {t('lsi.next')}
                                                            </BtnPrimary>
                                                        </div>
                                                    </Form>
                                                </>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
};
