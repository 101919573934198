import {t} from 'i18next'
import {useState} from 'react'
import SubHeading from '../../../../shared/custom/headings/subheading/SubHeading'
import DropzoneComponent from '../dropzone/DropzoneComponent'
import RowInputFileV from './steps/components/RowInputFileV'
import {FileDataModel} from "../../models/FileDataModel";
import {getBase64ToString} from '../../../../shared/utils/fileUtils'

function AddFileV({
                     addFile,
                     onClose,
                 }: {
    addFile: (file: FileDataModel) => void
    onClose?: () => void
}) {
    const [filesDropzone, setFilesDropzone] = useState<FileDataModel[] | undefined>(
        undefined
    )
    // const [files, setFiles] = useState<{file: any, base64?: string, name?: string, fileType?: string }[] | undefined>(undefined)
    // const fileArray = file.name.split('.');
    // getBase64(file).then(res => {
    //   setFiles([...(files || []), {file, base64: res, name: fileArray.filter((ele: string,index: number) => index < fileArray.length -1 ), fileType: fileArray[fileArray.length -1]}])
    // })

    const uploadFilesHandler = () => {
        filesDropzone && filesDropzone.length && addFile(filesDropzone[0])
    }
    const deleteHandler = (file: any) => {
        setFilesDropzone([
            ...(filesDropzone || []).filter((ele) => ele.file.path !== file.file.path),
        ])
    }

    const renameFile = (fileNew: any, name: string) => {
        setFilesDropzone(
            filesDropzone?.map((file) =>
                file.file.path === fileNew.file.path ? {...file, name} : file
            )
        )
    }
    const setDropzoneFile = (files: any[]) => {
        if (files.length) {
            const file = files[0];
            getBase64ToString(file).then((res: any) => {
                const fileArray = file.name.split('.');
                setFilesDropzone([{
                    file,
                    base64: res,
                    name: fileArray.filter((ele: string, index: number) => index < fileArray.length - 1).join(''),
                    FileType: fileArray[fileArray.length - 1]
                }])
            })
        } else {
            setFilesDropzone([]);
        }
    }
    return (
        <>
            <SubHeading
                title={t('note.newNote.addFile')}
                rightTemplate={
                    <>{onClose && <span className="pointer" onClick={onClose}></span>}</>
                }
            />

            <div className="row">
                <div className="col-md-12">
                    {!(filesDropzone && filesDropzone.length > 0) && (
                        <div className="col-md-12">
                            <DropzoneComponent onDrop={setDropzoneFile}/>
                        </div>
                    )}
                </div>

                <div className="col-md-12">
                    <div className="col-md-12">
                        {filesDropzone?.map((file, i) => (
                            <div className="d-flex align-items-center" key={i}>
                                <span className="me-5 text-nowrap">{t('note.newNote.nameFile')}</span>
                                <RowInputFileV
                                    key={i}
                                    deleteHandler={deleteHandler}
                                    uploadFilesHandler={uploadFilesHandler}
                                    renameFileHandler={(name) => renameFile(file, name)}
                                    file={file}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddFileV
