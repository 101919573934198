import {t} from "i18next";

export const defaultDevicesTableSorted = [{dataField: "DeliveryDate", order: "desc"}];

export const DeviceLogBookTypeTitle = () =>
    [
        t('lsi.state.unknown'),
        t('lsi.state.activating'),
        t('lsi.state.deactivation'),
        t('lsi.state.ordinaryAssistance'),
        t('lsi.state.scheduledAssistance'),
        t('lsi.state.correctiveAssistance'),
        t('lsi.state.extraordinaryAssistance'),
        t('lsi.state.qualityCheck'),
        t('lsi.state.functionalityCheck'),
        t('lsi.state.calibrationCheck'),
        t('lsi.state.checkElectricalSafety'),
        t('lsi.state.delivery'),
        t('lsi.state.training'),
        t('lsi.state.retreat'),
        t('lsi.state.technicalIntervention'),
    ];

export function getDeviceLogBookTypeValue(type) {
    let deviceTypeNumber;

    if (type === "UNKNOWN") {
        deviceTypeNumber = 0;
    } else if (type === "ATTIVAZIONE") {
        deviceTypeNumber = 1;
    } else if (type === "DISATTIVAZIONE") {
        deviceTypeNumber = 2;
    } else if (type === "MANUTENZIONEORDINARIA") {
        deviceTypeNumber = 3;
    } else if (type === "MANUTENZIONEPROGRAMMATA") {
        deviceTypeNumber = 4;
    } else if (type === "MANUTENZIONECORRETTIVA") {
        deviceTypeNumber = 5;
    } else if (type === "MANUTENZIONESTRAORDINARIA") {
        deviceTypeNumber = 6;
    } else if (type === "CONTROLLOQUALITA") {
        deviceTypeNumber = 7;
    } else if (type === "CONTROLLOFUNZIONALITA") {
        deviceTypeNumber = 8;
    } else if (type === "CONTROLLOTARATURA") {
        deviceTypeNumber = 9;
    } else if (type === "VERIFICASICUREZZAELETTRICA") {
        deviceTypeNumber = 10;
    } else if (type === "CONSEGNA") {
        deviceTypeNumber = 11;
    } else if (type === "FORMAZIONE") {
        deviceTypeNumber = 12;
    } else if (type === "RITIRO") {
        deviceTypeNumber = 13;
    } else if (type === "INTERVENTOTECNICO") {
        deviceTypeNumber = 14;
    }
    return deviceTypeNumber;
}