import React from 'react'
import {ValueName} from "../../../../../shared/utils/forms/models/DataForm";
import {FormElements} from "../../../../../shared/bootstrap/form/FormElements";
import {useDateCustom} from "../../../../../shared/custom_use/useDateCustom";

export default function DateManageV({
  date,
  isEditMode,
  formData,
  dataForm,
  changeValue,
  errors,
}: {
  date: string
  isEditMode: boolean
  formData: any
  dataForm: any
  changeValue: ({ value, name }: ValueName) => void
  errors: any
}) {
  const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()
  const dateItem = dateFormatGGmmmYYYYhhMM(date).split(',')

  return (
    <>
      {isEditMode ? (
        <div>

          <FormElements
              structure={formData}
              dataForm={dataForm}
              errors={errors}
              changeValue={changeValue}
          />

        </div>
      ) : (
        <div className="date text-right">
          <small>
            <div>{dateItem[0]}</div>
            <div>{dateItem[1]}</div>
          </small>
        </div>
      )}
    </>
  )
}
