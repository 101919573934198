import React from 'react';

interface HeadingProps {
    left: React.ReactNode
    rightTop?: React.ReactNode
    rightBottom?: React.ReactNode
}

const Heading = ({left, rightTop, rightBottom}: HeadingProps) => {
    return (
        <div className={"row heading"}>
            <div className="col-sm-12">
                <div className="d-flex justify-content-between">
                    {left}
                    {rightTop}
                </div>
            </div>
            <div className="col-sm-12">
                {rightBottom}
            </div>
        </div>
    );
};

export default Heading;
