import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-month-picker';
import "react-month-picker/scss/month-picker.scss";
import moment from "moment";
import "../../assets/css/monthPicker.css";
import {IconSVG} from "../../../shared/custom/icon/IconSVG";

class MonthBox extends Component {
    static propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            value: this.props.value || 'N/A',
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            value: props.value || 'N/A',
        }
    }

    render() {
        return (
            <div data-test="custom-month-picker" className="sh form-control" onClick={this._handleClick} style={{ display: "inline-table" }}>
                 <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                <span style={{ marginLeft: "10px" }}>{this.state.value}</span>
                <div style={{ float: "right" }}>
                   <IconSVG icon={'chevron-down'} size={'8px'}/>
                </div>
            </div>
        )
    }

    _handleClick = (e) => {
        this.props.onClick && this.props.onClick(e)
    }
}

const pickerLang = {
    months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
    from: 'Da', to: 'A',
}

export class CustomMonthPicker extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: props.value,
        }

        this.pickMonthYear = React.createRef()
    }

    render() {

        const { value } = this.state

        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
            return '?'
        }

        return (
            <>
                {
                    !value
                        ? <Picker
                            ref={this.pickMonthYear}
                            years={this.props.minYear}
                            value={value}
                            lang={pickerLang}
                            theme="light"
                            onDismiss={(value) => {
                                this.setState({ value: value })
                                this.props.handleDismiss(value)
                            }}
                        >
                            <MonthBox value={[{ year: moment().get("year"), month: moment().get("month") + 1 }].map(v => makeText(v)).join(' | ')} onClick={this._handleClickBox} />
                        </Picker>
                        : value.length > 0
                            ? <Picker
                                ref={this.pickMonthYear}
                                years={this.props.minYear}
                                value={value}
                                lang={pickerLang}
                                theme="light"
                                onChange={(year, month) => {
                                    let newValue = [];
                                    var removeValue = this.pickMonthYear.current.value() ? this.pickMonthYear.current.value().filter((el) => { return el.year === year && el.month === month }) : null;
                                    if (removeValue && removeValue.length > 0) {
                                        if (this.pickMonthYear.current.value().length === 1) {
                                            newValue = this.pickMonthYear.current.value();
                                        } else {
                                            let newArray = [];
                                            this.pickMonthYear.current.value().forEach(el => {
                                                if (el.year === removeValue[0].year && el.month === removeValue[0].month) {
                                                } else {
                                                    newArray.push(el);
                                                }
                                            });
                                            newValue = newArray;
                                        }
                                    } else {
                                        if (this.pickMonthYear.current.value()) {
                                            newValue = [
                                                ...this.pickMonthYear.current.value(),
                                                { year: year, month: month }
                                            ];
                                        } else {
                                            newValue.push({ year: year, month: month });
                                        }

                                    }
                                    this.setState({ value: newValue })
                                    this.props.handleChange(newValue)
                                }}
                            >
                                <MonthBox value={value.map(v => makeText(v)).join(' | ')} onClick={this._handleClickBox} />
                            </Picker>
                            : <Picker
                                ref={this.pickMonthYear}
                                years={this.props.minYear}
                                value={value}
                                lang={pickerLang}
                                theme="light"
                                onChange={(year, month, listIndex) => {
                                    if (listIndex === 1) {
                                        let date = this.pickMonthYear.current.value();
                                        let newValue = {
                                            from: { year: date.from.year, month: date.from.month },
                                            to: { year: year, month: month }
                                        }
                                        this.setState({ value: newValue })
                                        this.pickMonthYear.current.dismiss();
                                        this.props.handleDismiss(newValue)
                                    }
                                }}
                            >
                                <MonthBox value={makeText(value.from) + ' - ' + makeText(value.to)} onClick={this._handleClickBox} />
                            </Picker>
                }
            </>
        )
    }

    _handleClickBox = (e) => {
        this.pickMonthYear.current.show()
    }
}
