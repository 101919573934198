import React, {useState, useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import SidebarComponent from "../../../../libraries/sidebar/SidebarComponent";

export function ProvisionInfoMenu() {

    const [active, setActive] = useState("provisionSectionMain");

    const {currentState} = useSelector(
        (state) => ({
            currentState: state.spinner
        }),
        shallowEqual
    );

    const {count} = currentState

    useEffect(() => {
        if (count === 0) {
            setOffset();

            window.onscroll = setActiveOnScroll;
        }

        return () => {
            window.onscroll = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    let provisionSectionMainOffsetTop;
    let provisionPatientInfoOffsetTop;
    let provisionDevicesOffsetTop;
    let provisionAccessoriesOffsetTop;
    let provisionConsumablesOffsetTop;
    let provisionInfoDetailOffsetTop;

    function setOffset() {
        provisionSectionMainOffsetTop = document.getElementsByName('provisionSectionMain')[0].offsetTop;
        provisionPatientInfoOffsetTop = document.getElementsByName('provisionPatientInfo')[0].offsetTop - 1;
        provisionDevicesOffsetTop = document.getElementsByName('provisionDevices')[0].offsetTop - 1;
        provisionAccessoriesOffsetTop = document.getElementsByName('provisionAccessories')[0].offsetTop - 1;
        provisionConsumablesOffsetTop = document.getElementsByName('provisionConsumables')[0].offsetTop - 1;
        provisionInfoDetailOffsetTop = document.getElementsByName('provisionInfoDetail')[0].offsetTop - 1;
    }

    function setActiveOnScroll() {
        if (window.scrollY >= provisionSectionMainOffsetTop && window.scrollY <= provisionPatientInfoOffsetTop) {
            setActive("provisionSectionMain");
        } else if (window.scrollY >= provisionPatientInfoOffsetTop && window.scrollY <= provisionDevicesOffsetTop) {
            setActive("provisionPatientInfo");
        } else if (window.scrollY >= provisionDevicesOffsetTop && window.scrollY <= provisionAccessoriesOffsetTop) {
            setActive("provisionDevices");
        } else if (window.scrollY >= provisionAccessoriesOffsetTop && window.scrollY <= provisionConsumablesOffsetTop) {
            setActive("provisionAccessories");
        } else if (window.scrollY >= provisionConsumablesOffsetTop && window.scrollY <= provisionInfoDetailOffsetTop) {
            setActive("provisionConsumables");
        } else if (window.scrollY >= provisionInfoDetailOffsetTop) {
            setActive("provisionInfoDetail");
        }
    }


    const sections = [{
        name: 'provision',
        voices: [
            {
                name: 'provisionSectionMain',
                urlPage: '/#provisionSectionMain',
            },
            {
                name: 'provisionPatientInfo',
                urlPage: '/#provisionPatientInfo',
            },
            {
                name: 'provisionDevices',
                urlPage: '/#provisionDevices',
            },
            {
                name: 'provisionAccessories',
                urlPage: '/#provisionAccessories',
            },
            {
                name: 'provisionConsumables',
                urlPage: '/#provisionConsumables',
            },
            {
                name: 'provisionInfoDetail',
                urlPage: '/#provisionInfoDetail',
            },
        ]
    }]

    const clickVoice = (e, voice) => {
        window.onscroll = null;
        window.scrollTo(0, document.getElementsByName(voice.name)[0].offsetTop);
        setActive(voice.name);
        setOffset();
        setTimeout(() => {
            window.onscroll = setActiveOnScroll;
        }, 100);
    }


    return (
        <>
            <SidebarComponent currentPage={active} sections={sections} onClickVoice={clickVoice}/>


            {/*<div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
                <div className="navi-item my-2">
                    <a href="#provisionSectionMain"
                       className={active === "provisionSectionMain" ? "navi-link active" : "navi-link"}
                       onClick={(evt) => {
                           evt.preventDefault();
                           window.onscroll = null;
                           window.scrollTo(0, document.getElementsByName('provisionSectionMain')[0].offsetTop);
                           setActive("provisionSectionMain");
                           setOffset();
                           setTimeout(() => {
                               window.onscroll = setActiveOnScroll;
                           }, 100);
                       }}>
                    <span className="navi-icon me-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                 version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path
                                        d="M7,11 L15,11 C16.1045695,11 17,10.1045695 17,9 L17,8 L19,8 L19,9 C19,11.209139 17.209139,13 15,13 L7,13 L7,15 C7,15.5522847 6.55228475,16 6,16 C5.44771525,16 5,15.5522847 5,15 L5,9 C5,8.44771525 5.44771525,8 6,8 C6.55228475,8 7,8.44771525 7,9 L7,11 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <path
                                        d="M6,21 C7.1045695,21 8,20.1045695 8,19 C8,17.8954305 7.1045695,17 6,17 C4.8954305,17 4,17.8954305 4,19 C4,20.1045695 4.8954305,21 6,21 Z M6,23 C3.790861,23 2,21.209139 2,19 C2,16.790861 3.790861,15 6,15 C8.209139,15 10,16.790861 10,19 C10,21.209139 8.209139,23 6,23 Z"
                                        fill="#000000" fillRule="nonzero"/>
                                    <path
                                        d="M18,7 C19.1045695,7 20,6.1045695 20,5 C20,3.8954305 19.1045695,3 18,3 C16.8954305,3 16,3.8954305 16,5 C16,6.1045695 16.8954305,7 18,7 Z M18,9 C15.790861,9 14,7.209139 14,5 C14,2.790861 15.790861,1 18,1 C20.209139,1 22,2.790861 22,5 C22,7.209139 20.209139,9 18,9 Z"
                                        fill="#000000" fillRule="nonzero"/>
                                    <path
                                        d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z"
                                        fill="#000000" fillRule="nonzero"/>
                                </g>
                            </svg>
                        </span>
                    </span>
                        <span className="navi-text fw-bolder font-size-lg">Informazioni Principali</span>
                    </a>
                </div>
                <div className="navi-item my-2">
                    <a href="#provisionPatientInfo"
                       className={active === "provisionPatientInfo" ? "navi-link active" : "navi-link"}
                       onClick={(evt) => {
                           evt.preventDefault();
                           window.onscroll = null;
                           window.scrollTo(0, document.getElementsByName('provisionPatientInfo')[0].offsetTop);
                           setActive("provisionPatientInfo");
                           setOffset();
                           setTimeout(() => {
                               window.onscroll = setActiveOnScroll;
                           }, 100);
                       }}>
                    <span className="navi-icon me-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                 version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path
                                        d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <path
                                        d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z"
                                        fill="#000000"/>
                                </g>
                            </svg>
                        </span>
                    </span>
                        <span className="navi-text fw-bolder font-size-lg">Informazioni del Paziente</span>
                    </a>
                </div>
                <div className="navi-item my-2">
                    <a href="#provisionDevices"
                       className={active === "provisionDevices" ? "navi-link active" : "navi-link"} onClick={(evt) => {
                        evt.preventDefault();
                        window.onscroll = null;
                        window.scrollTo(0, document.getElementsByName('provisionDevices')[0].offsetTop);
                        setActive("provisionDevices");
                        setOffset();
                        setTimeout(() => {
                            window.onscroll = setActiveOnScroll;
                        }, 100);
                    }}>
                    <span className="navi-icon me-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                 version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path
                                        d="M15,15 L15,10 C15,9.44771525 15.4477153,9 16,9 C16.5522847,9 17,9.44771525 17,10 L17,15 L20,15 C21.1045695,15 22,15.8954305 22,17 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,17 C2,15.8954305 2.8954305,15 4,15 L15,15 Z M5,17 C4.44771525,17 4,17.4477153 4,18 C4,18.5522847 4.44771525,19 5,19 L10,19 C10.5522847,19 11,18.5522847 11,18 C11,17.4477153 10.5522847,17 10,17 L5,17 Z"
                                        fill="#000000"/>
                                    <path
                                        d="M20.5,7.7155722 L19.2133304,8.85714286 C18.425346,7.82897283 17.2569914,7.22292937 15.9947545,7.22292937 C14.7366498,7.22292937 13.571742,7.82497398 12.7836854,8.84737587 L11.5,7.70192243 C12.6016042,6.27273291 14.2349886,5.42857143 15.9947545,5.42857143 C17.7603123,5.42857143 19.3985009,6.27832502 20.5,7.7155722 Z M23.5,5.46053062 L22.1362873,6.57142857 C20.629466,4.78945909 18.4012066,3.73944576 15.9963045,3.73944576 C13.5947271,3.73944576 11.3692392,4.78653417 9.8623752,6.56427829 L8.5,5.45180053 C10.340077,3.28094376 13.0626024,2 15.9963045,2 C18.934073,2 21.6599771,3.28451636 23.5,5.46053062 Z"
                                        fill="#000000" fillRule="nonzero" opacity="0.3"/>
                                </g>
                            </svg>
                        </span>
                    </span>
                        <span className="navi-text fw-bolder font-size-lg">Dispositivi</span>
                    </a>
                </div>
                <div className="navi-item my-2">
                    <a href="#provisionAccessories"
                       className={active === "provisionAccessories" ? "navi-link active" : "navi-link"}
                       onClick={(evt) => {
                           evt.preventDefault();
                           window.onscroll = null;
                           window.scrollTo(0, document.getElementsByName('provisionAccessories')[0].offsetTop);
                           setActive("provisionAccessories");
                           setOffset();
                           setTimeout(() => {
                               window.onscroll = setActiveOnScroll;
                           }, 100);
                       }}>
                    <span className="navi-icon me-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                 version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"/>
                                    <path
                                        d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <path
                                        d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <path
                                        d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                        fill="#000000"/>
                                </g>
                            </svg>
                        </span>
                    </span>
                        <span className="navi-text fw-bolder font-size-lg">Accessori</span>
                    </a>
                </div>
                <div className="navi-item my-2">
                    <a href="#provisionConsumables"
                       className={active === "provisionConsumables" ? "navi-link active" : "navi-link"}
                       onClick={(evt) => {
                           evt.preventDefault();
                           window.onscroll = null;
                           window.scrollTo(0, document.getElementsByName('provisionConsumables')[0].offsetTop);
                           setActive("provisionConsumables");
                           setOffset();
                           setTimeout(() => {
                               window.onscroll = setActiveOnScroll;
                           }, 100);
                       }}>
                    <span className="navi-icon me-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                 version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path
                                        d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <polygon fill="#000000"
                                             points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"/>
                                </g>
                            </svg>
                        </span>
                    </span>
                        <span className="navi-text fw-bolder font-size-lg">Consumabili</span>
                    </a>
                </div>
                <div className="navi-item my-2">
                    <a href="#provisionInfoDetail"
                       className={active === "provisionInfoDetail" ? "navi-link active" : "navi-link"}
                       onClick={(evt) => {
                           evt.preventDefault();
                           window.onscroll = null;
                           window.scrollTo(0, document.getElementsByName('provisionInfoDetail')[0].offsetTop);
                           setActive("provisionInfoDetail");
                           setOffset();
                           setTimeout(() => {
                               window.onscroll = setActiveOnScroll;
                           }, 100);
                       }}>
                    <span className="navi-icon me-4">
                        <span className="svg-icon svg-icon-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                                 version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"/>
                                    <path
                                        d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <path
                                        d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                        fill="#000000" opacity="0.3"/>
                                    <path
                                        d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                        fill="#000000"/>
                                </g>
                            </svg>
                        </span>
                    </span>
                        <span className="navi-text fw-bolder font-size-lg">Informazioni</span>
                    </a>
                </div>
            </div>*/}
        </>
    );
}
