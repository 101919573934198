import React from "react";
import { ServiceIndicatorsUIProvider } from "../ServiceIndicatorsUIContext";
import { ServiceIndicatorsHome } from "./ServiceIndicatorsHome";

export function ServiceIndicatorsPage() {
  return (
    <ServiceIndicatorsUIProvider>
      <ServiceIndicatorsHome />
    </ServiceIndicatorsUIProvider>
  );
}
