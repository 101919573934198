import React from "react";
import ProgressStats from "../ProgressStats";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardPercentageDelivery = ({percentageTreatments}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator minHeight={"250px"} maxHeight={"250px"} view={percentageTreatments} title={t('lsi.ventiloTreatmentPercentageDelivery')}>
            {percentageTreatments && <ProgressStats
                active={percentageTreatments.patientsActive}
                used={percentageTreatments.patientsWithDeliveries}
                labelTitle={t('lsi.patientWithDelivery')}
                labelActive={t('lsi.actives')}
                labelUsed={t('lsi.withDelivery')}
            />
            }
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardPercentageDelivery);
