import React, { useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls";
import { KTScrollFilters } from "../../../component/scroll/Scroll";
import { CostsFilters } from "./CostsFilters";
import { CostsChart } from "./CostsChart";
import { CostsNotAccountedTables } from "./CostsNotAccountedTables";
import { CostsTable } from "./CostsTable";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const CostsHome = ({history}) => {
    const {t} = useLanguageForShared()

/*    //inizializzo lo scroll dei filtri
    useEffect(() => {
        KTScrollFilters.init("costsFilters")
    }, []);*/

    return (
        <div className="row layout-page" style={{zIndex: "1"}}>
            <div className="col-lg-2 filter-col">
                <Card className="card-sticky" id="costsFilters">
                    <CardBody className="offcanvas-content">
                        <CostsFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <h3 className={'title-page'}>{t('lsi.provision.costs')}</h3>

                <Card>
                    <CardHeader title={t('lsi.provision.accountedCosts')} className="fw-bolder"/>
                    <CardBody>
                        <CostsChart/>
                    </CardBody>
                </Card>
                <CostsTable history={history}/>
            </div>
        </div>
    );
};
