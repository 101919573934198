import React from "react";
import CostForTreatment from "./CostForTreatment";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardCostForTreatment = ({costs}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator view={costs && costs.length} title={t('lsi.costTreatment')}>
            <CostForTreatment costs={costs}/>
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardCostForTreatment);
