import React, {useEffect} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import * as actions from "../../../actions/provisions/provisionsActions";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultProvisionsPlannedSorted} from "../ProvisionsUIHelpers";
import moment from "moment";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PlannedProvisions() {
    const {t} = useLanguageForShared()

    const {currentState} = useSelector(
        (state) => ({currentState: state.provisions}),
        shallowEqual
    );

    const {provisionsPlannedList, provisionsPlannedColumns} = currentState;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchProvisionsPlanned(moment().startOf("week").format("YYYY-MM-DD") + "T00:00:00Z - " + moment().endOf("week").add(3, "week").format("YYYY-MM-DD") + "T23:59:59Z"));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    return (
        <>
            {
                provisionsPlannedColumns && provisionsPlannedColumns.length > 0
                    ? <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table-head-custom table-vertical-center"
                        bootstrap4
                        keyField="ProvisionType"
                        data={provisionsPlannedList}
                        columns={provisionsPlannedColumns}
                        defaultSorted={defaultProvisionsPlannedSorted}
                    >
                    </BootstrapTable>
                    : <span>{t('lsi.noDataAvailable')}</span>
            }
        </>

    );
}