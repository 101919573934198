import * as requestFromServer from "./serviceIndicatorsCrud";
import { serviceIndicatorsSlice } from "./serviceIndicatorsSlice";
import { spinnerCreate } from "../spinner/spinnerActions";
import moment from "moment";

const { actions } = serviceIndicatorsSlice;

function getCustomerId() {
    return localStorage.getItem("customerId");
}

function getServiceIndicatorsQueryParams(queryParams, isForChart, pagination, sort, isChangedSort) {
    let monthYear = queryParams.filter.MonthYear;
    let customerId = getCustomerId();
    let firstDateOfTheFromMonth = "";
    let lastDateOfTheToMonth = "";
    let params = 'customerId:"' + customerId + '" ';

    if (monthYear) {

        if (monthYear) {
            let dates = monthYear.split(" - ");
            let dateFrom = dates[0];
            let dateTo = dates[1];
            let splitDateFrom = dateFrom.split("/");
            let yearFrom = splitDateFrom[0];
            let monthFrom = splitDateFrom[1];
            let splitDateTo = dateTo.split("/");
            let yearTo = splitDateTo[0];
            let monthTo = splitDateTo[1];
            firstDateOfTheFromMonth = moment([yearFrom, (monthFrom - 1)]).format("YYYY-MM-DD") + "T00:00:00Z";
            lastDateOfTheToMonth = moment([yearTo, (monthTo - 1)]).endOf("month").format("YYYY-MM-DD") + "T23:59:59Z";
        }
    }

    params += ' dateFrom:"' + firstDateOfTheFromMonth + '" dateTo:"' + lastDateOfTheToMonth + '"';
    //params = 'customerId:"M013991" ' +' dateFrom:"' + firstDateOfTheFromMonth + '" dateTo:"' + lastDateOfTheToMonth + '"';

    return params;
}

export const fetchServiceIndicators = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, false, pagination, sort, isChangedSort);

    try {
        const response = await requestFromServer
            .findServiceIndicators(params);
        dispatch(actions.serviceIndicatorsFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchServiceIndicatorsForFile = (queryParams, callback) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {
        const response = await requestFromServer
            .findServiceIndicators(params);
        dispatch(actions.serviceIndicatorsForFileFetched({ entities: response.data, callback }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchServiceIndicatorsFilters = monthYear => async dispatch => {
    dispatch(spinnerCreate(true));

    let dates = monthYear.split(" - ");
    let dateFrom = dates[0];
    let dateTo = dates[1];
    let splitDateFrom = dateFrom.split("/");
    let yearFrom = splitDateFrom[0];
    let monthFrom = splitDateFrom[1];
    let splitDateTo = dateTo.split("/");
    let yearTo = splitDateTo[0];
    let monthTo = splitDateTo[1];
    let firstDateOfTheFromMonth = moment([yearFrom, (monthFrom - 1)]).format("YYYY-MM-DD") + "T00:00:00Z";
    let lastDateOfTheToMonth = moment([yearTo, (monthTo - 1)]).endOf("month").format("YYYY-MM-DD") + "T23:59:59Z";
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';
    params += 'dateFrom:"' + firstDateOfTheFromMonth + '" dateTo:"' + lastDateOfTheToMonth + '"';

    try {
        const response = await requestFromServer
            .findServiceIndicatorsFilters(params);
        dispatch(actions.serviceIndicatorsFitlersFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchServiceIndicatorsChart = (queryParams) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {
        const response = await requestFromServer
            .findServiceIndicatorsChart(params);
        dispatch(actions.serviceIndicatorsChartFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchServiceIndicatorsPercentageTreatments = (queryParams) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {

        const response = await requestFromServer
            .findServiceIndicatorsStatus(params);
        dispatch(actions.setPercentageTreatments(response.data.data.currentServiceIndicators[0]));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const fetchServiceIndicatorsExtraordinaryPerformance = (queryParams) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {

        const response = await requestFromServer
            .findServiceIndicatorsGraph(params);
        dispatch(actions.setExtraordinaryPerformance(response.data.data.serviceIndicatorsGraph));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}
export const fetchServiceIndicatorsO2States = (queryParams) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {

        const response = await requestFromServer
            .findServiceIndicatorsLox(params);
        dispatch(actions.setO2States(response.data.data.loxServiceIndicators));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}
export const fetchServiceIndicatorsO2ActiveStates = (queryParams) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {

        const response = await requestFromServer
            .findServiceIndicatorsStatusService(params);
        dispatch(setServiceIndicatorsStatus(response.data.data.statusServiceIndicators))
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}

export const setServiceIndicatorsStatus = (indicators) => async dispatch => {
    dispatch(actions.setO2ActiveStates(indicators.filter(ele => ele.treatmentFamily === 'O2')));
    dispatch(actions.setVtdActiveStates(indicators.filter(ele => ele.treatmentFamily === 'VTD')));

}

export const fetchServiceIndicatorsCostForTreatment = (queryParams) => async dispatch => {
    dispatch(spinnerCreate(true));

    let params = getServiceIndicatorsQueryParams(queryParams, true);

    try {
        const response = await requestFromServer
            .findServiceIndicatorsCostForTreatment(params);
        dispatch(actions.setCostForTreatments(response.data.data.invoiceServiceIndicators));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}
