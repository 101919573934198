import React, {useEffect, useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
//import { CostStatusPatientsColumnFormatter } from "../../../component/table/column-formatters/StatusColumnFormatter";
import {defaultCostListTableSorted} from "../CostsUIHelpers";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as actions from "../../../actions/invoices/invoicesActions";
import {ExportButton} from "../../../component/exportbutton/ExportButton";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import {
    CurrencyColumnFormatter,
    MonthYearColumnFormatter
} from "../../../component/table/column-formatters/TypeColumnFormatter";
import {useCostsUIContext} from "../CostsUIContext";
import {InfiniteScrollTable} from "../../../component/table/InfiniteScrollTable";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function CostsTable() {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    //prendo i filtri dal context
    const costsUIContext = useCostsUIContext();
    const costsUIProps = useMemo(() => {
        return {
            queryCostParams: costsUIContext.queryCostParams
        };
    }, [costsUIContext]);

    //prendo i dati utili alla tabella dallo store
    const {currentState} = useSelector(
        (state) => ({currentState: state.invoices}),
        shallowEqual
    );

    const {costsList, costsPagination} = currentState;

    //salvo nello stato del componente il sort della tabella che mi serve per il passarlo alla funzione per scaricare i dati dal db
    const [sortTable, setSortTable] = useState(undefined);

    //al caricamento del componente e ad ogni variazione dei filtri parte la query al db
    useEffect(() => {
            dispatch(actions.fetchCosts(costsUIProps.queryCostParams, null, sortTable));
    }, [costsUIProps.queryCostParams]);

    const columns = [
        {
            dataField: "PatientNumber",
            text: t('lsi.table.patientId'),

            sort: true,
        },
        {
            dataField: "PatientFullName",
            text: t('lsi.table.nominativePatient'),

            sort: true,
        },
        {
            dataField: "District",
            text: t('lsi.table.district'),

            sort: true,
        },
        {
            dataField: "Amount",
            text: t('lsi.table.amountNet'),
            align: "right",
            headerAlign: 'right',
            sort: true,
            formatter: CurrencyColumnFormatter,
        }

    ];
    return (
        <Card className="customHeaderPadding">
            <CardHeader title={t('lsi.provision.listCosts')} className="fw-bolder">
                <CardHeaderToolbar>
                    <ExportButton
                        data={[]}
                        loadData={(callback) => dispatch(actions.fetchCostsForFile(costsUIProps.queryCostParams, callback))}
                        columns={columns}
                        fileName={"costi"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"CostId"}
                    id={"costsTable"}
                    columns={columns}
                    data={costsList}
                    defaultSorted={defaultCostListTableSorted}
                    pagination={costsPagination}
                    noSelectRow={true}

                    /* onSelectCheckBox={(row, isSelect) => {
                         if (isSelect) {
                             setSelectedRows([...selectedRows, row]);
                         } else {
                             setSelectedRows(selectedRows.filter((el) => {
                                 return el.CostId !== row.CostId
                             }));
                         }
                     }}*/
                    loadData={(event, tableInfo) => {
                            if(
                                tableInfo
                            ){
                                const sortData ={ dataField: tableInfo.sortField, order: tableInfo.sortOrder }
                                setSortTable(sortData);
                                dispatch(actions.fetchCosts(costsUIProps.queryCostParams, costsPagination, sortData, true));
                            }else{
                                dispatch(actions.fetchCosts(costsUIProps.queryCostParams, costsPagination, sortTable, false));
                            }

                    }}
                    noData={t('lsi.noDataAvailable')}
                />
            </CardBody>
        </Card>
    );
}
