import React, {ReactNode} from 'react'
import {mergeClass} from "../../../../shared/utils/htmlUtils";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";


export const Sidebar = ({
                            isMenuOpen, 
                            isVisible,
                            hideSidebarHandler,
                            children,
                        }: {
     isMenuOpen: boolean;
    isVisible: boolean
    hideSidebarHandler: () => void
    children: ReactNode
}) => (
    <div className={mergeClass('sidebar-menu left', isVisible ? 'in' : '')}>
        <div className="sidebar-header pointer" onClick={hideSidebarHandler}>
            <IconSVG icon={isMenuOpen ? 'close' : 'menu'} size={'20px'} tintColor={configuration.theme.primary}/>
        </div>

        <div className="sidebar-body">{children}</div>
    </div>
)


