import {required} from "../../../../shared/utils/forms/utils/validationType";

export const formDataDateV: any = (t:any) => [
    {
        name: 'Date',
        type: 'input',
        dataElement: {
            type: 'date',
            placeholder: t('note.newNote.date'),
        },
        extra: {
            type: 'datePicker',
            settings: {
                timePicker: true
            }
        }
    },
]

export const formDataNoteV: any = (t:any) => [
    {
        name: 'Note',
        type: 'textarea',
        dataElement: {
            type: 'text',
            placeholder: t('note.newNote.note'),
        },
    },
]
export const formDataOperatorV: any = (t:any) => [
    {
        name: 'Operator',
        type: 'input',
        dataElement: {
            type: 'text',
            placeholder: t('note.newNote.operator'),
        },
    },
]

export const validationNewNoteV  = (t:any) => [
    {
        name: 'Date',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.date'),
                },
            },
        ],
    },
    {
        name: 'Subject',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.subject'),
                },
            },
        ],
    },
    {
        name: 'Note',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.note'),
                },
            },
        ],
    },
    {
        name: 'Operator',
        validations: [
            {
                name: required,
                params: {
                    name: t('note.newNote.operator'),
                },
            },
        ],
    },
]
