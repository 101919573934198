import {t} from 'i18next'
import React, {useEffect, useRef, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useLanguageForShared} from '../../utils/languages/useLanguageForShared'
import moment from "moment";
import {datePickerLocale} from "../../common/datePickerLocale";

const DatePickerRange = ({
                             children,
                             settings,
                             handleCallback,
                             myRef,
                         }: {
    children?: React.ReactChild
    settings: any
    handleCallback: (start: any, end: any, label?: any) => void
    myRef?: any
}) => {
    const inputRef = useRef<any>()
    const {lang} = useLanguageForShared()
    const [show, setShow] = useState(false)
    const settingsDatePiker = {
        opens: 'left',
        showCustomRangeLabel: true,
        locale: {
            format: 'L',
            separator: ' - ',
            ...datePickerLocale()
        },
    }

    useEffect(() => {
        setShow(false)
        setTimeout(() => {
            setShow(true)
        }, 1)
    }, [lang])
    return (
        <div className="date-picker input-date-piker">
            {/*<input type="text" value={myRef?.current?.value} ref={inputRef}/>*/}
            {show && <DateRangePicker
                data-test={'date-range-picker'}
                ref={inputRef}
                onCallback={(start, end) => {
                    handleCallback(start, end)
                }}
                // onApply={(event, data) => {
                //   console.log('apply')
                //   handleCallback(data.startDate, data.endDate, event)
                // }}
                initialSettings={{
                    ...settingsDatePiker,
                    ...settings,
                }}
            >
                <input data-test={"date-piker"}/>
            </DateRangePicker>}
            {/*{show && <DateRangePicker*/}
            {/*    ref={myRef}*/}
            {/*    initialSettings={{*/}
            {/*      ...settingsDatePiker,*/}
            {/*      ...settings,*/}
            {/*    }}*/}
            {/*    onCallback={handleCallback}*/}
            {/*>*/}
            {/*  {children}*/}
            {/*</DateRangePicker>}*/}
        </div>
    )
}

export default DatePickerRange
