import {ValueName} from "../../../../../shared/utils/forms/models/DataForm";
import {FormElements} from "../../../../../shared/bootstrap/form/FormElements";
import LabelValue from "../../../../../shared/custom/labelValue/LabelValue";
import React from "react";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";


export default function FooterManageL({
                                          operator,
                                          isEditMode,
                                          formData,
                                          dataForm,
                                          changeValue,
                                          errors,
                                          isSubmit
                                      }: {
    operator: string
    isEditMode: boolean
    formData: any
    dataForm: any
    changeValue: ({value, name}: ValueName) => void
    errors: any
    isSubmit?: boolean
}) {
    const {t} = useLanguageForShared()

    return (
        <>
            {isEditMode ? (
                <div className="w-100">
                    <FormElements
                        structure={formData}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}
                        isSubmit={isSubmit}
                    />
                </div>
            ) : (
                <>
                    <LabelValue label={t('note.operator')} value={operator} inline valueClass={'sm'}/>
                </>
            )}
        </>
    )
}
