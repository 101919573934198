import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialCostFilter } from "./CostsUIHelpers";
import moment from "moment";

const CostsUIContext = createContext();

export function useCostsUIContext() {
    return useContext(CostsUIContext);
}

export function CostsUIProvider({ costsUIEvents, children }) {
    let costsSelectedFilter = JSON.parse(localStorage.getItem('costsSelectedFilters-...'));

    if (!costsSelectedFilter) {
        costsSelectedFilter = {
            filter: {
                MonthYear: (moment().get("year") - 1) + "/" + (moment().get("month") + 1) + " - " + moment().get("year") + "/" + (moment().get("month") + 1),
                District: []
            },
            // sortOrder: "desc", // asc||desc
            // sortField: "InchargeDate",
        };
    }

    const [queryCostParams, setQueryCostParamsBase] = useState(costsSelectedFilter);
    const setQueryCostParams = useCallback(nextQueryParams => {
        setQueryCostParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryCostParams,
        setQueryCostParamsBase,
        setQueryCostParams,
    };

    return <CostsUIContext.Provider value={value}>{children}</CostsUIContext.Provider>;
}
