import React from "react";
import {ChartCustom} from "../../../../component/chartcustom/ChartCustom";
import useCarriedOutPerformance from "./useCarriedOutPerformance";
import configuration from "../../../../../shared/configuration";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CarriedOutPerformance = ({extraordinaryPerformance = []}) => {
    const {t} = useLanguageForShared()

    const {dataTable, dateTicks} = useCarriedOutPerformance(extraordinaryPerformance, t)
    const optionAdd = {
        colors: [configuration.theme.series.success170_hex, configuration.theme.series.s870_hex, configuration.theme.series.s170_hex, configuration.theme.series.s700_hex],
        seriesType: "bars"
    }


    const vAxisAdd = {
        ticks: dateTicks
    }
    const formatters = dataTable[0].slice(1).map((ele, i) => (
        {
            type: "NumberFormat",
            column: i + 1,
            options: {
                decimalSymbol: ',',
                groupingSymbol: '.'
            }
        }
    ))


    return <ChartCustom dataTable={dataTable} options={optionAdd} chartType="ComboChart" vAxis={vAxisAdd}
                        formatters={formatters}/>
}


export default React.memo(CarriedOutPerformance)
