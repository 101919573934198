import i18next from 'i18next'

export const diaryFilterData = () => [
    {
        name: 'search',
        type: 'input',
        dataElement: {
            placeholder: i18next.t('note.filters.search'),
            type: 'text',
        },
        col: {xs: '12', md: '6', lg: '3'}
    },
    {
        name: 'orderBy',
        type: 'select',
        options: [
            {
                value: 'recent',
                label: i18next.t('note.filters.recent'),
            },
            {
                value: 'older',
                label: i18next.t('note.filters.older'),
            },
        ],
        dataElement: {
            type: 'text',
            placeholderLabel: i18next.t('note.filters.orderBy'),
        },
        defaultValue: {
            label: i18next.t('note.filters.orderBy'),
            isDefault: true,
        },
        col: {xs: '12', md: '6', lg: '3'}
    },
    {
        name: 'deleteView',
        type: 'select',
        options: [
            {
                value: false,
                label: i18next.t('note.filters.active'),
            },
            {
                value: true,
                label: i18next.t('note.filters.deleted'),
            },
        ],
        col: {xs: '12', md: '6', lg: '3'},
       /* defaultValue: {
          label: i18next.t('note.filters.active'),
          isDefault: true,
          disabled: false,
        },*/
    },
]
