import {t} from 'i18next'
import Heading from '../../../../../shared/custom/headings/heading/Heading'

function EventStepInstructions({}: any) {
    return (
        <div>
            <div
                className={"heading-title"}>{t('note.newNote.instruction')}</div>
            <p>{t('note.newNote.instructionInfo')}</p>
        </div>
    )
}

export default EventStepInstructions
