import React from "react";
import {Chart} from "react-google-charts";

export function DetailProgress({used, labelUsed, labelActive, active}) {

    return (
        <>
            <div>
                <div className={'d-flex justify-content-between'}>
                    <div className={'d-flex align-items-baseline'}>
                        <h3 className={'mb-0 me-2 text-primary-dark'}>{used}</h3>
                        <span className={"text-gray"}>{labelUsed}</span>
                    </div>
                    <div className={'d-flex align-items-baseline'}>
                        <span className={"text-gray"}>{labelActive}</span>
                        <h3 className={'mb-0 ms-2 text-primary-dark'}>{active}</h3>
                    </div>
                </div>
            </div>
        </>
    );
}
