export class DeviceModel {
    constructor() {
        this.Id = 0;
        this.PatientId = "";
        this.Photo = "";
        this.DeviceType = "";
        this.BrandAndModel = "";
        this.Serial = "";
        this.ItemNumber = "";
        this.DeliveryDate = "";
        this.PickUpDate = "";
        this.LastPatientAssigned = "";
        this.TotalHoursOfUse = 0;
        this.Description = "";
        //this.Lot = "";
        this.UltimatePatientAssigned = [];
        this.LogBook = [];
        //this.Accessories = [];
        this.Assets = [];
        this.Reporting = "";
        this.CustomerId = "";
    }
}