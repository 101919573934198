import React, {useEffect, useState} from "react";
import ModalNotify from "./components/ModalNotify";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import PatientTitleModal from "./components/PatientTitleModal";
import {useDateCustom} from "../../shared/custom_use/useDateCustom";
import http from "../../shared/utils/http";
import NoteTitleModal from "./components/NoteTitleModal";
import httpData from "../../shared/utils/httpData";

const NotifyLibrary: React.FC<{
    token?: string
    extraData?: any
    events: number[]
    closeHandler: (data?: any) => void
}> = ({extraData = {}, events = [], closeHandler}) => {
    const [emails, setEmails] = useState([])
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYY}} = useDateCustom()
    useEffect(() => {
        http.get('eventnotify/GetSentMailAddresses').then(res => {
            setEmails(res);
        })
    }, [])

    const eventsTemplates = [
        {
            label: () => t('notify.patientCreate'),
            titleComponent: (data: any = '*test*') => <PatientTitleModal
                data={{
                    first_name: extraData?.first_name,
                    last_name: extraData?.last_name,
                }}/>,
            defaultMessage: (data: any) => {
                return `${t('notify.messageBodyPatient', {
                    org: extraData?.organizationName || '-',
                    date: extraData?.creationDate ? dateFormatGGmmmYY(extraData?.creationDate) : '-'
                })}`
            },
            eventType: events[0].toString(),
            baseLink:`${httpData.other.baseLink}?id=${extraData?.id}&orgid=${extraData?.organizationId}`
        },
        {
            label: () => t('notify.noteCreate'),
            titleComponent: (data: any = '*test*') => <NoteTitleModal data={{
                subject: extraData?.Subject || '-'
            }}/>,
            defaultMessage: (data: any) => {
                return `${t('notify.messageBodyNote', {
                    date:extraData?.Date ? dateFormatGGmmmYY(extraData?.Date) : '-'
                })}`
            },
            eventType: events[0].toString(),
            baseLink:`${httpData.other.baseLink}?id=${httpData.other.patientId}&orgid=${httpData.other.organizationId}`
        }
    ]

    return <>
        <ModalNotify template={eventsTemplates[events[0]]} emails={emails} closeHandler={closeHandler}/>
    </>
}
export default NotifyLibrary
