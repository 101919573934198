import moment from 'moment';

export const LocaleDatePicker = {
    format: "DD MMM YY",
    separator: " - ",
    applyLabel: "Applica",
    cancelLabel: "Annulla",
    fromLabel: "Da",
    toLabel: "A",
    daysOfWeek: [
        "Do",
        "Lu",
        "Ma",
        "Me",
        "Gi",
        "Ve",
        "Sa"
    ],
    monthNames: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
    ],
    firstDay: 1,
    customRangeLabel: "Personalizzata"
}

export const filterRanges = {
    'Oggi': [moment(), moment()],
    'Ieri': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Ultimi 7 Giorni': [moment().subtract(1, 'week'), moment()],
    'Ultimi 30 Giorni': [moment().subtract(1, 'month'), moment()],
    'Questo mese': [moment().startOf('month'), moment().endOf('month')],
    'Ultimo mese': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
};
