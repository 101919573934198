import {useCallback} from "react";
import {formatDateMonthYear, formatDateMonthYearYY} from "../../../../utils/dateUtils";
import useGraphServiceIndicator from "../useGraphServiceIndicator";

export default function (states = [], t){
    const dataTable = [[
        t('lsi.chart.monthYear'),
        t('lsi.chart.actives'),
        t('lsi.chart.activates'),
        t('lsi.chart.disactivates'),
        t('lsi.chart.total'),
    ], ...states.map(ele => [formatDateMonthYearYY(ele.month, ele.year), ele.active, ele.activating, ele.inactive,  (ele.active + ele.activating + ele.inactive)])]

    const {maxValue, dateTicks} = useGraphServiceIndicator(dataTable)
    return {
        dataTable,
        maxValue,
        dateTicks
    }
}
