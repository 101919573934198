import {t} from "i18next";

//import moment from "moment";

export const documentTypeTitles = ()=>[t('lsi.state.unknown'), t('lsi.state.invoice'), t('lsi.state.creditNote')];
export const defaultInvoiceListTableSorted = [{ dataField: "InvoiceDate", order: "desc" }];

// export const initialInvoiceFilter = {
//     filter: {
//         SearchInvoiceText: "",
//         InvoiceDate: moment().subtract(1, "month").format('DD MMM YY') + " - " + moment().format('DD MMM YY'),
//         DocumentType: undefined,
//         InvoicesDistricts: undefined,
//         InvoiceAmount: undefined
//     },
// };
