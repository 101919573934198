import React from 'react';

const ProductRow = ({ord}: any) => {

    return (
        <div className="row">
            <div className="col-12">
                <b className={'label-value value mb-2'} style={{'fontSize': '18px'}}>{ord?.title}</b>
                <p>{ord?.description}</p>
            </div>

        </div>
    );
};

export default ProductRow;
