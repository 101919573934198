import axios from "axios";
import { path } from "../../utils/constant";

export function findCosts(queryParams) {
    return axios.post(
        path,
        {
             query: 'query{billingPatientsInvoicesAndCosts(' + queryParams + '){pageInfo{endCursor hasNextPage}nodes{id patientNumber patientFirstName districtName patientLastName amount}}}'
        });
}


export function findCostsChart(queryParams) {
    return axios.post(
        path,
        {
            query: queryParams
        });
}

export function loadDistrictsChart(queryParams) {
    return axios.post(
        path,
        {
            query: queryParams
        });
}

export function findCostsNotAccounted(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{billingMonthMatrix(' + queryParams + '){nodes{year monthName districtname prescriptionName amount}}}'
        });
}

export function findInvoices(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{billingCustomerInvoices(' + queryParams + '){pageInfo{endCursor hasNextPage}nodes{id postingDate customerNumber customerName cig customerOrderDate customerOrderNumber conventionNumber amount}}}'
        });
}

export function findInvoicesFilters(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{billingReviewFilters(' + queryParams + '){districts documentTypes maxAmount minAmount}}'
        });
}

export function findCostsFilters(queryParams) {
    return axios.post(
        path,
        {
            query: 'query{billingTableFilters(' + queryParams + '){districts prescriptions prescriptors minCost maxCost}}'
        });
}
