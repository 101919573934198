import React, {useState} from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPatientInvoicesSorted} from "./PatientInfoUIHelpers"
import {
    PatientInvoiceActionTableColumnFormatter
} from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {tableSortByDate} from "../../../component/table/tableSort";
import {useDispatch} from "react-redux";
import "../../../assets/css/table.css";
import {
    CurrencyColumnFormatter,
    DateColumnFormatter, MonthColumnFormatter,
    MonthYearColumnFormatter
} from "../../../component/table/column-formatters/TypeColumnFormatter";

export function PatientCostsTable({patientInvoicesList, id, history}) {

    const [stateTable, setStateTable] = useState({field: null, order: null});
    const [index, setIndex] = useState(0);

    const handleCustomSort = (field, order) => {
        if (index !== 0) {
            if (order === "desc") {
                order = "asc";
            }
        }
        setStateTable(
            {
                field: 'MonthNumber',
                order: order === "asc" ? "desc" : "asc"
            }
        );
        setIndex(index === 0 ? index + 1 : index - 1);
    }

    const columns = [
        {
            dataField: "competenceDate",
            text: "Mese di riferimento",
            sort: true,
            sortFunc: tableSortByDate,
            formatter: MonthColumnFormatter,
        },
        {
            dataField: "serviceAmount",
            text: "Quota di servizio",
            sort: true,

        },
        {
            dataField: "competenceDays",
            text: "Giorni calcolati",
            sort: true,
        },
        {
            dataField: "amount",
            text: "Importo netto",
            sort: true,
            formatter: CurrencyColumnFormatter,
        }
    ];

    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table-head-custom table-vertical-center"
                bootstrap4
                keyField="Id"
                data={patientInvoicesList}
                columns={columns}
                defaultSorted={defaultPatientInvoicesSorted}
                noDataIndication={"Non sono presenti dati"}
                sort={{
                    dataField: stateTable.field,
                    order: stateTable.order
                }}
            >
            </BootstrapTable>
        </>
    );
}
