import React, {useMemo, /*useState,*/ useEffect} from "react";
import {Formik, Form} from "formik";
import {isEqual} from "lodash";
import {usePatientsUIContext} from "../PatientsUIContext";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
//import Select from 'react-select';
import {LocaleDatePicker, filterRanges} from "../../../component/datepicker/DateRangePickerConfig";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as actions from "../../../actions/patients/patientsActions";
import moment from "moment";
import {useRef} from "react";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const prepareFilter = (queryParams, values) => {

    const {
        searchText,
        filterByPeriod,
        filterByInchargeDate,
        filterByDischargeDate,
        filterByStatus,
        filterByGender,
        filterByDistricts,
        filterByProviders,
        //filterByPrescriptor,
        filterByPrescriptions
    } = values;

    const newQueryParams = {...queryParams};

    const filter = {
        SearchText: searchText,
        Period: filterByPeriod,
        InchargeDate: filterByInchargeDate,
        DischargeDate: filterByDischargeDate,
        Status: filterByStatus,
        Gender: filterByGender,
        District: filterByDistricts,
        Provider: filterByProviders,
        //Prescriptor: filterByPrescriptor,
        Prescriptions: filterByPrescriptions
    };

    newQueryParams.filter = filter;

    localStorage.setItem("patientsSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function PatientsFilters() {
    const {t} = useLanguageForShared()

    // Patients UI Context
    const patientsUIContext = usePatientsUIContext();
    const patientsUIProps = useMemo(() => {
        return {
            queryParams: patientsUIContext.queryParams,
            setQueryParams: patientsUIContext.setQueryParams,
        };
    }, [patientsUIContext]);

    const dispatch = useDispatch();

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(patientsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, patientsUIProps.queryParams)) {
            // if (newQueryParams.filter.InchargeDate !== patientsUIProps.queryParams.filter.InchargeDate || newQueryParams.filter.DischargeDate !== patientsUIProps.queryParams.filter.DischargeDate) {
            //     dispatch(actions.fetchPatientsFilters(newQueryParams.filter.InchargeDate, newQueryParams.filter.DischargeDate));
            // }
            patientsUIProps.setQueryParams(newQueryParams);
        }
    };

    const {currentState} = useSelector(
        (state) => ({currentState: state.patients}),
        shallowEqual
    );

    const {
        filterPatientsPrescriptions,
        filterPatientProviders,
        filterPatientStatus,
        filterPatientGender,
        //filterPrescriptor,
        filterDistrict
        //lastAvailableDate,
    } = currentState;
    // const [filterPrescriptorSelectedOption, setfilterPrescriptorSelectedOption] = useState(() => {
    //     let prescriptorSelected = [];
    //     patientsUIProps.queryParams.filter.Prescriptor.forEach(el => {
    //         prescriptorSelected.push({ value: el, label: el });
    //     });
    //     return prescriptorSelected;
    // });

    // useEffect(() => {
    //     if (!patientsUIProps.queryParams.filter.InchargeDate) {
    //         dispatch(actions.getLastAvailableDate());
    //     }
    // }, [dispatch]);

    useEffect(() => {
        if (patientsUIProps.queryParams.filter.InchargeDate || patientsUIProps.queryParams.filter.DischargeDate) {
            let values = {
                searchText: patientsUIProps.queryParams.filter.SearchText,
                filterByPeriod: patientsUIProps.queryParams.filter.Period,
                filterByInchargeDate: patientsUIProps.queryParams.filter.InchargeDate,
                filterByDischargeDate: patientsUIProps.queryParams.filter.DischargeDate,
                filterByStatus: patientsUIProps.queryParams.filter.Status,
                filterByGender: patientsUIProps.queryParams.filter.Gender,
                filterByDistricts: patientsUIProps.queryParams.filter.District,
                filterByProviders: patientsUIProps.queryParams.filter.Provider,
                //filterByPrescriptor: patientsUIProps.queryParams.filter.Prescriptor,
                filterByPrescriptions: patientsUIProps.queryParams.filter.Prescriptions
            }
            applyFilter(values);
        }
    }, [patientsUIProps.queryParams.filter.InchargeDate, patientsUIProps.queryParams.filter.DischargeDate]);

    useEffect(() => {
        dispatch(actions.fetchPatientsFilters(patientsUIProps.queryParams.filter.InchargeDate, patientsUIProps.queryParams.filter.DischargeDate));
    }, [patientsUIProps.queryParams.filter.InchargeDate, patientsUIProps.queryParams.filter.DischargeDate]);

    // const customSelectStyles = {
    //     control: (base, state) => ({
    //         ...base,
    //         borderColor: state.isFocused ? '#15bbff' : base.borderColor,
    //         '&:hover': { borderColor: '' },
    //         boxShadow: "none",
    //         borderRadius: "0"
    //     })
    // };

    const dischargeDateRef = useRef();
    const inchargeDateRef = useRef();

    const changeStartEndDate = (ref) => {
        ref.current.setStartDate(moment().format("DD MMM YY"));
        ref.current.setEndDate(moment().format("DD MMM YY"));
    }

    return (
        <Formik
            initialValues={{
                searchText: patientsUIProps.queryParams.filter.SearchText,
                filterByPeriod: patientsUIProps.queryParams.filter.Period,
                filterByInchargeDate: patientsUIProps.queryParams.filter.InchargeDate,
                filterByDischargeDate: patientsUIProps.queryParams.filter.DischargeDate,
                filterByStatus: patientsUIProps.queryParams.filter.Status,
                filterByGender: patientsUIProps.queryParams.filter.Gender,
                filterByDistricts: patientsUIProps.queryParams.filter.District,
                filterByProviders: patientsUIProps.queryParams.filter.Provider,
                //filterByPrescriptor: patientsUIProps.queryParams.filter.Prescriptor,
                filterByPrescriptions: patientsUIProps.queryParams.filter.Prescriptions
            }}
            onSubmit={(values, formikBag) => {
                if (values.filterByInchargeDate !== patientsUIProps.queryParams.filter.InchargeDate || values.filterByDischargeDate !== patientsUIProps.queryParams.filter.DischargeDate) {
                    values = {
                        searchText: "",
                        filterByPeriod: values.filterByPeriod,
                        filterByInchargeDate: values.filterByInchargeDate,
                        filterByDischargeDate: values.filterByDischargeDate,
                        filterByStatus: [],
                        filterByGender: [],
                        filterByDistricts: [],
                        filterByProviders: [],
                        //filterByPrescriptor: [],
                        filterByPrescriptions: []
                    }
                    //setfilterPrescriptorSelectedOption([]);
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                  values,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="form form-label-right filter">
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <input
                                type="text"
                                className="sh form-control"
                                name="searchText"
                                placeholder={t('lsi.search')}
                                onBlur={handleBlur}
                                value={values.searchText}
                                onChange={(e) => {
                                    setFieldValue("searchText", e.target.value);
                                    setTimeout(() => {
                                        handleSubmit();
                                    }, 1000);
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.period')}</h6>
                            <DateRangePicker
                                initialSettings={{
                                    showDropdowns: true,
                                    ranges: filterRanges,
                                    locale: LocaleDatePicker,
                                    linkedCalendars: false,
                                    startDate: values.filterByPeriod.split('-')[0],
                                    endDate: values.filterByPeriod.split('-')[1]
                                }}
                                onApply={(event, picker) => {
                                    setFieldValue("filterByPeriod", picker.startDate.format('DD MMM YY') + " - " + picker.endDate.format('DD MMM YY'));
                                    handleSubmit();
                                }}
                            >
                                <div className="sh form-control customPickerDisplay">
                                    <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                                    <span>{values.filterByPeriod}</span>
                                    <div style={{float: "right"}}>
                                        <IconSVG icon={'chevron-down'} size={'8px'}/>
                                    </div>
                                </div>
                            </DateRangePicker>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.provision.dateTaking')}</h6>
                            <DateRangePicker
                                ref={inchargeDateRef}
                                initialSettings={{
                                    showDropdowns: true,
                                    ranges: filterRanges,
                                    locale: LocaleDatePicker,
                                    linkedCalendars: false,
                                    startDate: values.filterByInchargeDate ? values.filterByInchargeDate.split('-')[0] : moment().format("DD MMM YY"),
                                    endDate: values.filterByInchargeDate ? values.filterByInchargeDate.split('-')[1] : moment().format("DD MMM YY")
                                }}
                                onEvent={(e, picker) => {
                                    if (e.type === "cancel") {
                                        setFieldValue("filterByInchargeDate", "");
                                        changeStartEndDate(inchargeDateRef);
                                        handleSubmit();
                                    } else if (e.type === "apply") {
                                        setFieldValue("filterByInchargeDate", picker.startDate.format('DD MMM YY') + " - " + picker.endDate.format('DD MMM YY'));
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="sh form-control customPickerDisplay">
                                    <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                                    <span>{values.filterByInchargeDate}</span>
                                    {
                                        values.filterByInchargeDate
                                            ? <button type="button" className="react-datepicker__close-icon"
                                                      style={{position: "inherit", marginLeft: "10px"}}
                                                      onClick={() => {
                                                          setFieldValue("filterByInchargeDate", "");
                                                          changeStartEndDate(inchargeDateRef);
                                                          handleSubmit();
                                                      }}
                                            />
                                            : null
                                    }
                                    <div style={{float: "right"}}>
                                        <IconSVG icon={'chevron-down'} size={'8px'}/>
                                    </div>
                                </div>
                            </DateRangePicker>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.provision.dateDisposal')}</h6>
                            <DateRangePicker
                                ref={dischargeDateRef}
                                initialSettings={{
                                    showDropdowns: true,
                                    ranges: filterRanges,
                                    locale: LocaleDatePicker,
                                    linkedCalendars: false,
                                    startDate: values.filterByDischargeDate ? values.filterByDischargeDate.split('-')[0] : moment().format("DD MMM YY"),
                                    endDate: values.filterByDischargeDate ? values.filterByDischargeDate.split('-')[1] : moment().format("DD MMM YY")
                                }}
                                onEvent={(e, picker) => {
                                    if (e.type === "cancel") {
                                        setFieldValue("filterByDischargeDate", "");
                                        changeStartEndDate(dischargeDateRef);
                                        handleSubmit();
                                    } else if (e.type === "apply") {
                                        setFieldValue("filterByDischargeDate", picker.startDate.format('DD MMM YY') + " - " + picker.endDate.format('DD MMM YY'));
                                        handleSubmit();
                                    }
                                }}
                            >
                                <div className="sh form-control customPickerDisplay">
                                    <IconSVG icon={'calendar'} size={'16px'} className="me-2"/>
                                    <span>{values.filterByDischargeDate}</span>
                                    {
                                        values.filterByDischargeDate !== ""
                                            ? <button type="button" className="react-datepicker__close-icon"
                                                      style={{position: "inherit", marginLeft: "10px"}}
                                                      onClick={() => {
                                                          setFieldValue("filterByDischargeDate", "");
                                                          changeStartEndDate(dischargeDateRef);
                                                          handleSubmit();
                                                      }}
                                            />
                                            : null
                                    }
                                    <div style={{float: "right"}}>
                                        <IconSVG icon={'chevron-down'} size={'8px'}/>
                                    </div>
                                </div>
                            </DateRangePicker>
                        </div>
                    </div>
                    {
                        filterPatientStatus && filterPatientStatus.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <h6 className="fw-bolder mb-3">{t('lsi.patientProfile.patientState')}</h6>
                                        {
                                            filterPatientStatus.map((el) => {
                                                return (
                                                    <div key={"div" + el.label}>
                                                        <label
                                                            className="checkbox"
                                                            key={"label" + el.label}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="filterByStatus"
                                                                id={el.label}
                                                                key={el.label}
                                                                value={el.value}
                                                                checked={values.filterByStatus.filter((e) => {
                                                                    return e === el.value
                                                                }).length > 0 ? true : false}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        values.filterByStatus = [
                                                                            ...values.filterByStatus,
                                                                            el.value
                                                                        ];
                                                                    } else {
                                                                        values.filterByStatus = values.filterByStatus.filter((value) => {
                                                                            return value !== el.value;
                                                                        });
                                                                    }
                                                                    handleSubmit();
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            {el.label}
                                                            <span/>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {
                        filterPatientGender && filterPatientGender.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <h6 className="fw-bolder mb-3">{t('lsi.registry.gender')}</h6>
                                        {
                                            filterPatientGender.map((el) => {
                                                return (
                                                    <div key={"div" + el.label}>
                                                        <label
                                                            className="checkbox"
                                                            key={"label" + el.label}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="filterByGender"
                                                                id={el.label}
                                                                key={el.label}
                                                                checked={values.filterByGender.filter((e) => {
                                                                    return e === el.value
                                                                }).length > 0 ? true : false}
                                                                value={el.value}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        values.filterByGender = [
                                                                            ...values.filterByGender,
                                                                            el.value
                                                                        ];
                                                                    } else {
                                                                        values.filterByGender = values.filterByGender.filter((value) => {
                                                                            return value !== el.value;
                                                                        });
                                                                    }
                                                                    handleSubmit();
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            {el.label}
                                                            <span/>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {
                        filterPatientProviders && filterPatientProviders.length > 1
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <h6 className="fw-bolder mb-3">{t('lsi.provider')}</h6>
                                        {
                                            filterPatientProviders.map((el) => {
                                                return (
                                                    <div key={"div" + el}>
                                                        <label
                                                            className="checkbox"
                                                            key={"label" + el}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="filterByProviders"
                                                                id={el.label}
                                                                key={el.label}
                                                                checked={values.filterByProviders.filter((e) => {
                                                                    return e === el
                                                                }).length > 0 ? true : false}
                                                                value={el}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        values.filterByProviders = [
                                                                            ...values.filterByProviders,
                                                                            el
                                                                        ];
                                                                    } else {
                                                                        values.filterByProviders = values.filterByProviders.filter((value) => {
                                                                            return value !== el;
                                                                        });
                                                                    }
                                                                    handleSubmit();
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            {el}
                                                            <span/>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {
                        filterDistrict && filterDistrict.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12" style={{overflow: "hidden"}}>
                                        <h6 className="fw-bolder mb-3">{t('lsi.registry.district')}</h6>
                                        {
                                            filterDistrict.map((el) => {
                                                return (
                                                    <div key={"div" + el}>
                                                        <OverlayTrigger
                                                            key={"overlay" + el}
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    {el}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <label
                                                                key={"label" + el}
                                                                className="checkbox"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByDistricts"
                                                                    id={el}
                                                                    key={"input" + el}
                                                                    value={el}
                                                                    checked={values.filterByDistricts.includes(el) ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByDistricts = [
                                                                                ...values.filterByDistricts,
                                                                                el
                                                                            ]
                                                                        } else {
                                                                            values.filterByDistricts = values.filterByDistricts.filter((value) => {
                                                                                return value !== el
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el}
                                                                <span/>
                                                            </label>
                                                        </OverlayTrigger>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                    {
                        filterPatientsPrescriptions && filterPatientsPrescriptions.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12" style={{overflow: "hidden"}}>
                                        <h6 className="fw-bolder mb-3">{t('lsi.patientProfile.treatments')}</h6>
                                        {
                                            filterPatientsPrescriptions.map((el) => {
                                                return (
                                                    <div key={"div" + el}>
                                                        <OverlayTrigger
                                                            key={"overlay" + el}
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    {el}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <label
                                                                key={"label" + el}
                                                                className="checkbox"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByPrescriptions"
                                                                    id={el}
                                                                    key={"input" + el}
                                                                    value={el}
                                                                    checked={values.filterByPrescriptions.includes(el) ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByPrescriptions = [
                                                                                ...values.filterByPrescriptions,
                                                                                el
                                                                            ]
                                                                        } else {
                                                                            values.filterByPrescriptions = values.filterByPrescriptions.filter((value) => {
                                                                                return value !== el
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />

                                                                {el}
                                                                <span/>
                                                            </label>
                                                        </OverlayTrigger>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                </Form>
            )}
        </Formik>
    );
}
