import React from "react";
import {
    getProvisionStateTitles,
    getProvisionStateValue,
    getProvisionTypeTitles, mapProvisionsColor,
    ProvisionStatusCssClasses
} from "../ProvisionsUIHelpers";
import Tag from "../../../../shared/custom/tag/Tag";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const ProvisionMainInfo = ({provisionMainSection, history}) => {
    const {t} = useLanguageForShared()

    return (
        <>
            {
                provisionMainSection
                    ? <div className={'list-key-value'}>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <div>
                                        <span className="font-size-h6">{t('lsi.provision.tipologyProvision')} </span>
                                        <span
                                            className="font-size-sm">{getProvisionTypeTitles(provisionMainSection.ProvisionType)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.table.documentNumber')}</span>
                                    <span className="font-size-sm">{provisionMainSection.DocumentNumber}</span>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.table.date')}</span>
                                    <span className="font-size-sm">{provisionMainSection.ProvisionDate}</span>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.table.status')}</span>
                                    <Tag label={getProvisionStateTitles(provisionMainSection.ProvisionState)}
                                         bgColor={mapProvisionsColor()[ProvisionStatusCssClasses[getProvisionStateValue(provisionMainSection.ProvisionState)]].bg}
                                         color={mapProvisionsColor()[ProvisionStatusCssClasses[getProvisionStateValue(provisionMainSection.ProvisionState)]].color}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.table.staffId')}</span>
                                    <span className="font-size-sm">{provisionMainSection.StaffInitials}</span>
                                </div>
                            </div>
                            {
                                provisionMainSection.ProvisionType === "CONSEGNA" || provisionMainSection.ProvisionType === "RITIRO"
                                    ? <>
                                        <div className="col-xl-2 col-lg-2 d-flex align-items-center me-5 my-1">
                                            <div className="d-flex flex-column">
                                                <span className="font-size-h6">{t('lsi.table.reasonCode')}</span>
                                                <span className="font-size-sm">{provisionMainSection.ReasonCode}</span>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                        <div className="row">
                            <label className="col-xl-3"/>
                            <div className="col-lg-9 col-xl-6">
                                <h5 className="fw-bold mt-10 mb-6"/>
                            </div>
                        </div>

                    </div>
                    : null
            }
        </>
    );
}
