import React from 'react'
import {Attachment} from '../../../models/ClinicalDiaryItemModel'
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../shared/configuration";
import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";
import BtnDefault from "../../../../../shared/bootstrap/button/default/BtnDefault";

export default function FileItemManageL({
                                            attachment,
                                            isEditMode,
                                            deleteHandler,
                                            downloadAction,
                                            note
                                        }: {
    attachment: Attachment
    isEditMode: boolean
    deleteHandler?: () => void
    downloadAction: (data: any) => void
    note: any
}) {
    const {t} = useLanguageForShared()

    const downloadHandler = (event: any) => {
        event.preventDefault()
        downloadAction(attachment)
    }
    return (
        <>
            <div className="file-item">
                <IconSVG icon={'document'} size={'20px'} tintColor={configuration.theme.primaryDark}/>
                <div className="name-file">
                    <a href="#" target="_blank"
                       onClick={(!note?.OperationType || note?.OperationType?.includes('download')) && downloadHandler}>
                        {attachment.FileName}.{attachment.FileType}
                    </a>
                </div>
                {isEditMode && (
                    <BtnDefault
                        className="outline"
                        icon="trash"
                        onClick={deleteHandler}
                        title={t('action.delete')}
                        tintColor={configuration.theme.primary}
                    ></BtnDefault>
                )}
            </div>
        </>
    )
}
