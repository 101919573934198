import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    Card,
    CardBody,
    Input
} from "../../../../_metronic/_partials/controls";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import {Formik, Form, Field} from "formik";
import * as actions from "../../../actions/generic/editActions";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientInvoiceEditHome() {
    const {t} = useLanguageForShared()

    const suhbeader = useSubheader();

    const {currentState} = useSelector(
        (state) => ({
            currentState: state.patients
        }),
        shallowEqual
    );

    const {patientById, selectedPatientInvoice} = currentState;

    useEffect(() => {
        suhbeader.setTitle(patientById ? `${t('lsi.edit.provisionAlert')}  - '${patientById.FullName}'` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('patientPrescriptionWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientById]);

    const customLabel = (props) => {
        return (
            <span>
                {props.field.value}
            </span>
        );
    }

    const dispatch = useDispatch();

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-12 col-lg-8">
                        {
                            selectedPatientInvoice
                                ? <Card>
                                    <CardBody>
                                        <div id="patientPrescriptionWizard" className="wizard wizard-3"
                                             data-wizard-state="step-first"
                                             data-wizard-clickable="true">
                                            <div className="wizard-nav">
                                                <div className="wizard-steps">
                                                    <div className="wizard-step" data-wizard-type="step"
                                                         data-wizard-state="current">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.generality')}
                                                                <IconSVG icon={'chevron-right'} size={'14px'}
                                                                         tintColor={configuration.theme.primaryDark}/></h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.note')}
                                                                <IconSVG icon={'chevron-right'} size={'14px'}
                                                                         tintColor={configuration.theme.primaryDark}/></h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.review')}
                                                                <IconSVG icon={'chevron-right'} size={'14px'}
                                                                         tintColor={configuration.theme.primaryDark}/></h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center div-custom">
                                                <div className="col-12">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={selectedPatientInvoice}
                                                        onSubmit={(values) => {
                                                            const model = {
                                                                GenericObject: {
                                                                    Reporting: values.Reporting,
                                                                    CustomerId: values.CustomerId,
                                                                    MonthYear: values.MonthYear,
                                                                    PrescriptionName: values.PrescriptionName,
                                                                    PrescriptorName: values.PrescriptorName,
                                                                    InchargeDate: values.InchargeDate,
                                                                    DischargeDate: values.DischargeDate,
                                                                    TherapyDays: values.TherapyDays,
                                                                    TotalAmount: values.TotalAmount,
                                                                    Section: "Fattura del paziente"
                                                                }
                                                            }
                                                            dispatch(actions.editEntity(model,t));
                                                        }}
                                                    >
                                                        {({handleSubmit}) => (
                                                            <>
                                                                <Form className="form" onSubmit={handleSubmit}>
                                                                    <div className="pb-5" data-wizard-type="step-content"
                                                                         data-wizard-state="current">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.generality')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="MonthYear"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.monthYear')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="PrescriptionName"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.prescription')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="PrescriptorName"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.prescriber')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="InchargeDate"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.dateTaking')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="DischargeDate"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.dateDisposal')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Field
                                                                                    name="TherapyDays"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.therapyDays')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <Field
                                                                                    name="TotalAmount"
                                                                                    component={Input}
                                                                                    label={t('lsi.provision.cost')}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.alert')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <Field
                                                                                    name="Reporting"
                                                                                    component="textarea"
                                                                                    rows="5"
                                                                                    className="sh form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark">{t('lsi.edit.reviewData')}</h4>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.generality')}</h6>
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.monthYear')}: </span>
                                                                                        <Field
                                                                                            name="MonthYear"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.prescription')}: </span>
                                                                                        <Field
                                                                                            name="PrescriptionName"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.prescriber')}: </span>
                                                                                        <Field
                                                                                            name="PrescriptorName"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.dateTaking')}: </span>
                                                                                        <Field
                                                                                            name="InchargeDate"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.dateDisposal')}: </span>
                                                                                        <Field
                                                                                            name="DischargeDate"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.therapyDays')}: </span>
                                                                                        <Field
                                                                                            name="TherapyDays"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-6 col-lg-4">
                                                                                        <span>{t('lsi.provision.cost')}: </span>
                                                                                        <Field
                                                                                            name="TotalAmount"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3">{t('lsi.edit.requestEdit')}</h6>
                                                                                <div
                                                                                    className="text-dark-50 line-height-lg">
                                                                                    <div>
                                                                                        <Field
                                                                                            name="Reporting"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-row">
                                                                        <BtnSecondary
                                                                            onClick={() => {
                                                                                window.history.back();
                                                                            }}
                                                                        >
                                                                            {t('lsi.cancel')}
                                                                        </BtnSecondary>
                                                                        <BtnSecondary
                                                                            data-wizard-type="action-prev"
                                                                        >
                                                                            {t('lsi.prev')}
                                                                        </BtnSecondary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-submit"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            {t('lsi.notify')}

                                                                        </BtnPrimary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-next"
                                                                        >
                                                                            {t('lsi.next')}
                                                                        </BtnPrimary>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
