import React from "react";
import {ChartCustom} from "../../../../component/chartcustom/ChartCustom";
import useCostForTreatment from "./useCostForTreatment";
import configuration from "../../../../../shared/configuration";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CostForTreatment = ({costs}) => {
    const {t} = useLanguageForShared()

    const {dataTable, treatments, dateTicks} = useCostForTreatment(costs, t);
    const optionAdd = {
        colors: [configuration.theme.series.success170_hex, configuration.theme.series.s870_hex, configuration.theme.series.s470_hex, configuration.theme.series.alert170_hex, configuration.theme.series.s700_hex, configuration.theme.series.success170_hex],
        fontName: configuration.theme.fontFamily,
        fontSize: 14,
        titleTextStyle: {
            italic: false,
            fontSize: 18,
            bold: false,
            color: configuration.theme.primaryDark,
        },
    }
    const vAxisAdd = {
        ticks: dateTicks,
    }
    const formatters = dataTable[0].slice(1).map((ele, i) => (
        {
            type: "NumberFormat",
            column: i + 1,
            options: {
                suffix: "€",
                decimalSymbol: ',',
                groupingSymbol: '.'
            }
        }
    ))

    return <>
        <ChartCustom dataTable={dataTable}
                     options={optionAdd}
                     vAxis={vAxisAdd}
                     chartType="LineChart"
                     formatters={formatters}
                     height={220 + (treatments.length > 4 ? 6 * treatments.length : 0)}/>
    </>
}

export default React.memo(CostForTreatment);
