import React, {useEffect, useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {defaultInvoiceListTableSorted} from "../InvoicesUIHelpers";

import {useInvoicesUIContext} from "../InvoicesUIContext";
import * as actions from "../../../actions/invoices/invoicesActions";
import {
    Card,
    CardBody,
    CardHeader, CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import {
    CurrencyColumnFormatter
} from "../../../component/table/column-formatters/TypeColumnFormatter";
import {InfiniteScrollTable} from "../../../component/table/InfiniteScrollTable";
import {ExportButton} from "../../../component/exportbutton/ExportButton";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function InvoicesTable() {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    const invoicesUIContext = useInvoicesUIContext();
    const invoicesUIProps = useMemo(() => {
        return {
            queryInvoiceParams: invoicesUIContext.queryInvoiceParams
        };
    }, [invoicesUIContext]);

    const {currentState} = useSelector(
        (state) => ({currentState: state.invoices}),
        shallowEqual
    );

    const {invoicesList, invoicesPagination} = currentState;

    const [selectedRows, setSelectedRows] = useState([]);
    const [sortTable, setSortTable] = useState(undefined);

    useEffect(() => {
            dispatch(actions.fetchInvoices(invoicesUIProps.queryInvoiceParams, null, sortTable));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicesUIProps.queryInvoiceParams, dispatch]);

    const columns = [
        {
            dataField: "Id",
            text: t('lsi.table.invoiceNumber'),
            sort: true,


        },
        {
            dataField: "PostingDate",
            text: t('lsi.table.datePosting'),
            sort: true,

        },
        {
            dataField: "CustomerNumber",
            text: t('lsi.table.customerId'),
            sort: false,
        },
        {
            dataField: "CustomerName",
            text: t('lsi.table.customer'),
            sort: false,

        },
        {
            dataField: "Cig",
            text: t('lsi.table.cig'),
            sort: true,

        },
        {
            dataField: "CustomerOrderDate",
            text: t('lsi.table.dateOrder'),
            sort: true,

        },
        {
            dataField: "CustomerOrderNumber",
            text: t('lsi.table.customerOrderNumber'),
            sort: true,

        },
        {
            dataField: "ConventionNumber",
            text: t('lsi.table.conventionNumber'),
            sort: true,

        },
        {
            dataField: "Amount",
            text: t('lsi.table.amountNet'),
            sort: true,
            align: "right",
            headerAlign: 'right',
            formatter: CurrencyColumnFormatter,
        }

    ];


    return (
        <Card className="customHeaderPadding">
            <CardHeader title={t('lsi.provision.listInvoices')} className="fw-bolder">
                 <CardHeaderToolbar>
                    <ExportButton
                        data={selectedRows.length > 0 ? selectedRows : []}
                        loadData={(callback) => dispatch(actions.fetchInvoicesForFile(invoicesUIProps.queryInvoiceParams, callback))}
                        columns={columns}
                        fileName={"fatture"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"Id"}
                    id="invoicesTable"
                    columns={columns}
                    data={invoicesList}
                    defaultSorted={defaultInvoiceListTableSorted}
                    pagination={invoicesPagination}
                    noSelectRow={true}
                    hoverEnable={false}
                    loadData={(event, tableInfo) => {
                        if (tableInfo) {
                            setSortTable({dataField: tableInfo.sortField, order: tableInfo.sortOrder});
                            dispatch(actions.fetchInvoices(invoicesUIProps.queryInvoiceParams, invoicesPagination, {
                                dataField: tableInfo.sortField,
                                order: tableInfo.sortOrder
                            }, true));
                        } else {
                            dispatch(actions.fetchInvoices(invoicesUIProps.queryInvoiceParams, invoicesPagination, sortTable, false));
                        }
                    }}
                    noData={t('lsi.noDataAvailable')}
                />
            </CardBody>
        </Card>
    );
}
