// import React, {useState} from 'react'
// import {Sidebar} from "./core/sidebar/Sidebar";
// import MenuSidebar from "./core/sidebar/MenuSidebar";
// import {sectionModel, voicesModel} from "./models/sectionsModel";

// const SidebarComponent: React.FC<{
//     token?: string
//     currentPage: string
//     sections: sectionModel[]
//     onClickVoice: (event: React.MouseEventHandler<HTMLDivElement>, voice: voicesModel) => void
// }> = ({currentPage, sections, onClickVoice}) => {

//     const [toggleSidebar, setToggleSidebar] = useState(true);
    
//  console.log(toggleSidebar);

//     return (
//         <>
//             <Sidebar
//                 isVisible={toggleSidebar || false}
//                 hideSidebarHandler={() =>
//                     setToggleSidebar && setToggleSidebar(!toggleSidebar)
//                 }>
//                 <MenuSidebar currentPage={currentPage} sections={sections}
//                              goToPageHandler={onClickVoice}/>
//             </Sidebar>
//         </>
//     )
// }

// export default SidebarComponent


import React, { useState, useEffect } from 'react';
import { Sidebar } from './core/sidebar/Sidebar';
import MenuSidebar from './core/sidebar/MenuSidebar';
import { sectionModel, voicesModel } from './models/sectionsModel';

const SidebarComponent: React.FC<{
  token?: string;
  currentPage: string;
  sections: sectionModel[];
  onClickVoice: (event: React.MouseEventHandler<HTMLDivElement>, voice: voicesModel) => void;
}> = ({ currentPage, sections, onClickVoice }) => {
  const [toggleSidebar, setToggleSidebar] = useState(window.innerWidth >= 1500);
  

  useEffect(() => {
    const handleResize = () => {
      setToggleSidebar(window.innerWidth >= 1500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <> 
        <Sidebar isVisible={toggleSidebar} hideSidebarHandler={() => setToggleSidebar && setToggleSidebar(!toggleSidebar)} isMenuOpen={toggleSidebar}>
          <MenuSidebar currentPage={currentPage} sections={sections} goToPageHandler={onClickVoice} />
        </Sidebar>
    </>
  );
};

export default SidebarComponent;
