import {t} from 'i18next'
import React from 'react'
import BtnDanger from '../../../../../../shared/bootstrap/button/danger/BtnDanger'
import {IconSVG} from "../../../../../../shared/custom/icon/IconSVG";
import BtnSecondary from "../../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import configuration from "../../../../../../shared/configuration";

export default function RowBtnReadV({
                                       editHandler,
                                       deleteHandler,
                                       notifyHandler,
                                   }: {
    editHandler: () => void
    deleteHandler: () => void
    notifyHandler: () => void
}) {
    return (
        <div className={'btn-group-distance'}>
            <BtnSecondary
                className=""
                onClick={notifyHandler}
                title={t('action.edit')}
                icon={'send-notify'}
                tintColor={configuration.theme.primary}
            >
            </BtnSecondary>
            <BtnSecondary
                className=""
                onClick={editHandler}
                title={t('action.edit')}
                icon={'edit'}
                tintColor={configuration.theme.primary}
            >
            </BtnSecondary>
            <BtnDanger
                className="outline"
                onClick={deleteHandler}
                title={t('action.delete')}
                icon={'trash'}
                tintColor={configuration.theme.danger_color}
            >
            </BtnDanger>
        </div>
    )
}
