import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialPatientFilter } from "./PatientsUIHelpers";
import moment from "moment"

const PatientsUIContext = createContext();

export function usePatientsUIContext() {
    return useContext(PatientsUIContext);
}

export function PatientsUIProvider({ patientsUIEvents, children }) {
    let patientsSelectedFilter = {};
    const {filter: {SearchText, Period, InchargeDate, DischargeDate, Status, Gender, District, Provider, Prescriptions}} = JSON.parse(localStorage.getItem('patientsSelectedFilters-...')) || {filter: {}};

    patientsSelectedFilter = {
        filter: {
            //InchargeDate: moment().subtract(1, "month").format('DD MMM YY') + " - " + moment().format('DD MMM YY'),
            SearchText:  SearchText || "",
            Period: Period ||  moment().subtract(2, "month").format('DD MMM YY') + " - " + moment().format('DD MMM YY'),
            InchargeDate: InchargeDate|| "",
            DischargeDate: DischargeDate || "",
            Status: Status || [],
            Gender: Gender || [],
            District: District || [],
            Provider: Provider || [],
            //Prescriptor: [],
            Prescriptions: Prescriptions || []
        },
        // sortOrder: "desc", // asc||desc
        // sortField: "InchargeDate",
    };

    const [queryParams, setQueryParamsBase] = useState(patientsSelectedFilter);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        openInfoPatientPage: patientsUIEvents.openInfoPatientPage
    };

    return <PatientsUIContext.Provider value={value}>{children}</PatientsUIContext.Provider>;
}
