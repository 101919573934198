import React from "react";

export default function ReportLayoutL({
                                          dateTemplate,
                                          subjectTemplate,
                                          notesTemplate,
                                          operatorTemplate,
                                          lastUpdateTemplate,
                                          toolbarRightTemplate,
                                          listFileTemplate,
                                          isEditMode,
                                      }: {
    dateTemplate: React.ReactChild
    subjectTemplate: React.ReactChild
    notesTemplate: React.ReactChild
    operatorTemplate: React.ReactChild
    lastUpdateTemplate: React.ReactChild
    toolbarRightTemplate?: React.ReactNode
    listFileTemplate: React.ReactChild
    isEditMode: boolean
}) {
    
    return (
        <>
            <div className={'col-12 col-md-8 col-lg order-2 order-md-1'}>
                <div className={!isEditMode ? 'd-flex w-100 gap-5 align-items-start' : 'row'}>
                    <div style={{width: !isEditMode ? '190px' : ''}}
                         className={!isEditMode ? '' : 'col-12 col-lg-6'}>
                        {dateTemplate}
                    </div>
                    {!isEditMode && lastUpdateTemplate}
                </div>
                <div className="">{notesTemplate}</div>
                {listFileTemplate}
            </div>
            <div className={'col-12  col-md-4 col-lg-auto  order-1 order-md-2 pb-3 pb-md-0'}>
                <div className={'ms-auto '} style={{marginTop: !isEditMode ? '-10px' : '0px'}}>
                    {toolbarRightTemplate}
                </div>
            </div>
        </>
    )
}
