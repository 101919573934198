import React, {useEffect, useState} from "react";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import DiaryComp from "../../../../libraries/diary/DiaryComp";
import axios from "axios";
import {path} from "../../../utils/constant";
import {b64toBlob} from "../../../../shared/utils/fileUtils";
import {saveAs} from "file-saver";
import {objDeleteProperties} from "../../../../shared/utils/objUtils";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedCustomer} from "../../../actions/users/usersSlice";
import {addErrorMessage, resetErrors} from "../../../../shared/redux/errors/errorSlice";

export function ReportPage({}) {
    const dispatch = useDispatch()
    const {t} = useLanguageForShared()
    const [reports, setReports] = useState([])
    const {user} = useSelector(state => state.auth);
    const customer = useSelector(selectSelectedCustomer);

    const errors = {
        AttachmentNotFound: 'attachment_not_found',
        FileNotSupported: 'file_not_supported',
        FileTooLarge: 'file_too_large',
        ReportNotFound: 'report_not_found'
    }
    const readOnly = !user?.roles?.includes('lsi_admin')
    useEffect(() => {
        customer.CustomerId && axios.get(`${path}/${customer.CustomerId}/reports/getall`).then(res => {
            const reps = res.data.reverse().map(ele => {
                    return formatReport(ele)
                }
            )
            setReports(reps)
        }, err => {
            const typeError = err?.response?.data?.detail
            if (typeError && errors[typeError]) {
                dispatch(addErrorMessage({title: t(`note.errors.${errors[typeError]}`)}))
            } else {
                dispatch(addErrorMessage({title: t(`note.errors.generic`)}))
            }
        })
    }, [customer.CustomerId]);
    const downloadAction = (data) => {
        dispatch(resetErrors())
        axios.get(`${path}/${customer.CustomerId}/reports/getattachment?attachmentId=${data.Id}&fileType=${data.FileType}`).then(res => {
            b64toBlob(res.data).then((r) => {
                const blob = new Blob([r], {
                    type: 'application/octet-stream',
                })
                saveAs(blob, data.FileName + '.' + data.FileType)
            })
        }, err => {
            const typeError = err?.response?.data?.detail
            if (typeError && errors[typeError]) {
                dispatch(addErrorMessage({title: t(`note.errors.${errors[typeError]}`)}))
            } else {
                dispatch(addErrorMessage({title: t(`note.errors.generic`)}))
            }
        })
    }
    const addNote = (data, callback) => {
        // {ReportId: d.Id, Name: data.name, Type: data.FileType, Base64: data.base64}
        axios.post(`${path}/${customer.CustomerId}/reports/create`, {
            CustomerId: customer.CustomerId, ...data, Attachments: (data.Attachments || []).map(att => ({
                Name: att.FileName,
                Type: att.FileType,
                Base64: att.FileBase64
            }))
        }).then(res => {
            setReports([formatReport(res.data), ...reports])
            callback && callback();
        }, err => {
            const typeError = err?.response?.data?.detail
            if (typeError && errors[typeError]) {
                dispatch(addErrorMessage({title: t(`note.errors.${errors[typeError]}`)}))
            } else {
                dispatch(addErrorMessage({title: t(`note.errors.generic`)}))
            }
        })
    }
    const deleteFileAction = (attachmentId, reportId, note, callback) => {
        dispatch(resetErrors())
        axios.delete(`${path}/${customer.CustomerId}/reports/removeattachment?reportId=${reportId}&attachmentId=${attachmentId}`).then(res => {
            const newNote = {
                ...note,
                Attachments: note.Attachments.filter((ele) => ele.Id !== attachmentId),
            }
            setReports(reports.map(ele => ele.Id === newNote.Id ? newNote : ele))
            callback && callback();
        }, err => {
            const typeError = err?.response?.data?.detail
            if (typeError && errors[typeError]) {
                dispatch(addErrorMessage({title: t(`note.errors.${errors[typeError]}`)}))
            } else {
                dispatch(addErrorMessage({title: t(`note.errors.generic`)}))
            }
        })
    }

    const formatAttachment = (att) => {
        const data = Object.keys(att).reduce((acc, el) => ({
            ...acc,
            [el.charAt(0).toUpperCase() + el.slice(1)]: att[el]
        }), {})
        return {
            ...data, FileName: data.Name, FileType: data.Type, FileDate: data.Date
        }
    }
    const formatReport = (data) => {
        const el = Object.keys(data).reduce((acc, el) => ({
            ...acc,
            [el.charAt(0).toUpperCase() + el.slice(1)]: data[el]
        }), {})
        return {
            ...el,
            ItemID: el.Id.toString(),
            ...(el.Attachments ? {
                Attachments: el.Attachments.map(ele => {
                        return formatAttachment(ele)
                    }
                )
            } : {})
        }
    }
    const editNoteAction = (note, callback) => {
        axios.put(`${path}/${customer.CustomerId}/reports/update`, {
            ...objDeleteProperties(note, ['ItemID', 'LastUpdate', 'OperationType']),
            Attachments: (note.Attachments || []).map(att => objDeleteProperties(att), ['FileName', 'FileType', 'FileDate'])
        }).then(res => {
            const el = formatReport(res.data);
            if(note.Deleted){
                setReports(reports.filter(ele => ele.Id !== el.Id))
            }else {
                setReports(reports.map(ele => ele.Id === el.Id ? el : ele))
            }

            callback && callback()
        }, err => {
            const typeError = err?.response?.data?.detail
            if (typeError && errors[typeError]) {
                dispatch(addErrorMessage({title: t(`note.errors.${errors[typeError]}`)}))
            } else {
                dispatch(addErrorMessage({title: t(`note.errors.generic`)}))
            }
        })
    }
    const uploadFileHandler = (data, d, callback) => {
        axios.post(`${path}/${customer.CustomerId}/reports/addattachment`, {
            ReportId: d.Id,
            Name: data.name,
            Type: data.FileType,
            Base64: data.base64
        }).then(res => {
            const el = formatReport(res.data);
            setReports(reports.map(ele => ele.Id === el.Id ? el : ele))
            callback && callback()
        }, err => {
            const typeError = err?.response?.data?.detail
            if (typeError && errors[typeError]) {
                dispatch(addErrorMessage({title: t(`note.errors.${errors[typeError]}`)}))
            } else {
                dispatch(addErrorMessage({title: t(`note.errors.generic`)}))
            }
        })
    }
    const [newNote, setNewNote] = useState()
    const [showNotify, setShowNotify] = useState()
    const diaries = [];
    return (
        <div className="row layout-page" style={{zIndex: "1"}}>
            <div className="col-lg-12 card-lg-10 content-col">
                <DiaryComp
                    readonly={readOnly}
                    downloadAction={downloadAction}
                    newNote={newNote}
                    setNewNote={setNewNote}
                    addNote={addNote}
                    setShowNotify={setShowNotify}
                    diaries={reports}
                    deleteFileAction={deleteFileAction}
                    editNoteAction={editNoteAction}
                    uploadFileHandler={uploadFileHandler}
                    showNotify={showNotify}
                    subjectTypes={[]}
                    diaryTemplate={'report'}
                    titleLabel={'note.titleReport'}
                    actionLabel={'note.action.newReport'}
                />
            </div>
        </div>
    );
}
