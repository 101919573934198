import React from "react";
import {ChartCustom} from "../../../../component/chartcustom/ChartCustom";
import useO2state from "./useO2state";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const O2State = ({states = []}) => {
    const {t} = useLanguageForShared()

    const {dataTable, dateTicks} = useO2state(states, t)
    const optionAdd = {
        colors: ['#F7A67E', '#5D62B5'],
        seriesType: "bars",
        series: {1: {type: "line"}},
    }

    const vAxisAdd = {
        ticks: dateTicks
    }
    const formatters = dataTable[0].slice(1).map((ele, i) => (
        {
            type: "NumberFormat",
            column: i + 1,
            options: {
                decimalSymbol: ',',
                groupingSymbol: '.'
            }
        }
    ))
    return <ChartCustom dataTable={dataTable} options={optionAdd} chartType="ComboChart" vAxis={vAxisAdd}
                        formatters={formatters}/>
}

export default React.memo(O2State)
