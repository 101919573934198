import React, { useState } from "react";
import {
    headerSortingClasses,
    sortCaret

} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientAccessoriesSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ AccessoriesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientAccessoriesTable({ accessories }) {
    const {t} = useLanguageForShared()

    const columns = [
/*        {
            isDummyField: true,
            dataField: "Photo",
            text: "",


            //formatter: FotoColumnFormatter,
            formatter: AccessoriesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,

            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "AccessoryType",
            text: t('lsi.table.accessoryType'),
            sort: true,



        },
        {
            dataField: "ItemNumber",
            text: t('lsi.table.itemNumber'),
            sort: true,



        },
        {
            dataField: "Description",
            text: t('lsi.table.description'),
            sort: false,


        },
        /*{
            dataField: "Serial",
            text: "Seriale",
            sort: false,


        },*/
        /*{
            dataField: "LotNumber",
            text: "Lotto",
            sort: false,


        },*/
        {
            dataField: "DeliveryDate",
            text: t('lsi.table.deliveryDate'),
            sort: true,
            sortFunc: tableSortByDate,

        },
        {
            dataField: "PickUpDate",
            text: t('lsi.table.pickUpDate'),
            sort: true,
            sortFunc: tableSortByDate,

        },
        /*{
            isDummyField: true,
            dataField: "Assets",
            text: "Documentazione",
            formatter: AssetsColumnFormatter,
        },*/
    ];

    const [count, setCount] = useState(20);

    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table-head-custom table-vertical-center"
                bootstrap4
                keyField="Serial"
                data={(accessories || []).slice(0, count)}
                columns={columns}
                defaultSorted={defaultPatientAccessoriesSorted}
                noDataIndication={t('lsi.noDataAvailable')}
            >
            </BootstrapTable>
            {
                accessories.length > 20 && count <= 20 &&
                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setCount(60)}>{t('lsi.more')}</div>
            }
        </>
    );
}
