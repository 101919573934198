import React, {useState} from 'react'
import {ClinicalDiaryItem} from '../../models/ClinicalDiaryItemModel'
import {FileDataModel} from '../../models/FileDataModel'
import NoteItemL from "./components/NoteItemL";
import NoteItemTemplateL from "./components/NoteItemTemplateL";
import {useForm} from "../../../../shared/utils/forms/useForm";
import {UseFormSubmitModel} from "../../../../shared/utils/forms/models/UseFormModel";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {validationNewNoteL, validationNewReportL} from "./formDataL";
import {DeleteFileActionType, EditNoteActionType, UploadFileActionType} from "../../models/ActionModel";

export default function ListNotesL({
                                       notes,
                                       newNote,
                                       setNewNote,
                                       editNoteAction,
                                       uploadFileHandler,
                                       deleteFile,
                                       addNote,
                                       readonly,
                                       downloadAction,
                                       diaryTemplate
                                   }: {
    notes: ClinicalDiaryItem[]
    newNote: boolean
    setNewNote: (state: boolean) => void
    editNoteAction: EditNoteActionType
    addNote: (data: ClinicalDiaryItem, callback: () => void) => void
    uploadFileHandler: UploadFileActionType
    deleteFile: DeleteFileActionType
    readonly: boolean,
    downloadAction: (data: any) => void
    diaryTemplate: string
}) {
    const {t} = useLanguageForShared()
    const [isAddFile, setIsAddFile] = useState(false)
    const [files, setFiles] = useState<FileDataModel[] | undefined>(undefined)

    const formDataSubject: any = [
        {
            name: 'SubjectText',
            label: t('note.newNote.subject'),
            type: 'input',
            /* options: subjectTypes.map((ele) => ({
                 ...ele,
                 label: t('note.object.' + ele.label),
             })),*/
            dataElement: {
                type: 'text',
            },
            display: {
                form: 'row',
                label: 'col-12 col-md-4 col-lg-2',
                child: 'col-12 col-md-8 col-lg-10'
            },
        },
    ]
    const reset = () => {
        setData({Date: new Date().toISOString()})
        setFiles([])
        setIsAddFile(false)
        setNewNote(false)
    }
    const submitHandler: UseFormSubmitModel = (event, data: any) => {
        addNote({
            ...data,
            Attachments:
                files?.map((file) => ({
                    FileBase64: file.base64,
                    FileName: file.name || file.file.name,
                    FileType: file.FileType,
                })) || undefined,
        }, () => {
            reset()
        })


    }

    const validationTemplates: any = {
        default: () => validationNewNoteL(t),
        report: () => validationNewReportL(t),
    }

    const {dataForm, changeValue, submitAction, errors, setData, isSubmit} = useForm(
        submitHandler, validationTemplates[diaryTemplate](t), {
            Date: new Date().toISOString(),
        }
    )
    const cancelAdd = () => {
        reset()
    }
    const addFileHandler = (file: FileDataModel) => {
        setFiles([...(files || []), file])
        setIsAddFile(false)
    }
    const removeFileInListHandler = (file: FileDataModel) => {
        setFiles([
            ...(files || []).filter(
                (ele) => ele.file.path !== file.file.path
            ),
        ])
    }
    const saveNote = () => {
        submitAction()
    }

    return (
        <div className={'d-flex flex-column row-gap-3 mt-2'}>
            {newNote && <NoteItemTemplateL
                downloadAction={downloadAction}
                isEditMode={true}
                newNote={newNote}
                changeValue={changeValue}
                dataForm={dataForm}
                errors={errors}
                formDataSubject={formDataSubject}
                cancelEdit={cancelAdd}
                isAddFile={isAddFile}
                setIsAddFile={setIsAddFile}
                uploadFile={addFileHandler}
                removeFileInListHandler={removeFileInListHandler}
                files={files}
                saveNote={saveNote}
                isSubmit={isSubmit}
                diaryTemplate={diaryTemplate}
            />}
            {notes && notes.length > 0 ? notes.map((note) => (
                    <div className="" key={note.ItemID}>
                        <NoteItemL
                            downloadAction={downloadAction}
                            readonly={readonly}
                            note={note}
                            editNoteAction={editNoteAction}
                            uploadFileHandler={uploadFileHandler}
                            deleteFileAction={deleteFile}
                            //subjectTypes={subjectTypes}
                            formDataSubject={formDataSubject}
                            diaryTemplate={diaryTemplate}
                        />
                    </div>
                )) :
                <div>{t('msg.success.emptyData.title')}</div>}
        </div>
    )
}
