import React from "react";
import {ChartCustom} from "../../../component/chartcustom/ChartCustom";
import {formatDateMonthYearYY} from "../../../utils/dateUtils";
import {getMaxValue} from "../../../utils/utility";
import useCalcTick from "../../serviceIndicators/ServiceIndicatorsHome/useCalcTick";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function CostsChartGraph({costChartData}) {
    const {t} = useLanguageForShared()


    const districs = costChartData[0].districts.map(ele => ele?.district || '-')
    const dataTable = [
        [t('lsi.chart.monthYear'), ...districs, t('lsi.chart.total')],
        ...costChartData.map(ele => [formatDateMonthYearYY(ele.month, ele.year), ...ele.districts.map(el => el?.cost), ele?.cost])
    ]
    const maxValueTotal = getMaxValue(dataTable)
    const maxValueDistrict = getMaxValue(dataTable, true)

    const {dateTicks, dataFormatter} = useCalcTick(maxValueDistrict, 3, 'currency')

    const optionAdd = {
        colors: [configuration.theme.series.s770_hex, configuration.theme.series.s170_hex, configuration.theme.series.alert170_hex, configuration.theme.series.s870_hex, configuration.theme.series.success170_hex],
        seriesType: "bars",
        series: {[districs.length]: {type: "line"}},
        bar: {
            width: 5,
        },
        chartArea: {
            width: "100%",
            left: 100,
            right: 10,
            top: 50 + 2 * districs.length,
            bottom: 50
        },
    }
    const vAxisAdd = {
        ticks: [
            {
                "v": 0,
                "f": dataFormatter('currency', 0)
            },
            {
                "v": Math.ceil(maxValueDistrict),
                "f": dataFormatter('currency', Math.ceil(maxValueDistrict))
            },
            {
                "v": Math.ceil(maxValueTotal),
                "f": dataFormatter('currency', Math.ceil(maxValueTotal))
            }
        ],
        logScale: true
    }
    const formatters = dataTable[0].slice(1).map((ele, i) => (
        {
            type: "NumberFormat",
            column: i + 1,
            options: {
                suffix: "€",
                decimalSymbol: ',',
                groupingSymbol: '.'
            }
        }
    ))

    return (
        <div>
            <ChartCustom dataTable={dataTable} options={optionAdd} chartType="ComboChart" vAxis={vAxisAdd}
                         formatters={formatters}
                         height={280}/>
        </div>
    );
}
