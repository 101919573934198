import React from 'react';

import Patient from "../../../../../shared/UI components/Patient";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const SubheaderPatientTemplate = ({data}: { data: any }) => {
    const {t} = useLanguageForShared()
    return (
        <>
            {data &&
                <Patient
                    patient={{
                        name: data.FirstName,
                        surname: data.LastName,
                        birthdate: data.BirthDate,
                        age: data.Age,
                        gender: data.Gender === 1 ? t('common.male') : data.Gender === 2 ? t('common.female') : t('common.notDefined')
                    }}/>
            }
            <div>
            </div>
        </>
    )
};

export default SubheaderPatientTemplate;
