import React, {useState} from 'react';
import InfoIcon from "../../../../../shared/UI components/InfoIcon";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const SubheaderProvisionTemplate = ({data}: { data: any }) => {
    const {t} = useLanguageForShared()

    const elements = [...(data?.Id ?[data.Id] : []), ...(data?.DocumentNumber ? [data.DocumentNumber] : [])]
    return (
        <>
            {data &&
                <>
                    {data &&
                        <InfoIcon label={elements.join(' - ')} value={t('lsi.provision.provision')} icon={'provision'}/>
                    }

                    <div></div>
                </>
            }
        </>
    )
};

export default SubheaderProvisionTemplate;
