import React, {useState} from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultPatientInvoicesSorted} from "./PatientInfoUIHelpers"
import {
    PatientInvoiceActionTableColumnFormatter
} from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {tableSortByDate} from "../../../component/table/tableSort";
import {useDispatch} from "react-redux";
import "../../../assets/css/table.css";
import {
    CurrencyColumnFormatter,
    DateColumnFormatter,
    MonthYearColumnFormatter
} from "../../../component/table/column-formatters/TypeColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientInvoicesTable({patientInvoicesList, id, history}) {
    const {t} = useLanguageForShared()

    const [stateTable, setStateTable] = useState({field: null, order: null});
    const [index, setIndex] = useState(0);

    const dispatch = useDispatch();

    const handleCustomSort = (field, order) => {
        if (index !== 0) {
            if (order === "desc") {
                order = "asc";
            }
        }
        setStateTable(
            {
                field: 'MonthNumber',
                order: order === "asc" ? "desc" : "asc"
            }
        );
        setIndex(index === 0 ? index + 1 : index - 1);
    }

    const columns = [
        {
            dataField: "PatientNumber",
            text: t('lsi.table.patientId'),
            sort: true,

        },
        {
            dataField: "documentNumber",
            text: t('lsi.table.documentNumber'),
            sort: true,

            onSort: handleCustomSort
        },
        {
            dataField: "treatmentDescription",
            text: t('lsi.table.treatmentDescription'),
            sort: true,

        },
        {
            dataField: "shipmentDate",
            text: t('lsi.table.shippingDate'),
            sort: true,



            sortFunc: tableSortByDate,
            formatter: DateColumnFormatter,
        },
        {
            dataField: "amount",
            text: t('lsi.table.amount'),
            sort: true,

            sortFunc: tableSortByDate,
            formatter: CurrencyColumnFormatter,
        }
    ];

    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table-head-custom table-vertical-center"
                bootstrap4
                keyField="Id"
                data={patientInvoicesList}
                columns={columns}
                defaultSorted={defaultPatientInvoicesSorted}
                noDataIndication={t('lsi.noDataAvailable')}
                sort={{
                    dataField: stateTable.field,
                    order: stateTable.order
                }}
            >
            </BootstrapTable>
        </>
    );
}
