import React, { useCallback, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

function Dropzone(_props: {
  onDrop: (acceptedFiles: any) => void
  children?: React.ReactChild
  options?: DropzoneOptions
  translationError?: any
}) {
  const [show, setShow] = useState(true)

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      _props.onDrop(acceptedFiles)
      setShow(false)
    },
    [_props]
  )
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    useFsAccessApi: false,
    ..._props.options,
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <ul>
        {errors.map((e) => (
          <li key={e.code}>
            {_props.translationError
              ? _props.translationError[e.code]
              : e.message}
          </li>
        ))}
      </ul>
    )
  })

  return (
    <>
      <div className="content bordered">
        <div className="dropZone" {...getRootProps()}>
          <input {...getInputProps()} />
          <div>{_props.children}</div>
        </div>
        {fileRejectionItems}
      </div>
    </>
  )
}

export default Dropzone
