import React from 'react';
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";

const PatientTitleModal = ({data}: { data: any }) => {
    const {t} = useLanguageForShared()
    return (
        <h3 className={'infoModal'}>
            {t('notify.patient')} <b>{data?.first_name} {data?.last_name}</b>
        </h3>
    );
};

export default PatientTitleModal;