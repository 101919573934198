import {checkIsActive} from "../../_helpers";
import HeaderComponent from "../../../libraries/header/HeaderComponent";
import React, {useEffect} from "react";
import {OrganizationModel} from "../../../libraries/header/models/Organization.model";
import {HeaderMenuModel} from "../../../libraries/header/models/Header.model";
import {useSelector} from "react-redux";
import {selectTemplate} from "../../../redux/menuSlice";
import {TemplateModel} from "../../../libraries/header/models/TemplateModel";
import SubheaderPatientTemplate from "./subHeaderTemplates/patient/SubheaderPatientTemplate";
import userManager from "../../../app/utils/oidcUserManager";
import SubheaderProvisionTemplate from "./subHeaderTemplates/provision/SubheaderProvisionTemplate";
import SubheaderDeviceTemplate from "./subHeaderTemplates/device/SubheaderDeviceTemplate";
import {useHistory} from "react-router-dom";
import {selectPermissions, selectSelectedCustomer} from "../../../app/actions/users/usersSlice";

interface HeaderWrapperModel {
    logout: () => void;
    user: any;
    changeOrganization?: (id: any) => void;
    currentOrganization?: OrganizationModel;
    organizations?: OrganizationModel[],
    logo: any
}

const HeaderWrapper: React.FC<HeaderWrapperModel> = ({
                                                         logout,
                                                         user,
                                                         changeOrganization,
                                                         organizations,
                                                         currentOrganization,
                                                         logo
                                                     }) => {
    const history = useHistory();
    const selectedCustomer = useSelector(selectSelectedCustomer);
    const permissions = useSelector(selectPermissions)
    console.log('headerWrapper')
    console.log(permissions, selectedCustomer?.CustomerFeatures)
    useEffect(() => {
        window.scrollTo(0, 0);
        history.listen(route => {
            setTimeout(() => {
                window.scrollTo(0, 0);
            })
        })
    }, [])
    const goProfile = () => {
        window.open(userManager.settings.authority + "/Identity/Account/Manage", '_blank');
    }

    const voices: HeaderMenuModel[] = [
        {
            name: 'patients',
            url: '/pazienti'
        },
        {
            name: 'services',
            url: '/prestazioni'
        },
        {
            name: 'devices',
            url: '/dispositivi'
        },
        {
            name: 'billing',
            children: [
                {
                    name: 'costs',
                    url: '/costi'
                },
                {
                    name: 'invoices',
                    url: '/fatture'
                }
            ]
        },
        {
            name: 'report',
            url: '/report'
        },
    ].filter(ele => permissions.includes(ele.name))


    const template = useSelector(selectTemplate);
    const templates: any = {
        patient: (data: TemplateModel) => {
            return <SubheaderPatientTemplate data={data}/>
        },
        provision: (data: TemplateModel) => {
            return <SubheaderProvisionTemplate data={data}/>
        },
        device: (data: TemplateModel) => {
            return <SubheaderDeviceTemplate data={data}/>
        }
    }
    const currentPage = voices.reduce((acc:any, voice) => acc? acc : (checkIsActive(window.location, voice.url)? voice.name : voice?.children?.find(ele => checkIsActive(window.location, ele.url))?.name), undefined);

    return <HeaderComponent logout={logout} languages={[]} voices={voices} user={user}
                            changeOrganization={changeOrganization}
                            organization_id={currentOrganization?.Id}
                            subHeaderHtml={template && templates[template.name] && templates[template.name](template.data)}
                            //innerTemplateSubNav={template && templates[template.name] && templates[template.name](template.data)}

                            currentPage={voices.find(voice => checkIsActive(window.location, voice.url))?.name as string}
                            currentOrganization={currentOrganization}
                            organizations={organizations}
                            goProfile={goProfile}
                            logo={logo}
                            backHostnames={process.env.REACT_APP_URL_SG ? [process.env.REACT_APP_URL_SG] : []}
                            editProfile={true}/>
}

export default HeaderWrapper
