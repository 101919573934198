import React from 'react';
import NoteContentLayoutL from "./NoteContentLayoutL";
import DateManageL from "./DateManageL";
import {formDataDateL, formDataNoteL, formDataOperatorL} from "../formDataL";
import SubjectManage from "./SubjectManage";
import ToolbarRightL from "./ToolbarRightL";
import NoteManage from "./NoteManage";
import FooterManageL from "./FooterManageL";
import LabelValue from "../../../../../shared/custom/labelValue/LabelValue";
import ListFilesL from "./ListFilesL";
import AddFileL from "../../event-store/AddFileL";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import Tag from "../../../../../shared/custom/tag/Tag";
import configuration from "../../../../../shared/configuration";
import {useDateCustom} from "../../../../../shared/custom_use/useDateCustom";

const NoteItemTemplateL = ({
                               note,
                               isEditMode,
                               newNote,
                               files,
                               changeValue,
                               dataForm,
                               errors,
                               formDataSubject,
                               deleteNote,
                               editNote,
                               setIsAddFile,
                               saveNote,
                               cancelEdit,
                               isAddFile,
                               deleteFile,
                               uploadFile,
                               removeFileInListHandler,
                               isSubmit,
                               readonly,
                               downloadAction,
                               diaryTemplate
                           }: any) => {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()

    return (
        <NoteContentLayoutL
            dateTemplate={
                <DateManageL
                    date={note?.Date}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    dataForm={dataForm}
                    errors={errors}
                    formData={formDataDateL(t)}
                    isSubmit={isSubmit}
                />
            }
            subjectTemplate={
                <SubjectManage
                    subject={note?.SubjectText}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    dataForm={dataForm}
                    errors={errors}
                    formData={formDataSubject}
                    isSubmit={isSubmit}
                />
            }
            toolbarRightTemplate={
                <>
                    {(!note?.Deleted && !readonly) && (
                        <ToolbarRightL
                            deleteNote={deleteNote}
                            editNote={editNote}
                            uploadHandler={() => setIsAddFile(true)}
                            saveHandler={saveNote}
                            cancelHandler={cancelEdit}
                            isEditMode={isEditMode}
                            isAddFile={isAddFile}
                            note={note}
                        />
                    )}
                    {note?.Deleted && <div className={'mt-3'}>
                        <Tag label={t('note.deleted')} bgColor={configuration.theme.grayLight}/>
                    </div>}
                </>
            }
            notesTemplate={
                <NoteManage
                    note={note?.Note}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    dataForm={dataForm}
                    errors={errors}
                    formData={formDataNoteL(t)}
                    isSubmit={isSubmit}
                />
            }
            operatorTemplate={
                <FooterManageL
                    operator={note?.Operator}
                    isEditMode={isEditMode}
                    changeValue={changeValue}
                    dataForm={dataForm}
                    errors={errors}
                    formData={formDataOperatorL(t)}
                    isSubmit={isSubmit}
                />
            }
            lastUpdateTemplate={<LabelValue label={t('note.lastEdited')}
                                            value={dateFormatGGmmmYYYYhhMM(note?.LastUpdate)} inline
                                            valueClass={'sm'}/>}
            listFileTemplate={
                <>
                    <ListFilesL
                        downloadAction={downloadAction}
                        attachments={note?.Attachments}
                        deleteItemHandler={deleteFile}
                        isEditMode={isEditMode}
                        newNote={newNote}
                        files={files}
                        setIsAddFile={setIsAddFile}
                        isAddFile={isAddFile}
                        removeFileInListHandler={removeFileInListHandler}
                        note={note}
                    />
                    {isAddFile && (
                        <AddFileL
                            addFile={(file) => uploadFile(file)}
                            onClose={() => setIsAddFile(false)}
                            diaryTemplate={diaryTemplate}
                        />
                    )}
                </>
            }
            isEditMode={isEditMode}
            diaryTemplate={diaryTemplate}
        />
    );
};

export default NoteItemTemplateL;
