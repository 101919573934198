import React from "react";
import {ChartCustom} from "../../../../component/chartcustom/ChartCustom";
import useO2ActiveState from "../o2activestate/useO2ActiveState";
import configuration from "../../../../../shared/configuration";


const PatientsTreatmentStatus = ({states = []}) => {

    const {dataTable, dateTicks} = useO2ActiveState(states);
    const optionAdd = {
        colors: [configuration.theme.series.success170_hex, configuration.theme.series.alert170_hex, configuration.theme.series.s870_hex, configuration.theme.series.s700_hex],
        seriesType: "bars",
        series: {3: {type: "line"}},
    }

    const vAxisAdd = {
        ticks: dateTicks
    }

    const formatters = dataTable[0].slice(1).map((ele, i) => (
        {
            type: "NumberFormat",
            column: i + 1,
            options: {
                decimalSymbol: ',',
                groupingSymbol: '.'
            }
        }
    ))
    return <ChartCustom dataTable={dataTable}
                        options={optionAdd} chartType="ComboChart" vAxis={vAxisAdd}
                        formatters={formatters}/>
}

export default React.memo(PatientsTreatmentStatus)
