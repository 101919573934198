import React, {useEffect, useMemo} from "react";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {Chart} from "react-google-charts";
import * as actions from "../../../actions/patients/patientsActions";
import {usePatientsUIContext} from "../PatientsUIContext";
import {getTicks} from "../../../utils/utility";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import {selectSelectedCustomer} from "../../../actions/users/usersSlice";

export function PatientsChart() {
    const {t} = useLanguageForShared()
    const selectedCustomer = useSelector(selectSelectedCustomer)
    const patientsUIContext = usePatientsUIContext();
    const patientsUIProps = useMemo(() => {
        return {
            queryParams: patientsUIContext.queryParams,
        };
    }, [patientsUIContext]);

    // Getting curret state of patients list from store (Redux)
    const {currentState} = useSelector(
        (state) => ({currentState: state.patients}),
        shallowEqual
    );

    const {entitiesChart} = currentState;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchPatientsChart(patientsUIProps.queryParams));
        charts();
    }, [patientsUIProps.queryParams, selectedCustomer]);

    function charts() {
        if (entitiesChart) {
            let graphs = [];
            let colors = [configuration.theme.series.s770_hex, configuration.theme.series.s870_hex, configuration.theme.series.s170_hex];
            let colorIndex = 0;
            let chartIndex = 0;

            entitiesChart.forEach((element) => {
                let dataTable = [[t('lsi.chart.treatment'), t('lsi.chart.patients')]];

                let maxValue = 0;

                element.values.forEach((el) => {
                    dataTable.push([el.prescriptionName, el.count]);
                    if (el.count > maxValue) {
                        maxValue = el.count;
                    }
                });

                let graphColors = [];

                if (colors[colorIndex]) {
                    graphColors = [colors[colorIndex]];
                } else {
                    colorIndex = 0;
                    graphColors = [colors[colorIndex]];
                }

                let monthYear = element.values[0].monthName + " " + element.values[0].year;

                let vticks = getTicks(maxValue, 4);

                const chartOptions = {
                    colors: graphColors,
                    fontName: configuration.theme.fontFamily,
                    fontSize: 12,
                    title: monthYear,
                    titleTextStyle: {
                        italic: false,
                        fontSize: 18,
                        bold: false,
                        color: configuration.theme.primaryDark,
                    },
                    chartArea: {
                        width: "100%",
                        left: 60,
                        top: 40,
                        bottom: 70
                    },
                    bar: {
                        groupWidth: 40
                    },
                    hAxis: {
                        title: t('lsi.chart.treatment'),
                        viewWindowMode: "pretty",
                        titleTextStyle: {
                            italic: false,
                            color: configuration.theme.primaryDark,
                        },
                        textStyle: {
                            italic: false,
                            color: configuration.theme.primaryDark,
                        },
                        slantedText: false,
                    },
                    vAxis: {
                        title: "",
                        ticks: vticks,
                        titleTextStyle: {
                            italic: false,
                            color: configuration.theme.primaryDark,
                        },
                        textStyle: {
                            italic: false,
                            color: configuration.theme.primaryDark,
                        },
                        baselineColor: "#F2F2F2",
                        gridlines: {
                            color: "#F2F2F2"
                        }
                    },
                    legend: {
                        position: "none",
                    }
                };

                graphs.push(
                    <Chart chartType="ColumnChart" height={200} data={dataTable} options={chartOptions}
                           key={"chart" + chartIndex}/>
                );

                colorIndex++;
                chartIndex++;
            });

            return graphs;
        } else {
            return (
                <span>{t('lsi.noDataAvailable')}</span>
            );
        }
    }

    return (
        charts()
    );
}
