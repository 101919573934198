import React from "react";
import ProgressStats from "../ProgressStats";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardPercentageAssistance = ({percentageTreatments}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator minHeight={"250px"} maxHeight={"250px"} view={percentageTreatments}
                                    title={t('lsi.ventiloTreatmentPercentageAssistence')}>
            {percentageTreatments && <ProgressStats
                active={percentageTreatments.patientsActive}
                used={percentageTreatments.patientsInAssistance}
                labelTitle={t('lsi.patientInAssistence')}
                labelActive={t('lsi.actives')}
                labelUsed={t('lsi.inAssistence')}
            />}
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardPercentageAssistance);
