import React from "react";
import userManager from "../../../utils/oidcUserManager";

function Login() {

  const onLoginButtonClick = () => {
      userManager.signoutRedirect();
  }

  return (
    <div className="login-form login-signin">
      <div className="text-center mb-10 mb-lg-20">
      </div>
      <div className="form-group row">
        <div className="col-lg-4">
        </div>
        <div className="col-lg-4 text-center">
          <button
            id="kt_login_signin_submit"
            //type="submit"
            //disabled={isSubmitting}
            onClick={onLoginButtonClick}
            className={`btn btn-primary fw-bold px-4 py-4 my-3`}
          >
            Cambia utente
          </button>
        </div>
        <div className="col-lg-4">

        </div>
      </div>
    </div>
  );
}

export default Login;
