import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../../shared/configuration";
import BtnDefault from "../../../../../../shared/bootstrap/button/default/BtnDefault";

export default function ListFilesReadL({
                                           files,
                                           removeFileInList,
                                       }: {
    files?: any[]
    removeFileInList: (file: any) => void
}) {
    const {t} = useLanguageForShared()

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="row list-files">
                        <>
                            {files &&
                                files.map((file, i) => (
                                    <div key={i} className="file-item d-flex align-items-center mb-2">
                                        <div className="name-file text-primary">
                                            {file.name || file.file.name}.{file.FileType}
                                        </div>
                                        <BtnDefault
                                            icon="trash"
                                            onClick={() => removeFileInList(file)}
                                            title={t('action.delete')}
                                            tintColor={configuration.theme.primary}
                                        ></BtnDefault>
                                    </div>
                                ))}
                            {/*                            {!isAddFile && (
                                <BtnSecondary
                                    className="outline pull-left"
                                    onClick={setIsAddFileHandler}
                                    title={t('note.action.addFile')}
                                >
                                    {t('note.newNote.addFile')}
                                </BtnSecondary>
                            )}*/}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
