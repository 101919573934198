import React from 'react'
import BtnDefault from '../../../bootstrap/button/default/BtnDefault'

export default function SubHeading({
  title,
  icon,
  rightTemplate,
  clickIcon,
}: {
  title: string
  icon?: string
  rightTemplate?: React.ReactNode
  clickIcon?: () => void
}) {
  return (
    <div className="subHeading">
      <h4 className="subHeading-title">
        {title}
        <div>
          {rightTemplate
            ? rightTemplate
            : icon && (
                <BtnDefault
                  icon={icon}
                  className="btn-xs"
                  onClick={clickIcon}
                ></BtnDefault>
              )}
        </div>
      </h4>
    </div>
  )
}
