import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/table.css";
import { DeviceLastPatientAssignedActionTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { useDispatch } from "react-redux";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function DeviceLastPatientAssignedTable({ deviceLastPatient, history }) {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "Nominative",
            text: t('lsi.table.nominative'),
        },
        {
            dataField: "FiscalCode",
            text: t('lsi.table.fiscalCode'),
        },
        {
            dataField: "District",
            text: t('lsi.table.district'),
        },
        {
            dataField: "DeliveryDate",
            text: t('lsi.table.deliveryDate'),
        },
        {
            dataField: "PickUpDate",
            text: t('lsi.table.pickUpDate'),
        },
        {
            isDummyField: true,
            dataField: "",
            text: t('lsi.table.actions'),
            formatter: DeviceLastPatientAssignedActionTableColumnFormatter,
            formatExtraData: {
                history,
                dispatch,
                title: t('lsi.sendNotification'),
            },
            classes: "pe-0",
            headerClasses: "pe-3",
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (e.target.localName === "td") {
                history.push(`/pazienti/${deviceLastPatient.length > 0 ? deviceLastPatient[0].Id : ""}/dettaglio`)
            }
        }
    }

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-hover table-vertical-center"
            bootstrap4
            keyField="Id"
            data={deviceLastPatient}
            columns={columns}
            noDataIndication={t('lsi.noDataAvailable')}
            selectRow={selectRow}
        >
        </BootstrapTable>
    );
}