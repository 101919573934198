import React from 'react'
import {ValueName} from "../../../../../shared/utils/forms/models/DataForm";
import {FormElements} from "../../../../../shared/bootstrap/form/FormElements";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import LabelValue from "../../../../../shared/custom/labelValue/LabelValue";
import {useDateCustom} from "../../../../../shared/custom_use/useDateCustom";

export default function DateManageL({
                                        date,
                                        isEditMode,
                                        formData,
                                        dataForm,
                                        changeValue,
                                        errors,
                                        isSubmit
                                    }: {
    date: string
    isEditMode: boolean
    formData: any
    dataForm: any
    changeValue: ({value, name}: ValueName) => void
    errors: any
    isSubmit?: boolean

}) {
    const {t} = useLanguageForShared()
    const {data: {dateFormatGGmmmYYYYhhMM}} = useDateCustom()
    const dateItem = dateFormatGGmmmYYYYhhMM(date)
    return (
        <>
            {isEditMode ? (
                <div>
                    <FormElements
                        structure={formData}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}
                        isSubmit={isSubmit}
                    />
                </div>
            ) : (
                <div className="">
                    <LabelValue label={t('note.noteFrom')} value={dateItem} inline valueClass={'sm'}/>
                </div>
            )}
        </>
    )
}
