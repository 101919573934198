import React from 'react'
import { Attachment } from '../../../models/ClinicalDiaryItemModel'
import BtnDanger from '../../../../../shared/bootstrap/button/danger/BtnDanger'
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../shared/configuration";
import {IconSVG} from "../../../../../shared/custom/icon/IconSVG";

export default function FileItemManageV({
  attachment,
  isEditMode,
  deleteHandler,
  downloadAction,
}: {
  attachment: Attachment
  isEditMode: boolean
  deleteHandler?: () => void
  downloadAction: (data: any) => void
}) {
  const {t} = useLanguageForShared()

  const downloadHandler = (event: any) => {
    event.preventDefault()
    downloadAction(attachment)
  }
  return (
    <div className="file-item mt-6">
      <IconSVG icon={'document'} size={'20px'} tintColor={configuration.theme.primaryDark}/>
      <div className="name-file">
        <a href="#" target="_blank" onClick={downloadHandler}>
          {attachment.FileName}.{attachment.FileType}
        </a>
      </div>
      {isEditMode && (
        <BtnDanger
          className="outline ml-12"
          icon="trash"
          onClick={deleteHandler}
          title={t('action.delete')}
          tintColor={configuration.theme.danger_color}
        ></BtnDanger>
      )}
    </div>
  )
}
