import {FormElements} from "../../../../../shared/bootstrap/form/FormElements";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

function EventStepData({dataForm, changeValue, errors, isSubmit, subjectTypes}: any) {

    const {t} = useLanguageForShared()

    const formStructure = [
        {
            name: 'Date',
            type: 'input',
            label: t('note.newNote.date'),
            display: {
                form: 'row',
                label: 'col-sm-12 col-md-3 text-right text-sm-left',
                child: 'col-sm-12 col-md-4',
            },
            dataElement: {
                type: 'date',
                placeholder: t('note.newNote.date'),
            },
            extra: {
                type: 'datePicker',
                settings: {
                    timePicker: true
                }
            }
        },
        {
            name: 'Subject',
            type: 'select',
            label: t('note.newNote.subject'),
            options: subjectTypes.map((ele: any) => ({
                ...ele,
                label: t('note.object.' + ele.label),
            })),
            display: {
                form: 'row',
                label: 'col-sm-12 col-md-3 text-right text-sm-left',
                child: 'col-sm-12 col-md-8',
            },
            dataElement: {
                type: 'text',
                placeholder: t('note.newNote.subject'),
            },
        },
        {
            name: 'Note',
            type: 'textarea',
            label: t('note.newNote.note'),
            display: {
                form: 'row',
                label: 'col-sm-12 col-md-3 text-right text-sm-left',
                child: 'col-sm-12 col-md-8',
            },
            dataElement: {
                type: 'text',
                placeholder: t('note.newNote.note'),
                rows: 10,
            },
        },
        {
            name: 'Operator',
            type: 'input',
            label: t('note.newNote.operator'),
            display: {
                form: 'row',
                label: 'col-sm-12 col-md-3 text-right text-sm-left',
                child: 'col-sm-12 col-md-8',
            },
            dataElement: {
                type: 'text',
                placeholder: t('note.newNote.operator'),
            },
        },
    ]

    return (
        <div>
            <div
                className={"heading-title"}>{t('note.newNote.insertData')}</div>

            <FormElements
                structure={formStructure}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
                isSubmit={isSubmit}
            />

            {/* {formStructure.map((ele: any, i: number) => (
        <div className="col-md-12" key={ele.name}>
          <FormGroupCustom
            structure={ele}
            errors={errors}
            value={dataForm[ele.name]}
            isSubmit={isSubmit}
            changeValue={changeValue}
          />
        </div>
      ))}*/}
        </div>
    )
}

export default EventStepData
