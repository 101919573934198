import React from "react";
import {useHistory} from "react-router-dom";
import {PatientRegistry} from "../../patients/PatientInfo/PatientRegistry";

export const ProvisionPatientInfo = ({provisionInfo}) => {

    let patientRegistry = provisionInfo ? provisionInfo.Patient : undefined;

    const history = useHistory();

    return (
        <>
            <PatientRegistry patientRegistry={patientRegistry} history={history} />
        </>
    );
}
