import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
//import { initialServiceIndicatorsFilter } from "./ServiceIndicatorsUIHelpers";
import moment from "moment"

const ServiceIndicatorsUIContext = createContext();

export function useServiceIndicatorsUIContext() {
    return useContext(ServiceIndicatorsUIContext);
}

export function ServiceIndicatorsUIProvider({ serviceIndicatorsUIEvents, children }) {

    //prende i valori dei filtri nel localstorage se presenti
    let serviceIndicatorsSelectedFilter = JSON.parse(localStorage.getItem('serviceIndicatorsSelectedFilters-...'));

    if (!serviceIndicatorsSelectedFilter) {
        serviceIndicatorsSelectedFilter = {
            filter: {
                MonthYear: (moment().get("year") - 1) + "/" + (moment().get("month") + 1) + " - " + moment().get("year") + "/" + (moment().get("month"))
            }
        };
    }

    //viene settato nello stato del componente il valore di default dei filtri
    const [queryParams, setQueryParamsBase] = useState(serviceIndicatorsSelectedFilter);
    //funzione che viene richiamata per settare i filtri
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }
            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }
            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams
    };

    return <ServiceIndicatorsUIContext.Provider value={value}>{children}</ServiceIndicatorsUIContext.Provider>;
}
