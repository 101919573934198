import React from 'react'
import { selectErrors, removeError } from './errorSlice'
import { useDispatch, useSelector } from 'react-redux'
import ToastListError from '../../custom/errors/toast/toastListError/ToastListError'

const ErrorManagement = () => {
  const list = useSelector(selectErrors)
  const dispatch = useDispatch()
  return (
    <ToastListError
      list={list}
      closeAction={(id) => dispatch(removeError(id))}
    />
  )
}

export default ErrorManagement
