import { t } from 'i18next'
import DatePicker from '../../../../shared/external_lib/datePickerRange/DatePickerRange'
import { FiltersDataModel } from '../../models/FiltersDataModel'
import { diaryFilterData } from './diaryFilterData'
import moment from 'moment'
import {ValueName} from "../../../../shared/utils/forms/models/DataForm";
import {FormElements} from "../../../../shared/bootstrap/form/FormElements";
import useLastMeasure from "../../../../shared/external_lib/datePickerRange/useLastMeasure";
import {useDateCustom} from "../../../../shared/custom_use/useDateCustom";

interface DiaryFilterModel {
  filters: FiltersDataModel
  setFilters: (data: FiltersDataModel) => void
  lastAvailableMeasureDate?: null | string
}

function DiaryFilter({ filters, setFilters, lastAvailableMeasureDate=null }: DiaryFilterModel) {
  const formData: any = diaryFilterData()
  const {data: {dateFormatGGmmmYYYY}} = useDateCustom()
  const changeValue = ({ value, name }: ValueName) => {
    setFilters({ ...filters, [name]: value })
  }

  const datePickerHandler = (start: any, end: any, label: any) => {

/*
    localStorage.setItem(`date-patient-${httpData.other.patientId}`, JSON.stringify({
      start: start?.startOf('day').toISOString(),
      end: end?.endOf('day').toISOString(),
    }))
*/

    setFilters({
      ...filters,
      startDate: start.startOf('day'),
      endDate: end.endOf('day')
    })
  }

  const {
    show,
    lastDateAvailable,
    lastDateAvailableWeek,
    lastDateAvailableMonth
  } = useLastMeasure(lastAvailableMeasureDate);

  return (
    <div className="row">
      <form className="d-flex w-100">
        <div className="w-100 ">
        <FormElements
            structure={formData}
            dataForm={filters}
            errors={{}}
            changeValue={changeValue}
        />
        </div>
        <div className="col-md-auto">
          <div className="btn-select-date">
            {show &&  <DatePicker
              settings={{
                ...filters,
                ranges: {
                  [t('calendarPiker.today')]: [moment(), moment()],
                  [t('calendarPiker.yesterday')]: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                  ],
                  [t('calendarPiker.last7dayAvailables')]: [
                    lastDateAvailableWeek,
                    lastDateAvailable,
                  ],
                  [t('calendarPiker.last30dayAvailables')]: [
                    lastDateAvailableMonth,
                    lastDateAvailable,
                  ],
                  [t('calendarPiker.last7day')]: [
                    moment().subtract(6, 'days'),
                    moment(),
                  ],
                  [t('calendarPiker.last30day')]: [
                    moment().subtract(29, 'days'),
                    moment(),
                  ],
                  /* 'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ], */
                },
              }}
              handleCallback={datePickerHandler}
            >
              <button type="button" className="sh btn btn-primary btn-date">
                <span className="glyphicon glyphicon-calendar mr-6"></span>
                {t('calendarPiker.period').toUpperCase()} :{' '}
                {`${dateFormatGGmmmYYYY(
                  filters.startDate
                ).toLocaleUpperCase()} - ${dateFormatGGmmmYYYY(
                  filters.endDate
                ).toLocaleUpperCase()}`}
                <span className="glyphicon glyphicon-chevron-down ml-6"></span>
              </button>
            </DatePicker>}
          </div>
        </div>
      </form>
    </div>
  )
}

export default DiaryFilter
