// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {mapStatusColor, PatientStatusCssClasses, PatientStatusTitles} from "../../../pages/patients/PatientsUIHelpers";
import {
    mapProvisionsColor,
    ProvisionStatusCssClasses,
    ProvisionStatusTitles
} from "../../../pages/provisions/ProvisionsUIHelpers"
import Tag from "../../../../shared/custom/tag/Tag";

export function StatusPatientsColumnFormatter(cellContent, row) {
    return (
        <Tag dataElement={{'data-test': 'status-'+row.Status}}  label={PatientStatusTitles()[row.Status]} bgColor={mapStatusColor()[PatientStatusCssClasses[row.Status]].bg}
             color={mapStatusColor()[PatientStatusCssClasses[row.Status]].color}/>
    );
}

export function ProvisionsStateColumnFormatter(cellContent, row) {
    if (row.Status !== undefined) {
        return <Tag label={ProvisionStatusTitles()[row.Status]}
                    bgColor={mapProvisionsColor()[ProvisionStatusCssClasses[row.Status]].bg}
                    color={mapProvisionsColor()[ProvisionStatusCssClasses[row.Status]].color}/>
    } else {
        return <Tag label={ProvisionStatusTitles()[row.ProvisionState]}
                    bgColor={mapProvisionsColor()[ProvisionStatusCssClasses[row.ProvisionState]].bg}
                    color={mapProvisionsColor()[ProvisionStatusCssClasses[row.ProvisionState]].color}/>
    }
    // const getLabelCssClasses = () => {
    //   return `label label-lg label-light-${
    //     ProvisionStatusCssClasses[row.Status]
    //     } label-inline`;
    // };
    // return (
    //   <span className={getLabelCssClasses()} style={{ width: "max-content" }}>
    //     {ProvisionStatusTitles[row.Status]}
    //   </span>
    // );
}

export function CostStatusPatientsColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-lg label-light-${PatientStatusCssClasses[row.PatientStatus]
        } label-inline`;
    };
    return (
        <span className={getLabelCssClasses()}>
      {PatientStatusTitles()[row.PatientStatus]}
    </span>
    );
}
