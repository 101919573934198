import React from 'react'
import RowBtnEditL from "./noteItemElement/RowBtnEditL";
import RowBtnReadL from "./noteItemElement/RowBtnReadL";

export default function ToolbarRightL({
                                          isEditMode,
                                          editNote,
                                          deleteNote,
                                          uploadHandler,
                                          saveHandler,
                                          cancelHandler,
                                          isAddFile,
                                          note
                                      }: {
    isEditMode: boolean
    editNote: () => void
    deleteNote: () => void
    uploadHandler: () => void
    saveHandler: () => void
    cancelHandler: () => void
    isAddFile: boolean
    note: any
}) {
    return (
        <div className="toolbar-right d-flex justify-content-end">
            {isEditMode ? (
                <RowBtnEditL
                    uploadHandler={uploadHandler}
                    saveHandler={saveHandler}
                    cancelHandler={cancelHandler}
                    isAddFile={isAddFile}
                />
            ) : (
                <RowBtnReadL
                    note={note}
                    editHandler={editNote} deleteHandler={deleteNote}/>
            )}
        </div>
    )
}
