import React from "react";
import { InvoicesUIProvider } from "../InvoicesUIContext";
import {InvoicesHome} from "./InvoicesHome";


export function InvoicesPage() {
  return (
    <InvoicesUIProvider>
      <InvoicesHome />
    </InvoicesUIProvider>
  );
}
