import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
// LayoutContext
import {useHtmlClassService} from "../_core/MetronicLayout";
// Import Layout components
import {Aside} from "./aside/Aside";
import {Footer} from "./footer/Footer";
import HeaderComponent from "../../../libraries/header/HeaderComponent";
import {useDispatch, useSelector} from "react-redux";
import userManager from "../../../app/utils/oidcUserManager";
import {actions} from "../../../app/modules/Auth/_redux/authRedux";
import {selectCustomersList, selectSelectedCustomer} from "../../../app/actions/users/usersSlice";
import {fetchCustomers, setCustomerId} from "../../../app/actions/users/usersActions";
import {checkIsActive} from "../../_helpers";
import {useLocation} from "react-router";
import FooterComponent from "../../../libraries/footer/FooterComponent";
import HeaderWrapper from "./HeaderWrapper";
import configuration from "../../../shared/configuration";
import {useHistory} from "react-router-dom";
import ErrorManagement from "../../../shared/redux/errors/ErrorManagement";

//import {StickyToolbar} from "./extras/StickyToolbar";

export function Layout({children}) {
    const uiService = useHtmlClassService();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user} = useSelector(state => state.auth);
    const selectedCustomer = useSelector(selectSelectedCustomer)
    const customerList = useSelector(selectCustomersList) || [];
    useEffect(() => {
        dispatch(fetchCustomers(user));
    }, [user]);
    const changeOrganization = (id) => {
        dispatch(setCustomerId(customerList.find(c => c.CustomerId === id)));
        history.push("/pazienti");
        // setTimeout(() => window.location.href = "/", 1000)
    }

    const logo = `${process.env.REACT_APP_LOAD_CONFIG}assets/images/logo/logo${"_lsi"}.svg`;
    const footerString = `${configuration?.external?.footerLabel || ""}`;

    const location = useLocation();

    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    const logoutHandler = () => {
        dispatch(actions.logout())
        userManager.signoutRedirect();
    }

    const currentOrganization = selectedCustomer
        ? selectedCustomer
        : customerList.length > 0
            ? (customerList.filter(e => {
                return e.CustomerId === localStorage.getItem("customerId")
            })[0] || customerList[0])
            : undefined
    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            {/*
            <HeaderMobile/>
*/}
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {layoutProps.asideDisplay && (<Aside/>)}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <HeaderWrapper
                            logout={logoutHandler} languages={[]} user={user}
                            changeOrganization={changeOrganization}
                            currentOrganization={currentOrganization ? {
                                Id: currentOrganization.CustomerId,
                                Name: currentOrganization.CustomerName,
                                Address: currentOrganization.CustomerAddress
                            } : undefined}
                            organizations={customerList.map(ele => ({
                                Id: ele.CustomerId,
                                Name: ele.CustomerName,
                                Address: ele.CustomerAddress
                            }))}
                            logo={logo}
                        />

                        {/*<Header/>*/}
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                        >
                            {/*{layoutProps.subheaderDisplay && <SubHeader/>}*/}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    <div className={layoutProps.contentContainerClasses}>
                                        {selectedCustomer && children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && selectedCustomer && {children}}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <ErrorManagement/>

                        <FooterComponent customerName={footerString}/>
                        {/*<Footer/>*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/*<QuickUser/>*/}
            {/*  <QuickPanel/>*/}
            {/*
            <ScrollTop/>
*/}
            {/* <StickyToolbar/> */}
            {/*end::Main*/}
            {/*
            <LayoutInit/>
*/}
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{selectedCustomer && children}</div>
    );
}
