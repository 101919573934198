import React, { useMemo, useEffect/*, useState*/ } from "react"
import { useServiceIndicatorsUIContext } from "../ServiceIndicatorsUIContext";
import { isEqual } from "lodash";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import moment from "moment";
import { CustomMonthPicker } from "../../../component/monthpicker/CustomMonthPicker";
import * as actions from "../../../actions/serviceIndicators/serviceIndicatorsActions";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const prepareFilter = (queryParams, values) => {
    const {
        filterByMonthYear
    } = values;

    const newQueryParams = { ...queryParams };
    const filter = {
        MonthYear: filterByMonthYear
    }

    newQueryParams.filter = filter;

    localStorage.setItem("serviceIndicatorsSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function ServiceIndicatorsFilters() {
    const {t} = useLanguageForShared()


    //prendo i valori di default
    const serviceIndicatorsUIContext = useServiceIndicatorsUIContext();
    const serviceIndicatorsUIProps = useMemo(() => {
        return {
            queryParams: serviceIndicatorsUIContext.queryParams,
            setQueryParams: serviceIndicatorsUIContext.setQueryParams,
        };
    }, [serviceIndicatorsUIContext]);

    const dispatch = useDispatch();

    //vengono applicati i filtri al submit della form
    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(serviceIndicatorsUIProps.queryParams, values);
        if (!isEqual(newQueryParams, serviceIndicatorsUIProps.queryParams)) {
            serviceIndicatorsUIProps.setQueryParams(newQueryParams);
        }
    };

    return (
        <Formik
            initialValues={{
                filterByMonthYear: serviceIndicatorsUIProps.queryParams.filter.MonthYear
            }}
            onSubmit={(values, formikBag) => {
                //resetto i filtri nel caso di modifica della data in quanto vengono ricaricati da 0 i filtri
                if (values.filterByMonthYear !== serviceIndicatorsUIProps.queryParams.filter.MonthYear) {
                    values = {
                        filterByMonthYear: values.filterByMonthYear
                    }
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
            }) => (
                    <Form onSubmit={handleSubmit} className="form form-label-right filter">
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <h6 className="fw-bolder mb-3">{t('lsi.provision.monthYear')}</h6>
                                <CustomMonthPicker
                                    value={
                                        {
                                            //valori di default
                                            from: { year: +values.filterByMonthYear.split(" - ")[0].split("/")[0], month: +values.filterByMonthYear.split(" - ")[0].split("/")[1] },
                                            to: { year: +values.filterByMonthYear.split(" - ")[1].split("/")[0], month: +values.filterByMonthYear.split(" - ")[1].split("/")[1] }
                                        }
                                    }
                                    minYear={{ min: moment().get("year") - 5 }}
                                    handleDismiss={(obj) => {
                                        //setto il valore del campo della form e faccio il submit della form
                                        setFieldValue("filterByMonthYear", obj.from.year + "/" + obj.from.month + " - " + obj.to.year + "/" + obj.to.month);
                                        handleSubmit();
                                    }}
                                />
                            </div>
                        </div>
                    </Form>
                )}
        </Formik>
    )
}
