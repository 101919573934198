import React from "react";
import O2State from "./o2State";
import CardLayoutServiceIndicator from "../card_layout/CardLayoutServiceIndicator";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

const CardO2State = ({states}) => {
    const {t} = useLanguageForShared()

    return (
        <CardLayoutServiceIndicator view={states && states.length} title={t('lsi.o2Treatment')}>
            <O2State states={states}/>
        </CardLayoutServiceIndicator>
    )
}

export default React.memo(CardO2State);
