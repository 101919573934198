import React, {useEffect, useMemo} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import {ServiceIndicatorsFilters} from "./ServiceIndicatorsFilters";
import CarriedOutPerformance from "./performance/CarriedOutPerformance";
import O2State from "./o2state/o2State";
import ProgressStats from "./ProgressStats";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCostForTreatments,
    selectExtraordinaryPerformance, selectO2ActiveStates, selectO2States,
    selectPercentageTreatments, selectVtdActiveStates
} from "../../../actions/serviceIndicators/serviceIndicatorsSlice";
import {useServiceIndicatorsUIContext} from "../ServiceIndicatorsUIContext";
import * as actions from "../../../actions/serviceIndicators/serviceIndicatorsActions";
import {EmptyMsg} from "./EmptyMsg";
import CardCostForTreatment from "./cost_treatment/CardCostForTreatment";
import CardO2ActiveState from "./o2activestate/CardO2ActiveState";
import CardPatientsTreatmentStatus from "./patients_trearment/CardPatientsTreatmentStatus";
import CardPercentageDelivery from "./percentage_delivery/CardPercentageDelivery";
import CardPercentageAssistance from "./percentage_assistance/CardPercentageAssistance";
import CardCarriedOutPerformance from "./performance/CardCarriedOutPerformance";
import CardO2State from "./o2state/CardO2State";


export const ServiceIndicatorsHome = ({history}) => {
    const costForTreatments = useSelector(selectCostForTreatments)
    const percentageTreatments = useSelector(selectPercentageTreatments)
    const extraordinaryPerformance = useSelector(selectExtraordinaryPerformance)
    const o2States = useSelector(selectO2States)
    const o2ActiveStates = useSelector(selectO2ActiveStates)
    const vtdActiveStates = useSelector(selectVtdActiveStates)
    //prendo i valori dei filtri
    const serviceIndicatorsUIContext = useServiceIndicatorsUIContext();
    const serviceIndicatorsUIProps = useMemo(() => {
        return {
            queryParams: serviceIndicatorsUIContext.queryParams
        };
    }, [serviceIndicatorsUIContext]);

    const dispatch = useDispatch();

    //al caricamento del componente lancio la query per caricarmi i dati e la funzione per generare il grafico
    useEffect(() => {
        dispatch(actions.fetchServiceIndicatorsCostForTreatment(serviceIndicatorsUIProps.queryParams))
        dispatch(actions.fetchServiceIndicatorsPercentageTreatments(serviceIndicatorsUIProps.queryParams))
        dispatch(actions.fetchServiceIndicatorsExtraordinaryPerformance(serviceIndicatorsUIProps.queryParams))
        dispatch(actions.fetchServiceIndicatorsO2States(serviceIndicatorsUIProps.queryParams))
        dispatch(actions.fetchServiceIndicatorsO2ActiveStates(serviceIndicatorsUIProps.queryParams))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceIndicatorsUIProps.queryParams, dispatch]);
    return (
        <div className="row layout-page" style={{zIndex: "1"}}>
            <div className="col-lg-2  filter-col">
                <Card className="card-sticky">
                    {/*<CardHeader title="Filtri" className="font-weight-bolder"/>*/}
                    <CardBody className="offcanvas-content">
                        <ServiceIndicatorsFilters/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10 card-lg-10 content-col">
                <div className="row">
                    <div className="col-lg-12 card-lg-12">
                        <CardCostForTreatment costs={costForTreatments}/>
                    </div>
                    <div className="col-lg-4 card-lg-4">
                        <CardPercentageDelivery percentageTreatments={percentageTreatments}/>
                    </div>
                    <div className="col-lg-8 card-lg-8">
                        <CardPatientsTreatmentStatus states={vtdActiveStates}/>
                    </div>
                    <div className="col-lg-4 card-lg-4">
                        <CardPercentageAssistance percentageTreatments={percentageTreatments}/>
                    </div>
                    <div className="col-lg-8 card-lg-8">
                        <CardCarriedOutPerformance extraordinaryPerformance={extraordinaryPerformance}/>
                    </div>
                    {o2ActiveStates?.length > 0 && <>
                        <div className="col-lg-12 card-lg-12">
                            <CardO2ActiveState states={o2ActiveStates}/>
                        </div>
                        <div className="col-lg-12 card-lg-12">
                            <CardO2State states={o2States}/>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    );
};
