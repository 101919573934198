import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import i18n, {updateI18n} from './shared/utils/languages/config/i18n'
import {setLanguageShared} from "./shared/utils/languages/setLanguageShared";

export interface GeneralSettingsModel {
  token?: string
  organizationId?: string
  patientId?: string
  language: string
}

const initialState: GeneralSettingsModel = {
  language: 'it',
}

export const generalSettingsSlice = createSlice({
  name: 'generalSettings',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setOrganizationId: (state, action: PayloadAction<string>) => {
      state.organizationId = action.payload
    },
    setPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
  },
})

export const {
  setToken,
  setOrganizationId,
  setLanguage,
  setPatientId
} = generalSettingsSlice.actions

export const setInitConfig = (): any => (dispatch: any) => {}

export const setLanguageAction = (language: string): any => (dispatch: any) => {
  updateI18n(language, () => {
    setLanguageShared(language);
    i18n.changeLanguage(language)
    dispatch(setLanguage(language))
  })
}

export const selectToken = (state: any) => state.generalSettings.token
export const selectPatientId = (state: any) => state.generalSettings.token
export const selectLanguage = (state: any) =>
  state.generalSettings.language

export default generalSettingsSlice.reducer
