import {FileDataModel} from '../../../../models/FileDataModel'
import BtnDanger from '../../../../../../shared/bootstrap/button/danger/BtnDanger'
import InputCustom from '../../../../../../shared/bootstrap/form/input/InputCustom'
import BtnSecondary from "../../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../../shared/configuration";

export default function RowInputFileV({
                                         deleteHandler,
                                         uploadFilesHandler,
                                         file,
                                         renameFileHandler,
                                     }: {
    deleteHandler: (file: FileDataModel) => void
    uploadFilesHandler: () => void
    file: FileDataModel
    renameFileHandler: (name: string) => void
}) {
    const {t} = useLanguageForShared()

    return (
        <div className="d-flex align-items-center w-100">
            <InputCustom
                value={file.name}
                changeValue={(e) => {
                    renameFileHandler(e)
                }}
                name="fileName"
                type="text"
            />
            <div className="d-flex">
                <BtnDanger
                    className="outline me-2 ms-2"
                    icon="trash"
                    onClick={() => deleteHandler(file)}
                    title={t('action.delete')}
                    tintColor={configuration.theme.danger_color}
                ></BtnDanger>
                <BtnSecondary
                    className="outline"
                    icon="check"
                    onClick={uploadFilesHandler}
                    title={t('action.confirm')}
                    tintColor={configuration.theme.primary}
                ></BtnSecondary>
            </div>
        </div>
    )
}
