import {getMaxValue, getTicks} from "../../../utils/utility";
import useCalcTick from "./useCalcTick";

export default function (dataTable, tickCount = 4){
    const maxValue =  getMaxValue(dataTable)
    const {dateTicks} = useCalcTick(maxValue, tickCount)
    return {
        maxValue,
        dateTicks
    }
}
