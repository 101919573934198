import React from 'react';
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";

const NoteTitleModal = ({data}: { data: any }) => {
    const {t} = useLanguageForShared()
    return (
        <h3 className={'infoModal'}>
            {t('notify.note')} <b>{data?.subject}</b>
        </h3>
    );
};

export default NoteTitleModal;