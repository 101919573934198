import React from "react";
import {mapStatusColor, PatientGenderTitles, PatientStatusCssClasses, PatientStatusTitles} from "../PatientsUIHelpers";
import Tag from "../../../../shared/custom/tag/Tag";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export const PatientRegistry = ({patientRegistry, history}) => {
    const {t} = useLanguageForShared()

    return (
        <>
            {
                patientRegistry
                    ? <div className={'list-key-value'}>
                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.firstName')}</span>
                                    <span
                                        className="font-size-sm">{patientRegistry.FirstName}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.lastName')}</span>
                                    <span
                                        className="font-size-sm">{patientRegistry.LastName}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.codes')}</span>
                                    {
                                        patientRegistry.PatientNumbers.length === 1
                                            ? <span className="font-size-sm">{patientRegistry.PatientNumbers[0]}</span>
                                            : <div>
                                                {
                                                    patientRegistry.PatientNumbers.map((el, index) => {
                                                        let patientCodes = [];
                                                        if (index === 0) {
                                                            patientCodes.push(<span className="font-size-sm"
                                                                                    key={'spanPatientNumber' + index}>{el + ", "}</span>)
                                                        } else {
                                                            if (index < patientRegistry.PatientNumbers.length - 1) {
                                                                patientCodes.push(
                                                                    <span className="font-size-sm"
                                                                          key={'spanPatientNumber' + index}><a onClick={() => {
                                                                        history.push("/pazienti/" + el + "/dettaglio")
                                                                    }} key={'aPatientNumber' + index}>{el + ","}</a></span>
                                                                );
                                                            } else {
                                                                patientCodes.push(
                                                                    <span className="font-size-sm"
                                                                          key={'spanPatientNumber' + index}><a onClick={() => {
                                                                        history.push("/pazienti/" + el + "/dettaglio")
                                                                    }} key={'aPatientNumber' + index}>{el}</a></span>
                                                                );
                                                            }
                                                        }
                                                        return patientCodes;
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.birthDate')}</span>
                                    <span
                                        className="font-size-sm">{patientRegistry.BirthDate}</span>
                                </div>
                            </div>

                        </div>

                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.fiscalCode')}</span>
                                    <span className="font-size-sm">{patientRegistry.FiscalCode}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.gender')}</span>
                                    <span
                                        className="font-size-sm">{PatientGenderTitles()[patientRegistry.Gender]}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.state')}</span>

                                    <Tag label={PatientStatusTitles()[patientRegistry.PatientState]}
                                         bgColor={mapStatusColor()[PatientStatusCssClasses[patientRegistry.PatientState]].bg}
                                         color={mapStatusColor()[PatientStatusCssClasses[patientRegistry.PatientState]].color}/>

                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.phone')}</span>
                                    <span className="font-size-sm">{patientRegistry.Phone}</span>
                                </div>
                            </div>

                        </div>

                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.email')}</span>
                                    <span className="font-size-sm">{patientRegistry.Email}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.district')}</span>
                                    <span className="font-size-sm">{patientRegistry.District}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.caregiver')}</span>
                                    <span className="font-size-sm">{patientRegistry.CareGiverNominative}</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.residenceAddress')}</span>
                                    <span className="font-size-sm">{patientRegistry.ResidenceAddress}</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.homeAddress')}</span>
                                    <span className="font-size-sm">{patientRegistry.HomeAddress}</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="col-xl-5 col-lg-5 d-flex align-items-center me-5 my-1">
                                <div className="d-flex flex-column">
                                    <span className="font-size-h6">{t('lsi.registry.otherAddresses')}</span>
                                    {
                                        patientRegistry.Addresses.length > 0
                                            ? patientRegistry.Addresses.map((el, index) => {
                                                return (
                                                    <div className="my-1" key={index}>
                                                        <span className="font-size-sm">{el}</span>
                                                    </div>
                                                )
                                            })
                                            : <span className="font-size-sm">-</span>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    : <span>{t('lsi.noDataAvailable')}</span>
            }
        </>
    );
}
