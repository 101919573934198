import React, { useMemo, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { isEqual } from "lodash";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import { useCostsUIContext } from "../CostsUIContext";
import { CustomMonthPicker } from "../../../component/monthpicker/CustomMonthPicker";
import moment from "moment";
import * as actions from "../../../actions/invoices/invoicesActions";
import {selectFilterCostsDistricts} from "../../../actions/invoices/invoicesSlice";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const prepareCostsFilter = (queryParams, values) => {
    const {
        filterByMonthYear,
        filterByDistricts
    } = values;

    const newQueryParams = { ...queryParams };

    const filter = {
        MonthYear: filterByMonthYear,
        District: filterByDistricts
    };

    newQueryParams.filter = filter;

    //setto nel local storage i filtri selezionati
    localStorage.setItem("costsSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function CostsFilters() {
    const {t} = useLanguageForShared()

    //prendo i filtri dal contesto e li setto in costsUIProps
    const costsUIContext = useCostsUIContext();
    const costsUIProps = useMemo(() => {
        return {
            queryCostParams: costsUIContext.queryCostParams,
            setQueryCostParams: costsUIContext.setQueryCostParams,
        };
    }, [costsUIContext]);

    const dispatch = useDispatch();
    const districts = useSelector(selectFilterCostsDistricts);

    //vengono applicati i filtri al submit della form
    const applyFilter = (values) => {
        const newQueryParams = prepareCostsFilter(costsUIProps.queryCostParams, values);
        if (!isEqual(newQueryParams, costsUIProps.queryCostParams)) {
            costsUIProps.setQueryCostParams(newQueryParams);
        }
    };

    return (
        <Formik
            initialValues={{
                filterByMonthYear: costsUIProps.queryCostParams.filter.MonthYear,
                filterByDistricts: costsUIProps.queryCostParams.filter.District || []
            }}
            onSubmit={(values, formikBag) => {
                //resetto i filtri nel caso di modifica della data in quanto vengono ricaricati da 0 i filtri
                if (values.filterByMonthYear !== costsUIProps.queryCostParams.filter.MonthYear) {
                    values = {
                        filterByMonthYear: values.filterByMonthYear,
                        filterByDistricts: []
                    }
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                  values,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="form form-label-right filter">
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.provision.monthYear')}:</h6>
                            <CustomMonthPicker
                                value={
                                    {
                                        //valori di default
                                        from: { year: +values.filterByMonthYear.split(" - ")[0].split("/")[0], month: +values.filterByMonthYear.split(" - ")[0].split("/")[1] },
                                        to: { year: +values.filterByMonthYear.split(" - ")[1].split("/")[0], month: +values.filterByMonthYear.split(" - ")[1].split("/")[1] }
                                    }
                                }
                                minYear={{ min: moment().get("year") - 5 }}
                                handleDismiss={(obj) => {
                                    //setto il valore del campo della form e faccio il submit della form
                                    setFieldValue("filterByMonthYear", obj.from.year + "/" + obj.from.month + " - " + obj.to.year + "/" + obj.to.month);
                                    handleSubmit();
                                }}
                            />
                        </div>
                    </div>
                    {
                        districts && districts.length > 0
                            ? <>

                                <div className="form-group row">
                                    <div className="col-lg-12" style={{overflow: "hidden"}}>
                                        <h6 className="fw-bolder mb-3">{t('lsi.table.district')}</h6>
                                        {
                                            districts.map((el) => {
                                                return (
                                                    <div key={"div" + el}>
                                                        <OverlayTrigger
                                                            key={"overlay" + el}
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    {el}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <label
                                                                key={"label" + el}
                                                                className="checkbox"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="filterByDistricts"
                                                                    id={el}
                                                                    key={"input" + el}
                                                                    value={el}
                                                                    checked={values.filterByDistricts.includes(el) ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            values.filterByDistricts = [
                                                                                ...values.filterByDistricts,
                                                                                el
                                                                            ]
                                                                        } else {
                                                                            values.filterByDistricts = values.filterByDistricts.filter((value) => {
                                                                                return value !== el
                                                                            });
                                                                        }
                                                                        handleSubmit();
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                {el}
                                                                <span/>
                                                            </label>
                                                        </OverlayTrigger>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                            : null
                    }
                </Form>
            )}
        </Formik>
    );
}
