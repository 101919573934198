import React from "react";
import {IconSVG} from "../../../shared/custom/icon/IconSVG";
import {components} from "react-select";


export function DropdownIndicator(props) {
    const CaretDownIcon = () => {
        return <IconSVG icon={'chevron-down'} size={'8px'}/>
    };


    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon/>
        </components.DropdownIndicator>
    );
}
