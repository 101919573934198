import React from "react";
import {Card, CardBody, CardHeader} from "../../../../../_metronic/_partials/controls";
import {EmptyMsg} from "../EmptyMsg";

const CardLayoutServiceIndicator = ({title, view, children, minHeight, maxHeight}) => {
    return (
        <Card className="" style={{"minHeight": minHeight ? minHeight : "auto", "maxHeight": maxHeight ? maxHeight : "auto"}}>
            <CardHeader title={title} className="font-weight-bolder"/>
            <CardBody className="offcanvas-content">
                {!!view ?
                    children : <EmptyMsg/>
                }
            </CardBody>
        </Card>
    )
}

export default CardLayoutServiceIndicator;
