import React from 'react';
import configuration from "../../shared/configuration";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import DiaryTemplateV from "./components/DiaryTemplateV";
import DiaryTemplateL from "./components/DiaryTemplateL";

const DiaryComp = ({
                       newNote,
                       setNewNote,
                       addNote,
                       setShowNotify,
                       diaries,
                       editNoteAction,
                       uploadFileHandler,
                       deleteFileAction,
                       showNotify,
                       readonly,
                       subjectTypes,
                       downloadAction,
                       diaryTemplate = 'default',
                       titleLabel,
                       actionLabel,
                   }: any) => {
    const {t} = useLanguageForShared()
    const templates: any = {
        templateV: () => <DiaryTemplateV
            deleteFileAction={deleteFileAction}
            downloadAction={downloadAction}
            newNote={newNote}
            setNewNote={setNewNote}
            addNote={addNote}
            setShowNotify={setShowNotify}
            diaries={diaries}
            editNoteAction={editNoteAction}
            uploadFileHandler={uploadFileHandler}
            showNotify={showNotify}
            readonly={readonly}
            subjectTypes={subjectTypes}/>,
    }
    return (
        <>
            {configuration.external.template && templates[configuration.external.template] ? templates[configuration.external.template]() :
                <DiaryTemplateL downloadAction={downloadAction} newNote={newNote}
                                readonly={readonly}
                                setNewNote={setNewNote}
                                addNote={addNote}
                                deleteFile={deleteFileAction}
                                diaries={diaries}
                                editNoteAction={editNoteAction}
                                uploadFileHandler={uploadFileHandler}
                                subjectTypes={subjectTypes}
                                diaryTemplate={diaryTemplate}
                                titleLabel={titleLabel}
                                actionLabel={actionLabel}
                />}
        </>
    );
};

export default DiaryComp;
