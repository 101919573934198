import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader, CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as patientActions from "../../../actions/patients/patientsActions";
import {PatientRegistry} from "./PatientRegistry";
import {PatientInfoMenu} from "./PatientInfoMenu";
import {PatientPrescriptions} from "./PatientPrescriptions";
import {PatientHistory} from "./PatientHistory";
import {PatientProvisionsTable} from "./PatientProvisionsTable";
import {PatientDevicesTable} from "./PatientDevicesTable";
import {PatientAccessoriesTable} from "./PatientAccessoriesTable";
import {PatientConsumablesTable} from "./PatientConsumablesTable";
import {PatientMedicinalsTable} from "./PatientMedicinalsTable";
import {PatientInvoicesTable} from "./PatientInvoicesTable";
import {PatientChart} from "./PatientChart";
import {PatientLoxChart} from "./PatientLoxChart";
import moment from "moment";
import {Prescriptions} from "./Prescriptions";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import {resetTemplateAction, setTemplate, setTemplateAction} from "../../../../redux/menuSlice";
import {PatientCostsTable} from "./PatientCostsTable";
import InfoMsg from "../../../../shared/custom/badge/info/InfoMsg";
import {PatientPrescription} from "./PatientPrescription";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientInfoHome({history, id}) {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    const {patient} = useSelector(
        (state) => ({
            patient: state.patients
        }),
        shallowEqual
    );

    const {patientById, /*patientInvoicesList,*/ patientChartData, patientLoxChartData, patientProvisionsNear} = patient
    const isPatientLoxGraph = patientById?.medicalPrescriptions?.length > 0 && !!patientById?.prescriptionDetails?.find(ele => ele?.treatmentFamily === 'O2');
    const isRequestPrescription = !!patientById?.latestPrescriptions?.length;
    const isPrescriptions = !!patientById?.prescriptionDetails?.find(ele => ele?.treatmentFamily === 'O2')

    useEffect(() => {
        dispatch(patientActions.fetchPatient(id));
        //dispatch(patientActions.fetchPatientInvoices(id));
        dispatch(patientActions.fetchPatientChart(id, moment().subtract(1, "year").format("YYYY-MM-DD") + "T00:00:00Z"));
        dispatch(patientActions.fetchPatientLoxChart(id, moment().subtract(1, "year").format("YYYY-MM-DD") + "T00:00:00Z"));
        dispatch(patientActions.fetchPatientProvisionsNear(id));
    }, [id, dispatch]);

    const goSGfeActive = () => {
        window.location.href = `${process.env.REACT_APP_URL_SG}paziente/${id}/active`
    }
    const goPatientSG = () => {
        window.location.href = `${process.env.REACT_APP_URL_SG}paziente/${id}`
    }
    useEffect(() => {
        patient && dispatch(setTemplateAction({name: 'patient', data: patientById}))
        return () => {
            patient && dispatch(resetTemplateAction())
        }
    }, [patient])
    return (
        <div className="row ae-page-content-container">
            <div className="col-lg-2 ae-sidebar-content">
                <Card className="card-sticky">
                    <CardBody>
                        <PatientInfoMenu isPatientLoxGraph={isPatientLoxGraph}
                                         isRequestPrescription={isRequestPrescription}/>
                    </CardBody>
                </Card>
            </div>
            <div className="col-lg-10">
                <h3 className={'title-page'}>{patientById ? `${t('lsi.patientProfile.label')} - ${patientById.FullName}` : ""} </h3>
                {!!patient?.patientById?.hasPendingOrders && <div className={'mt-3'}>
                    <InfoMsg clickAction={goPatientSG} label={t('lsi.patientProfile.requestProgress')}
                             variant={'info inline'}
                             iconColor={configuration.theme.info_color}/>
                </div>}

                <a name="patientGraph"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.summary12Month')} className="fw-bolder"/>
                    <CardBody>
                        <PatientChart patientChartData={patientChartData}
                                      patientProvisionsNear={patientProvisionsNear}/>
                    </CardBody>
                </Card>

                <a name="patientRegistry"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.registry')} className="fw-bolder">
                        <CardHeaderToolbar>
                            <BtnDefault
                                className="border-0 p-2 h-auto text-capitalize"
                                onClick={() => history.push(`/pazienti/${patientById.Id}/segnalazione`)}
                                icon={'priority_message'}
                                tintColor={configuration.theme.primary}
                                iconSize={'24px'}
                                disabled={!patientById}
                            >
                                {t('lsi.sendNotification')}
                            </BtnDefault>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PatientRegistry patientRegistry={patientById} history={history}/>
                    </CardBody>
                </Card>

                {isRequestPrescription && <>
                    <a name="patientRequestPrescription"/>
                    <Card>
                        <CardHeader title={t('lsi.patientProfile.requestsPrescription')} className="fw-bolder">
                        </CardHeader>
                        <CardBody>
                            <PatientPrescription patientRegistry={patientById}/>
                        </CardBody>
                    </Card>
                </>}

                <a name="patientHistory"/>
                <Card data-test="patientHistory">
                    <CardHeader title={t('lsi.patientProfile.history')} className="font-weight-bolder"/>
                    <CardBody>
                        <PatientHistory statusHistory={patientById ? patientById.History : []} history={history}/>
                    </CardBody>
                </Card>

                {isPatientLoxGraph && <>
                    <a name="patientLoxGraph"/>
                    <Card>
                        <CardHeader title={t('lsi.patientProfile.oxigenPrescription')} className="font-weight-bolder"/>
                        <CardBody>
                            <PatientLoxChart patientLoxChartData={patientLoxChartData}/>
                        </CardBody>
                    </Card>
                </>}

                {isPrescriptions && <>
                    <a name="prescriptions" />
                    <Card data-test="prescriptions">
                        <CardHeader title="Prescrizione" className="font-weight-bolder" />
                        <CardBody>
                            <Prescriptions prescriptions={patientById ? patientById.medicalPrescriptions : []} />
                        </CardBody>
                    </Card>
                </>}

                <a name="patientPrescriptions"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.treatments')} className="fw-bolder">
                        <CardHeaderToolbar>
                            <BtnDefault
                                className="border-0 p-2 h-auto text-capitalize"
                                onClick={goSGfeActive}
                                icon={'treatment'}
                                tintColor={configuration.theme.primary}
                                iconSize={'24px'}
                            >
                                {t('lsi.activateTreatment')}
                            </BtnDefault>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PatientPrescriptions prescriptions={patientById ? patientById.Prescriptions : []}
                                              history={history}/>
                    </CardBody>
                </Card>

                <a name="patientProvisions"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.provision')} className="fw-bolder"/>
                    <CardBody>
                        <PatientProvisionsTable provisions={patientById ? patientById.Provisions : []}
                                                history={history}/>
                    </CardBody>
                </Card>

                <a name="patientDevices"/>
                <Card data-test="patientDevices">
                    <CardHeader title={t('lsi.patientProfile.devices')} className="fw-bolder"/>
                    <CardBody>
                        <PatientDevicesTable devices={patientById ? patientById.Devices : []} history={history}/>
                    </CardBody>
                </Card>

                <a name="patientAccessories"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.accessories')} className="fw-bolder"/>
                    <CardBody>
                        <PatientAccessoriesTable accessories={patientById ? patientById.Accessories : []}/>
                    </CardBody>
                </Card>

                <a name="patientConsumable"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.consumables')} className="fw-bolder"/>
                    <CardBody>
                        <PatientConsumablesTable consumables={patientById ? patientById.Consumables : []}/>
                    </CardBody>
                </Card>

                <a name="patientMedicinals"/>
                <Card data-test="patientMedicinals">
                    <CardHeader title={t('lsi.patientProfile.medicines')} className="font-weight-bolder"/>
                    <CardBody>
                        <PatientMedicinalsTable medicinals={patientById ? patientById.Medicinals : []}/>
                    </CardBody>
                </Card>

                <a name="patientInvoices"/>
                <Card>
                    <CardHeader title={t('lsi.patientProfile.invoicedAmounts')} className="font-weight-bolder"/>
                    <CardBody>
                        {
                            patientById && patientById.patientInvoice && patientById.patientInvoice.length > 0
                                ? <PatientInvoicesTable patientInvoicesList={patientById.patientInvoice} id={id}
                                                        history={history}/>
                                : <span>{t('lsi.noDataAvailable')}</span>
                        }
                    </CardBody>
                </Card>
                <a name="patientCosts" />
                <Card>
                    <CardHeader title="Costi Paziente" className="font-weight-bolder" />
                    <CardBody>
                        {
                            patientById && patientById.patientCosts && patientById.patientCosts.length > 0
                                ? <PatientCostsTable patientInvoicesList={patientById.patientCosts} id={id}
                                                     history={history} />
                                : <span>Dati al momento non disponibili</span>
                        }
                    </CardBody>
                </Card>

            </div>
        </div>
    );
}
