import React from "react"
import BootstrapTable from "react-bootstrap-table-next";
import {
    DownloadProvisionAssetsTableColumnFormatter
} from "../../../component/table/column-formatters/ActionsColumnFormatter"
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionAssetsTable({provisionAssets, provisionNote, history, id}) {
    const {t} = useLanguageForShared()

    const columns = [
        {
            dataField: "AssetTypeDescription",
            text: t('lsi.table.description'),
        },
        {
            dataField: "AssetLink",
            text: t('lsi.table.download'),
            formatter: DownloadProvisionAssetsTableColumnFormatter,
            classes: "pe-0",
            headerClasses: "pe-3",
        },
    ];
    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                keyField="Id"
                data={provisionAssets}
                columns={columns}
                noDataIndication={t('lsi.noDataAvailable')}

            >
            </BootstrapTable>
            <div className="form-group">
                <h5 className="col-lg-1 col-xl-1 fw-bold mt-10 mb-6">{t('lsi.edit.note')}</h5>
                <div className="col-lg-12 col-xl-12">
                    <div className="input-group">
                        <textarea className="sh form-control form-control-solid" disabled readOnly rows="5"
                                  value={provisionNote}/>
                    </div>
                </div>
            </div>
        </>
    );
}