import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { modeChoose } from "../modeDataForm";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";


export function TemplateSleepRead({data}: any) {
  const {t} = useLanguageForShared();

  const structureModeChoose = Object.keys(modeChoose).reduce((acc: any, mode) => {
    return {
      ...acc, [mode]: modeChoose[mode].map((ele: string) => (ele))
    };
  }, {});

  return (
    <div className={"template-sg sleep-template row row-gap-4"}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
        <div className={"row row-gap-4"}>
          <div className="col-sm-12 col-md-12">
            <p className={"title-paragraph"}>{t('sg.prescription')}</p>
            <LabelValue label={t("prescription.template.mode.label")}
                        value={t(`prescription.template.${data?.mode}` || '-')}/>
            {structureModeChoose[data?.mode]?.map((ele: any) => <LabelValue
              label={t(`prescription.template.${ele.name}.label`)}
              value={data[ele.name] || '-'}/>)}
          </div>

          <div className="col-sm-12 col-md-12">
            <LabelValue
              label={t("prescription.template.device.label")}
              value={data?.device || '-'}/>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
        <div className={"row row-gap-4"}>
          <div className="col-sm-12 col-md-12">
            <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>

            <LabelValue label={t("prescription.template.rampa.label")}
                        value={data?.interface ? t("common.yes") : t("common.no")} className={"me-5"}/>
            {data?.interface && <>
              <LabelValue
                label={t("prescription.template.initialPress.label")}
                value={data?.initialPress || "-"} className={"me-5"}/>
              <LabelValue
                label={t("prescription.template.duration.label")}
                value={data?.duration || "-"} className={"me-5"}/>

            </>}
          </div>
          <div className="col-sm-12 col-md-12">
            <LabelValue label={t("prescription.template.interface.label")}
                        value={data?.interface ? t("common.yes") : t("common.no")} className={"me-5"}/>
            {data?.interface && <>
              <LabelValue
                label={t("prescription.template.interfaceType.label")}
                value={data?.interfaceType || "-"} className={"me-5"}/>
              <LabelValue
                label={t("prescription.template.interfaceModel.label")}
                value={data?.interfaceModel || "-"} className={"me-5"}/>
              <LabelValue
                label={t("prescription.template.interfaceSize.label")}
                value={data?.interfaceSize || "-"} className={"me-5"}/>
            </>}
          </div>
        </div>
      </div>
      <div className={"row row-gap-4"}>
        <div className="col-sm-12 col-md-12">
          <LabelValue label={t("prescription.template.note.label")}
                      value={data?.note}/>

        </div>
      </div>
    </div>
  );
}
