import { spinnerCreate } from "../spinner/spinnerActions";
import moment from "moment";
import * as requestFromServer from "./devicesCrud";
import { devicesSlice } from "./devicesSlice";

const { actions } = devicesSlice;

function getCustomerId() {
    return localStorage.getItem("customerId");
}

function getDevicesQueryParams(queryParams, isForFile, pagination, sort, isChangedSort) {
    let searchText = queryParams.filter.SearchText;
    let deliveryDate = queryParams.filter.DeliveryDate;
    let pickupDate = queryParams.filter.PickUpDate;
    let deviceType = queryParams.filter.DeviceType;
    //let deviceLot = queryParams.filter.DeviceLot;
    let deviceDistrict = queryParams.filter.DeviceDistrict;
    let customerId = getCustomerId();
    let params = 'customerId:"' + customerId + '" ';

    if (!isForFile) {
        if (isChangedSort) {
            params += 'first:50';
        } else {
            if (pagination) {
                params += 'first:' + pagination.Row + '';
                if (pagination.EndCursor) {
                    params += ' after:"' + pagination.EndCursor + '"';
                }
            } else {
                params += 'first:50';
            }
        }
    }

    if (searchText || deliveryDate || pickupDate || deviceType.length > 0 /*|| deviceLot.length > 0*/ || deviceDistrict.length > 0) {
        params += ' where:{';
        if (deliveryDate && deliveryDate !== "") {
            params += 'patientDeliveryDate_gte:"' + moment(deliveryDate.split('-')[0].trim(), "DD MMM YY").format('YYYY-MM-DD') + 'T00:00:00Z" patientDeliveryDate_lte:"' + moment(deliveryDate.split('-')[1].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }
        if (pickupDate && pickupDate !== "") {
            params += ' patientPickupDate_gte:"' + moment(pickupDate.split('-')[0].trim(), "DD MMM YY").format('YYYY-MM-DD') + 'T00:00:00Z" patientPickupDate_lte:"' + moment(pickupDate.split('-')[1].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }
        if (deviceType.length > 0) {
            deviceType.forEach((el, index) => {
                if (index === 0) {
                    params += ' deviceType_in:["' + el + '"';
                } else {
                    params += ',"' + el + '"';
                }
            });
            params += ']';
        }
        // if (deviceLot.length > 0) {
        //     deviceLot.forEach((el, index) => {
        //         if (index === 0) {
        //             params += ' lot_in:["' + el + '"';
        //         } else {
        //             params += ',"' + el + '"';
        //         }
        //     });
        //     params += ']';
        // }
        if (deviceDistrict.length > 0) {
            deviceDistrict.forEach((el, index) => {
                if (index === 0) {
                    if (el === "-") {
                        params += ' districtName_in:[' + null + '';
                    } else {
                        params += ' districtName_in:["' + el + '"';
                    }
                } else {
                    if (el === "-") {
                        params += ',' + null + '';
                    } else {
                        params += ',"' + el + '"';
                    }
                }
            });
            params += ']';
        }
        if (searchText) {
            params += ' OR:[{brand_contains:"' + searchText.toUpperCase() + '"},{model_contains:"' + searchText.toUpperCase() + '"},{serial_contains:"' + searchText.toUpperCase() + '"},{lastPatientLastName_contains:"' + searchText.toUpperCase() + '"},{lastPatientFirstName_contains:"' + searchText.toUpperCase() + '"}]';
        }

        params += '}';
    }

    if (!isForFile) {
        let field = [];

        if (sort.dataField === "DeviceType") {
            field.push("deviceType");
        } else if (sort.dataField === "BrandAndModel") {
            field.push("brand");
            field.push("model");
        } else if (sort.dataField === "Serial") {
            field.push("serial");
        } /*else if (sort.dataField === "Lot") {
            field.push("lot");
        }*/ else if (sort.dataField === "LastPatientAssigned") {
            field.push("lastPatientLastName");
            field.push("lastPatientFirstName");
        } else if (sort.dataField === "DeliveryDate") {
            field.push("patientDeliveryDate");
        } else if (sort.dataField === "PickUpDate") {
            field.push("patientPickupDate");
        }

        params += ' order_by: { ';

        field.forEach((el, index) => {
            if (index === 0) {
                params += el + ':' + sort.order.toUpperCase();
            } else {
                params += ',' + el + ':' + sort.order.toUpperCase();
            }
        });

        params += ' }';
    }
    return params;
}

export const fetchDevices = (queryParams, pagination, sort, isChangedSort) => async dispatch => {
    dispatch(spinnerCreate(true));
  
    let params = getDevicesQueryParams(queryParams, false, pagination, sort, isChangedSort);

    try {
        const response = await requestFromServer
            .findDevices(params);
        dispatch(actions.devicesFetched({ entities: response.data, holdPagination: pagination, isChangedSort: isChangedSort }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchDevicesForFile = (queryParams, callback) => async dispatch => {
    dispatch(spinnerCreate(true));
  
    let params = getDevicesQueryParams(queryParams, true);

    try {
        const response = await requestFromServer
            .findDevices(params);
        dispatch(actions.devicesForFileFetched({ entities: response.data, callback }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
};

export const fetchDevice = id => async dispatch => {
    if (!id) {
        return dispatch(actions.deviceFetched({ deviceDetail: undefined }));
    }
    dispatch(spinnerCreate(true));
    try {
        let customerId = getCustomerId();

        const response = await requestFromServer.getDeviceById(id, customerId);
        dispatch(actions.deviceFetched({ deviceDetail: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        console.error(error);
        dispatch(spinnerCreate(false));
    }
}

export const setLogBookRow = logBook => dispatch => {
    dispatch(actions.setLogBookRow({ data: logBook }));
}

export const setLastPatientAssignedRow = lastPatientAssigned => dispatch => {
    dispatch(actions.setLastPatientAssignedRow({ data: lastPatientAssigned }));
}

export const setAccessorieRow = accessorie => dispatch => {
    dispatch(actions.setAccessorieRow({ data: accessorie }));
}

export const fetchDevicesFilters = (deliveryDate, pickupDate) => async dispatch => {
    dispatch(spinnerCreate(true));
    let customerId = getCustomerId();
    let params = '(customerId:"' + customerId + '" ';
    if (deliveryDate || pickupDate) {
        if (deliveryDate) {
            params += 'deliveryDateFrom:"' + moment(deliveryDate.split('-')[0].trim(), "DD MMM YY").format('YYYY-MM-DD') + 'T00:00:00Z" deliveryDateTo:"' + moment(deliveryDate.split('-')[1].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }
        if (pickupDate) {
            params += 'pickupDateFrom:"' + moment(pickupDate.split('-')[0].trim(), "DD MMM YY").format('YYYY-MM-DD') + 'T00:00:00Z" pickupDateTo:"' + moment(pickupDate.split('-')[1].trim(), "DD MMM YY").format("YYYY-MM-DD") + 'T23:59:59Z"';
        }
    }
    params += ')';
    try {
        const response = await requestFromServer
            .findDevicesFilters(params);
        dispatch(actions.devicesFitlersFetched({ entities: response.data }));
        dispatch(spinnerCreate(false));
    }
    catch (error) {
        dispatch(spinnerCreate(false));
        console.error(error);
    }
}