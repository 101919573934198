import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { PaginationModel } from "../../models/paginationModel";
import { StaffModel } from "../../models/staffModel";

const initialStaffsState = {
    pagination: new PaginationModel(),
    staffsList: [],
    filterTitles: [],
    filterDistrict: [],
};

export const staffsSlice = createSlice({
    name: "staffs",
    initialState: initialStaffsState,
    reducers: {
        staffsFitlersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                if (entities.data.staffFilters) {
                    let titles = entities.data.staffFilters.titles;
                    let districts = entities.data.staffFilters.districts;

                    state.filterDistrict = districts.sort();
                    state.filterTitles = titles.sort();
                } else {
                    state.filterDistrict = [];
                    state.filterTitles = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        staffsFetched: (state, action) => {
            try {
                const { entities, holdPagination, isChangedSort } = action.payload;
                if (entities.data.staffs) {
                    let newStaffArray = [];
                    entities.data.staffs.nodes.forEach(el => {
                        let staffModel = new StaffModel();
                        staffModel.Id = el.id;
                        staffModel.Nominative = el.lastName + " " + el.firstName;
                        staffModel.StaffInitials = el.initials;
                        staffModel.Title = el.title;
                        staffModel.District = el.districtName;
                        staffModel.BirthDate = el.birthDate ? moment(el.birthDate).format("DD MMM YY") : "-";
                        staffModel.IdentityDocument = el.identityDocument ? el.identityDocument : "-";
                        newStaffArray.push(staffModel);
                    });

                    let pageInfo = entities.data.staffs.pageInfo;
                    let newPagination = new PaginationModel();
                    newPagination.EndCursor = pageInfo.endCursor;
                    newPagination.HasNextPage = pageInfo.hasNextPage;

                    if (isChangedSort) {
                        state.staffsList = newStaffArray;
                    } else {
                        state.staffsList = state.staffsList.length > 0 && holdPagination ? state.staffsList.concat(newStaffArray) : newStaffArray;
                    }
                    state.pagination = newPagination;
                } else {
                    state.pagination = new PaginationModel();
                    state.staffsList = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        staffsForFileFetched: (state, action) => {
            try {
                const { entities, callback } = action.payload;
                let newStaffArray = [];
                if (entities.data.staffs) {
                    entities.data.staffs.nodes.forEach(el => {
                        let staffModel = new StaffModel();
                        staffModel.Id = el.id;
                        staffModel.Nominative = el.lastName + " " + el.firstName;
                        staffModel.StaffInitials = el.initials;
                        staffModel.Title = el.title;
                        staffModel.District = el.districtName;
                        staffModel.BirthDate = el.birthDate ? moment(el.birthDate).format("DD MMM YY") : "-";
                        staffModel.IdentityDocument = el.identityDocument ? el.identityDocument : "-";
                        newStaffArray.push(staffModel);
                    });
                } 
                callback(newStaffArray);
            } catch (error) {
                console.error(error);
            }
        },
    }
});
