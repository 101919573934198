import React, {useEffect} from "react";
import {useSubheader} from "../../../../_metronic/layout";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import KTWizard from "../../../../_metronic/_assets/js/components/wizard";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-3.scss";
import {Formik, Form, Field} from "formik";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import * as actions from "../../../actions/generic/editActions";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import configuration from "../../../../shared/configuration";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function ProvisionAssetsEditHome({id}) {
    const {t} = useLanguageForShared()

    const suhbeader = useSubheader();

    const {provisionOtherInfo} = useSelector(
        (state) => ({
            provisionOtherInfo: state.provisions.provisionOtherInfo
        }),
        shallowEqual
    );

    useEffect(() => {
        suhbeader.setTitle(provisionOtherInfo ? `${t('lsi.provision.provisionInfoAlert')} - ${id}` : "");
        var options = {
            startStep: 1,
            clickableSteps: true
        }
        new KTWizard('provisionAssetsWizard', options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provisionOtherInfo]);

    const assets = provisionOtherInfo ? provisionOtherInfo.Assets : [];
    const assetsNote = provisionOtherInfo ? provisionOtherInfo.ProvisionNote : "";

    const customLabel = (props) => {
        return (
            <span>
                {props.field.value}
            </span>
        );
    }

    const dispatch = useDispatch();

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-12 col-lg-8">
                        {
                            assets && assets.length > 0
                                ? <Card>
                                    <CardBody>
                                        <div id="provisionAssetsWizard" className="wizard wizard-3"
                                             data-wizard-state="step-first" data-wizard-clickable="true">
                                            <div className="wizard-nav">
                                                <div className="wizard-steps">
                                                    <div className="wizard-step" data-wizard-type="step"
                                                         data-wizard-state="current">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.generality')}<IconSVG icon={'chevron-right'} size={'14px'}
                                                                                   tintColor={configuration.theme.primaryDark}/>

                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.note')}<IconSVG icon={'chevron-right'} size={'14px'}
                                                                             tintColor={configuration.theme.primaryDark}/>

                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                    <div className="wizard-step" data-wizard-type="step">
                                                        <div className="wizard-label">
                                                            <h3 className="wizard-title">
                                                                {t('lsi.edit.review')}<IconSVG icon={'chevron-right'} size={'14px'}
                                                                                  tintColor={configuration.theme.primaryDark}/>

                                                            </h3>
                                                            <div className="wizard-bar"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center div-custom">
                                                <div className="col-12">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={
                                                            {
                                                                ProvisionNote: assetsNote,
                                                                CustomerId: provisionOtherInfo.CustomerId,
                                                                ProvisionId: provisionOtherInfo.Id,
                                                                Reporting: provisionOtherInfo.Reporting
                                                            }
                                                        }
                                                        onSubmit={(values) => {
                                                            const model = {
                                                                GenericObject: {
                                                                    ProvisionId: values.ProvisionId,
                                                                    Reporting: values.Reporting,
                                                                    CustomerId: values.CustomerId,
                                                                    ProvisionNote: values.ProvisionNote,
                                                                    Section: "Informazioni prestazione"
                                                                }
                                                            }
                                                            dispatch(actions.editEntity(model,t));
                                                        }}
                                                    >
                                                        {({handleSubmit}) => (
                                                            <>
                                                                <Form className="form" onSubmit={handleSubmit}>
                                                                    <div className="pb-5" data-wizard-type="step-content"
                                                                         data-wizard-state="current">
                                                                        <h4 className="mb-10 fw-bold text-dark"> {t('lsi.edit.generality')}</h4>
                                                                        {
                                                                            assets.map((el, index) => {
                                                                                return (
                                                                                    <div key={"divInput" + index}
                                                                                         className="form-group row">
                                                                                        <div key={"div" + index}
                                                                                             className="col-lg-12">
                                                                                            <input key={"input" + index}
                                                                                                   type="text"
                                                                                                   className="sh form-control"
                                                                                                   disabled
                                                                                                   value={el.AssetTypeDescription}/>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <label> {t('lsi.edit.note')}</label>
                                                                                <Field
                                                                                    name="ProvisionNote"
                                                                                    component="textarea"
                                                                                    rows="5"
                                                                                    className="sh form-control"
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark"> {t('lsi.edit.alert')}</h4>
                                                                        <div className="form-group row">
                                                                            <div className="col-lg-12">
                                                                                <Field
                                                                                    name="Reporting"
                                                                                    component="textarea"
                                                                                    rows="5"
                                                                                    className="sh form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pb-5" data-wizard-type="step-content">
                                                                        <h4 className="mb-10 fw-bold text-dark"> {t('lsi.edit.reviewData')}</h4>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3"> {t('lsi.edit.generality')}</h6>
                                                                                <div
                                                                                    className="row text-dark-50 line-height-lg">
                                                                                    <div className="col-lg-12">
                                                                                        {
                                                                                            assets.map((el, index) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        key={"divRevision" + index}>
                                                                                                        <span>{el.AssetTypeDescription}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <div>
                                                                                            <Field
                                                                                                name="ProvisionNote"
                                                                                                component={customLabel}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-lg-12">
                                                                                <h6 className="fw-bolder mb-3"> {t('lsi.edit.requestEdit')}</h6>
                                                                                <div
                                                                                    className="text-dark-50 line-height-lg">
                                                                                    <div>
                                                                                        <Field
                                                                                            name="Reporting"
                                                                                            component={customLabel}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-row">
                                                                        <BtnSecondary
                                                                            onClick={() => {
                                                                                window.history.back();
                                                                            }}
                                                                        >
                                                                            {t('lsi.cancel')}
                                                                        </BtnSecondary>
                                                                        <BtnSecondary
                                                                            data-wizard-type="action-prev"
                                                                        >
                                                                            {t('lsi.prev')}
                                                                        </BtnSecondary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-submit"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            {t('lsi.notify')}
                                                                        </BtnPrimary>
                                                                        <BtnPrimary
                                                                            data-wizard-type="action-next"
                                                                        >
                                                                            {t('lsi.next')}

                                                                        </BtnPrimary>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
