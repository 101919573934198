import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/table.css";
import { DeviceLogBookActionTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { useDispatch } from "react-redux";
import {

} from "../../../../_metronic/_helpers";
import { defaultDeviceLogBookSorted } from "./DeviceInfoUIHelpers";
import { LogBookTypeColumnFormatter } from "../../../component/table/column-formatters/TypeColumnFormatter";
import { PatientLinkColumnFormatter, ProvisionLinkColumnFormatter } from "../../../component/table/column-formatters/LinkColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function DeviceLogBookTable({ deviceLogBook, history }) {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "Date",
            text: t('lsi.table.date'),
            sort: true,



            sortFunc: tableSortByDate,
        },
        {
            dataField: "Type",
            text: t('lsi.table.tipology'),
            sort: true,

            formatter: LogBookTypeColumnFormatter,
        },
        {
            dataField: "District",
            text: t('lsi.table.district'),
            sort: true,

        },
        {
            dataField: "StaffInitials",
            text: t('lsi.table.staffId'),
            sort: true,

        },
        {
            dataField: "ProvisionType",
            text: t('lsi.table.tipologyProvision'),
            sort: true,

            formatter: ProvisionLinkColumnFormatter,
        },
        {
            dataField: "PatientFullName",
            text: t('lsi.table.nominative'),
            sort: true,

            formatter: PatientLinkColumnFormatter,
        },
        {
            isDummyField: true,
            dataField: "",
            text: t('lsi.table.actions'),
            formatter: DeviceLogBookActionTableColumnFormatter,
            formatExtraData: {
                history,
                dispatch,
                title: t('lsi.sendNotification'),
            },
            classes: "pe-0",
            headerClasses: "pe-3",
        },
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={deviceLogBook}
            columns={columns}
            noDataIndication={t('lsi.noDataAvailable')}
            defaultSorted={defaultDeviceLogBookSorted}
        >
        </BootstrapTable>
    );
}
