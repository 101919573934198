import BtnDanger from '../../../../../../shared/bootstrap/button/danger/BtnDanger'
import BtnSecondary from '../../../../../../shared/bootstrap/button/secondary/BtnSecondary'
import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import configuration from "../../../../../../shared/configuration";

export default function ListFilesReadV({
                                      files,
                                      removeFileInList,
                                      setIsAddFileHandler,
                                      isAddFile,
                                  }: {
    files?: any[]
    removeFileInList: (file: any) => void
    setIsAddFileHandler: () => void
    isAddFile: boolean
}) {
    const {t} = useLanguageForShared()

    return (
        <div className="row mt-8">
            <div className="col-md-12">
                <div className="row">
                    <label className="control-label col-md-4">
                        <span className="pe-1">{t('note.newNote.filesList')}</span>
                    </label>
                    <div className="col-md-12 text-end mt-2">
                        <>
                            {files &&
                                files.map((file, i) => (
                                    <div key={i} className="d-flex align-items-center mb-2">
                                        <div className="me-auto">
                                            {file.name || file.file.name}.{file.FileType}
                                        </div>
                                        <BtnDanger
                                            className="outline"
                                            icon="trash"
                                            onClick={() => removeFileInList(file)}
                                            title={t('action.delete')}
                                            tintColor={configuration.theme.danger_color}
                                        ></BtnDanger>
                                    </div>
                                ))}
                            {!isAddFile && (
                                <BtnSecondary
                                    className="outline pull-left"
                                    onClick={setIsAddFileHandler}
                                    title={t('note.action.addFile')}
                                >
                                    {t('note.newNote.addFile')}
                                </BtnSecondary>
                            )}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
