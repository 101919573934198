import React, { useMemo, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { isEqual } from "lodash";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { LocaleDatePicker, filterRanges } from "../../../component/datepicker/DateRangePickerConfig";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import ReactBootstrapSlider from 'react-bootstrap-slider';
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as actions from "../../../actions/invoices/invoicesActions";
import {CustomMonthPicker} from "../../../component/monthpicker/CustomMonthPicker";
import moment from "moment";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
//import moment from "moment";

const prepareInvoicesFilter = (queryParams, values) => {
    const {
        filterByMonthYear
    } = values;

    const newQueryParams = { ...queryParams };

    const filter = {
        MonthYear: filterByMonthYear
    };

    newQueryParams.filter = filter;

    localStorage.setItem("invoicesSelectedFilters-...", JSON.stringify(newQueryParams));

    return newQueryParams;
};

export function InvoicesFilters() {
    const {t} = useLanguageForShared()

    const invoicesUIContext = useInvoicesUIContext();
    const invoicesUIProps = useMemo(() => {
        return {
            queryInvoiceParams: invoicesUIContext.queryInvoiceParams,
            setQueryInvoiceParams: invoicesUIContext.setQueryInvoiceParams,
        };
    }, [invoicesUIContext]);

    const dispatch = useDispatch();

    //vengono applicati i filtri al submit della form
    const applyFilter = (values) => {
        const newQueryParams = prepareInvoicesFilter(invoicesUIProps.queryInvoiceParams, values);
        if (!isEqual(newQueryParams, invoicesUIProps.queryInvoiceParams)) {
            invoicesUIProps.setQueryInvoiceParams(newQueryParams);
        }
    };

    // useEffect(() => {
    //     dispatch(actions.fetchInvoicesFilters(invoicesUIProps.queryInvoiceParams.filter.InvoiceDate));
    // }, [invoicesUIProps.queryInvoiceParams.filter.InvoiceDate]);

    //al caricamento della pagina viene lanciata la query per caricare i filtri
    useEffect(() => {
        dispatch(actions.fetchInvoicesFilters(invoicesUIProps.queryInvoiceParams.filter.MonthYear));
    }, [invoicesUIProps.queryInvoiceParams.filter.MonthYear]);

    return (
        <Formik
            initialValues={{
                filterByMonthYear: invoicesUIProps.queryInvoiceParams.filter.MonthYear
            }}
            onSubmit={(values, formikBag) => {
                //resetto i filtri nel caso di modifica della data in quanto vengono ricaricati da 0 i filtri
                if (values.filterByMonthYear !== invoicesUIProps.queryInvoiceParams.filter.MonthYear) {
                    values = {
                        filterByMonthYear: values.filterByMonthYear
                    }
                    formikBag.setValues(values);
                }
                applyFilter(values);
            }}
        >
            {({
                  values,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} className="form form-label-right filter">
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <h6 className="fw-bolder mb-3">{t('lsi.provision.monthYear')}:</h6>
                            <CustomMonthPicker
                                value={
                                    {
                                        //valori di default
                                        from: { year: +values.filterByMonthYear.split(" - ")[0].split("/")[0], month: +values.filterByMonthYear.split(" - ")[0].split("/")[1] },
                                        to: { year: +values.filterByMonthYear.split(" - ")[1].split("/")[0], month: +values.filterByMonthYear.split(" - ")[1].split("/")[1] }
                                    }
                                }
                                minYear={{ min: moment().get("year") - 5 }}
                                handleDismiss={(obj) => {
                                    //setto il valore del campo della form e faccio il submit della form
                                    setFieldValue("filterByMonthYear", obj.from.year + "/" + obj.from.month + " - " + obj.to.year + "/" + obj.to.month);
                                    handleSubmit();
                                }}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
