import React from 'react'
import {ClinicalDiaryItem} from '../models/ClinicalDiaryItemModel'
import ListNotesL from "./notes/ListNotesL";
import {DeleteFileActionType, EditNoteActionType, UploadFileActionType} from "../models/ActionModel";

const DiariesL: React.FC<{
    diaries: ClinicalDiaryItem[]
    newNote: boolean
    readonly: boolean
    setNewNote: (state: boolean) => void
    editNoteAction: EditNoteActionType
    deleteFile: DeleteFileActionType
    addNote: (data: ClinicalDiaryItem, callback: () => void) => void
    uploadFileHandler: UploadFileActionType
    downloadAction: (data: any) => void
    diaryTemplate: string
}> = ({
          diaries = [],
          newNote,
          setNewNote,
          addNote,
          deleteFile,
          editNoteAction,
          uploadFileHandler,
          downloadAction,
          readonly,
          diaryTemplate
      }) => {
    return (
        <div className="container-fluid">
            <ListNotesL
                downloadAction={downloadAction}
                deleteFile={deleteFile}
                readonly={readonly}
                addNote={addNote}
                notes={diaries}
                newNote={newNote}
                setNewNote={setNewNote}
                editNoteAction={editNoteAction}
                uploadFileHandler={uploadFileHandler}
                diaryTemplate={diaryTemplate}
            />

        </div>
    )
}

export default DiariesL
