import {t} from 'i18next'
import React from 'react'
import BtnDanger from '../../../../../../shared/bootstrap/button/danger/BtnDanger'
import BtnSecondary from "../../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import configuration from "../../../../../../shared/configuration";

export default function RowBtnEditV({
                                       uploadHandler,
                                       saveHandler,
                                       cancelHandler,
                                       isAddFile,
                                   }: {
    uploadHandler: () => void
    saveHandler: () => void
    cancelHandler: () => void
    isAddFile: boolean
}) {
    return (
        <div className={'btn-group-distance'}>
            <BtnSecondary
                className="outline"
                onClick={uploadHandler}
                disabled={isAddFile}
                title={t('note.action.uploadFile')}
                icon="upload"
                tintColor={configuration.theme.primary}
            ></BtnSecondary>
            <BtnSecondary
                className="outline"
                onClick={saveHandler}
                title={t('action.confirm')}
                icon="check"
                tintColor={configuration.theme.primary}
            ></BtnSecondary>
            <BtnDanger
                className="outline"
                onClick={cancelHandler}
                title={t('action.cancel')}
                icon="close"
                tintColor={configuration.theme.danger_color}
            ></BtnDanger>
        </div>
    )
}
