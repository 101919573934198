import React, { createContext, useContext } from "react";

const PatientInfoUIContext = createContext();

export function usePatientInfoUIContext() {
    return useContext(PatientInfoUIContext);
}

export function PatientInfoUIProvider({ patientInfoUIEvents, children }) {
    return <PatientInfoUIContext.Provider value={patientInfoUIEvents}>{children}</PatientInfoUIContext.Provider>;
}
