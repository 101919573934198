import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { defaultStaffSorted } from "../StaffsUIHelpers";
import {
    sortCaret,

} from "../../../../_metronic/_helpers";
import { useStaffsUIContext } from "../StaffsUIContext";
import * as actions from "../../../actions/staffs/staffsActions";
import { tableSortByDate } from "../../../component/table/tableSort";
import { InfiniteScrollTable } from "../../../component/table/InfiniteScrollTable";
import { ExportButton } from "../../../component/exportbutton/ExportButton";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { DownloadStaffTableColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function StaffsTable() {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();
    // Patients UI Context
    const staffsUIContext = useStaffsUIContext();
    const staffsUIProps = useMemo(() => {
        return {
            queryParams: staffsUIContext.queryParams
        };
    }, [staffsUIContext]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.staffs }),
        shallowEqual
    );

    const { staffsList, pagination } = currentState;

    const [selectedRows, setSelectedRows] = useState([]);
    const [sortTable, setSortTable] = useState(undefined);

    useEffect(() => {
        if (sortTable) {
            dispatch(actions.fetchStaffs(staffsUIProps.queryParams, null, sortTable));
        }
    }, [staffsUIProps.queryParams, dispatch]);

    const columns = [
        {
            dataField: "Nominative",
            text: t('lsi.table.nominative'),

            sort: true,

        },
        {
            dataField: "StaffInitials",
            text: t('lsi.table.initials'),
            sort: false,

            
        },
        {
            dataField: "Title",
            text: t('lsi.table.qualify'),
            sort: true,


        },
        {
            dataField: "BirthDate",
            text: t('lsi.table.birthDate'),
            sort: true,

            sortFunc: tableSortByDate,
        },
        {
            dataField: "IdentityDocument",
            text: t('lsi.table.identityDocument'),
        },
        {
            isDummyField: true,
            dataField: "Assets",
            text: "CV",
            formatter: DownloadStaffTableColumnFormatter,
            classes: "pe-0",
            headerClasses: "pe-3",
        },
    ];

    return (
        <Card className="customHeaderPadding">
            <CardHeader title="Lista Personale" className="fw-bolder">
                <CardHeaderToolbar>
                    <ExportButton
                        data={selectedRows.length > 0 ? selectedRows : []}
                        loadData={(callback) => dispatch(actions.fetchStaffsForFile(staffsUIProps.queryParams, callback))}
                        columns={columns}
                        fileName={"personale"}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <InfiniteScrollTable
                    keyField={"Id"}
                    id={"staffTable"}
                    columns={columns}
                    data={staffsList}
                    defaultSorted={defaultStaffSorted}
                    pagination={pagination}
                    onSelectCheckBox={(row, isSelect) => {
                        if (isSelect) {
                            setSelectedRows([...selectedRows, row]);
                        } else {
                            setSelectedRows(selectedRows.filter((el) => { return el.Id !== row.Id }));
                        }
                    }}
                    loadData={(event, tableInfo) => {
                        if (tableInfo) {
                            setSortTable({ dataField: tableInfo.sortField, order: tableInfo.sortOrder });
                            dispatch(actions.fetchStaffs(staffsUIProps.queryParams, pagination, { dataField: tableInfo.sortField, order: tableInfo.sortOrder }, true));
                        } else {
                            dispatch(actions.fetchStaffs(staffsUIProps.queryParams, pagination, sortTable, false));
                        }
                    }}
                    noData={t('lsi.noDataAvailable')}
                />
            </CardBody>
        </Card>
    );
}
