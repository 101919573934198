import React, { useState } from "react";
import {
    headerSortingClasses,
    sortCaret

} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientProvisionsSorted } from "./PatientInfoUIHelpers"
import { ProvisionsStateColumnFormatter } from "../../../component/table/column-formatters/StatusColumnFormatter"
import { ProvisionsTypeColumnFormatter } from "../../../component/table/column-formatters/TypeColumnFormatter"
import { tableSortByDate } from "../../../component/table/tableSort";
import "../../../assets/css/table.css";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientProvisionsTable({ provisions, history }) {
    const {t} = useLanguageForShared()

    const columns = [
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,

        },
        {
            dataField: "ProvisionType",
            text: t('lsi.table.tipologyProvision'),
            sort: true,


            formatter: ProvisionsTypeColumnFormatter,

        },
        {
            dataField: "orderNumber",
            text: t('lsi.table.orderNumber'),
            sort: true,
        },
        {
            dataField: "DocumentNumber",
            text: t('lsi.table.documentNumberProvision'),
            sort: true,
        },
        /*{
            dataField: "District",
            text: "Distretto Sanitario",
            sort: true,
        },*/
        {
            dataField: "ProvisionDate",
            text: t('lsi.table.date'),
            sort: true,
            sortFunc: tableSortByDate,
        },
        {
            dataField: "StaffInitials",
            text: t('lsi.table.staffId'),
            sort: true,
        },
        {
            dataField: "ProvisionState",
            text: t('lsi.table.status'),
            sort: true,
            formatter: ProvisionsStateColumnFormatter,
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            history.push(`/prestazioni/${row.Id}/dettaglio`)
        }
    }

    const [count, setCount] = useState(20);

    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table-head-custom table-hover table-vertical-center"
                bootstrap4
                keyField="Id"
                data={(provisions || []).slice(0, count)}
                columns={columns}
                defaultSorted={defaultPatientProvisionsSorted}
                noDataIndication={t('lsi.noDataAvailable')}
                selectRow={selectRow}
            >
            </BootstrapTable>
            {
                provisions.length > 20 && count <= 20 &&
                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setCount(60)}>{t('lsi.more')}</div>
            }
        </>
    );
}
