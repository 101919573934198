import React from "react";
import { CostsUIProvider } from "../CostsUIContext";
import { CostsHome } from "./CostsHome";

export function CostsPage({ history }) {
    return (
        <CostsUIProvider>
            <CostsHome history={history} />
        </CostsUIProvider>
    );
}
