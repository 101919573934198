import { t } from 'i18next'
import FormGroupCustom from "../../../../../shared/bootstrap/form/FormGroupCustom";
import {ValueName} from "../../../../../shared/utils/forms/models/DataForm";
import {FormElements} from "../../../../../shared/bootstrap/form/FormElements";


export default function FooterManageV({
  operator,
  lastUpdate,
  isEditMode,
  formData,
  dataForm,
  changeValue,
  errors,
}: {
  operator: string
  lastUpdate: string
  isEditMode: boolean
  formData: any
  dataForm: any
  changeValue: ({ value, name }: ValueName) => void
  errors: any
}) {
  return (
    <>
      {isEditMode ? (
        <div className="w-100">
          <FormElements
              structure={formData}
              dataForm={dataForm}
              errors={errors}
              changeValue={changeValue}
          />
        </div>
      ) : (
        <>
          <span>
            <b>{operator}</b>
          </span>
          <div>
            <small className="pe-1">{t('note.edited')}</small>
            <small>{lastUpdate}</small>
          </div>
        </>
      )}
    </>
  )
}
