import {classByArray} from '../../../functions/function'
import BtnDefault from '../default/BtnDefault'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode
    icon?: string
    iconSize?: string
    iconRight?: string
    className?: string,
    variant?: "danger" | "danger-outline"
    tintColor?: string

}

function BtnDanger({
                       children, className, variant = "danger", tintColor,
                       ...rest
                   }: ButtonProps) {
    const classList = classByArray(['btn-danger', className ? className : ''])

    return (
        <BtnDefault variant={variant} className={classList} tintColor={tintColor} {...rest} >
            {children}
        </BtnDefault>
    )
}

export default BtnDanger
