import React from "react";
import { StaffsUIProvider } from "../StaffsUIContext";
import { StaffsHome } from "./StaffsHome";

export function StaffsPage() {

  return (
    <StaffsUIProvider>
      <StaffsHome />
    </StaffsUIProvider>
  );
}
