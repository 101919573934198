import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../assets/css/table.css";
import {
    PatientPrescriptionsActionTableColumnFormatter
} from "../../../component/table/column-formatters/ActionsColumnFormatter";
import {useDispatch} from "react-redux";
import {
    headerSortingClasses,
    sortCaret

} from "../../../../_metronic/_helpers";
import {defaultPatientPrescriptionsSorted} from "./PatientInfoUIHelpers";
import {tableSortByDate} from "../../../component/table/tableSort";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";


export function PatientPrescriptions({prescriptions, history}) {
    const {t} = useLanguageForShared()

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "PatientId",
            text: t('lsi.table.treatmentId'),
            sort: true,

        },
        {
            dataField: "Code",
            text: t('lsi.table.completeId'),
            sort: true,

        },
        {
            dataField: "Description",
            text: t('lsi.table.description'),
            sort: false,

        },
        {
            dataField: "StartDate",
            text: t('lsi.table.startDate'),
            sort: true,

            sortFunc: tableSortByDate,
        },
        {
            dataField: "EndDate",
            text: t('lsi.table.endDate'),
            sort: true,

            sortFunc: tableSortByDate,
        },
        {
            isDummyField: true,
            dataField: "",
            text: t('lsi.table.actions'),
            formatter: PatientPrescriptionsActionTableColumnFormatter,
            formatExtraData: {
                history,
                dispatch,
            },
            classes: "pe-0",
            headerClasses: "pe-3",
        },
    ];

    return (
        <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table-head-custom table-vertical-center"
            bootstrap4
            keyField="Id"
            data={prescriptions}
            columns={columns}
            noDataIndication={t('lsi.noDataAvailable')}
            defaultSorted={defaultPatientPrescriptionsSorted}
        >
        </BootstrapTable>
    );
}
