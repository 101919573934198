import React, { useState } from "react";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { defaultPatientConsumablesSorted } from "./PatientInfoUIHelpers"
import { AssetsColumnFormatter, /*FotoColumnFormatter,*/ ConsumablesFotoColumnFormatter } from "../../../component/table/column-formatters/ActionsColumnFormatter";
import { tableSortByDate } from "../../../component/table/tableSort";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function PatientConsumablesTable({ consumables }) {
    const {t} = useLanguageForShared()

    const columns = [
/*        {
            isDummyField: true,
            dataField: "Photo",
            text: "",
            //formatter: FotoColumnFormatter,
            formatter: ConsumablesFotoColumnFormatter, // soluzione momentanea in quanto a volte arrivano foto 'rotte' dal db appena sistemato rimettere quello sopra
        },*/
        {
            dataField: "PatientId",
            text: t('lsi.table.patientId'),
            sort: true,

        },
        {
            dataField: "ConsumableType",
            text: t('lsi.table.tipology'),
            sort: true,

        },
        // {
        //     dataField: "BrandAndModel",
        //     text: "Marca e Modello",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     ,
        // },
        {
            dataField: "Description",
            text: t('lsi.table.description'),
            sort: false,
        },
        {
            dataField: "Serial",
            text: t('lsi.table.itemNumber'),
            sort: false,
        },
        {
            dataField: "LotNumber",
            text: t('lsi.table.lotNumber'),
            sort: false,
        },
        {
            dataField: "Quantity",
            text: t('lsi.table.quantity'),
            sort: true,

        },
        {
            dataField: "DeliveryDate",
            text: t('lsi.table.deliveryDate'),
            sort: true,
            sortFunc: tableSortByDate,

        },
        /*{
            isDummyField: true,
            dataField: "Assets",
            text: "Documentazione",
            formatter: AssetsColumnFormatter,
        },*/
    ];

    const [count, setCount] = useState(20);

    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                keyField="Serial"
                data={(consumables || []).slice(0, count)}
                columns={columns}
                defaultSorted={defaultPatientConsumablesSorted}
                noDataIndication={t('lsi.noDataAvailable')}
            >
            </BootstrapTable>
            {
                consumables.length > 20 && count <= 20 &&
                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setCount(60)}>{t('lsi.more')}</div>
            }
        </>
    );
}
