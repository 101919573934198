import {useState} from "react";
import {ClinicalDiaryItem} from "../models/ClinicalDiaryItemModel";
import {FiltersDataModel} from "../models/FiltersDataModel";
import moment from "moment";
import {isOldestDate, isSmallestDate} from "../../../shared/utils/dateUtils";
import httpData from "../../../shared/utils/httpData";

export const useDiariesFilter = (diaries: ClinicalDiaryItem[]) => {
    let initValue;

    /*    if (localStorage.getItem(`date-patient-${httpData.other.patientId}`)) {
            const dateToken = JSON.parse(localStorage.getItem(`date-patient-${httpData.other.patientId}`) || '');
            initValue = {startDate: moment(dateToken.start), endDate: moment(dateToken.end)}
        } else {
        }*/
    initValue = {startDate: moment().subtract('month', 6), endDate: moment()}

    const [filters, setFilters] = useState<FiltersDataModel>({
        startDate: moment(initValue.startDate).startOf('day'),
        endDate: moment(initValue.endDate).endOf('day'),
        deleteView: false
    })
    const searchLowerCase = (base: string, search: string) => {
        return base?.toLowerCase()?.trim()?.includes(search?.toLowerCase()?.trim())
    }

    const search = (data: ClinicalDiaryItem, str: string) => {
        return searchLowerCase(data.SubjectLabel, str) || searchLowerCase(data?.Note, str) || searchLowerCase(data?.Operator, str)
    }
    const diariesFilter = diaries.filter(ele => ele.Deleted === (filters.deleteView)).filter(ele => {
        return (!filters.search || search(ele, filters.search)) &&
            (isOldestDate(ele?.Date, filters.startDate) && isSmallestDate(ele?.Date, filters.endDate))
    })
    const orderDiaries = diariesFilter.sort((a: any, b: any) => {
        var aName = new Date(a?.Date).getTime();
        var bName = new Date(b?.Date).getTime();
        if (filters.orderBy == "older")
            return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
        else
            return ((aName < bName) ? 1 : ((aName > bName) ? -1 : 0));
    });
    return {
        filters,
        setFilters,
        diariesFilter: orderDiaries
    }
}
